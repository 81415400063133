const stopData = [
  {
    "ID": "3700998-1",
    "SiriID": 20,
    "Lat": 5899429,
    "Lng": 2282293,
    "Stops": "",
    "Name": "Kärdla lennujaam",
    "Info": "",
    "Street": "",
    "Area": "Hiiu MV",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700001-1",
    "SiriID": 28202,
    "Lat": 5905369,
    "Lng": 2388874,
    "Stops": "5700002-1,5700367-1",
    "Name": "Aasa",
    "Info": 5,
    "Street": "",
    "Area": "Lääne MV",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700002-1",
    "SiriID": 28203,
    "Lat": 5905354,
    "Lng": 2388871,
    "Stops": "5700001-1,5700116-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700012-1",
    "SiriID": 20011,
    "Lat": 5900567,
    "Lng": 2377046,
    "Stops": "5700013-1,5700070-1",
    "Name": "Auaste",
    "Info": 6,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700013-1",
    "SiriID": 20012,
    "Lat": 5900550,
    "Lng": 2377008,
    "Stops": "5700012-1,5700206-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700032-1",
    "SiriID": 21945,
    "Lat": 5893842,
    "Lng": 2353317,
    "Stops": "5700065-1",
    "Name": "Haapsalu bussijaam",
    "Info": 7,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700047-1",
    "SiriID": 20611,
    "Lat": 5894906,
    "Lng": 2363711,
    "Stops": "5700048-1,5700375-1",
    "Name": "Herjava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700048-1",
    "SiriID": 20612,
    "Lat": 5894905,
    "Lng": 2364006,
    "Stops": "5700047-1,5700321-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700064-1",
    "SiriID": 30199,
    "Lat": 5898262,
    "Lng": 2397931,
    "Stops": "5700337-1",
    "Name": "Jaakna",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700070-1",
    "SiriID": 29173,
    "Lat": 5901602,
    "Lng": 2379175,
    "Stops": "5700069-1,5700012-1",
    "Name": "Jalukse",
    "Info": 6,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700069-1",
    "SiriID": 29174,
    "Lat": 5901608,
    "Lng": 2379227,
    "Stops": "5700070-1,5700115-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700116-1",
    "SiriID": 29133,
    "Lat": 5903236,
    "Lng": 2382055,
    "Stops": "5700115-1,5700002-1",
    "Name": "Keedika",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700115-1",
    "SiriID": 29134,
    "Lat": 5903205,
    "Lng": 2382039,
    "Stops": "5700116-1,5700069-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700165-1",
    "SiriID": 24024,
    "Lat": 5909657,
    "Lng": 2400038,
    "Stops": "5700164-1,5700212-1",
    "Name": "Kuijõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700164-1",
    "SiriID": 24025,
    "Lat": 5909643,
    "Lng": 2399992,
    "Stops": "5700165-1,5700167-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700168-1",
    "SiriID": 20008,
    "Lat": 5908866,
    "Lng": 2397963,
    "Stops": "5700167-1,5700457-1",
    "Name": "Kuijõe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700167-1",
    "SiriID": 20009,
    "Lat": 5908858,
    "Lng": 2397998,
    "Stops": "5700168-1,5700164-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5701054-1",
    "SiriID": 3294,
    "Lat": 5922155,
    "Lng": 2367992,
    "Stops": "5701055-1,5700265-1",
    "Name": "Laimi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5701055-1",
    "SiriID": 4545,
    "Lat": 5922143,
    "Lng": 2367969,
    "Stops": "5701054-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700205-1",
    "SiriID": 26522,
    "Lat": 5899446,
    "Lng": 2370943,
    "Stops": "5700206-1,5700350-1",
    "Name": "Linnamäe",
    "Info": 6,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700206-1",
    "SiriID": 29559,
    "Lat": 5899446,
    "Lng": 2371014,
    "Stops": "5700205-1,5700013-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700212-1",
    "SiriID": 28659,
    "Lat": 5912592,
    "Lng": 2406606,
    "Stops": "5700211-1,22825-1,5700165-1",
    "Name": "Metsavahi",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700211-1",
    "SiriID": 28660,
    "Lat": 5912589,
    "Lng": 2406634,
    "Stops": "5700212-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700250-1",
    "SiriID": 31986,
    "Lat": 5893928,
    "Lng": 2354483,
    "Stops": "5700251-1,5700066-1",
    "Name": "Niine",
    "Info": 7,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700251-1",
    "SiriID": 31987,
    "Lat": 5893880,
    "Lng": 2354614,
    "Stops": "5700250-1,5700411-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700265-1",
    "SiriID": 9495,
    "Lat": 5922309,
    "Lng": 2368909,
    "Stops": "5700266-1,5701054-1",
    "Name": "Nõva",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700266-1",
    "SiriID": 9496,
    "Lat": 5922284,
    "Lng": 2368963,
    "Stops": "5700265-1,5700454-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700440-1",
    "SiriID": 32207,
    "Lat": 5893705,
    "Lng": 2356544,
    "Stops": "5700439-1,5700316-1",
    "Name": "Randsalu",
    "Info": 7,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700439-1",
    "SiriID": 32208,
    "Lat": 5893718,
    "Lng": 2356551,
    "Stops": "5700440-1,5700018-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700320-1",
    "SiriID": 25291,
    "Lat": 5895713,
    "Lng": 2366726,
    "Stops": "5700321-1,5700360-1",
    "Name": "Rannaküla",
    "Info": 6,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700321-1",
    "SiriID": 25292,
    "Lat": 5895644,
    "Lng": 2366524,
    "Stops": "5700320-1,5700048-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700018-1",
    "SiriID": 32209,
    "Lat": 5893738,
    "Lng": 2357017,
    "Stops": "5700019-1,5700439-1",
    "Name": "Rannarootsi",
    "Info": 7,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700019-1",
    "SiriID": 32210,
    "Lat": 5893758,
    "Lng": 2357211,
    "Stops": "5700018-1,5700444-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700351-1",
    "SiriID": 28136,
    "Lat": 5898995,
    "Lng": 2369825,
    "Stops": "5700350-1,5700361-1",
    "Name": "Räägu",
    "Info": 6,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700350-1",
    "SiriID": 29481,
    "Lat": 5899078,
    "Lng": 2370001,
    "Stops": "5700351-1,5700205-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700361-1",
    "SiriID": 29171,
    "Lat": 5897675,
    "Lng": 2368014,
    "Stops": "5700360-1,5700351-1",
    "Name": "Saunja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700360-1",
    "SiriID": 29172,
    "Lat": 5897658,
    "Lng": 2367961,
    "Stops": "5700361-1,5700320-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700367-1",
    "SiriID": 24518,
    "Lat": 5906440,
    "Lng": 2391330,
    "Stops": "5700368-1,5700001-1",
    "Name": "Seljaküla",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700368-1",
    "SiriID": 24519,
    "Lat": 5906490,
    "Lng": 2391326,
    "Stops": "5700367-1,5700457-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700376-1",
    "SiriID": 33039,
    "Lat": 5894698,
    "Lng": 2360586,
    "Stops": "5700375-1,5700518-1",
    "Name": "Silma",
    "Info": 7,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700375-1",
    "SiriID": 33040,
    "Lat": 5894695,
    "Lng": 2360631,
    "Stops": "5700376-1,5700047-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700411-1",
    "SiriID": 32146,
    "Lat": 5893798,
    "Lng": 2355295,
    "Stops": "5700412-1,5700251-1",
    "Name": "Tamme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700412-1",
    "SiriID": 32147,
    "Lat": 5893806,
    "Lng": 2355340,
    "Stops": "5700411-1,5700317-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700065-1",
    "SiriID": 31988,
    "Lat": 5893940,
    "Lng": 2353625,
    "Stops": "5700066-1,5700032-1",
    "Name": "Turg",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700066-1",
    "SiriID": 32004,
    "Lat": 5893932,
    "Lng": 2353705,
    "Stops": "5700065-1,5700250-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700443-1",
    "SiriID": 31960,
    "Lat": 5894008,
    "Lng": 2357967,
    "Stops": "5700444-1,5700518-1",
    "Name": "Uuemõisa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700444-1",
    "SiriID": 32211,
    "Lat": 5893943,
    "Lng": 2357715,
    "Stops": "5700443-1,5700019-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700317-1",
    "SiriID": 32205,
    "Lat": 5893759,
    "Lng": 2355776,
    "Stops": "5700316-1,5700412-1",
    "Name": "Vahtra rist",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700316-1",
    "SiriID": 32206,
    "Lat": 5893763,
    "Lng": 2355821,
    "Stops": "5700317-1,5700440-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700453-1",
    "SiriID": 9493,
    "Lat": 5921335,
    "Lng": 2371257,
    "Stops": "5700454-1,22824-1",
    "Name": "Vaisi",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700454-1",
    "SiriID": 9494,
    "Lat": 5921334,
    "Lng": 2371180,
    "Stops": "5700453-1,5700266-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700458-1",
    "SiriID": 20006,
    "Lat": 5908542,
    "Lng": 2393031,
    "Stops": "5700457-1",
    "Name": "Valgeristi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700457-1",
    "SiriID": 20007,
    "Lat": 5908531,
    "Lng": 2393042,
    "Stops": "5700458-1,5700368-1,5700168-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700338-1",
    "SiriID": 25469,
    "Lat": 5899870,
    "Lng": 2405879,
    "Stops": "5700337-1",
    "Name": "Risti",
    "Info": "",
    "Street": "Tallinna mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700337-1",
    "SiriID": 25470,
    "Lat": 5899850,
    "Lng": 2405886,
    "Stops": "5700338-1,22723-1,5700064-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5700518-1",
    "SiriID": 22,
    "Lat": 5894159,
    "Lng": 2358367,
    "Stops": "5700443-1,5700376-1",
    "Name": "Mõisa",
    "Info": 7,
    "Street": "Ääsmäe - Haapsalu - Rohuküla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900019-1",
    "SiriID": 26893,
    "Lat": 5958299,
    "Lng": 2610998,
    "Stops": "5900018-1,5900510-1",
    "Name": "Altja",
    "Info": 0,
    "Street": 0,
    "Area": "Lääne-Viru MV",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900018-1",
    "SiriID": 30461,
    "Lat": 5958304,
    "Lng": 2610998,
    "Stops": "5900019-1,5900921-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5901176-1",
    "SiriID": 6052,
    "Lat": 5957479,
    "Lng": 2595053,
    "Stops": "5901177-1,5900366-1",
    "Name": "Eha",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5901177-1",
    "SiriID": 6053,
    "Lat": 5957463,
    "Lng": 2595166,
    "Stops": "5901176-1,5900990-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900069-1",
    "SiriID": 24593,
    "Lat": 5956698,
    "Lng": 2629953,
    "Stops": "5900068-1,5900876-1",
    "Name": "Eisma",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900068-1",
    "SiriID": 6041,
    "Lat": 5956700,
    "Lng": 2629968,
    "Stops": "5900069-1,5900665-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900072-1",
    "SiriID": 30125,
    "Lat": 5956391,
    "Lng": 2586621,
    "Stops": "5900073-1,5900282-1",
    "Name": "Eru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900073-1",
    "SiriID": 6032,
    "Lat": 5956398,
    "Lng": 2586604,
    "Stops": "5900072-1,5900779-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900085-1",
    "SiriID": 30129,
    "Lat": 5942204,
    "Lng": 2627574,
    "Stops": "",
    "Name": "Haljala tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900200-1",
    "SiriID": 25045,
    "Lat": 5954357,
    "Lng": 2640704,
    "Stops": "5900201-1",
    "Name": "Karepa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900201-1",
    "SiriID": 6040,
    "Lat": 5954362,
    "Lng": 2640702,
    "Stops": "5900200-1,5900664-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900262-1",
    "SiriID": 32168,
    "Lat": 5955937,
    "Lng": 2599072,
    "Stops": "5900260-1,5900926-1",
    "Name": "Koljaku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900260-1",
    "SiriID": 32169,
    "Lat": 5955916,
    "Lng": 2599106,
    "Stops": "5900262-1,5900495-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900267-1",
    "SiriID": 32171,
    "Lat": 5961619,
    "Lng": 2601899,
    "Stops": "5900268-1,5900341-1",
    "Name": "Koolimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900268-1",
    "SiriID": 6025,
    "Lat": 5961628,
    "Lng": 2601904,
    "Stops": "5900267-1,5900553-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900283-1",
    "SiriID": 24952,
    "Lat": 5956715,
    "Lng": 2588992,
    "Stops": "5900282-1",
    "Name": "Korjuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900282-1",
    "SiriID": 6031,
    "Lat": 5956722,
    "Lng": 2588986,
    "Stops": "5900283-1,5900072-1,5900335-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900303-1",
    "SiriID": 32190,
    "Lat": 5954480,
    "Lng": 2621326,
    "Stops": "5900302-1,5901034-1,5900537-1",
    "Name": "Kulli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900302-1",
    "SiriID": 32191,
    "Lat": 5954455,
    "Lng": 2621358,
    "Stops": "5900303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900333-1",
    "SiriID": 25206,
    "Lat": 5960121,
    "Lng": 2591901,
    "Stops": "5900334-1",
    "Name": "Käsmu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900335-1",
    "SiriID": 24780,
    "Lat": 5958900,
    "Lng": 2590814,
    "Stops": "5900334-1,5900282-1",
    "Name": "Käsmu järv",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900334-1",
    "SiriID": 6030,
    "Lat": 5958896,
    "Lng": 2590833,
    "Stops": "5900335-1,5900333-1,5900365-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900341-1",
    "SiriID": 32246,
    "Lat": 5960862,
    "Lng": 2599596,
    "Stops": "5900340-1,5900267-1,5900925-1",
    "Name": "Lahe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900340-1",
    "SiriID": 6026,
    "Lat": 5960866,
    "Lng": 2599586,
    "Stops": "5900341-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900359-1",
    "SiriID": 32257,
    "Lat": 5954355,
    "Lng": 2611152,
    "Stops": "5900358-1,5900683-1,5900510-1",
    "Name": "Lauli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900358-1",
    "SiriID": 6034,
    "Lat": 5954349,
    "Lng": 2611165,
    "Stops": "5900359-1,5900732-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900366-1",
    "SiriID": 32260,
    "Lat": 5957554,
    "Lng": 2593692,
    "Stops": "5900365-1,5901176-1",
    "Name": "Lepispea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900365-1",
    "SiriID": 6029,
    "Lat": 5957561,
    "Lng": 2593691,
    "Stops": "5900366-1,5900334-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900495-1",
    "SiriID": 32435,
    "Lat": 5954134,
    "Lng": 2603659,
    "Stops": "5900496-1,5900260-1",
    "Name": "Nõmme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900496-1",
    "SiriID": 6033,
    "Lat": 5954124,
    "Lng": 2603661,
    "Stops": "5900495-1,5900684-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900510-1",
    "SiriID": 32524,
    "Lat": 5956624,
    "Lng": 2610140,
    "Stops": "5900019-1,5900359-1",
    "Name": "Oandu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5901125-1",
    "SiriID": 6050,
    "Lat": 5954417,
    "Lng": 2617761,
    "Stops": "5901145-1,5900936-1",
    "Name": "Paisjärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5901145-1",
    "SiriID": 6051,
    "Lat": 5954444,
    "Lng": 2617681,
    "Stops": "5901125-1,5900731-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900538-1",
    "SiriID": 32557,
    "Lat": 5955724,
    "Lng": 2623992,
    "Stops": "5900537-1,5900877-1",
    "Name": "Pajuveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900537-1",
    "SiriID": 6037,
    "Lat": 5955723,
    "Lng": 2623974,
    "Stops": "5900538-1,5900303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900552-1",
    "SiriID": 32564,
    "Lat": 5961485,
    "Lng": 2603986,
    "Stops": "5900553-1,5900559-1",
    "Name": "Pedassaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900553-1",
    "SiriID": 6024,
    "Lat": 5961498,
    "Lng": 2603980,
    "Stops": "5900552-1,5900268-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900559-1",
    "SiriID": 32567,
    "Lat": 5960330,
    "Lng": 2606424,
    "Stops": "5900560-1,5900552-1",
    "Name": "Pihlaspea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900560-1",
    "SiriID": 6023,
    "Lat": 5960335,
    "Lng": 2606442,
    "Stops": "5900559-1,5900922-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900582-1",
    "SiriID": 32577,
    "Lat": 5918340,
    "Lng": 2619396,
    "Stops": "5900583-1",
    "Name": "Porkuni kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900583-1",
    "SiriID": 5804,
    "Lat": 5918324,
    "Lng": 2619389,
    "Stops": "5900582-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900651-1",
    "SiriID": 31916,
    "Lat": 5917306,
    "Lng": 2660059,
    "Stops": "5900652-1",
    "Name": "Roela mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900652-1",
    "SiriID": 31917,
    "Lat": 5917291,
    "Lng": 2660032,
    "Stops": "5900651-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900664-1",
    "SiriID": 32648,
    "Lat": 5954945,
    "Lng": 2637856,
    "Stops": "5900665-1,5900201-1",
    "Name": "Rutja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900665-1",
    "SiriID": 6039,
    "Lat": 5954936,
    "Lng": 2637846,
    "Stops": "5900664-1,5900068-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900685-1",
    "SiriID": 26554,
    "Lat": 5953502,
    "Lng": 2604978,
    "Stops": "5900684-1,5900682-1",
    "Name": "Sagadi küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900684-1",
    "SiriID": 32658,
    "Lat": 5953532,
    "Lng": 2604957,
    "Stops": "5900685-1,5900496-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900683-1",
    "SiriID": 30306,
    "Lat": 5953849,
    "Lng": 2608205,
    "Stops": "5900682-1,5900359-1",
    "Name": "Sagadi mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900682-1",
    "SiriID": 6038,
    "Lat": 5953842,
    "Lng": 2608200,
    "Stops": "5900683-1,5900685-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900731-1",
    "SiriID": 32701,
    "Lat": 5954218,
    "Lng": 2615217,
    "Stops": "5900732-1,5901145-1",
    "Name": "Sikkani",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900732-1",
    "SiriID": 32702,
    "Lat": 5954235,
    "Lng": 2615172,
    "Stops": "5900731-1,5900358-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5990021-1",
    "SiriID": 34046,
    "Lat": 5926549,
    "Lng": 2596116,
    "Stops": "",
    "Name": "Tapa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5901034-1",
    "SiriID": 6049,
    "Lat": 5954372,
    "Lng": 2619628,
    "Stops": "5900936-1,5900303-1",
    "Name": "Tiigi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900877-1",
    "SiriID": 26566,
    "Lat": 5957682,
    "Lng": 2627171,
    "Stops": "5900876-1,5900538-1",
    "Name": "Vainupea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900876-1",
    "SiriID": 6042,
    "Lat": 5957690,
    "Lng": 2627203,
    "Stops": "5900877-1,5900069-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900908-1",
    "SiriID": 4374,
    "Lat": 5953793,
    "Lng": 2581306,
    "Stops": "33803-1",
    "Name": "Vatku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900921-1",
    "SiriID": 26567,
    "Lat": 5959901,
    "Lng": 2608307,
    "Stops": "5900922-1,5900018-1",
    "Name": "Vergi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900922-1",
    "SiriID": 6022,
    "Lat": 5959898,
    "Lng": 2608290,
    "Stops": "5900921-1,5900560-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900926-1",
    "SiriID": 32591,
    "Lat": 5957641,
    "Lng": 2597017,
    "Stops": "5900925-1,5900990-1,5900262-1",
    "Name": "Vergi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900925-1",
    "SiriID": 6027,
    "Lat": 5957670,
    "Lng": 2597047,
    "Stops": "5900926-1,5900341-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900936-1",
    "SiriID": 32595,
    "Lat": 5954862,
    "Lng": 2618544,
    "Stops": "5901125-1,5901034-1",
    "Name": "Vihula",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900990-1",
    "SiriID": 26582,
    "Lat": 5957620,
    "Lng": 2596038,
    "Stops": "5900989-1,5901177-1,5900926-1",
    "Name": "Võsu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900989-1",
    "SiriID": 6028,
    "Lat": 5957630,
    "Lng": 2596034,
    "Stops": "5900990-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000717-1",
    "SiriID": 5675,
    "Lat": 5915480,
    "Lng": 2471657,
    "Stops": "7000718-1,7000440-1",
    "Name": "Aandu tinglik",
    "Info": "",
    "Street": "",
    "Area": "Rapla MV",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000718-1",
    "SiriID": 5676,
    "Lat": 5915480,
    "Lng": 2471703,
    "Stops": "7000717-1,7000190-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000002-1",
    "SiriID": 21810,
    "Lat": 5911746,
    "Lng": 2462675,
    "Stops": "7000001-1,7000478-1",
    "Name": "Adila",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000001-1",
    "SiriID": 28160,
    "Lat": 5911743,
    "Lng": 2462661,
    "Stops": "7000002-1,7000701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000008-1",
    "SiriID": 9970,
    "Lat": 5920773,
    "Lng": 2465694,
    "Stops": "7000007-1,7000383-1",
    "Name": "Aespa",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000007-1",
    "SiriID": 14583,
    "Lat": 5920765,
    "Lng": 2465691,
    "Stops": "7000008-1,7000050-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000017-1",
    "SiriID": 22486,
    "Lat": 5915758,
    "Lng": 2485175,
    "Stops": "7000018-1,7000336-1",
    "Name": "Alesti",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000018-1",
    "SiriID": 22487,
    "Lat": 5915771,
    "Lng": 2485185,
    "Stops": "7000017-1,7000028-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000028-1",
    "SiriID": 22488,
    "Lat": 5917447,
    "Lng": 2484469,
    "Stops": "7000027-1,7000018-1",
    "Name": "Angerja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000027-1",
    "SiriID": 22489,
    "Lat": 5917475,
    "Lng": 2484489,
    "Stops": "7000028-1,7000025-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000026-1",
    "SiriID": 5356,
    "Lat": 5917587,
    "Lng": 2484440,
    "Stops": "7000025-1,7000393-1",
    "Name": "Angerja tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000025-1",
    "SiriID": 5357,
    "Lat": 5917576,
    "Lng": 2484435,
    "Stops": "7000026-1,7000027-1,7000258-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000050-1",
    "SiriID": 14056,
    "Lat": 5920568,
    "Lng": 2463658,
    "Stops": "7000704-1,7000700-1,7000007-1",
    "Name": "Entusiast",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000049-1",
    "SiriID": 10053,
    "Lat": 5915940,
    "Lng": 2464953,
    "Stops": "7000722-1,7000510-1,7000207-1",
    "Name": "Hageri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000722-1",
    "SiriID": 5662,
    "Lat": 5915894,
    "Lng": 2465768,
    "Stops": "7000049-1,7000051-1,7000142-1",
    "Name": "Hageri kool tinglik",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000051-1",
    "SiriID": 25965,
    "Lat": 5916192,
    "Lng": 2466928,
    "Stops": "7000052-1,7000722-1",
    "Name": "Hageri küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000052-1",
    "SiriID": 25966,
    "Lat": 5916194,
    "Lng": 2466997,
    "Stops": "7000051-1,7000441-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000072-1",
    "SiriID": 14052,
    "Lat": 5921655,
    "Lng": 2466445,
    "Stops": "7000205-1,23836-1,7000384-1",
    "Name": "Ikaruse",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000656-1",
    "SiriID": 5184,
    "Lat": 5919310,
    "Lng": 2470291,
    "Stops": "7000657-1,7000723-1,7000726-1",
    "Name": "Jaagumardi",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000657-1",
    "SiriID": 5197,
    "Lat": 5919261,
    "Lng": 2470391,
    "Stops": "7000656-1,7000441-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000089-1",
    "SiriID": 23404,
    "Lat": 5911614,
    "Lng": 2496878,
    "Stops": "7000090-1,7000556-1",
    "Name": "Jaluse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000090-1",
    "SiriID": 28994,
    "Lat": 5911601,
    "Lng": 2496875,
    "Stops": "7000089-1,7000325-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000098-1",
    "SiriID": 23907,
    "Lat": 5913636,
    "Lng": 2492520,
    "Stops": "7000101-1",
    "Name": "Järlepa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000100-1",
    "SiriID": 23327,
    "Lat": 5912717,
    "Lng": 2491028,
    "Stops": "7000101-1,7000557-1",
    "Name": "Järlepa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000101-1",
    "SiriID": 5591,
    "Lat": 5912741,
    "Lng": 2491018,
    "Stops": "7000100-1,7000734-1,7000098-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000712-1",
    "SiriID": 5670,
    "Lat": 5913615,
    "Lng": 2475400,
    "Stops": "7000297-1,7000537-1,7000729-1",
    "Name": "Kadaka tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000716-1",
    "SiriID": 5684,
    "Lat": 5920946,
    "Lng": 2471229,
    "Stops": "23842-1,7000726-1,7000721-1",
    "Name": "Kallaste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000137-1",
    "SiriID": 20698,
    "Lat": 5899156,
    "Lng": 2446644,
    "Stops": "7000580-1,7000250-1",
    "Name": "Kangermaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000700-1",
    "SiriID": 5709,
    "Lat": 5919977,
    "Lng": 2463074,
    "Stops": "7000050-1,47319-1,7000709-1",
    "Name": "Karmeni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000142-1",
    "SiriID": 22730,
    "Lat": 5912801,
    "Lng": 2466920,
    "Stops": "7000141-1,7000722-1",
    "Name": "Karumaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000141-1",
    "SiriID": 22731,
    "Lat": 5912785,
    "Lng": 2466912,
    "Stops": "7000142-1,7000450-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000726-1",
    "SiriID": 5671,
    "Lat": 5920306,
    "Lng": 2472214,
    "Stops": "7000716-1,7000656-1",
    "Name": "Katikodu tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000163-1",
    "SiriID": 22483,
    "Lat": 5913233,
    "Lng": 2484197,
    "Stops": "7000162-1,7000335-1",
    "Name": "Kernu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000162-1",
    "SiriID": 23199,
    "Lat": 5913222,
    "Lng": 2484178,
    "Stops": "7000163-1,7000411-1,7000714-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000701-1",
    "SiriID": 5614,
    "Lat": 5912759,
    "Lng": 2461308,
    "Stops": "7000166-1,7000001-1",
    "Name": "Keskuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000713-1",
    "SiriID": 5672,
    "Lat": 5916250,
    "Lng": 2480271,
    "Stops": "7000719-1,7000725-1,7000715-1",
    "Name": "Kivimäe tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000188-1",
    "SiriID": 25971,
    "Lat": 5916765,
    "Lng": 2475985,
    "Stops": "7000187-1,7000705-1",
    "Name": "Kohila",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000187-1",
    "SiriID": 28683,
    "Lat": 5916758,
    "Lng": 2476006,
    "Stops": "7000188-1,7000725-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000190-1",
    "SiriID": 25969,
    "Lat": 5916889,
    "Lng": 2474461,
    "Stops": "7000189-1,7000718-1",
    "Name": "Kohila jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000189-1",
    "SiriID": 25970,
    "Lat": 5916785,
    "Lng": 2474746,
    "Stops": "7000190-1,7000710-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000705-1",
    "SiriID": 5698,
    "Lat": 5916733,
    "Lng": 2475475,
    "Stops": "7000710-1,7000188-1",
    "Name": "Kohila kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000710-1",
    "SiriID": 5661,
    "Lat": 5916739,
    "Lng": 2475460,
    "Stops": "7000705-1,7000189-1",
    "Name": "Kohila kool välja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000206-1",
    "SiriID": 24699,
    "Lat": 5915075,
    "Lng": 2461844,
    "Stops": "7000207-1,7000720-1,7000407-1",
    "Name": "Kongo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000207-1",
    "SiriID": 25963,
    "Lat": 5915097,
    "Lng": 2461925,
    "Stops": "7000206-1,7000049-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000208-1",
    "SiriID": 28518,
    "Lat": 5894794,
    "Lng": 2446756,
    "Stops": "7000580-1",
    "Name": "Konnaveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000729-1",
    "SiriID": 5688,
    "Lat": 5911226,
    "Lng": 2471505,
    "Stops": "7000730-1,7000712-1",
    "Name": "Krimmiküla tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000706-1",
    "SiriID": 5682,
    "Lat": 5921411,
    "Lng": 2463254,
    "Stops": "7000704-1,23349-1",
    "Name": "Kvartsi tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000233-1",
    "SiriID": 9287,
    "Lat": 5909748,
    "Lng": 2446785,
    "Stops": "21940-1,7000489-1",
    "Name": "Käbiküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000239-1",
    "SiriID": 20486,
    "Lat": 5910611,
    "Lng": 2479827,
    "Stops": "7000240-1",
    "Name": "Kärneri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000240-1",
    "SiriID": 28992,
    "Lat": 5910709,
    "Lng": 2479802,
    "Stops": "7000239-1,7000298-1,7000410-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000250-1",
    "SiriID": 29016,
    "Lat": 5902204,
    "Lng": 2444998,
    "Stops": "7000608-1,7000137-1",
    "Name": "Kükita tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000258-1",
    "SiriID": 26248,
    "Lat": 5917767,
    "Lng": 2482620,
    "Stops": "7000259-1,7000719-1,7000025-1",
    "Name": "Laagri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000259-1",
    "SiriID": 26663,
    "Lat": 5917792,
    "Lng": 2482600,
    "Stops": "7000258-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000297-1",
    "SiriID": 23192,
    "Lat": 5913475,
    "Lng": 2478203,
    "Stops": "7000296-1,7000712-1,7000299-1",
    "Name": "Lohu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000296-1",
    "SiriID": 23193,
    "Lat": 5913503,
    "Lng": 2478214,
    "Stops": "7000297-1,7000714-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000298-1",
    "SiriID": 20911,
    "Lat": 5911836,
    "Lng": 2478908,
    "Stops": "7000299-1,7000240-1",
    "Name": "Lohu jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000299-1",
    "SiriID": 23191,
    "Lat": 5911875,
    "Lng": 2478919,
    "Stops": "7000298-1,7000297-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000714-1",
    "SiriID": 5668,
    "Lat": 5913900,
    "Lng": 2479087,
    "Stops": "7000715-1,7000296-1,7000162-1",
    "Name": "Loone tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000715-1",
    "SiriID": 5669,
    "Lat": 5913952,
    "Lng": 2479078,
    "Stops": "7000714-1,7000713-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000315-1",
    "SiriID": 23005,
    "Lat": 5913324,
    "Lng": 2488420,
    "Stops": "7000314-1,7000335-1",
    "Name": "Lõiuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000314-1",
    "SiriID": 23329,
    "Lat": 5913303,
    "Lng": 2488459,
    "Stops": "7000315-1,7000733-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000720-1",
    "SiriID": 5679,
    "Lat": 5916053,
    "Lng": 2459706,
    "Stops": "7000206-1",
    "Name": "Lümandu tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000321-1",
    "SiriID": 22492,
    "Lat": 5909920,
    "Lng": 2501417,
    "Stops": "7000324-1",
    "Name": "Mahtra",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000711-1",
    "SiriID": 5666,
    "Lat": 5916347,
    "Lng": 2477190,
    "Stops": "7000725-1,7000536-1",
    "Name": "Mesika tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000725-1",
    "SiriID": 5667,
    "Lat": 5916376,
    "Lng": 2477200,
    "Stops": "7000711-1,7000187-1,7000713-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000336-1",
    "SiriID": 22484,
    "Lat": 5914364,
    "Lng": 2484958,
    "Stops": "7000337-1,7000017-1",
    "Name": "Miiliste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000335-1",
    "SiriID": 5590,
    "Lat": 5914185,
    "Lng": 2484943,
    "Stops": "7000337-1,7000163-1,7000315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000337-1",
    "SiriID": 5358,
    "Lat": 5914212,
    "Lng": 2484928,
    "Stops": "7000335-1,7000336-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000704-1",
    "SiriID": 74,
    "Lat": 5920799,
    "Lng": 2463050,
    "Stops": "7000050-1,7000706-1",
    "Name": "Mobile",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000658-1",
    "SiriID": 4231,
    "Lat": 5921246,
    "Lng": 2468094,
    "Stops": "7000703-1,7000724-1",
    "Name": "Mäevana",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000703-1",
    "SiriID": 5613,
    "Lat": 5921279,
    "Lng": 2468094,
    "Stops": "7000658-1,7000721-1,23836-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000393-1",
    "SiriID": 21637,
    "Lat": 5918130,
    "Lng": 2485311,
    "Stops": "7000731-1,7000026-1",
    "Name": "Pahkla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000731-1",
    "SiriID": 5680,
    "Lat": 5918131,
    "Lng": 2485341,
    "Stops": "7000393-1,7000727-1",
    "Name": "Pahkla tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000396-1",
    "SiriID": 26889,
    "Lat": 5907169,
    "Lng": 2442226,
    "Stops": "7000616-1,22746-1",
    "Name": "Pajaka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000166-1",
    "SiriID": 21312,
    "Lat": 5913172,
    "Lng": 2460848,
    "Stops": "7000167-1,7000701-1",
    "Name": "Pihali",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000167-1",
    "SiriID": 24243,
    "Lat": 5913193,
    "Lng": 2460844,
    "Stops": "7000166-1,7000407-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000732-1",
    "SiriID": 5681,
    "Lat": 5919765,
    "Lng": 2490318,
    "Stops": "7000728-1,22124-1",
    "Name": "Piiri tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000410-1",
    "SiriID": 23197,
    "Lat": 5912111,
    "Lng": 2483677,
    "Stops": "7000411-1,7000240-1",
    "Name": "Pirgu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000411-1",
    "SiriID": 23198,
    "Lat": 5912125,
    "Lng": 2483708,
    "Stops": "7000410-1,7000162-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000424-1",
    "SiriID": 20196,
    "Lat": 5917640,
    "Lng": 2479517,
    "Stops": "7000423-1,7000560-1",
    "Name": "Prillimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000423-1",
    "SiriID": 26666,
    "Lat": 5917617,
    "Lng": 2479578,
    "Stops": "7000424-1,7000498-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000441-1",
    "SiriID": 25967,
    "Lat": 5916636,
    "Lng": 2469995,
    "Stops": "7000440-1,7000052-1,7000657-1",
    "Name": "Päevati",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000440-1",
    "SiriID": 25968,
    "Lat": 5916633,
    "Lng": 2470045,
    "Stops": "7000441-1,7000717-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000719-1",
    "SiriID": 5674,
    "Lat": 5917279,
    "Lng": 2481183,
    "Stops": "7000497-1,7000258-1,7000713-1",
    "Name": "Pärmitehase tinglik",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000723-1",
    "SiriID": 5664,
    "Lat": 5920275,
    "Lng": 2468954,
    "Stops": "7000724-1,7000656-1",
    "Name": "Raasi tinglik",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000724-1",
    "SiriID": 5665,
    "Lat": 5920326,
    "Lng": 2468927,
    "Stops": "7000723-1,7000658-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000451-1",
    "SiriID": 22732,
    "Lat": 5911569,
    "Lng": 2468133,
    "Stops": "7000450-1,7000730-1,7000477-1",
    "Name": "Rabivere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000450-1",
    "SiriID": 27239,
    "Lat": 5911615,
    "Lng": 2468139,
    "Stops": "7000451-1,7000141-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000462-1",
    "SiriID": 20182,
    "Lat": 5893569,
    "Lng": 2474705,
    "Stops": "",
    "Name": "Raikküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000477-1",
    "SiriID": 20620,
    "Lat": 5911056,
    "Lng": 2463825,
    "Stops": "7000478-1,7000451-1",
    "Name": "Rehe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000478-1",
    "SiriID": 20782,
    "Lat": 5911050,
    "Lng": 2463785,
    "Stops": "7000477-1,7000002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000489-1",
    "SiriID": 20052,
    "Lat": 5907721,
    "Lng": 2445440,
    "Stops": "7000616-1,7000233-1",
    "Name": "Ruunavere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000707-1",
    "SiriID": 5695,
    "Lat": 5919207,
    "Lng": 2478771,
    "Stops": "7000708-1,23288-1",
    "Name": "Sadama tinglik",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000708-1",
    "SiriID": 5696,
    "Lat": 5919177,
    "Lng": 2478761,
    "Stops": "7000707-1,7000561-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000497-1",
    "SiriID": 26664,
    "Lat": 5917603,
    "Lng": 2480734,
    "Stops": "7000498-1,7000719-1",
    "Name": "Salutaguse",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000498-1",
    "SiriID": 26665,
    "Lat": 5917617,
    "Lng": 2480672,
    "Stops": "7000497-1,7000423-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000733-1",
    "SiriID": 5693,
    "Lat": 5912926,
    "Lng": 2489978,
    "Stops": "7000734-1,7000314-1",
    "Name": "Sepiku tinglik",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000734-1",
    "SiriID": 5694,
    "Lat": 5912909,
    "Lng": 2490037,
    "Stops": "7000733-1,7000101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000510-1",
    "SiriID": 10051,
    "Lat": 5917493,
    "Lng": 2465040,
    "Stops": "7000511-1,7000709-1,7000049-1",
    "Name": "Sutlema",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000511-1",
    "SiriID": 10052,
    "Lat": 5917492,
    "Lng": 2465092,
    "Stops": "7000510-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000730-1",
    "SiriID": 5689,
    "Lat": 5910104,
    "Lng": 2469850,
    "Stops": "7000729-1,7000451-1",
    "Name": "Suurküla tinglik",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000406-1",
    "SiriID": 23299,
    "Lat": 5914211,
    "Lng": 2458819,
    "Stops": "7000407-1,21920-1",
    "Name": "Sõeru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000407-1",
    "SiriID": 26858,
    "Lat": 5914211,
    "Lng": 2458886,
    "Stops": "7000406-1,7000167-1,7000206-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000537-1",
    "SiriID": 23194,
    "Lat": 5915933,
    "Lng": 2476344,
    "Stops": "7000536-1,7000712-1",
    "Name": "Tohisoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000536-1",
    "SiriID": 23195,
    "Lat": 5915962,
    "Lng": 2476338,
    "Stops": "7000537-1,7000711-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000721-1",
    "SiriID": 5685,
    "Lat": 5921325,
    "Lng": 2469516,
    "Stops": "7000703-1,23291-1,7000716-1",
    "Name": "Tselluloosi tinglik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000727-1",
    "SiriID": 5686,
    "Lat": 5918992,
    "Lng": 2487411,
    "Stops": "7000728-1,7000731-1",
    "Name": "Tugikodu tinglik",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000728-1",
    "SiriID": 5687,
    "Lat": 5919015,
    "Lng": 2487417,
    "Stops": "7000727-1,7000732-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000325-1",
    "SiriID": 22108,
    "Lat": 5910537,
    "Lng": 2500158,
    "Stops": "7000324-1,7000090-1",
    "Name": "Töökoja",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000324-1",
    "SiriID": 26890,
    "Lat": 5910527,
    "Lng": 2500245,
    "Stops": "7000325-1,7000321-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000557-1",
    "SiriID": 23326,
    "Lat": 5911966,
    "Lng": 2494358,
    "Stops": "7000556-1,7000100-1",
    "Name": "Umbru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000556-1",
    "SiriID": 28785,
    "Lat": 5911951,
    "Lng": 2494393,
    "Stops": "7000557-1,7000089-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000560-1",
    "SiriID": 26667,
    "Lat": 5918007,
    "Lng": 2478367,
    "Stops": "7000558-1,7000424-1",
    "Name": "Urge",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000558-1",
    "SiriID": 27566,
    "Lat": 5918046,
    "Lng": 2478324,
    "Stops": "7000560-1,7000559-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000559-1",
    "SiriID": 28161,
    "Lat": 5918221,
    "Lng": 2478307,
    "Stops": "7000561-1,7000558-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000561-1",
    "SiriID": 30263,
    "Lat": 5918258,
    "Lng": 2478297,
    "Stops": "7000559-1,7000708-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000580-1",
    "SiriID": 20136,
    "Lat": 5897609,
    "Lng": 2447472,
    "Stops": "7000137-1,7000208-1",
    "Name": "Vaimõisa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000709-1",
    "SiriID": 5663,
    "Lat": 5918460,
    "Lng": 2464745,
    "Stops": "7000510-1,7000700-1",
    "Name": "Vana-Aespa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000608-1",
    "SiriID": 20187,
    "Lat": 5904669,
    "Lng": 2444348,
    "Stops": "7000616-1,7000250-1",
    "Name": "Varbola tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000616-1",
    "SiriID": 26677,
    "Lat": 5906256,
    "Lng": 2444892,
    "Stops": "7000489-1,7000608-1,7000396-1",
    "Name": "Vardi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000015-1",
    "SiriID": 9936,
    "Lat": 5920600,
    "Lng": 2466581,
    "Stops": "7000016-1,7000383-1",
    "Name": "A/K Loodus",
    "Info": 4,
    "Street": "Tõdva - Hageri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000016-1",
    "SiriID": 9937,
    "Lat": 5920574,
    "Lng": 2466568,
    "Stops": "7000015-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000205-1",
    "SiriID": 9969,
    "Lat": 5921661,
    "Lng": 2466420,
    "Stops": "7000072-1",
    "Name": "Ikaruse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000383-1",
    "SiriID": 9938,
    "Lat": 5920925,
    "Lng": 2466294,
    "Stops": "7000384-1,7000008-1,7000015-1",
    "Name": "Suvila",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000384-1",
    "SiriID": 9939,
    "Lat": 5920959,
    "Lng": 2466299,
    "Stops": "7000383-1,7000072-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00502-1",
    "SiriID": 5651,
    "Lat": 5944985,
    "Lng": 2460483,
    "Stops": "00132-1,00109-1",
    "Name": "Kakumäe sadam",
    "Info": 0,
    "Street": "Nooda tee",
    "Area": "Tallinna TA",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21310-1",
    "SiriID": 10832,
    "Lat": 5928543,
    "Lng": 2561281,
    "Stops": "21309-1,21302-1",
    "Name": "Aegviidu",
    "Info": 4,
    "Street": 0,
    "Area": "Aegviidu vald",
    "City": "Harju maakond",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21309-1",
    "SiriID": 10833,
    "Lat": 5928518,
    "Lng": 2561308,
    "Stops": "21310-1,21308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21311-1",
    "SiriID": 12147,
    "Lat": 5928760,
    "Lng": 2560928,
    "Stops": "21301-1,21304-1",
    "Name": "Aegviidu kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21312-1",
    "SiriID": 34004,
    "Lat": 5927043,
    "Lng": 2565282,
    "Stops": "21313-1,21306-1",
    "Name": "Nelijärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21313-1",
    "SiriID": 34248,
    "Lat": 5927051,
    "Lng": 2565250,
    "Stops": "21312-1,21305-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21314-1",
    "SiriID": 228,
    "Lat": 5927088,
    "Lng": 2564057,
    "Stops": "21315-1,21305-1",
    "Name": "Urbukse",
    "Info": "",
    "Street": "JÄGALA - KÄRAVETE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21315-1",
    "SiriID": 229,
    "Lat": 5927088,
    "Lng": 2564016,
    "Stops": "21314-1,21307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21302-1",
    "SiriID": 5294,
    "Lat": 5928571,
    "Lng": 2561018,
    "Stops": "21301-1,21310-1",
    "Name": "Aegviidu",
    "Info": "",
    "Street": "Jägala-Kärevete",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21301-1",
    "SiriID": 5295,
    "Lat": 5928594,
    "Lng": 2560982,
    "Stops": "21302-1,21311-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21304-1",
    "SiriID": 5291,
    "Lat": 5929910,
    "Lng": 2561906,
    "Stops": "21303-1,21311-1",
    "Name": "Kosenõmme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21303-1",
    "SiriID": 5293,
    "Lat": 5929909,
    "Lng": 2561969,
    "Stops": "21304-1,37006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21306-1",
    "SiriID": 5298,
    "Lat": 5926915,
    "Lng": 2565231,
    "Stops": "21312-1",
    "Name": "Nelijärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21305-1",
    "SiriID": 5299,
    "Lat": 5927056,
    "Lng": 2565078,
    "Stops": "21313-1,21314-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21307-1",
    "SiriID": 5296,
    "Lat": 5927461,
    "Lng": 2562922,
    "Stops": "21308-1,21315-1",
    "Name": "Nikerjärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21308-1",
    "SiriID": 5297,
    "Lat": 5927493,
    "Lng": 2562883,
    "Stops": "21307-1,21309-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37401-1",
    "SiriID": 12127,
    "Lat": 5939317,
    "Lng": 2544423,
    "Stops": "37301-1",
    "Name": "Härmakosu",
    "Info": "",
    "Street": 0,
    "Area": "Anija vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37902-1",
    "SiriID": 5261,
    "Lat": 5934006,
    "Lng": 2531975,
    "Stops": "37901-1,21468-1",
    "Name": "Kehra",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37901-1",
    "SiriID": 5262,
    "Lat": 5934018,
    "Lng": 2532006,
    "Stops": "37902-1,37910-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37907-1",
    "SiriID": 5265,
    "Lat": 5933869,
    "Lng": 2533638,
    "Stops": "37906-1,37910-1",
    "Name": "Kehra II",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37906-1",
    "SiriID": 5266,
    "Lat": 5933838,
    "Lng": 2533669,
    "Stops": "37907-1,37905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37007-1",
    "SiriID": 11700,
    "Lat": 5934224,
    "Lng": 2557333,
    "Stops": "37002-1",
    "Name": "Pillapalu küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37905-1",
    "SiriID": 10844,
    "Lat": 5933722,
    "Lng": 2533406,
    "Stops": "37903-1,37904-1,37906-1",
    "Name": "Kehra",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37904-1",
    "SiriID": 10845,
    "Lat": 5933676,
    "Lng": 2533521,
    "Stops": "37905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37204-1",
    "SiriID": 10840,
    "Lat": 5931434,
    "Lng": 2541092,
    "Stops": "37203-1,37101-1",
    "Name": "Lahinguvälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37203-1",
    "SiriID": 10841,
    "Lat": 5931447,
    "Lng": 2541022,
    "Stops": "37204-1,21431-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37102-1",
    "SiriID": 10834,
    "Lat": 5929824,
    "Lng": 2546511,
    "Stops": "37101-1",
    "Name": "Mustjõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37101-1",
    "SiriID": 10835,
    "Lat": 5929835,
    "Lng": 2546441,
    "Stops": "37102-1,37204-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38202-1",
    "SiriID": 10849,
    "Lat": 5935681,
    "Lng": 2526728,
    "Stops": "38201-1,21468-1",
    "Name": "Parila",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38201-1",
    "SiriID": 10850,
    "Lat": 5935698,
    "Lng": 2526617,
    "Stops": "38202-1,23060-1,41016-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21402-1",
    "SiriID": 5234,
    "Lat": 5924792,
    "Lng": 2532807,
    "Stops": "21401-1,21466-1,21424-1",
    "Name": "Alavere",
    "Info": 4,
    "Street": "Alavere ring",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21401-1",
    "SiriID": 5235,
    "Lat": 5924779,
    "Lng": 2532944,
    "Stops": "21402-1,21446-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37202-1",
    "SiriID": 5283,
    "Lat": 5937200,
    "Lng": 2553037,
    "Stops": "37201-1,37001-1",
    "Name": "Metskonna",
    "Info": "",
    "Street": "Jägala-Kärevete",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37201-1",
    "SiriID": 5284,
    "Lat": 5937222,
    "Lng": 2552990,
    "Stops": "37202-1,37306-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38503-1",
    "SiriID": 5300,
    "Lat": 5939444,
    "Lng": 2530169,
    "Stops": "38504-1,38502-1",
    "Name": "Partsaare",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38504-1",
    "SiriID": 5301,
    "Lat": 5939429,
    "Lng": 2530162,
    "Stops": "38503-1,38402-1,37501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37001-1",
    "SiriID": 5285,
    "Lat": 5935587,
    "Lng": 2558281,
    "Stops": "37002-1,37202-1",
    "Name": "Pillapalu",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37002-1",
    "SiriID": 5286,
    "Lat": 5935565,
    "Lng": 2558329,
    "Stops": "37001-1,37003-1,37007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37003-1",
    "SiriID": 5287,
    "Lat": 5935125,
    "Lng": 2560621,
    "Stops": "37004-1,37002-1",
    "Name": "Pillapalu kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37004-1",
    "SiriID": 5288,
    "Lat": 5935105,
    "Lng": 2560660,
    "Stops": "37003-1,37005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37302-1",
    "SiriID": 5279,
    "Lat": 5937836,
    "Lng": 2545223,
    "Stops": "37301-1,37305-1",
    "Name": "Raudoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37301-1",
    "SiriID": 5280,
    "Lat": 5937841,
    "Lng": 2545163,
    "Stops": "37302-1,37304-1,37401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37502-1",
    "SiriID": 5273,
    "Lat": 5938754,
    "Lng": 2533541,
    "Stops": "37501-1,37503-1",
    "Name": "Soodla",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37501-1",
    "SiriID": 5274,
    "Lat": 5938760,
    "Lng": 2533477,
    "Stops": "37502-1,38301-1,38504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37504-1",
    "SiriID": 5275,
    "Lat": 5938665,
    "Lng": 2536614,
    "Stops": "37503-1,37303-1",
    "Name": "Soodla kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37503-1",
    "SiriID": 5276,
    "Lat": 5938674,
    "Lng": 2536534,
    "Stops": "37504-1,37502-1,37601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37306-1",
    "SiriID": 5281,
    "Lat": 5937983,
    "Lng": 2549558,
    "Stops": "37305-1,37201-1",
    "Name": "Tõrrepõhja",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37305-1",
    "SiriID": 5282,
    "Lat": 5938000,
    "Lng": 2549502,
    "Stops": "37306-1,37302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37006-1",
    "SiriID": 5289,
    "Lat": 5931578,
    "Lng": 2565059,
    "Stops": "37005-1,21303-1",
    "Name": "Uuejärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37005-1",
    "SiriID": 5290,
    "Lat": 5931606,
    "Lng": 2565074,
    "Stops": "37006-1,37004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37304-1",
    "SiriID": 5277,
    "Lat": 5937770,
    "Lng": 2542301,
    "Stops": "37303-1,37301-1",
    "Name": "Vikipalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37303-1",
    "SiriID": 5278,
    "Lat": 5937777,
    "Lng": 2542240,
    "Stops": "37304-1,37504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37914-1",
    "SiriID": 4612,
    "Lat": 5933439,
    "Lng": 2533233,
    "Stops": "37915-1,37908-1",
    "Name": "Kehra",
    "Info": "",
    "Street": "KAUNISSAARE - KEHRA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37915-1",
    "SiriID": 4613,
    "Lat": 5933459,
    "Lng": 2533243,
    "Stops": "37914-1,37903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21463-1",
    "SiriID": 9437,
    "Lat": 5932652,
    "Lng": 2534184,
    "Stops": "37912-1,21450-1",
    "Name": "Lasteaia",
    "Info": "",
    "Street": "Kaunissaare-Kehra",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21449-1",
    "SiriID": 5256,
    "Lat": 5931886,
    "Lng": 2535067,
    "Stops": "21450-1,21405-1",
    "Name": "Lehtmetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21450-1",
    "SiriID": 5257,
    "Lat": 5931906,
    "Lng": 2535108,
    "Stops": "21449-1,21463-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37912-1",
    "SiriID": 5258,
    "Lat": 5932996,
    "Lng": 2533925,
    "Stops": "37913-1,21463-1",
    "Name": "Sõpruse väljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37913-1",
    "SiriID": 5260,
    "Lat": 5933023,
    "Lng": 2533917,
    "Stops": "37912-1,37908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37903-1",
    "SiriID": 10558,
    "Lat": 5933687,
    "Lng": 2533307,
    "Stops": "37905-1,37915-1",
    "Name": "Kehra raudteejaam",
    "Info": "",
    "Street": "Kehra jaama tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38402-1",
    "SiriID": 5304,
    "Lat": 5938344,
    "Lng": 2529528,
    "Stops": "38401-1,38504-1",
    "Name": "Anija",
    "Info": 3,
    "Street": "Kose - Jägala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38401-1",
    "SiriID": 5305,
    "Lat": 5938319,
    "Lng": 2529542,
    "Stops": "38402-1,38403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38403-1",
    "SiriID": 9110,
    "Lat": 5938114,
    "Lng": 2529953,
    "Stops": "38401-1,38301-1",
    "Name": "Anija lapsed",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21406-1",
    "SiriID": 5254,
    "Lat": 5930543,
    "Lng": 2535685,
    "Stops": "21405-1,21417-1",
    "Name": "Kaunissaare",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21405-1",
    "SiriID": 5255,
    "Lat": 5930591,
    "Lng": 2535655,
    "Stops": "21406-1,21449-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38301-1",
    "SiriID": 11093,
    "Lat": 5937256,
    "Lng": 2531553,
    "Stops": "38302-1,38403-1,37501-1",
    "Name": "Kuusemäe",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38302-1",
    "SiriID": 11094,
    "Lat": 5937194,
    "Lng": 2531593,
    "Stops": "38301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21416-1",
    "SiriID": 5246,
    "Lat": 5927316,
    "Lng": 2535816,
    "Stops": "21415-1,21423-1",
    "Name": "Mesila",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21415-1",
    "SiriID": 5247,
    "Lat": 5927383,
    "Lng": 2535930,
    "Stops": "21416-1,21426-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21418-1",
    "SiriID": 5252,
    "Lat": 5929836,
    "Lng": 2536438,
    "Stops": "21417-1,21431-1",
    "Name": "Palmimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21417-1",
    "SiriID": 5253,
    "Lat": 5929942,
    "Lng": 2536230,
    "Stops": "21418-1,21406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21424-1",
    "SiriID": 5232,
    "Lat": 5925434,
    "Lng": 2532343,
    "Stops": "21423-1,21402-1,21435-1",
    "Name": "Perila tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21423-1",
    "SiriID": 5233,
    "Lat": 5925484,
    "Lng": 2532606,
    "Stops": "21424-1,21416-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21426-1",
    "SiriID": 5248,
    "Lat": 5928110,
    "Lng": 2537301,
    "Stops": "21425-1,21415-1",
    "Name": "Pikva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21425-1",
    "SiriID": 5249,
    "Lat": 5928141,
    "Lng": 2537348,
    "Stops": "21426-1,21432-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21432-1",
    "SiriID": 5250,
    "Lat": 5929228,
    "Lng": 2537554,
    "Stops": "21431-1,21425-1",
    "Name": "Raastu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21431-1",
    "SiriID": 5251,
    "Lat": 5929304,
    "Lng": 2537535,
    "Stops": "21432-1,21418-1,37203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21467-1",
    "SiriID": 13858,
    "Lat": 5934219,
    "Lng": 2531005,
    "Stops": "21468-1",
    "Name": "Salumetsa",
    "Info": "",
    "Street": "KOSE - JÄGALA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21468-1",
    "SiriID": 4257,
    "Lat": 5934307,
    "Lng": 2531113,
    "Stops": "21467-1,37902-1,38202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21466-1",
    "SiriID": 13311,
    "Lat": 5924464,
    "Lng": 2532431,
    "Stops": "21402-1,22119-1",
    "Name": "Alavere õpilased",
    "Info": "",
    "Street": "Kose maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21456-1",
    "SiriID": 5244,
    "Lat": 5920565,
    "Lng": 2542345,
    "Stops": "21420-1",
    "Name": "Voose",
    "Info": "",
    "Street": "Paunküla-Vetla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21464-1",
    "SiriID": 13318,
    "Lat": 5921216,
    "Lng": 2550335,
    "Stops": "21465-1,21451-1",
    "Name": "Kreo",
    "Info": "",
    "Street": "PERILA - JÄNEDA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21465-1",
    "SiriID": 13319,
    "Lat": 5921261,
    "Lng": 2550363,
    "Stops": "21464-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21413-1",
    "SiriID": 5228,
    "Lat": 5927779,
    "Lng": 2527981,
    "Stops": "21414-1,21459-1",
    "Name": "Lükati",
    "Info": 3,
    "Street": "Perila-Jäneda",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21414-1",
    "SiriID": 5229,
    "Lat": 5927736,
    "Lng": 2528064,
    "Stops": "21413-1,21436-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21420-1",
    "SiriID": 5240,
    "Lat": 5922127,
    "Lng": 2541566,
    "Stops": "21419-1,21452-1,21456-1",
    "Name": "Paluküla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21419-1",
    "SiriID": 5241,
    "Lat": 5922148,
    "Lng": 2541521,
    "Stops": "21420-1,21442-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23031-1",
    "SiriID": 5224,
    "Lat": 5928303,
    "Lng": 2523753,
    "Stops": "23032-1,21458-1",
    "Name": "Puidutööstuse",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21436-1",
    "SiriID": 5230,
    "Lat": 5926306,
    "Lng": 2530722,
    "Stops": "21435-1,21414-1",
    "Name": "Rooküla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21435-1",
    "SiriID": 5231,
    "Lat": 5926271,
    "Lng": 2530744,
    "Stops": "21436-1,21424-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21442-1",
    "SiriID": 5238,
    "Lat": 5923147,
    "Lng": 2538808,
    "Stops": "21441-1,21419-1",
    "Name": "Soo-otsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21441-1",
    "SiriID": 5239,
    "Lat": 5923174,
    "Lng": 2538792,
    "Stops": "21442-1,21445-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21445-1",
    "SiriID": 5236,
    "Lat": 5924380,
    "Lng": 2535528,
    "Stops": "21446-1,21441-1",
    "Name": "Uuearu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21446-1",
    "SiriID": 5237,
    "Lat": 5924409,
    "Lng": 2535494,
    "Stops": "21445-1,21401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21452-1",
    "SiriID": 5242,
    "Lat": 5922136,
    "Lng": 2544302,
    "Stops": "21451-1,21420-1",
    "Name": "Vetla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21451-1",
    "SiriID": 5243,
    "Lat": 5922114,
    "Lng": 2544347,
    "Stops": "21452-1,21464-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37802-1",
    "SiriID": 5267,
    "Lat": 5935207,
    "Lng": 2533962,
    "Stops": "37801-1,37911-1",
    "Name": "Idu",
    "Info": "",
    "Street": "Soodla-Kehra",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37801-1",
    "SiriID": 5268,
    "Lat": 5935227,
    "Lng": 2534012,
    "Stops": "37802-1,37604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37602-1",
    "SiriID": 5271,
    "Lat": 5937054,
    "Lng": 2535430,
    "Stops": "37601-1,37603-1",
    "Name": "Lilli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37601-1",
    "SiriID": 5272,
    "Lat": 5937228,
    "Lng": 2535380,
    "Stops": "37602-1,37503-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37604-1",
    "SiriID": 5269,
    "Lat": 5936232,
    "Lng": 2535701,
    "Stops": "37603-1,37801-1",
    "Name": "Looküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37603-1",
    "SiriID": 5270,
    "Lat": 5936260,
    "Lng": 2535704,
    "Stops": "37604-1,37602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37908-1",
    "SiriID": 10557,
    "Lat": 5933158,
    "Lng": 2533443,
    "Stops": "37913-1,37914-1",
    "Name": "Kehra kool",
    "Info": "",
    "Street": "Spordi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37911-1",
    "SiriID": 5263,
    "Lat": 5934208,
    "Lng": 2533263,
    "Stops": "37910-1,37802-1",
    "Name": "Nooruse väljak",
    "Info": "",
    "Street": "Tisleri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "37910-1",
    "SiriID": 5264,
    "Lat": 5934194,
    "Lng": 2533258,
    "Stops": "37911-1,37907-1,37901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47206-1",
    "SiriID": 4018,
    "Lat": 5937195,
    "Lng": 2435349,
    "Stops": "47207-1,47210-1",
    "Name": "Jüri",
    "Info": 3,
    "Street": 0,
    "Area": "Harku vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47207-1",
    "SiriID": 4025,
    "Lat": 5937193,
    "Lng": 2435335,
    "Stops": "47206-1,21739-1,47202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47203-1",
    "SiriID": 4017,
    "Lat": 5938281,
    "Lng": 2433351,
    "Stops": "47202-1,21579-1,21542-1",
    "Name": "Kadaka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47202-1",
    "SiriID": 4024,
    "Lat": 5938277,
    "Lng": 2433352,
    "Stops": "47203-1,21721-1,47207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47222-1",
    "SiriID": 5835,
    "Lat": 5943250,
    "Lng": 2456366,
    "Stops": "47223-1,21552-1",
    "Name": "Liiva tee",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47223-1",
    "SiriID": 5836,
    "Lat": 5943231,
    "Lng": 2456422,
    "Stops": "47222-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47210-1",
    "SiriID": 4020,
    "Lat": 5936971,
    "Lng": 2437446,
    "Stops": "47211-1,47206-1",
    "Name": "Mäe",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47211-1",
    "SiriID": 4027,
    "Lat": 5936967,
    "Lng": 2437447,
    "Stops": "47210-1,47208-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47209-1",
    "SiriID": 4021,
    "Lat": 5936537,
    "Lng": 2440567,
    "Stops": "47208-1,21583-1,21515-1",
    "Name": "Männi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47208-1",
    "SiriID": 4028,
    "Lat": 5936534,
    "Lng": 2440557,
    "Stops": "47209-1,47211-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47220-1",
    "SiriID": 5833,
    "Lat": 5942483,
    "Lng": 2458586,
    "Stops": "47221-1,47218-1,00117-1",
    "Name": "Sinilille tee",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47221-1",
    "SiriID": 5834,
    "Lat": 5942500,
    "Lng": 2458536,
    "Stops": "47220-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47218-1",
    "SiriID": 5831,
    "Lat": 5942029,
    "Lng": 2459160,
    "Stops": "47219-1,47220-1,47004-1",
    "Name": "Välja tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47219-1",
    "SiriID": 5832,
    "Lat": 5941979,
    "Lng": 2459056,
    "Stops": "47218-1,47005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23395-1",
    "SiriID": 143,
    "Lat": 5940796,
    "Lng": 2454666,
    "Stops": "23394-1,21598-1,47007-1",
    "Name": "Tammi tee",
    "Info": "",
    "Street": "HARKU - RANNAMÕISA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23394-1",
    "SiriID": 144,
    "Lat": 5940733,
    "Lng": 2454654,
    "Stops": "23395-1,23366-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47001-1",
    "SiriID": 12192,
    "Lat": 5941297,
    "Lng": 2460699,
    "Stops": "20813-3,47003-1",
    "Name": "Harkujärve",
    "Info": "",
    "Street": "Järvekalda tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20813-3",
    "SiriID": 12208,
    "Lat": 5941271,
    "Lng": 2460674,
    "Stops": "47009-1,47001-1,02002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47009-1",
    "SiriID": 12230,
    "Lat": 5941271,
    "Lng": 2460634,
    "Stops": "20813-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21509-1",
    "SiriID": 4694,
    "Lat": 5938114,
    "Lng": 2444580,
    "Stops": "21511-1,21561-1",
    "Name": "Kruusaaugu",
    "Info": "",
    "Street": "Kiia - Vääna-Viti",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21511-1",
    "SiriID": 4696,
    "Lat": 5938130,
    "Lng": 2444526,
    "Stops": "21509-1,21592-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21576-1",
    "SiriID": 8558,
    "Lat": 5942681,
    "Lng": 2441696,
    "Stops": "21577-1,21540-1",
    "Name": "Liikva/Lätte",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21577-1",
    "SiriID": 8559,
    "Lat": 5942662,
    "Lng": 2441733,
    "Stops": "21576-1,21516-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21522-1",
    "SiriID": 4692,
    "Lat": 5940421,
    "Lng": 2441001,
    "Stops": "21523-1,21566-1",
    "Name": "Metsatuka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21523-1",
    "SiriID": 4693,
    "Lat": 5940465,
    "Lng": 2441002,
    "Stops": "21522-1,21563-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21540-1",
    "SiriID": 4661,
    "Lat": 5943417,
    "Lng": 2440220,
    "Stops": "21541-1,21576-1",
    "Name": "Suvila",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21541-1",
    "SiriID": 4662,
    "Lat": 5943413,
    "Lng": 2440211,
    "Stops": "21540-1,47212-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21560-1",
    "SiriID": 4697,
    "Lat": 5937502,
    "Lng": 2446917,
    "Stops": "21561-1,23343-1",
    "Name": "Vahiküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21561-1",
    "SiriID": 4698,
    "Lat": 5937527,
    "Lng": 2446874,
    "Stops": "21560-1,21509-1,21591-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21563-1",
    "SiriID": 4686,
    "Lat": 5941553,
    "Lng": 2441568,
    "Stops": "21562-1,21523-1",
    "Name": "Vaila",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21562-1",
    "SiriID": 4687,
    "Lat": 5941575,
    "Lng": 2441612,
    "Stops": "21563-1,21516-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47212-1",
    "SiriID": 4422,
    "Lat": 5944532,
    "Lng": 2438094,
    "Stops": "47213-1,21541-1",
    "Name": "Viti tankla",
    "Info": "",
    "Street": "KIIA - VÄÄNA-VITI",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47213-1",
    "SiriID": 4423,
    "Lat": 5944542,
    "Lng": 2438085,
    "Stops": "47212-1,21573-1,21530-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21568-1",
    "SiriID": 4688,
    "Lat": 5938876,
    "Lng": 2441201,
    "Stops": "21569-1,21592-1,47010-1",
    "Name": "Vääna kool",
    "Info": "",
    "Street": "Kiia - Vääna-Viti",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21569-1",
    "SiriID": 4689,
    "Lat": 5938907,
    "Lng": 2441228,
    "Stops": "21568-1,21567-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21566-1",
    "SiriID": 4690,
    "Lat": 5939664,
    "Lng": 2441277,
    "Stops": "21567-1,21522-1",
    "Name": "Vääna/Väänatammi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21567-1",
    "SiriID": 4691,
    "Lat": 5939643,
    "Lng": 2441282,
    "Stops": "21566-1,21569-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47004-1",
    "SiriID": 12197,
    "Lat": 5941593,
    "Lng": 2460122,
    "Stops": "47003-1,47218-1",
    "Name": "Kiriku",
    "Info": "",
    "Street": "Kiriku tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47003-1",
    "SiriID": 12198,
    "Lat": 5941585,
    "Lng": 2460270,
    "Stops": "47004-1,47001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21501-1",
    "SiriID": 4637,
    "Lat": 5944883,
    "Lng": 2446395,
    "Stops": "21502-1,21519-1",
    "Name": "Ilmandu",
    "Info": "",
    "Street": "Klooga mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21502-1",
    "SiriID": 4638,
    "Lat": 5944954,
    "Lng": 2446251,
    "Stops": "21501-1,21529-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00118-1",
    "SiriID": 803,
    "Lat": 5943581,
    "Lng": 2458196,
    "Stops": "00119-1,00128-1",
    "Name": "Jõeküla",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00119-1",
    "SiriID": 804,
    "Lat": 5943609,
    "Lng": 2458117,
    "Stops": "00118-1,47040-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21504-1",
    "SiriID": 4625,
    "Lat": 5942964,
    "Lng": 2455122,
    "Stops": "21503-1,00123-1",
    "Name": "Kallaste",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21503-1",
    "SiriID": 4626,
    "Lat": 5942947,
    "Lng": 2455019,
    "Stops": "21504-1,21597-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21518-1",
    "SiriID": 4635,
    "Lat": 5944630,
    "Lng": 2447881,
    "Stops": "21519-1,47018-1",
    "Name": "Maasika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21519-1",
    "SiriID": 4636,
    "Lat": 5944691,
    "Lng": 2447702,
    "Stops": "21518-1,21501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21520-1",
    "SiriID": 4629,
    "Lat": 5943339,
    "Lng": 2452470,
    "Stops": "21521-1,21534-1",
    "Name": "Mere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21521-1",
    "SiriID": 4630,
    "Lat": 5943319,
    "Lng": 2452558,
    "Stops": "21520-1,21548-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21525-1",
    "SiriID": 4641,
    "Lat": 5945580,
    "Lng": 2442617,
    "Stops": "21524-1,21595-1,21538-1",
    "Name": "Muraste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21524-1",
    "SiriID": 4642,
    "Lat": 5945549,
    "Lng": 2442803,
    "Stops": "21525-1,47201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21526-1",
    "SiriID": 4665,
    "Lat": 5941670,
    "Lng": 2434398,
    "Stops": "21527-1,47215-1,21542-1",
    "Name": "Naage",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21527-1",
    "SiriID": 4666,
    "Lat": 5941708,
    "Lng": 2434419,
    "Stops": "21526-1,21565-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21528-1",
    "SiriID": 12890,
    "Lat": 5945294,
    "Lng": 2445432,
    "Stops": "21529-1,21532-1",
    "Name": "Pangapealse",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21529-1",
    "SiriID": 12891,
    "Lat": 5945203,
    "Lng": 2445625,
    "Stops": "21528-1,21502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21532-1",
    "SiriID": 4639,
    "Lat": 5945405,
    "Lng": 2444569,
    "Stops": "21533-1,21528-1",
    "Name": "Ranna tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21533-1",
    "SiriID": 4640,
    "Lat": 5945433,
    "Lng": 2444345,
    "Stops": "21532-1,47201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21534-1",
    "SiriID": 4631,
    "Lat": 5943687,
    "Lng": 2451444,
    "Stops": "21535-1,21520-1",
    "Name": "Rannamõisa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21535-1",
    "SiriID": 4632,
    "Lat": 5943721,
    "Lng": 2451295,
    "Stops": "21534-1,21546-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21538-1",
    "SiriID": 4643,
    "Lat": 5945314,
    "Lng": 2440831,
    "Stops": "21539-1,47205-1,21525-1",
    "Name": "Suurupi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21539-1",
    "SiriID": 4644,
    "Lat": 5945275,
    "Lng": 2440606,
    "Stops": "21538-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21546-1",
    "SiriID": 4633,
    "Lat": 5943810,
    "Lng": 2450264,
    "Stops": "47018-1,21535-1,21559-1",
    "Name": "Sõrve tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47018-1",
    "SiriID": 34,
    "Lat": 5943821,
    "Lng": 2450250,
    "Stops": "21546-1,21518-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21549-1",
    "SiriID": 4627,
    "Lat": 5943028,
    "Lng": 2453910,
    "Stops": "21548-1,21593-1",
    "Name": "Tabasalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21548-1",
    "SiriID": 4628,
    "Lat": 5943076,
    "Lng": 2453774,
    "Stops": "21549-1,21521-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21552-1",
    "SiriID": 4622,
    "Lat": 5943380,
    "Lng": 2456392,
    "Stops": "21553-1,47222-1,00123-1",
    "Name": "Tiskre",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21553-1",
    "SiriID": 4624,
    "Lat": 5943461,
    "Lng": 2456465,
    "Stops": "21552-1,47039-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00116-1",
    "SiriID": 801,
    "Lat": 5943142,
    "Lng": 2460146,
    "Stops": "00117-1,00120-1",
    "Name": "Tiskre oja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00117-1",
    "SiriID": 802,
    "Lat": 5943164,
    "Lng": 2460062,
    "Stops": "00116-1,00113-1,47220-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47039-1",
    "SiriID": 63,
    "Lat": 5943709,
    "Lng": 2457166,
    "Stops": "00115-1,47040-1,21553-1",
    "Name": "Vahepere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47040-1",
    "SiriID": 64,
    "Lat": 5943694,
    "Lng": 2457434,
    "Stops": "47039-1,00119-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21564-1",
    "SiriID": 4663,
    "Lat": 5942173,
    "Lng": 2435144,
    "Stops": "21565-1,21571-1",
    "Name": "Viti",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21565-1",
    "SiriID": 4664,
    "Lat": 5942129,
    "Lng": 2434968,
    "Stops": "21564-1,21527-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21570-1",
    "SiriID": 4659,
    "Lat": 5943357,
    "Lng": 2436705,
    "Stops": "21571-1,21572-1",
    "Name": "Vääna-Jõesuu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21571-1",
    "SiriID": 4660,
    "Lat": 5943330,
    "Lng": 2436705,
    "Stops": "21570-1,21564-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21572-1",
    "SiriID": 4657,
    "Lat": 5944509,
    "Lng": 2437812,
    "Stops": "21573-1,21570-1",
    "Name": "Vääna-Viti",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21573-1",
    "SiriID": 4658,
    "Lat": 5944517,
    "Lng": 2437867,
    "Stops": "21572-1,47213-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21597-1",
    "SiriID": 11833,
    "Lat": 5942592,
    "Lng": 2454305,
    "Stops": "21598-1,21599-1,21503-1",
    "Name": "Brooklyn",
    "Info": "",
    "Street": "Kooli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21598-1",
    "SiriID": 11834,
    "Lat": 5942577,
    "Lng": 2454314,
    "Stops": "21597-1,23395-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21580-1",
    "SiriID": 9451,
    "Lat": 5938488,
    "Lng": 2440040,
    "Stops": "21581-1,21505-1,21510-1",
    "Name": "Adra/ Vääna tallid",
    "Info": 3,
    "Street": "Kumna - Vääna",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21581-1",
    "SiriID": 9452,
    "Lat": 5938462,
    "Lng": 2440047,
    "Stops": "21580-1,21582-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21582-1",
    "SiriID": 9453,
    "Lat": 5936766,
    "Lng": 2440732,
    "Stops": "21583-1,21581-1",
    "Name": "Humala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21583-1",
    "SiriID": 9454,
    "Lat": 5936742,
    "Lng": 2440725,
    "Stops": "21582-1,47209-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21584-1",
    "SiriID": 9457,
    "Lat": 5934029,
    "Lng": 2444810,
    "Stops": "21585-1,21514-1",
    "Name": "Kumna/Metsaküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21585-1",
    "SiriID": 9458,
    "Lat": 5934011,
    "Lng": 2444852,
    "Stops": "21584-1,21557-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21514-1",
    "SiriID": 4701,
    "Lat": 5934645,
    "Lng": 2443215,
    "Stops": "21515-1,21584-1,21771-1",
    "Name": "Kütke",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21515-1",
    "SiriID": 4702,
    "Lat": 5934669,
    "Lng": 2443200,
    "Stops": "21514-1,47209-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21596-1",
    "SiriID": 12114,
    "Lat": 5932369,
    "Lng": 2445157,
    "Stops": "21512-1,21556-1",
    "Name": "Kumna mõis",
    "Info": "",
    "Street": "Kumna tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21594-1",
    "SiriID": 11969,
    "Lat": 5939070,
    "Lng": 2458058,
    "Stops": "23301-1,21589-1",
    "Name": "Harku kauplus",
    "Info": 2,
    "Street": "Laagri - Harku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21589-1",
    "SiriID": 3961,
    "Lat": 5938958,
    "Lng": 2457986,
    "Stops": "21588-1,21594-1",
    "Name": "Instituudi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21588-1",
    "SiriID": 3962,
    "Lat": 5938863,
    "Lng": 2457889,
    "Stops": "21589-1,47224-1",
    "Name": "",
    "Info": "",
    "Street": "LAAGRI - HARKU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23376-1",
    "SiriID": 9470,
    "Lat": 5937352,
    "Lng": 2458472,
    "Stops": "21590-1,23331-1",
    "Name": "Tammemäe",
    "Info": "",
    "Street": "Laagri - Harku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23331-1",
    "SiriID": 5710,
    "Lat": 5937827,
    "Lng": 2457933,
    "Stops": "23332-1,23376-1",
    "Name": "Uusküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23332-1",
    "SiriID": 5711,
    "Lat": 5937870,
    "Lng": 2457884,
    "Stops": "23331-1,47224-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47201-1",
    "SiriID": 3974,
    "Lat": 5945754,
    "Lng": 2444160,
    "Stops": "21533-1,21524-1",
    "Name": "Muraste kool",
    "Info": "",
    "Street": "Lee tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21574-1",
    "SiriID": 4680,
    "Lat": 5943034,
    "Lng": 2447381,
    "Stops": "21575-1,21544-1",
    "Name": "Kuusiku",
    "Info": "",
    "Street": "LIIKVA - RANNAMÕISA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21575-1",
    "SiriID": 4681,
    "Lat": 5943031,
    "Lng": 2447435,
    "Stops": "21574-1,21558-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21516-1",
    "SiriID": 4684,
    "Lat": 5942401,
    "Lng": 2442216,
    "Stops": "21517-1,21577-1,21562-1",
    "Name": "Liikva",
    "Info": "",
    "Street": "Liikva-Rannamõisa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21517-1",
    "SiriID": 4685,
    "Lat": 5942398,
    "Lng": 2442274,
    "Stops": "21516-1,21545-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21544-1",
    "SiriID": 4682,
    "Lat": 5942992,
    "Lng": 2445051,
    "Stops": "21545-1,21574-1",
    "Name": "Sõrve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21545-1",
    "SiriID": 4683,
    "Lat": 5942986,
    "Lng": 2444950,
    "Stops": "21544-1,21517-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21558-1",
    "SiriID": 4678,
    "Lat": 5943332,
    "Lng": 2449427,
    "Stops": "21559-1,21575-1",
    "Name": "Tuuleveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21559-1",
    "SiriID": 4679,
    "Lat": 5943369,
    "Lng": 2449489,
    "Stops": "21558-1,21546-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21595-1",
    "SiriID": 12125,
    "Lat": 5946341,
    "Lng": 2441867,
    "Stops": "21525-1",
    "Name": "Kratt 3",
    "Info": "",
    "Street": "Liivakivi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21593-1",
    "SiriID": 10463,
    "Lat": 5942868,
    "Lng": 2453707,
    "Stops": "21599-1,21549-1",
    "Name": "Kooli",
    "Info": "",
    "Street": "Nooruse tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21599-1",
    "SiriID": 11832,
    "Lat": 5942787,
    "Lng": 2453667,
    "Stops": "21593-1,21597-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21512-1",
    "SiriID": 4705,
    "Lat": 5931881,
    "Lng": 2445307,
    "Stops": "21513-1,21596-1",
    "Name": "Künka",
    "Info": 3,
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21513-1",
    "SiriID": 4706,
    "Lat": 5931764,
    "Lng": 2445112,
    "Stops": "21512-1,21507-1,21846-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21556-1",
    "SiriID": 4703,
    "Lat": 5932793,
    "Lng": 2446823,
    "Stops": "21557-1,21596-1",
    "Name": "Tutermaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21557-1",
    "SiriID": 4704,
    "Lat": 5932876,
    "Lng": 2446981,
    "Stops": "21556-1,21585-1,23320-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21506-1",
    "SiriID": 9721,
    "Lat": 5946866,
    "Lng": 2439323,
    "Stops": "21543-1",
    "Name": "Kivi tee",
    "Info": 2,
    "Street": "Suurupi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21530-1",
    "SiriID": 4645,
    "Lat": 5945596,
    "Lng": 2439141,
    "Stops": "21531-1,47204-1,47213-1",
    "Name": "Orase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21531-1",
    "SiriID": 4646,
    "Lat": 5945618,
    "Lng": 2439105,
    "Stops": "21530-1,21551-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21537-1",
    "SiriID": 4652,
    "Lat": 5946633,
    "Lng": 2438074,
    "Stops": "21555-1",
    "Name": "Suurupi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21543-1",
    "SiriID": 4655,
    "Lat": 5946330,
    "Lng": 2438975,
    "Stops": "21555-1,21550-1,21506-1",
    "Name": "Sõpruse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21550-1",
    "SiriID": 4647,
    "Lat": 5946018,
    "Lng": 2439270,
    "Stops": "21551-1,21543-1",
    "Name": "Tempo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21551-1",
    "SiriID": 4648,
    "Lat": 5946000,
    "Lng": 2439238,
    "Stops": "21550-1,21531-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21555-1",
    "SiriID": 4650,
    "Lat": 5946328,
    "Lng": 2438460,
    "Stops": "21543-1,21537-1",
    "Name": "Tuletorni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47005-1",
    "SiriID": 12201,
    "Lat": 5941796,
    "Lng": 2458739,
    "Stops": "47006-1,47219-1",
    "Name": "Alasniidu tee",
    "Info": "",
    "Street": "Sütemetsa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47006-1",
    "SiriID": 12202,
    "Lat": 5941720,
    "Lng": 2458638,
    "Stops": "47005-1,47007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47007-1",
    "SiriID": 12203,
    "Lat": 5941466,
    "Lng": 2458343,
    "Stops": "47006-1,23395-1",
    "Name": "Oja tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21507-1",
    "SiriID": 4792,
    "Lat": 5931262,
    "Lng": 2446454,
    "Stops": "21508-1,21513-1",
    "Name": "Korvi",
    "Info": 3,
    "Street": "Tallinna ringtee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21508-1",
    "SiriID": 4793,
    "Lat": 5931320,
    "Lng": 2446973,
    "Stops": "21507-1,47327-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47214-1",
    "SiriID": 4182,
    "Lat": 5941030,
    "Lng": 2431448,
    "Stops": "47215-1,21758-1",
    "Name": "Hallikivi",
    "Info": "",
    "Street": "Talvoja tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47215-1",
    "SiriID": 4183,
    "Lat": 5941014,
    "Lng": 2431472,
    "Stops": "47214-1,47216-1,21526-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47217-1",
    "SiriID": 4600,
    "Lat": 5940504,
    "Lng": 2430901,
    "Stops": "47216-1,21736-1",
    "Name": "Kevadvõtme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47216-1",
    "SiriID": 4601,
    "Lat": 5940500,
    "Lng": 2430931,
    "Stops": "47217-1,47215-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47224-1",
    "SiriID": 5938,
    "Lat": 5938606,
    "Lng": 2457360,
    "Stops": "21588-1,23332-1,23306-1",
    "Name": "Uue",
    "Info": 2,
    "Street": "Uus tänav",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47204-1",
    "SiriID": 4023,
    "Lat": 5945555,
    "Lng": 2440687,
    "Stops": "47205-1,21530-1",
    "Name": "Hindreku",
    "Info": "",
    "Street": "Vana-Klooga maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47205-1",
    "SiriID": 4030,
    "Lat": 5945553,
    "Lng": 2440699,
    "Stops": "47204-1,21538-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21578-1",
    "SiriID": 9126,
    "Lat": 5939626,
    "Lng": 2431679,
    "Stops": "21579-1,21792-1",
    "Name": "Loigu",
    "Info": 3,
    "Street": "Vääna - Keila-Joa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21579-1",
    "SiriID": 9127,
    "Lat": 5939581,
    "Lng": 2431802,
    "Stops": "21578-1,47203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21510-1",
    "SiriID": 12041,
    "Lat": 5939696,
    "Lng": 2436728,
    "Stops": "21542-1,21580-1",
    "Name": "Tisleri talu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21542-1",
    "SiriID": 12115,
    "Lat": 5939695,
    "Lng": 2436696,
    "Stops": "21510-1,47203-1,21526-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21505-1",
    "SiriID": 12233,
    "Lat": 5938845,
    "Lng": 2440775,
    "Stops": "47010-1,21580-1",
    "Name": "Vääna",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47010-1",
    "SiriID": 13859,
    "Lat": 5938828,
    "Lng": 2440942,
    "Stops": "21505-1,21568-1",
    "Name": "",
    "Info": "",
    "Street": "VÄÄNA - KEILA-JOA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21592-1",
    "SiriID": 11962,
    "Lat": 5938831,
    "Lng": 2441349,
    "Stops": "21568-1,21511-1",
    "Name": "Vääna kool",
    "Info": "",
    "Street": "Väänamõisa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24507-1",
    "SiriID": 88,
    "Lat": 5945960,
    "Lng": 2514395,
    "Stops": "24508-1,24510-1,47030-1",
    "Name": "Ellandvahe",
    "Info": 3,
    "Street": 0,
    "Area": "Jõelähtme vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25602-1",
    "SiriID": 12186,
    "Lat": 5939827,
    "Lng": 2518742,
    "Stops": "25601-1,41010-1",
    "Name": "Haljava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24513-1",
    "SiriID": 3841,
    "Lat": 5945456,
    "Lng": 2510784,
    "Stops": "24510-1,47034-1,24505-1",
    "Name": "Hõbemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47020-1",
    "SiriID": 12154,
    "Lat": 5945837,
    "Lng": 2491045,
    "Stops": "47021-1,47101-1",
    "Name": "Iru küla",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47021-1",
    "SiriID": 12155,
    "Lat": 5945843,
    "Lng": 2491024,
    "Stops": "47020-1,47105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24512-1",
    "SiriID": 3994,
    "Lat": 5946924,
    "Lng": 2514381,
    "Stops": "24508-1,24511-1,24509-1",
    "Name": "Jõelähtme golfiväljak",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26915-1",
    "SiriID": 635,
    "Lat": 5945127,
    "Lng": 2519106,
    "Stops": "26902-1,27001-1,26101-1",
    "Name": "Jägala ühistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26407-1",
    "SiriID": 5962,
    "Lat": 5952342,
    "Lng": 2518587,
    "Stops": "26408-1",
    "Name": "Kadastiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26408-1",
    "SiriID": 5963,
    "Lat": 5952336,
    "Lng": 2518587,
    "Stops": "26407-1,26404-1,26410-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47022-1",
    "SiriID": 14748,
    "Lat": 5944852,
    "Lng": 2497316,
    "Stops": "47023-1,47024-1",
    "Name": "Kogre",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47023-1",
    "SiriID": 14749,
    "Lat": 5944883,
    "Lng": 2497396,
    "Stops": "47022-1,19604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24508-1",
    "SiriID": 89,
    "Lat": 5946751,
    "Lng": 2514616,
    "Stops": "24512-1,24515-1,24507-1",
    "Name": "Koila tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25303-1",
    "SiriID": 11981,
    "Lat": 5942623,
    "Lng": 2509120,
    "Stops": "25302-1,41117-1",
    "Name": "Kostivere kool",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26801-1",
    "SiriID": 12107,
    "Lat": 5949456,
    "Lng": 2531561,
    "Stops": "26701-1,28414-1",
    "Name": "Kullamäe",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25807-1",
    "SiriID": 12142,
    "Lat": 5941718,
    "Lng": 2523157,
    "Stops": "25803-1,25809-1",
    "Name": "Kuuse/Jägala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47024-1",
    "SiriID": 14742,
    "Lat": 5944769,
    "Lng": 2496369,
    "Stops": "47025-1,47022-1,19404-1",
    "Name": "Liivamäe",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47025-1",
    "SiriID": 14743,
    "Lat": 5944766,
    "Lng": 2496124,
    "Stops": "47024-1,47037-1,25108-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25107-1",
    "SiriID": 4415,
    "Lat": 5944149,
    "Lng": 2496164,
    "Stops": "25108-1,25103-1",
    "Name": "Liivamäe küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25108-1",
    "SiriID": 4512,
    "Lat": 5944154,
    "Lng": 2496177,
    "Stops": "25107-1,47025-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47011-1",
    "SiriID": 14738,
    "Lat": 5944458,
    "Lng": 2492907,
    "Stops": "47012-1,47014-1,14308-1",
    "Name": "Loo tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47012-1",
    "SiriID": 14739,
    "Lat": 5944382,
    "Lng": 2492987,
    "Stops": "47011-1,23918-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47013-1",
    "SiriID": 14740,
    "Lat": 5944554,
    "Lng": 2493417,
    "Stops": "47014-1,47036-1",
    "Name": "Loovälja tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47014-1",
    "SiriID": 14741,
    "Lat": 5944548,
    "Lng": 2493191,
    "Stops": "47013-1,47011-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "27004-1",
    "SiriID": 5842,
    "Lat": 5945106,
    "Lng": 2503486,
    "Stops": "27005-1,24403-1",
    "Name": "Lõunakarjääri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "27005-1",
    "SiriID": 5843,
    "Lat": 5945085,
    "Lng": 2503272,
    "Stops": "27004-1,47026-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47026-1",
    "SiriID": 14753,
    "Lat": 5945211,
    "Lng": 2501500,
    "Stops": "47027-1,27005-1,24805-1",
    "Name": "Maardu mõisa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24509-1",
    "SiriID": 87,
    "Lat": 5947159,
    "Lng": 2513002,
    "Stops": "24512-1,47103-1",
    "Name": "Manniva",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47103-1",
    "SiriID": 147,
    "Lat": 5947595,
    "Lng": 2511817,
    "Stops": "24509-1,24206-1",
    "Name": "Mäealuse tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26409-1",
    "SiriID": 5964,
    "Lat": 5952322,
    "Lng": 2517626,
    "Stops": "26410-1,26502-1",
    "Name": "Okka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26410-1",
    "SiriID": 5965,
    "Lat": 5952314,
    "Lng": 2517642,
    "Stops": "26409-1,26408-1,26504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47030-1",
    "SiriID": 14946,
    "Lat": 5944058,
    "Lng": 2513758,
    "Stops": "25902-1,25403-1,24507-1",
    "Name": "Olerex",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24806-1",
    "SiriID": 4253,
    "Lat": 5942952,
    "Lng": 2503212,
    "Stops": "24803-1,24805-1",
    "Name": "Pohla",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26406-1",
    "SiriID": 218,
    "Lat": 5949325,
    "Lng": 2517863,
    "Stops": "26405-1,26303-1",
    "Name": "Punakivi",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26405-1",
    "SiriID": 219,
    "Lat": 5949447,
    "Lng": 2517964,
    "Stops": "26406-1,26401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47104-1",
    "SiriID": 152,
    "Lat": 5946050,
    "Lng": 2491190,
    "Stops": "47105-1",
    "Name": "Põllu",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47105-1",
    "SiriID": 153,
    "Lat": 5946051,
    "Lng": 2491176,
    "Stops": "47104-1,47021-1,16507-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24506-1",
    "SiriID": 11087,
    "Lat": 5945958,
    "Lng": 2508737,
    "Stops": "24505-1",
    "Name": "Rebala küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24505-1",
    "SiriID": 11088,
    "Lat": 5945947,
    "Lng": 2508764,
    "Stops": "24506-1,24501-1,24513-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26305-1",
    "SiriID": 726,
    "Lat": 5947403,
    "Lng": 2515217,
    "Stops": "26302-1,24511-1,26303-1",
    "Name": "Rippsilla tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24903-1",
    "SiriID": 48,
    "Lat": 5942601,
    "Lng": 2499198,
    "Stops": "24904-1",
    "Name": "Saha",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24904-1",
    "SiriID": 49,
    "Lat": 5942588,
    "Lng": 2499205,
    "Stops": "24903-1,24901-1,24801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24510-1",
    "SiriID": 86,
    "Lat": 5945885,
    "Lng": 2511705,
    "Stops": "24513-1,24507-1",
    "Name": "Sareta tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24106-1",
    "SiriID": 3996,
    "Lat": 5950223,
    "Lng": 2503996,
    "Stops": "24105-1",
    "Name": "Saviranna tee",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47033-1",
    "SiriID": 14850,
    "Lat": 5943282,
    "Lng": 2508231,
    "Stops": "24706-1,24704-1,47035-1",
    "Name": "Teeristi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24515-1",
    "SiriID": 3997,
    "Lat": 5946829,
    "Lng": 2515248,
    "Stops": "24508-1,26301-1,27003-1",
    "Name": "Tiitso/Klaukse",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47034-1",
    "SiriID": 14846,
    "Lat": 5944734,
    "Lng": 2510382,
    "Stops": "47038-1,24513-1,24503-1",
    "Name": "Uus-Rebala",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47038-1",
    "SiriID": 14971,
    "Lat": 5944772,
    "Lng": 2510164,
    "Stops": "47034-1,24502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25104-1",
    "SiriID": 212,
    "Lat": 5943095,
    "Lng": 2496186,
    "Stops": "25105-1,24902-1,25102-1",
    "Name": "Vanasauna tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25105-1",
    "SiriID": 3723,
    "Lat": 5943101,
    "Lng": 2496175,
    "Stops": "25104-1,25103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24803-1",
    "SiriID": 14440,
    "Lat": 5942544,
    "Lng": 2504282,
    "Stops": "47035-1,24806-1",
    "Name": "Veski põik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47035-1",
    "SiriID": 14442,
    "Lat": 5942531,
    "Lng": 2504455,
    "Stops": "24803-1,47033-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47036-1",
    "SiriID": 14744,
    "Lat": 5944677,
    "Lng": 2495097,
    "Stops": "47037-1,47013-1",
    "Name": "Vibeliku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47037-1",
    "SiriID": 14745,
    "Lat": 5944674,
    "Lng": 2495317,
    "Stops": "47036-1,47025-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25809-1",
    "SiriID": 4591,
    "Lat": 5942408,
    "Lng": 2521461,
    "Stops": "25806-1,25807-1,26910-1",
    "Name": "Jõeääre",
    "Info": 3,
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25808-1",
    "SiriID": 4583,
    "Lat": 5941319,
    "Lng": 2523369,
    "Stops": "25803-1",
    "Name": "Raasiku tee",
    "Info": "",
    "Street": "ARUVALLA - JÄGALA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25601-1",
    "SiriID": 5327,
    "Lat": 5939843,
    "Lng": 2518772,
    "Stops": "25602-1,25806-1",
    "Name": "Haljava",
    "Info": "",
    "Street": "Haljava tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25903-1",
    "SiriID": 5480,
    "Lat": 5944005,
    "Lng": 2514495,
    "Stops": "25904-1,25901-1",
    "Name": "Jõelähtme vallamaja",
    "Info": "",
    "Street": "Jõelähtme-Kemba",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25904-1",
    "SiriID": 5481,
    "Lat": 5943980,
    "Lng": 2514569,
    "Stops": "25903-1,26103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26101-1",
    "SiriID": 5476,
    "Lat": 5944394,
    "Lng": 2519238,
    "Stops": "26102-1,26915-1",
    "Name": "Koeralooga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26102-1",
    "SiriID": 5477,
    "Lat": 5944362,
    "Lng": 2519219,
    "Stops": "26101-1,26107-1,26106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26105-1",
    "SiriID": 5478,
    "Lat": 5944115,
    "Lng": 2517012,
    "Stops": "26106-1",
    "Name": "Koogi kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26106-1",
    "SiriID": 5479,
    "Lat": 5944102,
    "Lng": 2517059,
    "Stops": "26105-1,26103-1,26102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47028-1",
    "SiriID": 14844,
    "Lat": 5945221,
    "Lng": 2520069,
    "Stops": "26902-1,26911-1",
    "Name": "Neeme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47029-1",
    "SiriID": 14845,
    "Lat": 5945117,
    "Lng": 2519963,
    "Stops": "26902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26911-1",
    "SiriID": 5472,
    "Lat": 5945703,
    "Lng": 2520983,
    "Stops": "26912-1,47028-1",
    "Name": "Ruuküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26912-1",
    "SiriID": 5473,
    "Lat": 5945708,
    "Lng": 2521006,
    "Stops": "26911-1,26914-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26913-1",
    "SiriID": 5470,
    "Lat": 5945936,
    "Lng": 2522798,
    "Stops": "26914-1,28006-1",
    "Name": "Upsi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26914-1",
    "SiriID": 5471,
    "Lat": 5945925,
    "Lng": 2522781,
    "Stops": "26913-1,26912-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25404-1",
    "SiriID": 5329,
    "Lat": 5943370,
    "Lng": 2511938,
    "Stops": "25403-1,25302-1,25401-1",
    "Name": "Urke",
    "Info": "",
    "Street": "Jõelähtme-Kostivere",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25403-1",
    "SiriID": 5330,
    "Lat": 5943514,
    "Lng": 2512289,
    "Stops": "25404-1,24504-1,47030-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25802-1",
    "SiriID": 5373,
    "Lat": 5942984,
    "Lng": 2519238,
    "Stops": "25801-1,26104-1",
    "Name": "Jägala jõgi",
    "Info": "",
    "Street": "Jägala-Kärevete",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25801-1",
    "SiriID": 5374,
    "Lat": 5942992,
    "Lng": 2519264,
    "Stops": "25802-1,26901-1,25805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38502-1",
    "SiriID": 5302,
    "Lat": 5940272,
    "Lng": 2527441,
    "Stops": "38501-1,38503-1",
    "Name": "Kehra tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "38501-1",
    "SiriID": 5303,
    "Lat": 5940325,
    "Lng": 2527310,
    "Stops": "38502-1,25804-1,30301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25804-1",
    "SiriID": 5377,
    "Lat": 5941344,
    "Lng": 2523844,
    "Stops": "25803-1,38501-1",
    "Name": "Raasiku tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25803-1",
    "SiriID": 5378,
    "Lat": 5941443,
    "Lng": 2523465,
    "Stops": "25808-1,25804-1,25807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25806-1",
    "SiriID": 5375,
    "Lat": 5942256,
    "Lng": 2521032,
    "Stops": "25805-1,25809-1,25601-1",
    "Name": "Tammiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25805-1",
    "SiriID": 5376,
    "Lat": 5942304,
    "Lng": 2520974,
    "Stops": "25806-1,25801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26601-1",
    "SiriID": 5466,
    "Lat": 5951040,
    "Lng": 2526864,
    "Stops": "26602-1,47032-1",
    "Name": "Kaberneeme",
    "Info": "",
    "Street": "Kaberla-Kaberneeme",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26602-1",
    "SiriID": 5467,
    "Lat": 5951070,
    "Lng": 2526923,
    "Stops": "26601-1,26603-1,26604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26603-1",
    "SiriID": 5468,
    "Lat": 5951771,
    "Lng": 2527453,
    "Stops": "26602-1",
    "Name": "Kaberneeme kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26905-1",
    "SiriID": 5462,
    "Lat": 5947465,
    "Lng": 2524915,
    "Stops": "26906-1,28004-1",
    "Name": "Kalevi-Liiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26906-1",
    "SiriID": 5463,
    "Lat": 5947488,
    "Lng": 2524943,
    "Stops": "26905-1,26907-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26907-1",
    "SiriID": 5464,
    "Lat": 5948529,
    "Lng": 2526017,
    "Stops": "26908-1,26906-1",
    "Name": "Metsaoja/Ihita",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26908-1",
    "SiriID": 5465,
    "Lat": 5948595,
    "Lng": 2526053,
    "Stops": "26907-1,26604-1,26401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24103-1",
    "SiriID": 11900,
    "Lat": 5948290,
    "Lng": 2503986,
    "Stops": "24104-1,19520-1",
    "Name": "Nõmme talu",
    "Info": 2,
    "Street": "Kallavere-Ülgase",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24104-1",
    "SiriID": 12547,
    "Lat": 5948283,
    "Lng": 2504132,
    "Stops": "24103-1,24201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24201-1",
    "SiriID": 5496,
    "Lat": 5948568,
    "Lng": 2506190,
    "Stops": "24202-1,24104-1",
    "Name": "Rüütli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24202-1",
    "SiriID": 5497,
    "Lat": 5948574,
    "Lng": 2506235,
    "Stops": "24201-1,24203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24203-1",
    "SiriID": 5494,
    "Lat": 5948752,
    "Lng": 2507499,
    "Stops": "24204-1,24202-1",
    "Name": "Ülgase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24204-1",
    "SiriID": 5495,
    "Lat": 5948743,
    "Lng": 2507535,
    "Stops": "24203-1,24205-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26605-1",
    "SiriID": 5960,
    "Lat": 5950217,
    "Lng": 2529063,
    "Stops": "26604-1,26702-1",
    "Name": "Mäe tee",
    "Info": 3,
    "Street": "KIIU - KABERNEEME",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26604-1",
    "SiriID": 5961,
    "Lat": 5950213,
    "Lng": 2529027,
    "Stops": "26605-1,26602-1,26908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26702-1",
    "SiriID": 10922,
    "Lat": 5950107,
    "Lng": 2530534,
    "Stops": "26701-1,26605-1",
    "Name": "Haapse",
    "Info": "",
    "Street": "Kiiu-Kaberneeme",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26701-1",
    "SiriID": 10923,
    "Lat": 5950062,
    "Lng": 2530658,
    "Stops": "26702-1,26801-1",
    "Name": "Haapse küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24801-1",
    "SiriID": 5337,
    "Lat": 5942550,
    "Lng": 2501688,
    "Stops": "24802-1,24904-1",
    "Name": "Maardu mõis",
    "Info": 2,
    "Street": "Lagedi-Kostivere",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24802-1",
    "SiriID": 5338,
    "Lat": 5942549,
    "Lng": 2501738,
    "Stops": "24801-1,24805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24901-1",
    "SiriID": 5339,
    "Lat": 5942503,
    "Lng": 2499004,
    "Stops": "24902-1,24904-1",
    "Name": "Saha",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24902-1",
    "SiriID": 5340,
    "Lat": 5942449,
    "Lng": 2498868,
    "Stops": "24901-1,25104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24702-1",
    "SiriID": 7130,
    "Lat": 5945048,
    "Lng": 2505725,
    "Stops": "24701-1,24401-1",
    "Name": "Kostivere tee",
    "Info": "",
    "Street": "MAARDU - RAASIKU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24805-1",
    "SiriID": 11898,
    "Lat": 5943095,
    "Lng": 2501817,
    "Stops": "24802-1,24806-1,47026-1",
    "Name": "Kaunissaare teerist",
    "Info": "",
    "Street": "Maardu mõisa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25501-1",
    "SiriID": 5323,
    "Lat": 5938581,
    "Lng": 2516371,
    "Stops": "25502-1,41009-1,41012-1",
    "Name": "Aruaru",
    "Info": 3,
    "Street": "Maardu-Raasiku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25502-1",
    "SiriID": 7428,
    "Lat": 5938630,
    "Lng": 2516355,
    "Stops": "25501-1,25402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25302-1",
    "SiriID": 5331,
    "Lat": 5942847,
    "Lng": 2509336,
    "Stops": "25301-1,25303-1,25404-1",
    "Name": "Kostivere",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25301-1",
    "SiriID": 5332,
    "Lat": 5942887,
    "Lng": 2509279,
    "Stops": "25302-1,24706-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24701-1",
    "SiriID": 5361,
    "Lat": 5945070,
    "Lng": 2505533,
    "Stops": "24702-1,24404-1",
    "Name": "Kostivere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25402-1",
    "SiriID": 5325,
    "Lat": 5941358,
    "Lng": 2512305,
    "Stops": "25401-1,25502-1",
    "Name": "Parasmäe",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25401-1",
    "SiriID": 5326,
    "Lat": 5941408,
    "Lng": 2512215,
    "Stops": "25402-1,25404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24706-1",
    "SiriID": 7474,
    "Lat": 5943289,
    "Lng": 2508391,
    "Stops": "47033-1,25301-1",
    "Name": "Teeristi",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24704-1",
    "SiriID": 5333,
    "Lat": 5943933,
    "Lng": 2507447,
    "Stops": "24703-1,47033-1",
    "Name": "Vandjala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24703-1",
    "SiriID": 5334,
    "Lat": 5944024,
    "Lng": 2507359,
    "Stops": "24704-1,24402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24105-1",
    "SiriID": 174,
    "Lat": 5949885,
    "Lng": 2504255,
    "Stops": "24106-1,19501-1",
    "Name": "Mäeotsa tee",
    "Info": "",
    "Street": "Mere tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24205-1",
    "SiriID": 5763,
    "Lat": 5948925,
    "Lng": 2508503,
    "Stops": "24206-1,24204-1",
    "Name": "Koljunuki",
    "Info": "",
    "Street": "Mäealuse tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24206-1",
    "SiriID": 5764,
    "Lat": 5948821,
    "Lng": 2509176,
    "Stops": "24205-1,47103-1",
    "Name": "Kostiranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25102-1",
    "SiriID": 5342,
    "Lat": 5941622,
    "Lng": 2494482,
    "Stops": "25101-1,25104-1",
    "Name": "Lagedi mõis",
    "Info": "",
    "Street": "Nehatu-Loo-Lagedi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24002-1",
    "SiriID": 5581,
    "Lat": 5947944,
    "Lng": 2499095,
    "Stops": "24001-1,19704-1",
    "Name": "Lepalinnu ühistu",
    "Info": "",
    "Street": "Orumetsa tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24001-1",
    "SiriID": 5582,
    "Lat": 5947937,
    "Lng": 2499169,
    "Stops": "24002-1,24003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24003-1",
    "SiriID": 5579,
    "Lat": 5947931,
    "Lng": 2499957,
    "Stops": "24004-1,24001-1",
    "Name": "Uuevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24004-1",
    "SiriID": 5580,
    "Lat": 5947911,
    "Lng": 2500048,
    "Stops": "24003-1,19511-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26107-1",
    "SiriID": 5371,
    "Lat": 5943530,
    "Lng": 2519791,
    "Stops": "26901-1,26102-1,26909-1",
    "Name": "Jägala jõgi",
    "Info": 3,
    "Street": "Peterburi mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26901-1",
    "SiriID": 5372,
    "Lat": 5943490,
    "Lng": 2519634,
    "Stops": "26107-1,25801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25902-1",
    "SiriID": 5367,
    "Lat": 5943953,
    "Lng": 2513985,
    "Stops": "25901-1,47030-1",
    "Name": "Jõelähtme",
    "Info": "",
    "Street": "Peterburi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25901-1",
    "SiriID": 5368,
    "Lat": 5943983,
    "Lng": 2514032,
    "Stops": "25902-1,25903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26104-1",
    "SiriID": 5369,
    "Lat": 5943549,
    "Lng": 2516564,
    "Stops": "26103-1,25802-1",
    "Name": "Koogi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26103-1",
    "SiriID": 5370,
    "Lat": 5943582,
    "Lng": 2516557,
    "Stops": "26104-1,26106-1,25904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24401-1",
    "SiriID": 5362,
    "Lat": 5945079,
    "Lng": 2506396,
    "Stops": "24402-1,24702-1",
    "Name": "Kostivere tee",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24402-1",
    "SiriID": 9662,
    "Lat": 5945008,
    "Lng": 2506991,
    "Stops": "24401-1,24501-1,24703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24502-1",
    "SiriID": 5363,
    "Lat": 5944727,
    "Lng": 2509121,
    "Stops": "24501-1,47038-1",
    "Name": "Linnake",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24501-1",
    "SiriID": 5364,
    "Lat": 5944824,
    "Lng": 2508701,
    "Stops": "24502-1,24402-1,24505-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24504-1",
    "SiriID": 5365,
    "Lat": 5944299,
    "Lng": 2511811,
    "Stops": "24503-1,25403-1",
    "Name": "Männisalu",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24503-1",
    "SiriID": 5366,
    "Lat": 5944344,
    "Lng": 2511737,
    "Stops": "24504-1,47034-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14308-1",
    "SiriID": 10796,
    "Lat": 5944468,
    "Lng": 2491656,
    "Stops": "16604-1,47011-1,19105-1",
    "Name": "Pirita jõgi",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26910-1",
    "SiriID": 5379,
    "Lat": 5943619,
    "Lng": 2521842,
    "Stops": "26909-1,25809-1,28101-1",
    "Name": "Ruu",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26909-1",
    "SiriID": 5380,
    "Lat": 5943647,
    "Lng": 2521823,
    "Stops": "26910-1,26107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24404-1",
    "SiriID": 5359,
    "Lat": 5945098,
    "Lng": 2505095,
    "Stops": "24403-1,24701-1",
    "Name": "Vet.Punkt",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24403-1",
    "SiriID": 5360,
    "Lat": 5945136,
    "Lng": 2504948,
    "Stops": "24404-1,27004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "24511-1",
    "SiriID": 3973,
    "Lat": 5947087,
    "Lng": 2514701,
    "Stops": "24512-1,26305-1",
    "Name": "Ristikangru",
    "Info": 3,
    "Street": "Ristikangru tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "27001-1",
    "SiriID": 5482,
    "Lat": 5945400,
    "Lng": 2517868,
    "Stops": "27002-1,26915-1",
    "Name": "Asula tee",
    "Info": "",
    "Street": "Ruu-Ihasalu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26404-1",
    "SiriID": 12133,
    "Lat": 5951977,
    "Lng": 2518923,
    "Stops": "26403-1,26408-1",
    "Name": "Ihasalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26403-1",
    "SiriID": 12134,
    "Lat": 5951923,
    "Lng": 2518943,
    "Stops": "26404-1,47031-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26401-1",
    "SiriID": 5488,
    "Lat": 5950034,
    "Lng": 2518316,
    "Stops": "26402-1,26405-1,26908-1",
    "Name": "Ihasalu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26402-1",
    "SiriID": 5489,
    "Lat": 5950238,
    "Lng": 2518454,
    "Stops": "26401-1,47032-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26301-1",
    "SiriID": 5484,
    "Lat": 5947192,
    "Lng": 2515783,
    "Stops": "26302-1,24515-1",
    "Name": "Jõesuu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26302-1",
    "SiriID": 5485,
    "Lat": 5947289,
    "Lng": 2515656,
    "Stops": "26301-1,26305-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "27002-1",
    "SiriID": 5483,
    "Lat": 5945558,
    "Lng": 2517773,
    "Stops": "27003-1,27001-1",
    "Name": "Jägala-Joa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "27003-1",
    "SiriID": 4251,
    "Lat": 5945707,
    "Lng": 2517776,
    "Stops": "27002-1,24515-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26304-1",
    "SiriID": 5486,
    "Lat": 5947924,
    "Lng": 2515710,
    "Stops": "26303-1",
    "Name": "Metsatoa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26303-1",
    "SiriID": 5487,
    "Lat": 5947911,
    "Lng": 2515753,
    "Stops": "26304-1,26305-1,26406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26503-1",
    "SiriID": 5490,
    "Lat": 5951319,
    "Lng": 2517077,
    "Stops": "26504-1,47031-1",
    "Name": "Metsavahi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26504-1",
    "SiriID": 5491,
    "Lat": 5951340,
    "Lng": 2517052,
    "Stops": "26503-1,26410-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26502-1",
    "SiriID": 5492,
    "Lat": 5952869,
    "Lng": 2515893,
    "Stops": "26501-1,26409-1",
    "Name": "Neeme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26501-1",
    "SiriID": 5493,
    "Lat": 5952945,
    "Lng": 2515889,
    "Stops": "26502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "26902-1",
    "SiriID": 5474,
    "Lat": 5945217,
    "Lng": 2519924,
    "Stops": "47028-1,47029-1,26915-1",
    "Name": "Neeme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47031-1",
    "SiriID": 14443,
    "Lat": 5951385,
    "Lng": 2519193,
    "Stops": "47032-1,26403-1,26503-1",
    "Name": "Rootsikari",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47032-1",
    "SiriID": 14444,
    "Lat": 5951355,
    "Lng": 2519254,
    "Stops": "47031-1,26402-1,26601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47101-1",
    "SiriID": 145,
    "Lat": 5945356,
    "Lng": 2491375,
    "Stops": "47102-1,19108-1,47020-1",
    "Name": "Nurme",
    "Info": 2,
    "Street": "Ämma tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47102-1",
    "SiriID": 146,
    "Lat": 5945343,
    "Lng": 2491370,
    "Stops": "47101-1,16510-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25103-1",
    "SiriID": 5344,
    "Lat": 5943492,
    "Lng": 2494571,
    "Stops": "25105-1,23918-1,25107-1",
    "Name": "Loo",
    "Info": "",
    "Street": "Lagedi-Kostivere",
    "Area": "Jõelähtme vald, Loo alevik",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "25101-1",
    "SiriID": 7173,
    "Lat": 5941601,
    "Lng": 2494465,
    "Stops": "25102-1,43115-1",
    "Name": "Lagedi mõis",
    "Info": "",
    "Street": "Nehatu-Loo-Lagedi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21832-1",
    "SiriID": 12807,
    "Lat": 5930118,
    "Lng": 2442419,
    "Stops": "21825-1,23327-1",
    "Name": "Eha",
    "Info": 3,
    "Street": 0,
    "Area": "Keila linn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21836-1",
    "SiriID": 12770,
    "Lat": 5931331,
    "Lng": 2440634,
    "Stops": "21834-1,21844-1,21851-1",
    "Name": "Haigla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21837-1",
    "SiriID": 12803,
    "Lat": 5930489,
    "Lng": 2442536,
    "Stops": "21825-1,21806-1",
    "Name": "Jõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21833-1",
    "SiriID": 12806,
    "Lat": 5930100,
    "Lng": 2441114,
    "Stops": "21835-1,21830-1",
    "Name": "Kase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21826-1",
    "SiriID": 12045,
    "Lat": 5931095,
    "Lng": 2441365,
    "Stops": "21845-1,21809-1",
    "Name": "Keila algkool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21845-1",
    "SiriID": 165,
    "Lat": 5931052,
    "Lng": 2441229,
    "Stops": "21826-1,21824-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21827-1",
    "SiriID": 11995,
    "Lat": 5931139,
    "Lng": 2442756,
    "Stops": "21842-1,21847-1",
    "Name": "Keila Gümnaasium",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21839-1",
    "SiriID": 14626,
    "Lat": 5931404,
    "Lng": 2441269,
    "Stops": "21851-1,21808-1",
    "Name": "Keila kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21851-1",
    "SiriID": 4217,
    "Lat": 5931416,
    "Lng": 2441226,
    "Stops": "21839-1,21819-1,21836-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21835-1",
    "SiriID": 12745,
    "Lat": 5930106,
    "Lng": 2440804,
    "Stops": "21833-1",
    "Name": "Keila Veskid",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21847-1",
    "SiriID": 169,
    "Lat": 5931033,
    "Lng": 2443061,
    "Stops": "21846-1,21827-1",
    "Name": "Luha",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21842-1",
    "SiriID": 14105,
    "Lat": 5931029,
    "Lng": 2442511,
    "Stops": "21827-1,21852-1,21806-1",
    "Name": "Põhja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21850-1",
    "SiriID": 4265,
    "Lat": 5932333,
    "Lng": 2438759,
    "Stops": "21817-1,21803-1,21771-1",
    "Name": "Sinilille",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21844-1",
    "SiriID": 84,
    "Lat": 5931174,
    "Lng": 2440264,
    "Stops": "21848-1,21836-1",
    "Name": "Sopsu-Tooma",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21816-1",
    "SiriID": 4784,
    "Lat": 5932502,
    "Lng": 2437956,
    "Stops": "21817-1",
    "Name": "SOS lasteküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21817-1",
    "SiriID": 9707,
    "Lat": 5932510,
    "Lng": 2437979,
    "Stops": "21816-1,21765-1,21850-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21834-1",
    "SiriID": 12810,
    "Lat": 5931346,
    "Lng": 2440625,
    "Stops": "21836-1",
    "Name": "Staadion/Keila haigla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21829-1",
    "SiriID": 10756,
    "Lat": 5930620,
    "Lng": 2441663,
    "Stops": "21828-1,21805-1",
    "Name": "Keila",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21828-1",
    "SiriID": 10757,
    "Lat": 5930610,
    "Lng": 2441725,
    "Stops": "21829-1,21818-1,21831-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21803-1",
    "SiriID": 9509,
    "Lat": 5931977,
    "Lng": 2440031,
    "Stops": "21810-1,21850-1",
    "Name": "Harju Elekter",
    "Info": "",
    "Street": "Geoloogide tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21804-1",
    "SiriID": 4799,
    "Lat": 5930744,
    "Lng": 2441449,
    "Stops": "21805-1,21824-1",
    "Name": "Jaama",
    "Info": "",
    "Street": "Haapsalu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21805-1",
    "SiriID": 4800,
    "Lat": 5930728,
    "Lng": 2441465,
    "Stops": "21804-1,21829-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21822-1",
    "SiriID": 11541,
    "Lat": 5930823,
    "Lng": 2442190,
    "Stops": "21838-1,21821-1,21806-1",
    "Name": "Keskväljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21838-1",
    "SiriID": 13861,
    "Lat": 5930817,
    "Lng": 2442132,
    "Stops": "21822-1,21818-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21823-1",
    "SiriID": 11542,
    "Lat": 5930768,
    "Lng": 2440859,
    "Stops": "21824-1,21848-1",
    "Name": "Männiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21824-1",
    "SiriID": 11543,
    "Lat": 5930761,
    "Lng": 2440972,
    "Stops": "21823-1,21804-1,21845-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21813-1",
    "SiriID": 4801,
    "Lat": 5930776,
    "Lng": 2439783,
    "Stops": "21812-1,47421-1",
    "Name": "Rõõmu kaubamaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21812-1",
    "SiriID": 4802,
    "Lat": 5930777,
    "Lng": 2440099,
    "Stops": "21849-1,21813-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21818-1",
    "SiriID": 10214,
    "Lat": 5930666,
    "Lng": 2441770,
    "Stops": "21828-1,21838-1",
    "Name": "Keila raudteejaam",
    "Info": "",
    "Street": "Jaama tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21806-1",
    "SiriID": 4794,
    "Lat": 5930792,
    "Lng": 2442691,
    "Stops": "21842-1,21822-1,21837-1",
    "Name": "Keila kirik",
    "Info": "",
    "Street": "Keskväljak",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21848-1",
    "SiriID": 170,
    "Lat": 5930934,
    "Lng": 2440283,
    "Stops": "21849-1,21844-1,21823-1",
    "Name": "Vaikne",
    "Info": "",
    "Street": "Kruusa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21849-1",
    "SiriID": 171,
    "Lat": 5930914,
    "Lng": 2440261,
    "Stops": "21848-1,21812-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21846-1",
    "SiriID": 168,
    "Lat": 5931063,
    "Lng": 2443151,
    "Stops": "21847-1,21513-1",
    "Name": "Luha",
    "Info": "",
    "Street": "Luha",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21852-1",
    "SiriID": 4695,
    "Lat": 5930875,
    "Lng": 2442190,
    "Stops": "21821-1,21842-1",
    "Name": "Keskväljak",
    "Info": "",
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21821-1",
    "SiriID": 11540,
    "Lat": 5930873,
    "Lng": 2442171,
    "Stops": "21852-1,21822-1,21843-1",
    "Name": "Kultuurikeskus/Keskväljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21808-1",
    "SiriID": 4797,
    "Lat": 5931151,
    "Lng": 2441537,
    "Stops": "21809-1,21839-1",
    "Name": "Pargi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21809-1",
    "SiriID": 4798,
    "Lat": 5931130,
    "Lng": 2441540,
    "Stops": "21808-1,21843-1,21826-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21819-1",
    "SiriID": 11538,
    "Lat": 5931560,
    "Lng": 2441197,
    "Stops": "21820-1,21851-1",
    "Name": "Tervisekeskus/Harju KEK",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21820-1",
    "SiriID": 11539,
    "Lat": 5931602,
    "Lng": 2441173,
    "Stops": "21819-1,21811-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21843-1",
    "SiriID": 66,
    "Lat": 5931134,
    "Lng": 2441668,
    "Stops": "21809-1,21821-1",
    "Name": "Piiri",
    "Info": "",
    "Street": "Piiri tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21830-1",
    "SiriID": 12805,
    "Lat": 5930363,
    "Lng": 2441429,
    "Stops": "21831-1,21833-1",
    "Name": "Aia",
    "Info": "",
    "Street": "Tähe tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21831-1",
    "SiriID": 12804,
    "Lat": 5930361,
    "Lng": 2441674,
    "Stops": "21830-1,21828-1,21825-1",
    "Name": "Tähe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21825-1",
    "SiriID": 11544,
    "Lat": 5930342,
    "Lng": 2442554,
    "Stops": "21837-1,21832-1,21831-1",
    "Name": "Ülesõidu",
    "Info": "",
    "Street": "Ülesõidu tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22862-1",
    "SiriID": 6106,
    "Lat": 5918347,
    "Lng": 2408564,
    "Stops": "22802-1,22829-1",
    "Name": "Audevälja",
    "Info": 4,
    "Street": 0,
    "Area": "Keila vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22865-1",
    "SiriID": 6104,
    "Lat": 5919920,
    "Lng": 2395133,
    "Stops": "22864-1",
    "Name": "Hatu-Roosi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22867-1",
    "SiriID": 6081,
    "Lat": 5929144,
    "Lng": 2415421,
    "Stops": "22866-1,47418-1",
    "Name": "Hobuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47404-1",
    "SiriID": 6073,
    "Lat": 5933238,
    "Lng": 2429508,
    "Stops": "21704-1,21759-1",
    "Name": "Illurmaa",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23514-1",
    "SiriID": 6096,
    "Lat": 5923808,
    "Lng": 2428915,
    "Stops": "23515-1,23506-1",
    "Name": "Jaama pood",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23515-1",
    "SiriID": 6095,
    "Lat": 5923768,
    "Lng": 2429464,
    "Stops": "23513-1,23514-1,21956-1",
    "Name": "Jaama rist",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23516-1",
    "SiriID": 6094,
    "Lat": 5924267,
    "Lng": 2429967,
    "Stops": "23517-1,23510-1",
    "Name": "Jaani rist",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47406-1",
    "SiriID": 6074,
    "Lat": 5933639,
    "Lng": 2424621,
    "Stops": "21793-1,21799-1,21773-1",
    "Name": "Karjaaia",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23518-1",
    "SiriID": 6093,
    "Lat": 5924378,
    "Lng": 2427960,
    "Stops": "23506-1,21761-1",
    "Name": "Karlimetsa",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47409-1",
    "SiriID": 6066,
    "Lat": 5936896,
    "Lng": 2426566,
    "Stops": "47410-1,47408-1,47412-1",
    "Name": "Karukella",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22868-1",
    "SiriID": 6087,
    "Lat": 5923605,
    "Lng": 2401990,
    "Stops": "22849-1,22874-1",
    "Name": "Kase-Andrese",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22869-1",
    "SiriID": 6107,
    "Lat": 5922206,
    "Lng": 2410828,
    "Stops": "22816-1,22847-1",
    "Name": "Kinksi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47411-1",
    "SiriID": 6069,
    "Lat": 5936828,
    "Lng": 2425865,
    "Stops": "47410-1",
    "Name": "Kirjatuvi",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47407-1",
    "SiriID": 6076,
    "Lat": 5932035,
    "Lng": 2423336,
    "Stops": "47403-1,21773-1",
    "Name": "Klooga kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21796-1",
    "SiriID": 24,
    "Lat": 5934451,
    "Lng": 2424779,
    "Stops": "21780-1,47422-1,21713-1",
    "Name": "Kloogaranna rongijaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22872-1",
    "SiriID": 6090,
    "Lat": 5918182,
    "Lng": 2413843,
    "Stops": "22871-1,22829-1",
    "Name": "Kobru",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47420-1",
    "SiriID": 6100,
    "Lat": 5928526,
    "Lng": 2435327,
    "Stops": "21782-1,47415-1",
    "Name": "Kulna jaam",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47410-1",
    "SiriID": 6067,
    "Lat": 5936660,
    "Lng": 2426148,
    "Stops": "47411-1,47409-1,21748-1",
    "Name": "Kurekella",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22866-1",
    "SiriID": 6082,
    "Lat": 5928797,
    "Lng": 2412779,
    "Stops": "22812-1,22867-1,22853-1",
    "Name": "Kõrtsu",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "474114-1",
    "SiriID": 6072,
    "Lat": 5933531,
    "Lng": 2427988,
    "Stops": "21760-1,21759-1",
    "Name": "Kõviste",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22874-1",
    "SiriID": 6086,
    "Lat": 5927625,
    "Lng": 2402892,
    "Stops": "22868-1",
    "Name": "Kärre",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47408-1",
    "SiriID": 6098,
    "Lat": 5936814,
    "Lng": 2427213,
    "Stops": "47409-1,21721-1",
    "Name": "Liivoja rist",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47423-1",
    "SiriID": 6077,
    "Lat": 5930648,
    "Lng": 2424702,
    "Stops": "21776-1",
    "Name": "Lodijärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23517-1",
    "SiriID": 6102,
    "Lat": 5924281,
    "Lng": 2429786,
    "Stops": "23516-1",
    "Name": "Mardi",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47422-1",
    "SiriID": 6075,
    "Lat": 5934749,
    "Lng": 2424931,
    "Stops": "21796-1,21715-1",
    "Name": "Mere põik",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22871-1",
    "SiriID": 6105,
    "Lat": 5916620,
    "Lng": 2411057,
    "Stops": "22870-1,22801-1,22872-1",
    "Name": "Metsarahu",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47419-1",
    "SiriID": 6097,
    "Lat": 5933728,
    "Lng": 2437457,
    "Stops": "21769-1,21706-1,21755-1",
    "Name": "Metsa-Uuetoa",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22870-1",
    "SiriID": 6089,
    "Lat": 5915810,
    "Lng": 2410593,
    "Stops": "22871-1,22826-1",
    "Name": "Metslõugu",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47415-1",
    "SiriID": 6099,
    "Lat": 5927296,
    "Lng": 2436348,
    "Stops": "21775-1,47420-1",
    "Name": "Mõisahärra",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22863-1",
    "SiriID": 6103,
    "Lat": 5921947,
    "Lng": 2404060,
    "Stops": "22807-1,22822-1",
    "Name": "Mäeklause",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22919-1",
    "SiriID": 6084,
    "Lat": 5935136,
    "Lng": 2405332,
    "Stops": "22901-1,22903-1",
    "Name": "Nikolai kirik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22864-1",
    "SiriID": 6088,
    "Lat": 5920831,
    "Lng": 2401390,
    "Stops": "22822-1,22865-1",
    "Name": "Pae kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22918-1",
    "SiriID": 6083,
    "Lat": 5935653,
    "Lng": 2405288,
    "Stops": "22901-1",
    "Name": "Peetri kindlus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47412-1",
    "SiriID": 6068,
    "Lat": 5937596,
    "Lng": 2426167,
    "Stops": "47409-1,21723-1,21763-1",
    "Name": "Postipoiss",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47405-1",
    "SiriID": 6071,
    "Lat": 5934854,
    "Lng": 2427939,
    "Stops": "21754-1",
    "Name": "Põhja Pauli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47417-1",
    "SiriID": 6079,
    "Lat": 5932955,
    "Lng": 2417385,
    "Stops": "21786-1,21716-1,22908-1",
    "Name": "Põllküla jaam",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23520-1",
    "SiriID": 6092,
    "Lat": 5923138,
    "Lng": 2421765,
    "Stops": "23504-1,23501-1",
    "Name": "Pärniti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23519-1",
    "SiriID": 6091,
    "Lat": 5923200,
    "Lng": 2419941,
    "Stops": "23503-1,22820-1",
    "Name": "Rummu sireli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47413-1",
    "SiriID": 6070,
    "Lat": 5937575,
    "Lng": 2424170,
    "Stops": "47401-1,21701-1",
    "Name": "Spordihoone",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47421-1",
    "SiriID": 6101,
    "Lat": 5930504,
    "Lng": 2438321,
    "Stops": "21794-1,21746-1,21813-1",
    "Name": "Tamme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47418-1",
    "SiriID": 6080,
    "Lat": 5930667,
    "Lng": 2417698,
    "Stops": "47416-1,22867-1",
    "Name": "Tammi",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22873-1",
    "SiriID": 6085,
    "Lat": 5925483,
    "Lng": 2417231,
    "Stops": "22810-1,22827-1",
    "Name": "Veti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47416-1",
    "SiriID": 6078,
    "Lat": 5932064,
    "Lng": 2418040,
    "Stops": "21785-1,47418-1,21779-1",
    "Name": "Väike Kadri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21776-1",
    "SiriID": 10767,
    "Lat": 5932046,
    "Lng": 2425826,
    "Stops": "21772-1,21777-1,47423-1",
    "Name": "Klooga",
    "Info": 3,
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21777-1",
    "SiriID": 10768,
    "Lat": 5932031,
    "Lng": 2425898,
    "Stops": "21776-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21778-1",
    "SiriID": 10777,
    "Lat": 5932447,
    "Lng": 2421827,
    "Stops": "21779-1,21774-1",
    "Name": "Klooga aedlinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21779-1",
    "SiriID": 10778,
    "Lat": 5932463,
    "Lng": 2421724,
    "Stops": "21778-1,21729-1,47416-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21780-1",
    "SiriID": 16,
    "Lat": 5934379,
    "Lng": 2424595,
    "Stops": "21793-1,21796-1",
    "Name": "Kloogaranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21793-1",
    "SiriID": 17,
    "Lat": 5934350,
    "Lng": 2424584,
    "Stops": "21780-1,47406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21781-1",
    "SiriID": 12307,
    "Lat": 5928632,
    "Lng": 2435439,
    "Stops": "21782-1,21718-1",
    "Name": "Kulna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21782-1",
    "SiriID": 12308,
    "Lat": 5928625,
    "Lng": 2435444,
    "Stops": "21781-1,47420-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22907-1",
    "SiriID": 10785,
    "Lat": 5933323,
    "Lng": 2413445,
    "Stops": "22908-1,22854-1",
    "Name": "Laoküla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22908-1",
    "SiriID": 10786,
    "Lat": 5933317,
    "Lng": 2413454,
    "Stops": "22907-1,47417-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21783-1",
    "SiriID": 10763,
    "Lat": 5931408,
    "Lng": 2432034,
    "Stops": "21784-1,21740-1",
    "Name": "Niitvälja",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21784-1",
    "SiriID": 10764,
    "Lat": 5931397,
    "Lng": 2432076,
    "Stops": "21783-1,21719-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21786-1",
    "SiriID": 10781,
    "Lat": 5932907,
    "Lng": 2417417,
    "Stops": "21785-1,47417-1",
    "Name": "Põllküla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21785-1",
    "SiriID": 10782,
    "Lat": 5932899,
    "Lng": 2417433,
    "Stops": "21786-1,47416-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47403-1",
    "SiriID": 5944,
    "Lat": 5932076,
    "Lng": 2422814,
    "Stops": "21774-1,47407-1",
    "Name": "Klooga aedlinna",
    "Info": 3,
    "Street": "Aedlinna tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21794-1",
    "SiriID": 62,
    "Lat": 5930291,
    "Lng": 2438395,
    "Stops": "47421-1",
    "Name": "Tammermaa",
    "Info": "",
    "Street": "Kadaka tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21706-1",
    "SiriID": 10364,
    "Lat": 5933916,
    "Lng": 2439194,
    "Stops": "21771-1,47419-1",
    "Name": "Karjaküla",
    "Info": "",
    "Street": "Karjaküla tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21771-1",
    "SiriID": 9714,
    "Lat": 5933396,
    "Lng": 2439877,
    "Stops": "21706-1,21850-1,21514-1",
    "Name": "Talu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21718-1",
    "SiriID": 4773,
    "Lat": 5929450,
    "Lng": 2434242,
    "Stops": "21719-1,21725-1,21781-1",
    "Name": "Kulna",
    "Info": "",
    "Street": "Keila - Haapsalu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21719-1",
    "SiriID": 4774,
    "Lat": 5929471,
    "Lng": 2434282,
    "Stops": "21718-1,21747-1,21784-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21724-1",
    "SiriID": 4775,
    "Lat": 5929006,
    "Lng": 2433132,
    "Stops": "21725-1,21731-1",
    "Name": "Lehola",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21725-1",
    "SiriID": 4776,
    "Lat": 5929030,
    "Lng": 2433295,
    "Stops": "21724-1,21718-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21730-1",
    "SiriID": 4778,
    "Lat": 5927965,
    "Lng": 2430659,
    "Stops": "21731-1,21733-1",
    "Name": "Luuri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21731-1",
    "SiriID": 4779,
    "Lat": 5928002,
    "Lng": 2430821,
    "Stops": "21730-1,21724-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21732-1",
    "SiriID": 4780,
    "Lat": 5926476,
    "Lng": 2429153,
    "Stops": "21733-1,21762-1",
    "Name": "Maeru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21733-1",
    "SiriID": 4781,
    "Lat": 5926654,
    "Lng": 2429193,
    "Stops": "21732-1,21730-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21747-1",
    "SiriID": 4771,
    "Lat": 5930520,
    "Lng": 2437300,
    "Stops": "21746-1,21719-1",
    "Name": "Pusuvälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21746-1",
    "SiriID": 4772,
    "Lat": 5930526,
    "Lng": 2437516,
    "Stops": "21747-1,47421-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21761-1",
    "SiriID": 4782,
    "Lat": 5925281,
    "Lng": 2428335,
    "Stops": "21762-1,23518-1",
    "Name": "Viigardi",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21762-1",
    "SiriID": 4783,
    "Lat": 5925320,
    "Lng": 2428380,
    "Stops": "21761-1,21732-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21766-1",
    "SiriID": 9687,
    "Lat": 5933247,
    "Lng": 2437263,
    "Stops": "21767-1,21814-1",
    "Name": "Karjaküla kalmistu",
    "Info": 3,
    "Street": "KEILA - KEILA-JOA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21767-1",
    "SiriID": 9712,
    "Lat": 5933253,
    "Lng": 2437242,
    "Stops": "21766-1,21768-1,21797-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21721-1",
    "SiriID": 4756,
    "Lat": 5937570,
    "Lng": 2430933,
    "Stops": "21720-1,47202-1,21708-1,47408-1",
    "Name": "Käesalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21720-1",
    "SiriID": 4757,
    "Lat": 5937541,
    "Lng": 2430959,
    "Stops": "21721-1,21739-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21738-1",
    "SiriID": 4758,
    "Lat": 5936008,
    "Lng": 2433859,
    "Stops": "21739-1,21756-1",
    "Name": "Muuluka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21739-1",
    "SiriID": 4759,
    "Lat": 5936030,
    "Lng": 2433856,
    "Stops": "21738-1,47207-1,21720-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21755-1",
    "SiriID": 4760,
    "Lat": 5934734,
    "Lng": 2435560,
    "Stops": "21756-1,47419-1",
    "Name": "Tõmmiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21756-1",
    "SiriID": 4761,
    "Lat": 5934761,
    "Lng": 2435539,
    "Stops": "21755-1,21738-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21772-1",
    "SiriID": 10575,
    "Lat": 5932081,
    "Lng": 2425799,
    "Stops": "21773-1,21776-1",
    "Name": "Klooga raudteejaam",
    "Info": "",
    "Street": "Klooga jaama tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21773-1",
    "SiriID": 10576,
    "Lat": 5932081,
    "Lng": 2425760,
    "Stops": "21772-1,47407-1,47406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21736-1",
    "SiriID": 4674,
    "Lat": 5940462,
    "Lng": 2429854,
    "Stops": "21737-1,47217-1,21791-1",
    "Name": "Internaatkool",
    "Info": "",
    "Street": "Klooga mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21737-1",
    "SiriID": 4675,
    "Lat": 5940573,
    "Lng": 2429777,
    "Stops": "21736-1,21757-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21709-1",
    "SiriID": 4672,
    "Lat": 5939565,
    "Lng": 2430084,
    "Stops": "21708-1,21792-1",
    "Name": "Keila-Joa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21708-1",
    "SiriID": 4673,
    "Lat": 5939493,
    "Lng": 2429956,
    "Stops": "47402-1,21709-1,21721-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47402-1",
    "SiriID": 31,
    "Lat": 5939500,
    "Lng": 2429860,
    "Stops": "21708-1,21764-1",
    "Name": "Keila-Joa mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21714-1",
    "SiriID": 4744,
    "Lat": 5935260,
    "Lng": 2425897,
    "Stops": "21715-1,21749-1",
    "Name": "Kloogaranna noortelaager",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21715-1",
    "SiriID": 4745,
    "Lat": 5935171,
    "Lng": 2425956,
    "Stops": "21714-1,47422-1,21754-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21763-1",
    "SiriID": 4670,
    "Lat": 5939466,
    "Lng": 2428156,
    "Stops": "21764-1,21734-1,47412-1",
    "Name": "Kungla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21764-1",
    "SiriID": 4671,
    "Lat": 5939425,
    "Lng": 2428309,
    "Stops": "21763-1,47402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21749-1",
    "SiriID": 4742,
    "Lat": 5936202,
    "Lng": 2425465,
    "Stops": "21748-1,21714-1",
    "Name": "Kõltsu mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21748-1",
    "SiriID": 4743,
    "Lat": 5936278,
    "Lng": 2425384,
    "Stops": "21749-1,47410-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21722-1",
    "SiriID": 4740,
    "Lat": 5937819,
    "Lng": 2423807,
    "Stops": "21707-1,21795-1",
    "Name": "Laulasmaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21795-1",
    "SiriID": 210,
    "Lat": 5937975,
    "Lng": 2423747,
    "Stops": "21722-1,21744-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21723-1",
    "SiriID": 4741,
    "Lat": 5937475,
    "Lng": 2424263,
    "Stops": "47401-1,47412-1",
    "Name": "Laulasmaa kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21701-1",
    "SiriID": 211,
    "Lat": 5937575,
    "Lng": 2424071,
    "Stops": "47413-1,21707-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47401-1",
    "SiriID": 4668,
    "Lat": 5937567,
    "Lng": 2424170,
    "Stops": "47413-1,21723-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21735-1",
    "SiriID": 4667,
    "Lat": 5939682,
    "Lng": 2427325,
    "Stops": "21734-1,21752-1",
    "Name": "Meremõisa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21734-1",
    "SiriID": 4669,
    "Lat": 5939603,
    "Lng": 2427552,
    "Stops": "21735-1,21763-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21789-1",
    "SiriID": 13263,
    "Lat": 5934265,
    "Lng": 2426723,
    "Stops": "21790-1,21753-1",
    "Name": "Raudkotka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21790-1",
    "SiriID": 13264,
    "Lat": 5934253,
    "Lng": 2426676,
    "Stops": "21789-1,21713-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21751-1",
    "SiriID": 4736,
    "Lat": 5938567,
    "Lng": 2423673,
    "Stops": "21752-1,21745-1,21702-1",
    "Name": "Tornimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21752-1",
    "SiriID": 4737,
    "Lat": 5938590,
    "Lng": 2423721,
    "Stops": "21751-1,21735-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21753-1",
    "SiriID": 4746,
    "Lat": 5934480,
    "Lng": 2426812,
    "Stops": "21754-1,21789-1",
    "Name": "Treppoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21754-1",
    "SiriID": 4747,
    "Lat": 5934533,
    "Lng": 2426844,
    "Stops": "21753-1,47405-1,21715-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21757-1",
    "SiriID": 4676,
    "Lat": 5941075,
    "Lng": 2430120,
    "Stops": "21758-1,21737-1",
    "Name": "Türisalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21758-1",
    "SiriID": 4677,
    "Lat": 5941084,
    "Lng": 2430227,
    "Stops": "21757-1,47214-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21791-1",
    "SiriID": 13350,
    "Lat": 5939919,
    "Lng": 2430249,
    "Stops": "21792-1,21736-1",
    "Name": "Vääna tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21792-1",
    "SiriID": 13351,
    "Lat": 5939855,
    "Lng": 2430301,
    "Stops": "21791-1,21709-1,21578-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21775-1",
    "SiriID": 12251,
    "Lat": 5926812,
    "Lng": 2437140,
    "Stops": "47415-1",
    "Name": "Ohtu",
    "Info": "",
    "Street": "Kulna - Vasalemma",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21702-1",
    "SiriID": 4734,
    "Lat": 5938789,
    "Lng": 2422111,
    "Stops": "21703-1,21751-1",
    "Name": "Heliküla",
    "Info": "",
    "Street": "LAULASMAA - LOHUSALU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21703-1",
    "SiriID": 4735,
    "Lat": 5938813,
    "Lng": 2422049,
    "Stops": "21702-1,21742-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21726-1",
    "SiriID": 4730,
    "Lat": 5939597,
    "Lng": 2420227,
    "Stops": "21727-1,21743-1",
    "Name": "Lohusalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21727-1",
    "SiriID": 4731,
    "Lat": 5939598,
    "Lng": 2420215,
    "Stops": "21726-1,21728-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21728-1",
    "SiriID": 4729,
    "Lat": 5940102,
    "Lng": 2420167,
    "Stops": "21727-1",
    "Name": "Lohusalu sadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21707-1",
    "SiriID": 209,
    "Lat": 5937727,
    "Lng": 2423770,
    "Stops": "21722-1,21701-1",
    "Name": "Lohusalu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21742-1",
    "SiriID": 4732,
    "Lat": 5939081,
    "Lng": 2421299,
    "Stops": "21743-1,21703-1",
    "Name": "Pansionaat",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21743-1",
    "SiriID": 4733,
    "Lat": 5939101,
    "Lng": 2421229,
    "Stops": "21742-1,21726-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21744-1",
    "SiriID": 4738,
    "Lat": 5938076,
    "Lng": 2423282,
    "Stops": "21745-1,21795-1",
    "Name": "Puhkekodu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21745-1",
    "SiriID": 4739,
    "Lat": 5938157,
    "Lng": 2423209,
    "Stops": "21744-1,21751-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21768-1",
    "SiriID": 9688,
    "Lat": 5933642,
    "Lng": 2437325,
    "Stops": "21769-1,21767-1",
    "Name": "Uuetoa",
    "Info": "",
    "Street": "Naaritsa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21769-1",
    "SiriID": 9713,
    "Lat": 5933656,
    "Lng": 2437373,
    "Stops": "21768-1,47419-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21729-1",
    "SiriID": 4412,
    "Lat": 5933785,
    "Lng": 2422503,
    "Stops": "21799-1,21711-1,21779-1",
    "Name": "Adruvahi",
    "Info": "",
    "Street": "Paldiski maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21799-1",
    "SiriID": 4413,
    "Lat": 5933742,
    "Lng": 2422818,
    "Stops": "21729-1,47406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21710-1",
    "SiriID": 4764,
    "Lat": 5933965,
    "Lng": 2420710,
    "Stops": "21711-1,21788-1",
    "Name": "Aida",
    "Info": 4,
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21711-1",
    "SiriID": 4765,
    "Lat": 5933938,
    "Lng": 2420792,
    "Stops": "21710-1,21729-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21797-1",
    "SiriID": 3478,
    "Lat": 5933008,
    "Lng": 2434734,
    "Stops": "21798-1,21767-1",
    "Name": "Benita kodu",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21798-1",
    "SiriID": 4082,
    "Lat": 5933015,
    "Lng": 2434701,
    "Stops": "21797-1,21741-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21810-1",
    "SiriID": 4788,
    "Lat": 5931988,
    "Lng": 2440366,
    "Stops": "21811-1,21803-1",
    "Name": "Ehitusmarket",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21811-1",
    "SiriID": 4789,
    "Lat": 5931920,
    "Lng": 2440521,
    "Stops": "21810-1,21820-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21704-1",
    "SiriID": 4752,
    "Lat": 5932859,
    "Lng": 2430207,
    "Stops": "21705-1,47404-1",
    "Name": "Illuka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21705-1",
    "SiriID": 4753,
    "Lat": 5932803,
    "Lng": 2430342,
    "Stops": "21704-1,21740-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21712-1",
    "SiriID": 4748,
    "Lat": 5934112,
    "Lng": 2426728,
    "Stops": "21713-1,21760-1",
    "Name": "Kloogaranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21713-1",
    "SiriID": 4749,
    "Lat": 5934142,
    "Lng": 2426537,
    "Stops": "21712-1,21790-1,21796-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22854-1",
    "SiriID": 13435,
    "Lat": 5932707,
    "Lng": 2411823,
    "Stops": "22855-1,22907-1,22912-1",
    "Name": "Laoküla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22855-1",
    "SiriID": 13856,
    "Lat": 5932609,
    "Lng": 2411944,
    "Stops": "22854-1,22852-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21814-1",
    "SiriID": 4786,
    "Lat": 5932665,
    "Lng": 2437638,
    "Stops": "21765-1,21766-1",
    "Name": "Mudaaugu",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21765-1",
    "SiriID": 4787,
    "Lat": 5932646,
    "Lng": 2437817,
    "Stops": "21814-1,21817-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21740-1",
    "SiriID": 4754,
    "Lat": 5932819,
    "Lng": 2432668,
    "Stops": "21741-1,21705-1,21783-1",
    "Name": "Niitvälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21741-1",
    "SiriID": 4755,
    "Lat": 5932871,
    "Lng": 2432964,
    "Stops": "21740-1,21798-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21787-1",
    "SiriID": 12413,
    "Lat": 5934294,
    "Lng": 2419444,
    "Stops": "21788-1,21717-1",
    "Name": "Põllküla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21788-1",
    "SiriID": 12414,
    "Lat": 5934231,
    "Lng": 2419624,
    "Stops": "21787-1,21710-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21759-1",
    "SiriID": 4750,
    "Lat": 5933556,
    "Lng": 2428232,
    "Stops": "474114-1,47404-1",
    "Name": "Türnpu park",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21760-1",
    "SiriID": 4751,
    "Lat": 5933626,
    "Lng": 2427965,
    "Stops": "474114-1,21712-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21717-1",
    "SiriID": 4767,
    "Lat": 5934482,
    "Lng": 2418551,
    "Stops": "21716-1,21787-1",
    "Name": "Vanaranna tee/ Kersalu",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21774-1",
    "SiriID": 10577,
    "Lat": 5932110,
    "Lng": 2422627,
    "Stops": "47403-1,21778-1",
    "Name": "Klooga pood",
    "Info": 3,
    "Street": "Aedlinna tee",
    "Area": "Keila vald, Klooga alevik",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22053-1",
    "SiriID": 12988,
    "Lat": 5935384,
    "Lng": 2477397,
    "Stops": "22020-1,22019-1",
    "Name": "Kangru",
    "Info": 2,
    "Street": "KURNA - TUHALA",
    "Area": "Kiili vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22062-1",
    "SiriID": 14571,
    "Lat": 5930270,
    "Lng": 2484286,
    "Stops": "22007-1,22014-1",
    "Name": "Kiili kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22009-1",
    "SiriID": 5062,
    "Lat": 5933215,
    "Lng": 2482320,
    "Stops": "43182-1,22041-1",
    "Name": "Kurna tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22011-1",
    "SiriID": 5059,
    "Lat": 5934316,
    "Lng": 2480811,
    "Stops": "22012-1,22005-1",
    "Name": "Lauri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22014-1",
    "SiriID": 5073,
    "Lat": 5929413,
    "Lng": 2485184,
    "Stops": "22013-1,22062-1",
    "Name": "Lähtse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22063-1",
    "SiriID": 14569,
    "Lat": 5930988,
    "Lng": 2483271,
    "Stops": "22064-1,22007-1",
    "Name": "Maksima",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22064-1",
    "SiriID": 14570,
    "Lat": 5931052,
    "Lng": 2483174,
    "Stops": "22063-1,22032-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22024-1",
    "SiriID": 5074,
    "Lat": 5928474,
    "Lng": 2487603,
    "Stops": "22023-1,22015-1",
    "Name": "Paali",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22067-1",
    "SiriID": 5824,
    "Lat": 5925941,
    "Lng": 2488494,
    "Stops": "22066-1,22025-1,22069-1",
    "Name": "Siberi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22066-1",
    "SiriID": 5825,
    "Lat": 5925963,
    "Lng": 2488518,
    "Stops": "22067-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22065-1",
    "SiriID": 2385,
    "Lat": 5923964,
    "Lng": 2491138,
    "Stops": "22068-1,22152-1",
    "Name": "Sõmeru küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22068-1",
    "SiriID": 5826,
    "Lat": 5924521,
    "Lng": 2489866,
    "Stops": "22069-1,22065-1",
    "Name": "Toominga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22069-1",
    "SiriID": 5827,
    "Lat": 5924535,
    "Lng": 2489816,
    "Stops": "22068-1,22067-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22008-1",
    "SiriID": 5068,
    "Lat": 5930777,
    "Lng": 2483796,
    "Stops": "22007-1",
    "Name": "Kiili",
    "Info": "",
    "Street": "Kurtna - Tuhala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22007-1",
    "SiriID": 5069,
    "Lat": 5930767,
    "Lng": 2483764,
    "Stops": "22008-1,22063-1,22062-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22012-1",
    "SiriID": 5058,
    "Lat": 5934248,
    "Lng": 2480794,
    "Stops": "22011-1,22043-1",
    "Name": "Lauri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22013-1",
    "SiriID": 5072,
    "Lat": 5929305,
    "Lng": 2485346,
    "Stops": "22014-1,22023-1",
    "Name": "Lähtse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22005-1",
    "SiriID": 5054,
    "Lat": 5934753,
    "Lng": 2480083,
    "Stops": "22006-1,22011-1",
    "Name": "Metsavahi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22006-1",
    "SiriID": 5055,
    "Lat": 5934810,
    "Lng": 2479943,
    "Stops": "22005-1,22035-1,43161-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22032-1",
    "SiriID": 5066,
    "Lat": 5932188,
    "Lng": 2481818,
    "Stops": "22031-1,22064-1,22046-1",
    "Name": "Miku tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22031-1",
    "SiriID": 5067,
    "Lat": 5932293,
    "Lng": 2481885,
    "Stops": "22032-1,22042-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22016-1",
    "SiriID": 5076,
    "Lat": 5927518,
    "Lng": 2487461,
    "Stops": "22015-1,22025-1",
    "Name": "Nabala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22015-1",
    "SiriID": 5077,
    "Lat": 5927519,
    "Lng": 2487474,
    "Stops": "22016-1,22024-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22042-1",
    "SiriID": 5064,
    "Lat": 5932680,
    "Lng": 2482056,
    "Stops": "22041-1,22031-1",
    "Name": "Oti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22041-1",
    "SiriID": 5065,
    "Lat": 5932809,
    "Lng": 2482145,
    "Stops": "22042-1,22009-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22023-1",
    "SiriID": 5075,
    "Lat": 5928491,
    "Lng": 2487618,
    "Stops": "22024-1,22013-1,43169-1",
    "Name": "Paali",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22044-1",
    "SiriID": 5060,
    "Lat": 5933849,
    "Lng": 2481352,
    "Stops": "22043-1,22010-1",
    "Name": "Ööbiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22043-1",
    "SiriID": 5061,
    "Lat": 5933861,
    "Lng": 2481264,
    "Stops": "22044-1,22012-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22020-1",
    "SiriID": 5045,
    "Lat": 5935428,
    "Lng": 2477257,
    "Stops": "22053-1,06613-1",
    "Name": "Kangru",
    "Info": "",
    "Street": "Tallinn - Rapla - Türi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22019-1",
    "SiriID": 5044,
    "Lat": 5935285,
    "Lng": 2477326,
    "Stops": "22053-1,22035-1",
    "Name": "Kangru/Nabala tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22040-1",
    "SiriID": 5048,
    "Lat": 5933226,
    "Lng": 2477342,
    "Stops": "22039-1,22046-1",
    "Name": "Luige",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22039-1",
    "SiriID": 5049,
    "Lat": 5933325,
    "Lng": 2477392,
    "Stops": "22040-1,22054-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22036-1",
    "SiriID": 5046,
    "Lat": 5934588,
    "Lng": 2477402,
    "Stops": "22035-1,22055-1",
    "Name": "Mareti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22035-1",
    "SiriID": 5047,
    "Lat": 5934741,
    "Lng": 2477433,
    "Stops": "22036-1,22019-1,22006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22028-1",
    "SiriID": 5050,
    "Lat": 5931866,
    "Lng": 2476106,
    "Stops": "22027-1,23238-1",
    "Name": "Sausti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22027-1",
    "SiriID": 5051,
    "Lat": 5931958,
    "Lng": 2476348,
    "Stops": "22028-1,22047-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22054-1",
    "SiriID": 12986,
    "Lat": 5933926,
    "Lng": 2477385,
    "Stops": "22055-1,22039-1",
    "Name": "Tammejärve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22055-1",
    "SiriID": 12987,
    "Lat": 5933955,
    "Lng": 2477408,
    "Stops": "22054-1,22036-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22046-1",
    "SiriID": 9792,
    "Lat": 5932660,
    "Lng": 2477853,
    "Stops": "22047-1,22040-1,22032-1",
    "Name": "Ringtee",
    "Info": "",
    "Street": "Tallinna ringtee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22047-1",
    "SiriID": 9793,
    "Lat": 5932513,
    "Lng": 2477529,
    "Stops": "22046-1,22027-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22003-1",
    "SiriID": 5082,
    "Lat": 5926300,
    "Lng": 2481835,
    "Stops": "22004-1,22021-1",
    "Name": "Arusta",
    "Info": "",
    "Street": "Tõdva - Nabala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22004-1",
    "SiriID": 5083,
    "Lat": 5926277,
    "Lng": 2481912,
    "Stops": "22003-1,22018-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22022-1",
    "SiriID": 5084,
    "Lat": 5926569,
    "Lng": 2480135,
    "Stops": "22021-1,22033-1",
    "Name": "Nõmmiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22021-1",
    "SiriID": 5085,
    "Lat": 5926563,
    "Lng": 2480142,
    "Stops": "22022-1,22003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22018-1",
    "SiriID": 5080,
    "Lat": 5926320,
    "Lng": 2483706,
    "Stops": "22017-1,22004-1",
    "Name": "Paekna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22017-1",
    "SiriID": 5081,
    "Lat": 5926300,
    "Lng": 2483773,
    "Stops": "22018-1,22026-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22026-1",
    "SiriID": 5078,
    "Lat": 5926218,
    "Lng": 2486124,
    "Stops": "22025-1,22017-1",
    "Name": "Piissoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22025-1",
    "SiriID": 5079,
    "Lat": 5926221,
    "Lng": 2486192,
    "Stops": "22026-1,22067-1,22016-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22033-1",
    "SiriID": 5087,
    "Lat": 5926842,
    "Lng": 2478877,
    "Stops": "22022-1,23251-1",
    "Name": "Sookaera",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22341-1",
    "SiriID": 117,
    "Lat": 5912553,
    "Lng": 2514044,
    "Stops": "22342-1,22344-1",
    "Name": "Alansi",
    "Info": 4,
    "Street": 0,
    "Area": "Kose vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22342-1",
    "SiriID": 119,
    "Lat": 5912517,
    "Lng": 2514128,
    "Stops": "22341-1,22336-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22349-1",
    "SiriID": 4032,
    "Lat": 5910206,
    "Lng": 2535747,
    "Stops": "22302-1,22308-1",
    "Name": "Ardu kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22348-1",
    "SiriID": 81,
    "Lat": 5911761,
    "Lng": 2520250,
    "Stops": "22355-1,22317-1,22335-1",
    "Name": "Harmi kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22355-1",
    "SiriID": 4458,
    "Lat": 5911754,
    "Lng": 2520256,
    "Stops": "22348-1,22324-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22356-1",
    "SiriID": 4424,
    "Lat": 5919399,
    "Lng": 2499271,
    "Stops": "22357-1",
    "Name": "Heinamäe",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22357-1",
    "SiriID": 4427,
    "Lat": 5919374,
    "Lng": 2499302,
    "Stops": "22356-1,22373-1,22146-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22103-1",
    "SiriID": 5101,
    "Lat": 5919608,
    "Lng": 2497027,
    "Stops": "22104-1,22367-1",
    "Name": "Kata",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22104-1",
    "SiriID": 5102,
    "Lat": 5919669,
    "Lng": 2497019,
    "Stops": "22103-1,22199-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22343-1",
    "SiriID": 118,
    "Lat": 5912769,
    "Lng": 2511122,
    "Stops": "22344-1,22352-1",
    "Name": "Kirivalla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22344-1",
    "SiriID": 120,
    "Lat": 5912775,
    "Lng": 2511244,
    "Stops": "22343-1,22341-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22362-1",
    "SiriID": 4527,
    "Lat": 5912163,
    "Lng": 2536172,
    "Stops": "22363-1,22302-1",
    "Name": "Kiruvere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22363-1",
    "SiriID": 4528,
    "Lat": 5912186,
    "Lng": 2536117,
    "Stops": "22362-1,22332-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22375-1",
    "SiriID": 4623,
    "Lat": 5921575,
    "Lng": 2522191,
    "Stops": "22155-1,22130-1",
    "Name": "Kivisepa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22196-1",
    "SiriID": 4246,
    "Lat": 5921411,
    "Lng": 2513342,
    "Stops": "22111-1,22177-1,22131-1",
    "Name": "Kivivõhma",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22105-1",
    "SiriID": 184,
    "Lat": 5919227,
    "Lng": 2504449,
    "Stops": "22106-1,22184-1",
    "Name": "Kolu 1",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22106-1",
    "SiriID": 186,
    "Lat": 5919364,
    "Lng": 2504655,
    "Stops": "22105-1,22379-1,22365-1",
    "Name": "Kolu 4",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22183-1",
    "SiriID": 133,
    "Lat": 5918450,
    "Lng": 2516140,
    "Stops": "22167-1,22171-1",
    "Name": "Kose algkool",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22167-1",
    "SiriID": 12035,
    "Lat": 5918498,
    "Lng": 2516569,
    "Stops": "22108-1,22183-1,22158-1",
    "Name": "Kose gümnaasium",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22351-1",
    "SiriID": 4442,
    "Lat": 5913750,
    "Lng": 2508525,
    "Stops": "22352-1",
    "Name": "Kuusiku I",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22352-1",
    "SiriID": 4454,
    "Lat": 5913739,
    "Lng": 2508578,
    "Stops": "22351-1,22343-1,22369-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22350-1",
    "SiriID": 4459,
    "Lat": 5909244,
    "Lng": 2525710,
    "Stops": "22316-1,22311-1,22314-1",
    "Name": "Kõue tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22360-1",
    "SiriID": 4530,
    "Lat": 5921657,
    "Lng": 2519302,
    "Stops": "22372-1,22177-1",
    "Name": "Külakeskuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22372-1",
    "SiriID": 4619,
    "Lat": 5921638,
    "Lng": 2519309,
    "Stops": "22360-1,22182-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22172-1",
    "SiriID": 200,
    "Lat": 5917653,
    "Lng": 2514483,
    "Stops": "22171-1,22149-1,22173-1",
    "Name": "Liiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22171-1",
    "SiriID": 201,
    "Lat": 5917659,
    "Lng": 2514500,
    "Stops": "22172-1,22183-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22366-1",
    "SiriID": 4597,
    "Lat": 5919325,
    "Lng": 2497425,
    "Stops": "22367-1,22373-1,22376-1",
    "Name": "Männimäe",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22178-1",
    "SiriID": 14574,
    "Lat": 5920786,
    "Lng": 2525864,
    "Stops": "22179-1,22166-1",
    "Name": "Palvere",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22179-1",
    "SiriID": 14575,
    "Lat": 5920792,
    "Lng": 2525842,
    "Stops": "22178-1,22181-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22345-1",
    "SiriID": 224,
    "Lat": 5914219,
    "Lng": 2532561,
    "Stops": "22322-1",
    "Name": "Paunküla kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22347-1",
    "SiriID": 3967,
    "Lat": 5909812,
    "Lng": 2528443,
    "Stops": "22346-1,22315-1",
    "Name": "Rava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22346-1",
    "SiriID": 3968,
    "Lat": 5909803,
    "Lng": 2528525,
    "Stops": "22347-1,22308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22361-1",
    "SiriID": 4540,
    "Lat": 5916150,
    "Lng": 2521058,
    "Stops": "22165-1,22140-1,22159-1",
    "Name": "Ravila tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22353-1",
    "SiriID": 4460,
    "Lat": 5915788,
    "Lng": 2530628,
    "Stops": "22354-1",
    "Name": "Sae",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22354-1",
    "SiriID": 4461,
    "Lat": 5915807,
    "Lng": 2530587,
    "Stops": "22353-1,22340-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22144-1",
    "SiriID": 182,
    "Lat": 5922098,
    "Lng": 2503366,
    "Stops": "22143-1,23173-1,22365-1",
    "Name": "Saula",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22143-1",
    "SiriID": 183,
    "Lat": 5922103,
    "Lng": 2503658,
    "Stops": "22144-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22364-1",
    "SiriID": 4526,
    "Lat": 5911717,
    "Lng": 2531292,
    "Stops": "22332-1,22307-1",
    "Name": "Silmsi",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22377-1",
    "SiriID": 1767,
    "Lat": 5918548,
    "Lng": 2509406,
    "Stops": "22118-1,22174-1,22114-1",
    "Name": "Tade",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22182-1",
    "SiriID": 93,
    "Lat": 5921179,
    "Lng": 2519890,
    "Stops": "22156-1,22372-1,22132-1",
    "Name": "Vana tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22166-1",
    "SiriID": 12105,
    "Lat": 5920053,
    "Lng": 2529092,
    "Stops": "22178-1,22147-1",
    "Name": "Vilama",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22369-1",
    "SiriID": 4596,
    "Lat": 5915751,
    "Lng": 2512646,
    "Stops": "22173-1,22352-1",
    "Name": "Karla 1",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22118-1",
    "SiriID": 189,
    "Lat": 5918293,
    "Lng": 2508400,
    "Stops": "22186-1,22117-1,22377-1",
    "Name": "Kurena 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22365-1",
    "SiriID": 4551,
    "Lat": 5920167,
    "Lng": 2502819,
    "Stops": "22106-1,22144-1",
    "Name": "Saula küla",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22358-1",
    "SiriID": 4524,
    "Lat": 5917186,
    "Lng": 2505844,
    "Stops": "22359-1,22188-1",
    "Name": "Kanavere",
    "Info": "",
    "Street": "Kanavere - Oru",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22359-1",
    "SiriID": 4525,
    "Lat": 5917181,
    "Lng": 2505825,
    "Stops": "22358-1,22195-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22194-1",
    "SiriID": 4244,
    "Lat": 5916642,
    "Lng": 2502156,
    "Stops": "22374-1,22368-1",
    "Name": "Nõrava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22374-1",
    "SiriID": 4621,
    "Lat": 5916633,
    "Lng": 2502181,
    "Stops": "22194-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22376-1",
    "SiriID": 4243,
    "Lat": 5918603,
    "Lng": 2496064,
    "Stops": "22366-1",
    "Name": "Võivere",
    "Info": "",
    "Street": "Kata - Võivere tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22111-1",
    "SiriID": 5116,
    "Lat": 5920160,
    "Lng": 2510393,
    "Stops": "22113-1,22196-1",
    "Name": "Kose-Uuemõisa",
    "Info": 4,
    "Street": "Kesk tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22373-1",
    "SiriID": 4620,
    "Lat": 5919136,
    "Lng": 2498870,
    "Stops": "22169-1,22357-1,22366-1",
    "Name": "Oru kool",
    "Info": 3,
    "Street": "Keskuse tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22188-1",
    "SiriID": 192,
    "Lat": 5918211,
    "Lng": 2508222,
    "Stops": "22186-1,22358-1",
    "Name": "Kurena 5",
    "Info": 4,
    "Street": "Kolu - Kurena",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22184-1",
    "SiriID": 185,
    "Lat": 5919033,
    "Lng": 2504426,
    "Stops": "22185-1,22105-1",
    "Name": "Kolu 2",
    "Info": 3,
    "Street": "KOLU - TAMMIKU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22185-1",
    "SiriID": 187,
    "Lat": 5919031,
    "Lng": 2504376,
    "Stops": "22184-1,22145-1",
    "Name": "Kolu 3",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22134-1",
    "SiriID": 5099,
    "Lat": 5920346,
    "Lng": 2495261,
    "Stops": "22133-1,22198-1",
    "Name": "Pärtlimäe",
    "Info": "",
    "Street": "Kolu-Tammiku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22133-1",
    "SiriID": 5100,
    "Lat": 5920399,
    "Lng": 2495091,
    "Stops": "22134-1,22123-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22146-1",
    "SiriID": 5105,
    "Lat": 5918578,
    "Lng": 2502494,
    "Stops": "22145-1,22195-1,22357-1",
    "Name": "Sepiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22145-1",
    "SiriID": 5106,
    "Lat": 5918574,
    "Lng": 2502560,
    "Stops": "22146-1,22185-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22102-1",
    "SiriID": 5141,
    "Lat": 5919510,
    "Lng": 2518515,
    "Stops": "22101-1,22131-1",
    "Name": "Ahisilla",
    "Info": 4,
    "Street": "Kose - Jägala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22101-1",
    "SiriID": 5142,
    "Lat": 5919433,
    "Lng": 2518536,
    "Stops": "22102-1,22142-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22115-1",
    "SiriID": 195,
    "Lat": 5918201,
    "Lng": 2511985,
    "Stops": "22190-1,22189-1,22150-1",
    "Name": "Kuivajõe 3",
    "Info": "",
    "Street": "KOSE - JÄGALA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22120-1",
    "SiriID": 5133,
    "Lat": 5923354,
    "Lng": 2528430,
    "Stops": "22119-1,22129-1",
    "Name": "Lendermaa",
    "Info": "",
    "Street": "Kose - Jägala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22119-1",
    "SiriID": 5134,
    "Lat": 5923389,
    "Lng": 2528514,
    "Stops": "22120-1,21466-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22130-1",
    "SiriID": 5135,
    "Lat": 5922172,
    "Lng": 2525064,
    "Stops": "22181-1,22375-1",
    "Name": "Palvere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22129-1",
    "SiriID": 5136,
    "Lat": 5922180,
    "Lng": 2525364,
    "Stops": "22181-1,22120-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22131-1",
    "SiriID": 5139,
    "Lat": 5920573,
    "Lng": 2518463,
    "Stops": "22132-1,22102-1,22196-1",
    "Name": "Pikavere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22132-1",
    "SiriID": 5140,
    "Lat": 5920687,
    "Lng": 2518598,
    "Stops": "22131-1,22182-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22150-1",
    "SiriID": 5117,
    "Lat": 5918403,
    "Lng": 2512923,
    "Stops": "22149-1,22115-1",
    "Name": "Tindimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22149-1",
    "SiriID": 5118,
    "Lat": 5918402,
    "Lng": 2513091,
    "Stops": "22150-1,22172-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22156-1",
    "SiriID": 5137,
    "Lat": 5921241,
    "Lng": 2520513,
    "Stops": "22155-1,22182-1",
    "Name": "Viskla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22155-1",
    "SiriID": 5138,
    "Lat": 5921260,
    "Lng": 2520687,
    "Stops": "22156-1,22375-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22304-1",
    "SiriID": 5156,
    "Lat": 5910717,
    "Lng": 2516722,
    "Stops": "22303-1,22335-1",
    "Name": "Habaja",
    "Info": "",
    "Street": "Kose - Purila",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22303-1",
    "SiriID": 5157,
    "Lat": 5910710,
    "Lng": 2516734,
    "Stops": "22304-1,22327-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22157-1",
    "SiriID": 5121,
    "Lat": 5917600,
    "Lng": 2516968,
    "Stops": "22158-1,22162-1",
    "Name": "Kose kalmistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22158-1",
    "SiriID": 5122,
    "Lat": 5917701,
    "Lng": 2516959,
    "Stops": "22157-1,22167-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22370-1",
    "SiriID": 532,
    "Lat": 5916492,
    "Lng": 2517749,
    "Stops": "22371-1",
    "Name": "Kose-Risti 5",
    "Info": "",
    "Street": "KOSE - PURILA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22371-1",
    "SiriID": 691,
    "Lat": 5916521,
    "Lng": 2517764,
    "Stops": "22370-1,22109-1,22139-1",
    "Name": "Kose-Risti 6",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22318-1",
    "SiriID": 5152,
    "Lat": 5912937,
    "Lng": 2520448,
    "Stops": "22317-1,22325-1",
    "Name": "Ojasoo",
    "Info": "",
    "Street": "Kose - Purila",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22317-1",
    "SiriID": 5153,
    "Lat": 5912899,
    "Lng": 2520468,
    "Stops": "22318-1,22348-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22325-1",
    "SiriID": 5150,
    "Lat": 5914482,
    "Lng": 2520172,
    "Stops": "22326-1,22318-1",
    "Name": "Sange",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22326-1",
    "SiriID": 5151,
    "Lat": 5914536,
    "Lng": 2520160,
    "Stops": "22325-1,22140-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22328-1",
    "SiriID": 5158,
    "Lat": 5908967,
    "Lng": 2517498,
    "Stops": "22327-1,7000256-1,22313-1",
    "Name": "Saumetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22327-1",
    "SiriID": 5159,
    "Lat": 5908982,
    "Lng": 2517494,
    "Stops": "22328-1,22303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22336-1",
    "SiriID": 5154,
    "Lat": 5911800,
    "Lng": 2517061,
    "Stops": "22335-1,22342-1",
    "Name": "Äksi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22335-1",
    "SiriID": 5155,
    "Lat": 5911789,
    "Lng": 2517075,
    "Stops": "22336-1,22304-1,22348-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22181-1",
    "SiriID": 92,
    "Lat": 5922126,
    "Lng": 2525214,
    "Stops": "22130-1,22129-1,22179-1",
    "Name": "Palvere teerist",
    "Info": "",
    "Street": "KOSE - RAVILA - NÕMBRA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22135-1",
    "SiriID": 5147,
    "Lat": 5918508,
    "Lng": 2522143,
    "Stops": "22136-1",
    "Name": "Ravila",
    "Info": "",
    "Street": "Kose - Ravila - Nõmbra",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22136-1",
    "SiriID": 5148,
    "Lat": 5918492,
    "Lng": 2522115,
    "Stops": "22135-1,22153-1,22160-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22154-1",
    "SiriID": 5145,
    "Lat": 5918400,
    "Lng": 2519541,
    "Stops": "22153-1,22142-1",
    "Name": "Toomingamäe",
    "Info": "",
    "Street": "KOSE - RAVILA - NÕMBRA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22153-1",
    "SiriID": 5146,
    "Lat": 5918404,
    "Lng": 2519590,
    "Stops": "22154-1,22136-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22142-1",
    "SiriID": 5143,
    "Lat": 5918566,
    "Lng": 2518044,
    "Stops": "22141-1,22154-1,22101-1",
    "Name": "Sanatooriumi",
    "Info": "",
    "Street": "Kose sanatooriumi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22141-1",
    "SiriID": 5144,
    "Lat": 5918547,
    "Lng": 2518025,
    "Stops": "22142-1,22107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22107-1",
    "SiriID": 9775,
    "Lat": 5918599,
    "Lng": 2516632,
    "Stops": "22108-1,22141-1",
    "Name": "Kose",
    "Info": "",
    "Street": "KOSE TEE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22108-1",
    "SiriID": 10109,
    "Lat": 5918595,
    "Lng": 2516616,
    "Stops": "22107-1,22167-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22173-1",
    "SiriID": 13415,
    "Lat": 5916653,
    "Lng": 2512472,
    "Stops": "22369-1,22172-1",
    "Name": "Karla",
    "Info": "",
    "Street": "Kuivajõe - Karla tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22189-1",
    "SiriID": 194,
    "Lat": 5918066,
    "Lng": 2511945,
    "Stops": "22115-1",
    "Name": "Kuivajõe 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22114-1",
    "SiriID": 5113,
    "Lat": 5919058,
    "Lng": 2511236,
    "Stops": "22113-1,22164-1,22377-1",
    "Name": "Krei",
    "Info": "",
    "Street": "Kuivajõe - Kose-Uuemõisa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22113-1",
    "SiriID": 5114,
    "Lat": 5919096,
    "Lng": 2511218,
    "Stops": "22114-1,22111-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22190-1",
    "SiriID": 196,
    "Lat": 5918236,
    "Lng": 2511805,
    "Stops": "22115-1,22116-1,22191-1",
    "Name": "Kuivajõe 4",
    "Info": "",
    "Street": "KUIVAJÕE - KOSE-UUEMÕISA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22164-1",
    "SiriID": 197,
    "Lat": 5918549,
    "Lng": 2511601,
    "Stops": "22163-1,22114-1",
    "Name": "Kuivajõe 5",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22163-1",
    "SiriID": 198,
    "Lat": 5918524,
    "Lng": 2511580,
    "Stops": "22191-1,22164-1",
    "Name": "Kuivajõe 6",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22174-1",
    "SiriID": 13409,
    "Lat": 5917816,
    "Lng": 2509979,
    "Stops": "22175-1,22377-1",
    "Name": "Sepa",
    "Info": "",
    "Street": "Kuivajõe - Kurena tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22175-1",
    "SiriID": 13410,
    "Lat": 5917801,
    "Lng": 2510003,
    "Stops": "22174-1,22116-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22191-1",
    "SiriID": 199,
    "Lat": 5918505,
    "Lng": 2511541,
    "Stops": "22163-1,22190-1",
    "Name": "Kuivajõe 7",
    "Info": "",
    "Street": "Kuivajõe III",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22116-1",
    "SiriID": 193,
    "Lat": 5918177,
    "Lng": 2511595,
    "Stops": "22190-1,22175-1",
    "Name": "Kuivajõe 1",
    "Info": "",
    "Street": "Kuivajõe VI",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22186-1",
    "SiriID": 190,
    "Lat": 5918208,
    "Lng": 2508335,
    "Stops": "22188-1,22187-1,22118-1",
    "Name": "Kurena 3",
    "Info": "",
    "Street": "Kurena tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22187-1",
    "SiriID": 191,
    "Lat": 5918150,
    "Lng": 2508318,
    "Stops": "22186-1",
    "Name": "Kurena 4",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22306-1",
    "SiriID": 5170,
    "Lat": 5905669,
    "Lng": 2526427,
    "Stops": "22305-1,22320-1",
    "Name": "Jaagu",
    "Info": "",
    "Street": "Kõue-Virla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22305-1",
    "SiriID": 5171,
    "Lat": 5905649,
    "Lng": 2526494,
    "Stops": "22306-1,22334-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22320-1",
    "SiriID": 5172,
    "Lat": 5907770,
    "Lng": 2525485,
    "Stops": "22319-1,22306-1",
    "Name": "Paunaste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22319-1",
    "SiriID": 5173,
    "Lat": 5907955,
    "Lng": 2525578,
    "Stops": "22320-1,22314-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22334-1",
    "SiriID": 5169,
    "Lat": 5904820,
    "Lng": 2525688,
    "Stops": "22305-1",
    "Name": "Virla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22309-1",
    "SiriID": 6769,
    "Lat": 5908603,
    "Lng": 2524006,
    "Stops": "22313-1,22314-1",
    "Name": "Kõue",
    "Info": "",
    "Street": "Ojasoo-Ardu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22312-1",
    "SiriID": 5164,
    "Lat": 5909795,
    "Lng": 2524285,
    "Stops": "22311-1,22329-1",
    "Name": "Kõue mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22311-1",
    "SiriID": 5165,
    "Lat": 5909771,
    "Lng": 2524308,
    "Stops": "22312-1,22350-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22316-1",
    "SiriID": 5166,
    "Lat": 5909295,
    "Lng": 2525821,
    "Stops": "22350-1,22315-1",
    "Name": "Kõue tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22315-1",
    "SiriID": 5167,
    "Lat": 5909367,
    "Lng": 2526017,
    "Stops": "22316-1,22347-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22323-1",
    "SiriID": 5160,
    "Lat": 5911771,
    "Lng": 2522470,
    "Stops": "22324-1,22330-1",
    "Name": "Riidamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22324-1",
    "SiriID": 5161,
    "Lat": 5911786,
    "Lng": 2522420,
    "Stops": "22323-1,22355-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22330-1",
    "SiriID": 5162,
    "Lat": 5910753,
    "Lng": 2523386,
    "Stops": "22329-1,22323-1",
    "Name": "Sääsküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22329-1",
    "SiriID": 5163,
    "Lat": 5910733,
    "Lng": 2523465,
    "Stops": "22330-1,22312-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22161-1",
    "SiriID": 9836,
    "Lat": 5916710,
    "Lng": 2517619,
    "Stops": "22162-1,22110-1",
    "Name": "Kose-Risti 3",
    "Info": "",
    "Street": "Pikk tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22162-1",
    "SiriID": 9837,
    "Lat": 5916726,
    "Lng": 2517629,
    "Stops": "22161-1,22157-1",
    "Name": "Kose-Risti 4",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22195-1",
    "SiriID": 4245,
    "Lat": 5917447,
    "Lng": 2502158,
    "Stops": "22368-1,22146-1,22359-1",
    "Name": "Nõrava tee",
    "Info": 3,
    "Street": "Sepiku - Nõrava",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22368-1",
    "SiriID": 4595,
    "Lat": 5917440,
    "Lng": 2502126,
    "Stops": "22195-1,22194-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22117-1",
    "SiriID": 188,
    "Lat": 5918343,
    "Lng": 2508187,
    "Stops": "22118-1,22378-1",
    "Name": "Kurena 1",
    "Info": 4,
    "Street": "Tartu maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22302-1",
    "SiriID": 5182,
    "Lat": 5910301,
    "Lng": 2535956,
    "Stops": "22301-1,22349-1,22362-1",
    "Name": "Ardu",
    "Info": "",
    "Street": "Tartu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22301-1",
    "SiriID": 5183,
    "Lat": 5910286,
    "Lng": 2535993,
    "Stops": "22302-1,22338-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22109-1",
    "SiriID": 5124,
    "Lat": 5916668,
    "Lng": 2517623,
    "Stops": "22110-1,22371-1",
    "Name": "Kose-Risti 1",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22110-1",
    "SiriID": 5123,
    "Lat": 5916693,
    "Lng": 2517588,
    "Stops": "22161-1,22109-1",
    "Name": "Kose-Risti 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22308-1",
    "SiriID": 5180,
    "Lat": 5910662,
    "Lng": 2532475,
    "Stops": "22307-1,22349-1,22346-1",
    "Name": "Kurgemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22307-1",
    "SiriID": 5181,
    "Lat": 5910707,
    "Lng": 2532443,
    "Stops": "22308-1,22364-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22337-1",
    "SiriID": 11726,
    "Lat": 5909448,
    "Lng": 2538609,
    "Stops": "22338-1",
    "Name": "Nõmmeküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22338-1",
    "SiriID": 11727,
    "Lat": 5909479,
    "Lng": 2538565,
    "Stops": "22337-1,22301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22322-1",
    "SiriID": 5176,
    "Lat": 5914115,
    "Lng": 2531743,
    "Stops": "22321-1,22345-1,22331-1",
    "Name": "Paunküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22321-1",
    "SiriID": 5177,
    "Lat": 5914225,
    "Lng": 2531642,
    "Stops": "22322-1,22339-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22331-1",
    "SiriID": 5178,
    "Lat": 5912757,
    "Lng": 2531951,
    "Stops": "22332-1,22322-1",
    "Name": "Paunküla veehoidla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22332-1",
    "SiriID": 5179,
    "Lat": 5912716,
    "Lng": 2531958,
    "Stops": "22331-1,22364-1,22363-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22137-1",
    "SiriID": 5129,
    "Lat": 5916191,
    "Lng": 2523645,
    "Stops": "22138-1,22140-1",
    "Name": "Raveliku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22138-1",
    "SiriID": 5130,
    "Lat": 5916197,
    "Lng": 2523704,
    "Stops": "22137-1,22148-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22140-1",
    "SiriID": 5125,
    "Lat": 5916070,
    "Lng": 2521151,
    "Stops": "22361-1,22137-1,22326-1",
    "Name": "Ravila tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22139-1",
    "SiriID": 5126,
    "Lat": 5916170,
    "Lng": 2520582,
    "Stops": "22165-1,22371-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22339-1",
    "SiriID": 11724,
    "Lat": 5915339,
    "Lng": 2529431,
    "Stops": "22340-1,22321-1",
    "Name": "Rõõsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22340-1",
    "SiriID": 11725,
    "Lat": 5915367,
    "Lng": 2529417,
    "Stops": "22339-1,22354-1,22147-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22148-1",
    "SiriID": 5131,
    "Lat": 5916603,
    "Lng": 2527050,
    "Stops": "22147-1,22138-1",
    "Name": "Sõmeru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22147-1",
    "SiriID": 5132,
    "Lat": 5916594,
    "Lng": 2527197,
    "Stops": "22148-1,22340-1,22166-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22314-1",
    "SiriID": 5174,
    "Lat": 5908607,
    "Lng": 2524130,
    "Stops": "22309-1,22319-1,22350-1",
    "Name": "Kõue",
    "Info": "",
    "Street": "Triigi-Kõue",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22313-1",
    "SiriID": 5175,
    "Lat": 5908582,
    "Lng": 2523931,
    "Stops": "22309-1,22328-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22199-1",
    "SiriID": 4393,
    "Lat": 5919989,
    "Lng": 2497085,
    "Stops": "22104-1,22198-1",
    "Name": "Jõekääru talu",
    "Info": 3,
    "Street": "Tuhala tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22198-1",
    "SiriID": 4392,
    "Lat": 5920457,
    "Lng": 2497013,
    "Stops": "22199-1,22134-1",
    "Name": "Kataveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22197-1",
    "SiriID": 4391,
    "Lat": 5921932,
    "Lng": 2497005,
    "Stops": "22151-1,22122-1",
    "Name": "Veetõusme talu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22378-1",
    "SiriID": 1778,
    "Lat": 5919204,
    "Lng": 2506018,
    "Stops": "22379-1,22117-1",
    "Name": "Tõnuküla tee",
    "Info": "",
    "Street": "Tõnuküla tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22379-1",
    "SiriID": 4770,
    "Lat": 5919200,
    "Lng": 2506001,
    "Stops": "22378-1,22106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22122-1",
    "SiriID": 5095,
    "Lat": 5921521,
    "Lng": 2495340,
    "Stops": "22121-1,22197-1",
    "Name": "Metsaküla",
    "Info": "",
    "Street": "VAIDA - URGE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22121-1",
    "SiriID": 5096,
    "Lat": 5921498,
    "Lng": 2495321,
    "Stops": "22122-1,22123-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22124-1",
    "SiriID": 5097,
    "Lat": 5920744,
    "Lng": 2494185,
    "Stops": "22123-1,7000732-1",
    "Name": "Nõmme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22123-1",
    "SiriID": 5098,
    "Lat": 5920763,
    "Lng": 2494262,
    "Stops": "22124-1,22133-1,22121-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22128-1",
    "SiriID": 5091,
    "Lat": 5922719,
    "Lng": 2499887,
    "Stops": "22127-1,22151-1",
    "Name": "Otiveski",
    "Info": "",
    "Street": "Vaida-Urge",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22127-1",
    "SiriID": 5092,
    "Lat": 5922708,
    "Lng": 2500096,
    "Stops": "22128-1,23174-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22151-1",
    "SiriID": 5093,
    "Lat": 5922506,
    "Lng": 2496754,
    "Stops": "22152-1,22197-1,22128-1",
    "Name": "Tuhala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22152-1",
    "SiriID": 5094,
    "Lat": 5922535,
    "Lng": 2496725,
    "Stops": "22151-1,22065-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22367-1",
    "SiriID": 4598,
    "Lat": 5919359,
    "Lng": 2497392,
    "Stops": "22366-1,22103-1",
    "Name": "Männimäe",
    "Info": "",
    "Street": "Vana tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22168-1",
    "SiriID": 13250,
    "Lat": 5918907,
    "Lng": 2498979,
    "Stops": "22169-1",
    "Name": "Oru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22169-1",
    "SiriID": 13252,
    "Lat": 5918916,
    "Lng": 2498878,
    "Stops": "22168-1,22373-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22165-1",
    "SiriID": 10116,
    "Lat": 5916143,
    "Lng": 2521017,
    "Stops": "22361-1,22139-1",
    "Name": "Ravila tee",
    "Info": 4,
    "Street": "VARDJA - RAVILA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22159-1",
    "SiriID": 5127,
    "Lat": 5917045,
    "Lng": 2521089,
    "Stops": "22160-1,22361-1",
    "Name": "Vardja",
    "Info": "",
    "Street": "Vardja-Ravila",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22160-1",
    "SiriID": 5128,
    "Lat": 5917068,
    "Lng": 2521124,
    "Stops": "22159-1,22136-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22177-1",
    "SiriID": 13383,
    "Lat": 5923268,
    "Lng": 2517296,
    "Stops": "22360-1,22196-1",
    "Name": "Viskla õpilaste",
    "Info": "",
    "Street": "VISKLA - PIKAVERE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "31201-1",
    "SiriID": 12161,
    "Lat": 5944014,
    "Lng": 2551978,
    "Stops": "30801-1,29306-1",
    "Name": "Hirvli küla",
    "Info": "",
    "Street": 0,
    "Area": "Kuusalu vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33401-1",
    "SiriID": 13253,
    "Lat": 5963944,
    "Lng": 2550582,
    "Stops": "33301-1",
    "Name": "Juminda",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33203-1",
    "SiriID": 162,
    "Lat": 5960610,
    "Lng": 2551549,
    "Stops": "33202-1,33503-1",
    "Name": "Juminda tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33202-1",
    "SiriID": 163,
    "Lat": 5960602,
    "Lng": 2551490,
    "Stops": "33203-1,33201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28703-1",
    "SiriID": 5387,
    "Lat": 5944670,
    "Lng": 2538345,
    "Stops": "28707-1",
    "Name": "Kiiu / Kiiu mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28707-1",
    "SiriID": 4618,
    "Lat": 5944689,
    "Lng": 2538312,
    "Stops": "28703-1,28705-1,28702-1",
    "Name": "Kiiu mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28104-1",
    "SiriID": 12156,
    "Lat": 5944602,
    "Lng": 2527304,
    "Stops": "28103-1,28105-1,28203-1",
    "Name": "Kodasoo küla",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28103-1",
    "SiriID": 12157,
    "Lat": 5944590,
    "Lng": 2527270,
    "Stops": "28104-1,28101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30009-1",
    "SiriID": 11853,
    "Lat": 5949249,
    "Lng": 2560107,
    "Stops": "30001-1",
    "Name": "Kolga kool",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30010-1",
    "SiriID": 11854,
    "Lat": 5949175,
    "Lng": 2561475,
    "Stops": "30002-1,30007-1",
    "Name": "Kolga lasteaed",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30801-1",
    "SiriID": 12160,
    "Lat": 5943725,
    "Lng": 2550967,
    "Stops": "31201-1",
    "Name": "Kursi küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30607-1",
    "SiriID": 12545,
    "Lat": 5944547,
    "Lng": 2543548,
    "Stops": "30601-1,30608-1",
    "Name": "Kuusalu keskus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29005-1",
    "SiriID": 11632,
    "Lat": 5945201,
    "Lng": 2541683,
    "Stops": "29005-2,29004-1",
    "Name": "Kuusalu kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29005-2",
    "SiriID": 13865,
    "Lat": 5945182,
    "Lng": 2541694,
    "Stops": "29005-3,29005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29005-3",
    "SiriID": 13866,
    "Lat": 5945166,
    "Lng": 2541706,
    "Stops": "29005-2,29005-4",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29005-4",
    "SiriID": 13867,
    "Lat": 5945149,
    "Lng": 2541720,
    "Stops": "29005-5,29005-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29005-5",
    "SiriID": 13868,
    "Lat": 5945147,
    "Lng": 2541753,
    "Stops": "29005-4,30602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30605-1",
    "SiriID": 11844,
    "Lat": 5944325,
    "Lng": 2542723,
    "Stops": "30601-1",
    "Name": "Kuusalu lasteaed",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32201-1",
    "SiriID": 12173,
    "Lat": 5947931,
    "Lng": 2572348,
    "Stops": "32002-1,32102-1",
    "Name": "Kõnnu küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28203-1",
    "SiriID": 12158,
    "Lat": 5944780,
    "Lng": 2530245,
    "Stops": "28201-1,28104-1,28452-1",
    "Name": "Rummu küla",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30301-1",
    "SiriID": 12159,
    "Lat": 5942570,
    "Lng": 2530458,
    "Stops": "30302-1,38501-1",
    "Name": "Saunja küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30302-1",
    "SiriID": 13279,
    "Lat": 5942595,
    "Lng": 2530436,
    "Stops": "30301-1,28202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "31301-1",
    "SiriID": 12162,
    "Lat": 5943795,
    "Lng": 2558817,
    "Stops": "30202-1",
    "Name": "Sigula küla",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32102-1",
    "SiriID": 9560,
    "Lat": 5946518,
    "Lng": 2575246,
    "Stops": "32101-1,32201-1",
    "Name": "Soosilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32101-1",
    "SiriID": 9561,
    "Lat": 5946514,
    "Lng": 2575304,
    "Stops": "32102-1,32104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5900779-1",
    "SiriID": 24639,
    "Lat": 5955795,
    "Lng": 2582967,
    "Stops": "5901023-1,5900073-1",
    "Name": "Tammispea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "5901023-1",
    "SiriID": 23,
    "Lat": 5955741,
    "Lng": 2582855,
    "Stops": "5900779-1,33803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33301-1",
    "SiriID": 12167,
    "Lat": 5962532,
    "Lng": 2549681,
    "Stops": "33401-1,33201-1",
    "Name": "Tammistu küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33502-1",
    "SiriID": 160,
    "Lat": 5960058,
    "Lng": 2555384,
    "Stops": "33503-1,33501-1,32811-1",
    "Name": "Tapurla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33503-1",
    "SiriID": 161,
    "Lat": 5960056,
    "Lng": 2555335,
    "Stops": "33502-1,33203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33501-1",
    "SiriID": 12216,
    "Lat": 5960733,
    "Lng": 2555864,
    "Stops": "33502-1",
    "Name": "Tapurla küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22176-1",
    "SiriID": 13394,
    "Lat": 5951127,
    "Lng": 2551965,
    "Stops": "29701-1,29602-1",
    "Name": "Tsitre linnamägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29801-1",
    "SiriID": 12165,
    "Lat": 5948762,
    "Lng": 2556105,
    "Stops": "29802-1,29602-1",
    "Name": "Uuri küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29802-1",
    "SiriID": 12166,
    "Lat": 5948730,
    "Lng": 2556116,
    "Stops": "29801-1,29310-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32104-1",
    "SiriID": 5504,
    "Lat": 5946818,
    "Lng": 2578577,
    "Stops": "32103-1,32101-1",
    "Name": "Valgejõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32103-1",
    "SiriID": 5505,
    "Lat": 5946815,
    "Lng": 2578654,
    "Stops": "32104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33805-1",
    "SiriID": 6012,
    "Lat": 5955174,
    "Lng": 2578240,
    "Stops": "33806-1,33703-1",
    "Name": "Vihasoo  perspektiivne",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33804-1",
    "SiriID": 6011,
    "Lat": 5954409,
    "Lng": 2579802,
    "Stops": "33803-1,33802-1",
    "Name": "Vihasoo kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33806-1",
    "SiriID": 6013,
    "Lat": 5955166,
    "Lng": 2578290,
    "Stops": "33805-1,33801-1",
    "Name": "Vihasoo perspektiivne",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32811-1",
    "SiriID": 158,
    "Lat": 5958881,
    "Lng": 2559569,
    "Stops": "32812-1,33502-1",
    "Name": "Virve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32812-1",
    "SiriID": 159,
    "Lat": 5958862,
    "Lng": 2559594,
    "Stops": "32811-1,32805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29312-1",
    "SiriID": 141,
    "Lat": 5947077,
    "Lng": 2552914,
    "Stops": "29313-1,29309-1",
    "Name": "Kahala küla",
    "Info": "",
    "Street": "JÕELÄHTME - KEMBA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29313-1",
    "SiriID": 142,
    "Lat": 5947010,
    "Lng": 2552682,
    "Stops": "29312-1,29308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29105-1",
    "SiriID": 214,
    "Lat": 5945793,
    "Lng": 2545099,
    "Stops": "29106-1,29101-1,29103-1",
    "Name": "Leesi teerist",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29106-1",
    "SiriID": 215,
    "Lat": 5945781,
    "Lng": 2545077,
    "Stops": "29105-1,29108-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28002-1",
    "SiriID": 5456,
    "Lat": 5946886,
    "Lng": 2528193,
    "Stops": "28001-1,28007-1,28105-1",
    "Name": "Haavakannu",
    "Info": 3,
    "Street": "Jõelähtme-Kemba",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28001-1",
    "SiriID": 5457,
    "Lat": 5946893,
    "Lng": 2528147,
    "Stops": "28002-1,28003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28004-1",
    "SiriID": 5458,
    "Lat": 5946815,
    "Lng": 2525364,
    "Stops": "28003-1,26905-1,28006-1",
    "Name": "Kaberla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28003-1",
    "SiriID": 5459,
    "Lat": 5946826,
    "Lng": 2525569,
    "Stops": "28004-1,28001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29303-1",
    "SiriID": 5406,
    "Lat": 5947166,
    "Lng": 2556076,
    "Stops": "29304-1,30101-1",
    "Name": "Kahala kauplus",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29304-1",
    "SiriID": 5407,
    "Lat": 5947155,
    "Lng": 2556045,
    "Stops": "29303-1,29310-1,29301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29307-1",
    "SiriID": 5420,
    "Lat": 5946605,
    "Lng": 2550802,
    "Stops": "29308-1,29104-1",
    "Name": "Kahala-Liiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29308-1",
    "SiriID": 5421,
    "Lat": 5946622,
    "Lng": 2550884,
    "Stops": "29307-1,29313-1,29305-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29309-1",
    "SiriID": 5408,
    "Lat": 5947394,
    "Lng": 2554461,
    "Stops": "29310-1,29312-1",
    "Name": "Kalamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29310-1",
    "SiriID": 5409,
    "Lat": 5947381,
    "Lng": 2554637,
    "Stops": "29309-1,29304-1,29802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28701-1",
    "SiriID": 5434,
    "Lat": 5945193,
    "Lng": 2538260,
    "Stops": "28702-1",
    "Name": "Kiiu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28702-1",
    "SiriID": 5435,
    "Lat": 5945183,
    "Lng": 2538339,
    "Stops": "28701-1,28707-1,29003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28005-1",
    "SiriID": 5460,
    "Lat": 5946399,
    "Lng": 2523903,
    "Stops": "28006-1",
    "Name": "Kääniku",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28006-1",
    "SiriID": 5461,
    "Lat": 5946392,
    "Lng": 2523911,
    "Stops": "28005-1,26913-1,28004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28451-1",
    "SiriID": 5454,
    "Lat": 5946745,
    "Lng": 2531561,
    "Stops": "28452-1,28414-1",
    "Name": "Lamme",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28452-1",
    "SiriID": 5455,
    "Lat": 5946750,
    "Lng": 2531515,
    "Stops": "28451-1,28008-1,28203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30102-1",
    "SiriID": 5403,
    "Lat": 5947258,
    "Lng": 2558900,
    "Stops": "30101-1,30005-1,29311-1",
    "Name": "Mustametsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30101-1",
    "SiriID": 5404,
    "Lat": 5947259,
    "Lng": 2558727,
    "Stops": "30102-1,29303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30006-1",
    "SiriID": 5401,
    "Lat": 5947575,
    "Lng": 2560983,
    "Stops": "30005-1,30001-1,31401-1",
    "Name": "Männiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30005-1",
    "SiriID": 5402,
    "Lat": 5947558,
    "Lng": 2560799,
    "Stops": "30006-1,30102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28007-1",
    "SiriID": 5452,
    "Lat": 5946921,
    "Lng": 2530205,
    "Stops": "28008-1,28002-1",
    "Name": "Põhjakivi",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28008-1",
    "SiriID": 5453,
    "Lat": 5946908,
    "Lng": 2530236,
    "Stops": "28007-1,28452-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29003-1",
    "SiriID": 5432,
    "Lat": 5945238,
    "Lng": 2541492,
    "Stops": "29004-1,28801-1,28702-1",
    "Name": "Rihumäe",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29004-1",
    "SiriID": 5433,
    "Lat": 5945246,
    "Lng": 2541609,
    "Stops": "29005-1,29003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29103-1",
    "SiriID": 5422,
    "Lat": 5945840,
    "Lng": 2547567,
    "Stops": "29104-1,29105-1",
    "Name": "Sõitme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29104-1",
    "SiriID": 5423,
    "Lat": 5945833,
    "Lng": 2547616,
    "Stops": "29103-1,30701-1,29307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28454-1",
    "SiriID": 5436,
    "Lat": 5945733,
    "Lng": 2535213,
    "Stops": "28453-1,28455-1",
    "Name": "Valkla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28453-1",
    "SiriID": 5437,
    "Lat": 5945741,
    "Lng": 2535275,
    "Stops": "28454-1,28417-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33803-1",
    "SiriID": 10076,
    "Lat": 5954411,
    "Lng": 2579847,
    "Stops": "33804-1,5900908-1,5901023-1",
    "Name": "Vihasoo kool",
    "Info": "",
    "Street": "Kasispea-Ilumäe",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30608-1",
    "SiriID": 4001,
    "Lat": 5944517,
    "Lng": 2544742,
    "Stops": "30607-1",
    "Name": "Kuusalu rahvamaja",
    "Info": "",
    "Street": "Keskväljak",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28705-1",
    "SiriID": 5388,
    "Lat": 5944596,
    "Lng": 2537769,
    "Stops": "28706-1,28707-1",
    "Name": "Kiiu viadukt",
    "Info": "",
    "Street": "Kiiu liiklussõlm",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28706-1",
    "SiriID": 9693,
    "Lat": 5944417,
    "Lng": 2537611,
    "Stops": "28705-1,28456-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28417-1",
    "SiriID": 5438,
    "Lat": 5946694,
    "Lng": 2536219,
    "Stops": "28418-1,28453-1",
    "Name": "Mäepea",
    "Info": "",
    "Street": "Kiiu-Kaberneeme",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28418-1",
    "SiriID": 5439,
    "Lat": 5946725,
    "Lng": 2536209,
    "Stops": "28417-1,28415-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28415-1",
    "SiriID": 5444,
    "Lat": 5947788,
    "Lng": 2535138,
    "Stops": "28416-1,28418-1",
    "Name": "Rägapaju",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28416-1",
    "SiriID": 5445,
    "Lat": 5947817,
    "Lng": 2535142,
    "Stops": "28415-1,28414-1,28504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28105-1",
    "SiriID": 5655,
    "Lat": 5944814,
    "Lng": 2527139,
    "Stops": "28106-1,28104-1,28002-1",
    "Name": "Jõe",
    "Info": 3,
    "Street": "KODASOO - KABERLA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28106-1",
    "SiriID": 5701,
    "Lat": 5944811,
    "Lng": 2527115,
    "Stops": "28105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30002-1",
    "SiriID": 5405,
    "Lat": 5949084,
    "Lng": 2561344,
    "Stops": "30001-1,30010-1",
    "Name": "Kolga",
    "Info": 4,
    "Street": "Kolga-Pudisoo",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30001-1",
    "SiriID": 7505,
    "Lat": 5949054,
    "Lng": 2561238,
    "Stops": "30002-1,30009-1,30006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30602-1",
    "SiriID": 5427,
    "Lat": 5945005,
    "Lng": 2543108,
    "Stops": "30604-1,29001-1,29005-5",
    "Name": "Kuusalu tee",
    "Info": "",
    "Street": "Kuusalu liiklussõlm",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30604-1",
    "SiriID": 5430,
    "Lat": 5944747,
    "Lng": 2543207,
    "Stops": "30601-1,30602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30601-1",
    "SiriID": 5429,
    "Lat": 5944551,
    "Lng": 2543435,
    "Stops": "30607-1,30604-1,30605-1",
    "Name": "Kuusalu keskus",
    "Info": "",
    "Street": "Kuusalu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29001-1",
    "SiriID": 5426,
    "Lat": 5945269,
    "Lng": 2543308,
    "Stops": "29006-1,30602-1",
    "Name": "Kuusalu küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29211-1",
    "SiriID": 5418,
    "Lat": 5947957,
    "Lng": 2546621,
    "Stops": "29212-1,29102-1",
    "Name": "Kalda",
    "Info": "",
    "Street": "Kuusalu-Leesi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29212-1",
    "SiriID": 5419,
    "Lat": 5947971,
    "Lng": 2546692,
    "Stops": "29211-1,29401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33101-1",
    "SiriID": 5530,
    "Lat": 5958429,
    "Lng": 2551399,
    "Stops": "33102-1,33201-1",
    "Name": "Kiiu-Aabla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33102-1",
    "SiriID": 5531,
    "Lat": 5958429,
    "Lng": 2551425,
    "Stops": "33101-1,33003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33001-1",
    "SiriID": 5534,
    "Lat": 5956396,
    "Lng": 2553499,
    "Stops": "33002-1,32902-1",
    "Name": "Kolga-Aabla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33002-1",
    "SiriID": 5535,
    "Lat": 5956429,
    "Lng": 2553482,
    "Stops": "33001-1,33004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33201-1",
    "SiriID": 5529,
    "Lat": 5960425,
    "Lng": 2549860,
    "Stops": "33202-1,33301-1,33101-1",
    "Name": "Leesi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29401-1",
    "SiriID": 5416,
    "Lat": 5948994,
    "Lng": 2547885,
    "Stops": "29402-1,29212-1",
    "Name": "Loo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29402-1",
    "SiriID": 5417,
    "Lat": 5949027,
    "Lng": 2547913,
    "Stops": "29401-1,29601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33003-1",
    "SiriID": 5532,
    "Lat": 5957644,
    "Lng": 2552714,
    "Stops": "33004-1,33102-1",
    "Name": "Mereranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33004-1",
    "SiriID": 5533,
    "Lat": 5957629,
    "Lng": 2552735,
    "Stops": "33003-1,33002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29601-1",
    "SiriID": 5414,
    "Lat": 5950414,
    "Lng": 2551871,
    "Stops": "29602-1,29402-1",
    "Name": "Muuksi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29602-1",
    "SiriID": 5415,
    "Lat": 5950445,
    "Lng": 2551922,
    "Stops": "29601-1,22176-1,29801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32901-1",
    "SiriID": 5536,
    "Lat": 5954529,
    "Lng": 2556228,
    "Stops": "32902-1,29902-1",
    "Name": "Pedaspea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32902-1",
    "SiriID": 5537,
    "Lat": 5954536,
    "Lng": 2556240,
    "Stops": "32901-1,33001-1,32801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29902-1",
    "SiriID": 5410,
    "Lat": 5951947,
    "Lng": 2555452,
    "Stops": "29901-1,29702-1,32901-1",
    "Name": "Pudisoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29901-1",
    "SiriID": 5411,
    "Lat": 5951930,
    "Lng": 2555476,
    "Stops": "29902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29101-1",
    "SiriID": 5424,
    "Lat": 5946710,
    "Lng": 2544889,
    "Stops": "29102-1,29105-1",
    "Name": "Raja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29102-1",
    "SiriID": 5425,
    "Lat": 5946741,
    "Lng": 2544924,
    "Stops": "29101-1,29211-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29701-1",
    "SiriID": 5412,
    "Lat": 5951507,
    "Lng": 2552566,
    "Stops": "29702-1,22176-1",
    "Name": "Tsitre",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29702-1",
    "SiriID": 5413,
    "Lat": 5951495,
    "Lng": 2552626,
    "Stops": "29701-1,29902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28801-1",
    "SiriID": 11089,
    "Lat": 5946288,
    "Lng": 2541229,
    "Stops": "28802-1,29003-1",
    "Name": "Allika küla",
    "Info": "",
    "Street": "Kuusalu-Valkla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28802-1",
    "SiriID": 11090,
    "Lat": 5946319,
    "Lng": 2541243,
    "Stops": "28801-1,28902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28902-1",
    "SiriID": 11091,
    "Lat": 5947849,
    "Lng": 2541077,
    "Stops": "28901-1,28802-1",
    "Name": "Põhja küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28901-1",
    "SiriID": 11092,
    "Lat": 5947868,
    "Lng": 2541095,
    "Stops": "28902-1,28501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28502-1",
    "SiriID": 5440,
    "Lat": 5949299,
    "Lng": 2537538,
    "Stops": "28501-1,28503-1",
    "Name": "Salmistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28501-1",
    "SiriID": 5441,
    "Lat": 5949133,
    "Lng": 2537901,
    "Stops": "28502-1,28901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28504-1",
    "SiriID": 5442,
    "Lat": 5949009,
    "Lng": 2536444,
    "Stops": "28503-1,28416-1",
    "Name": "Valkla laager",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28503-1",
    "SiriID": 5443,
    "Lat": 5949027,
    "Lng": 2536479,
    "Stops": "28504-1,28502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32701-1",
    "SiriID": 5508,
    "Lat": 5952466,
    "Lng": 2569729,
    "Stops": "32702-1,32704-1",
    "Name": "Kolgaküla",
    "Info": "",
    "Street": "Liiapeksi-Loksa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32702-1",
    "SiriID": 5509,
    "Lat": 5952478,
    "Lng": 2569777,
    "Stops": "32701-1,32705-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33702-1",
    "SiriID": 5512,
    "Lat": 5954162,
    "Lng": 2573645,
    "Stops": "33703-1,33704-1",
    "Name": "Kotka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33703-1",
    "SiriID": 5513,
    "Lat": 5954214,
    "Lng": 2573656,
    "Stops": "33702-1,34501-1,33805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32703-1",
    "SiriID": 5506,
    "Lat": 5951680,
    "Lng": 2568474,
    "Stops": "32704-1,30008-1",
    "Name": "Kõnnu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32704-1",
    "SiriID": 5507,
    "Lat": 5951684,
    "Lng": 2568518,
    "Stops": "32703-1,32701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30004-1",
    "SiriID": 5397,
    "Lat": 5946320,
    "Lng": 2563841,
    "Stops": "31402-1,31401-1",
    "Name": "Loksa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32706-1",
    "SiriID": 5510,
    "Lat": 5953140,
    "Lng": 2571640,
    "Stops": "32705-1,33704-1",
    "Name": "Luha",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32705-1",
    "SiriID": 5511,
    "Lat": 5953133,
    "Lng": 2571576,
    "Stops": "32706-1,32702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30008-1",
    "SiriID": 5399,
    "Lat": 5948761,
    "Lng": 2564969,
    "Stops": "30007-1,32703-1",
    "Name": "Ulliallika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30007-1",
    "SiriID": 5400,
    "Lat": 5948753,
    "Lng": 2564915,
    "Stops": "30008-1,30010-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34501-1",
    "SiriID": 5516,
    "Lat": 5956068,
    "Lng": 2572437,
    "Stops": "34502-1,33703-1",
    "Name": "Vanaveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34502-1",
    "SiriID": 5517,
    "Lat": 5956097,
    "Lng": 2572465,
    "Stops": "34501-1,35007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32802-1",
    "SiriID": 5524,
    "Lat": 5956874,
    "Lng": 2562467,
    "Stops": "32801-1,32806-1",
    "Name": "Eindri",
    "Info": "",
    "Street": "Loksa-Hara",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32801-1",
    "SiriID": 5525,
    "Lat": 5956863,
    "Lng": 2562459,
    "Stops": "32802-1,32804-1,32902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32804-1",
    "SiriID": 5522,
    "Lat": 5956377,
    "Lng": 2563894,
    "Stops": "32803-1,32801-1",
    "Name": "Hara",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32803-1",
    "SiriID": 5523,
    "Lat": 5956366,
    "Lng": 2563931,
    "Stops": "32804-1,32809-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32806-1",
    "SiriID": 5526,
    "Lat": 5957716,
    "Lng": 2561063,
    "Stops": "32805-1,32802-1",
    "Name": "Hara side",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32805-1",
    "SiriID": 5527,
    "Lat": 5957731,
    "Lng": 2561074,
    "Stops": "32806-1,32812-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32808-1",
    "SiriID": 5518,
    "Lat": 5956320,
    "Lng": 2568782,
    "Stops": "32807-1,32810-1",
    "Name": "Külaotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32807-1",
    "SiriID": 5519,
    "Lat": 5956331,
    "Lng": 2568848,
    "Stops": "32808-1,35006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32810-1",
    "SiriID": 5520,
    "Lat": 5956171,
    "Lng": 2567090,
    "Stops": "32809-1,32808-1",
    "Name": "Lohja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32809-1",
    "SiriID": 5521,
    "Lat": 5956170,
    "Lng": 2567044,
    "Stops": "32810-1,32803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34201-1",
    "SiriID": 7530,
    "Lat": 5963623,
    "Lng": 2568705,
    "Stops": "34202-1,34207-1",
    "Name": "Kõrve",
    "Info": "",
    "Street": "Loksa-Pärispea",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34202-1",
    "SiriID": 7531,
    "Lat": 5963596,
    "Lng": 2568675,
    "Stops": "34201-1,34104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34101-1",
    "SiriID": 5552,
    "Lat": 5961095,
    "Lng": 2570043,
    "Stops": "34102-1,34105-1",
    "Name": "Mäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34102-1",
    "SiriID": 5553,
    "Lat": 5961116,
    "Lng": 2570017,
    "Stops": "34101-1,34103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34204-1",
    "SiriID": 7535,
    "Lat": 5965391,
    "Lng": 2569386,
    "Stops": "34205-1",
    "Name": "Pärispea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34103-1",
    "SiriID": 5554,
    "Lat": 5962554,
    "Lng": 2568575,
    "Stops": "34104-1,34102-1",
    "Name": "Suurpea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34104-1",
    "SiriID": 5555,
    "Lat": 5962559,
    "Lng": 2568592,
    "Stops": "34103-1,34202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34105-1",
    "SiriID": 5550,
    "Lat": 5960726,
    "Lng": 2570749,
    "Stops": "34106-1,34101-1",
    "Name": "Toomarahva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34106-1",
    "SiriID": 5551,
    "Lat": 5960696,
    "Lng": 2570765,
    "Stops": "34105-1,35102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34207-1",
    "SiriID": 9740,
    "Lat": 5964536,
    "Lng": 2569986,
    "Stops": "34205-1,34201-1",
    "Name": "Viinistu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34401-1",
    "SiriID": 5546,
    "Lat": 5961362,
    "Lng": 2577474,
    "Stops": "34402-1,34403-1",
    "Name": "Ahju",
    "Info": "",
    "Street": "Loksa-Viinistu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34402-1",
    "SiriID": 5547,
    "Lat": 5961337,
    "Lng": 2577481,
    "Stops": "34401-1,34004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34001-1",
    "SiriID": 5544,
    "Lat": 5959953,
    "Lng": 2576105,
    "Stops": "34002-1,34003-1",
    "Name": "Kasispea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34002-1",
    "SiriID": 5545,
    "Lat": 5959914,
    "Lng": 2575976,
    "Stops": "34001-1,35003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34004-1",
    "SiriID": 139,
    "Lat": 5960318,
    "Lng": 2576751,
    "Stops": "34003-1,34402-1",
    "Name": "Kasispea küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34003-1",
    "SiriID": 140,
    "Lat": 5960271,
    "Lng": 2576662,
    "Stops": "34004-1,34001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34403-1",
    "SiriID": 5548,
    "Lat": 5962055,
    "Lng": 2577646,
    "Stops": "34404-1,34401-1",
    "Name": "Turbuneeme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34404-1",
    "SiriID": 5549,
    "Lat": 5962098,
    "Lng": 2577638,
    "Stops": "34403-1,34301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34301-1",
    "SiriID": 7536,
    "Lat": 5964547,
    "Lng": 2575217,
    "Stops": "34302-1,34205-1,34404-1",
    "Name": "Viinistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34302-1",
    "SiriID": 7537,
    "Lat": 5964570,
    "Lng": 2575222,
    "Stops": "34301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "31404-1",
    "SiriID": 12175,
    "Lat": 5946214,
    "Lng": 2564272,
    "Stops": "31402-1,32001-1",
    "Name": "Liiapeksi tee",
    "Info": "",
    "Street": "Peterburi mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29302-1",
    "SiriID": 5393,
    "Lat": 5945810,
    "Lng": 2555319,
    "Stops": "29301-1",
    "Name": "Kahala",
    "Info": "",
    "Street": "Peterburi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29301-1",
    "SiriID": 5394,
    "Lat": 5945838,
    "Lng": 2555218,
    "Stops": "29302-1,29304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29306-1",
    "SiriID": 5391,
    "Lat": 5945550,
    "Lng": 2551893,
    "Stops": "29305-1,31201-1",
    "Name": "Kahala-Kursi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29305-1",
    "SiriID": 5392,
    "Lat": 5945678,
    "Lng": 2551722,
    "Stops": "29306-1,29308-1,30702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28202-1",
    "SiriID": 5383,
    "Lat": 5944137,
    "Lng": 2530997,
    "Stops": "28201-1,30302-1,28455-1",
    "Name": "Kivisilla",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28201-1",
    "SiriID": 5384,
    "Lat": 5944165,
    "Lng": 2530913,
    "Stops": "28202-1,28203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28102-1",
    "SiriID": 5381,
    "Lat": 5943934,
    "Lng": 2527354,
    "Stops": "28101-1",
    "Name": "Kodasoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28101-1",
    "SiriID": 5382,
    "Lat": 5943965,
    "Lng": 2527308,
    "Stops": "28102-1,28103-1,26910-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30202-1",
    "SiriID": 5395,
    "Lat": 5946035,
    "Lng": 2559174,
    "Stops": "29311-1,31301-1",
    "Name": "Kolga tee",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29311-1",
    "SiriID": 5396,
    "Lat": 5946113,
    "Lng": 2558974,
    "Stops": "30202-1,30102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30702-1",
    "SiriID": 5389,
    "Lat": 5945225,
    "Lng": 2547738,
    "Stops": "30701-1,29305-1",
    "Name": "Kupu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "30701-1",
    "SiriID": 5390,
    "Lat": 5945250,
    "Lng": 2547649,
    "Stops": "30702-1,29104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32001-1",
    "SiriID": 5502,
    "Lat": 5946381,
    "Lng": 2570201,
    "Stops": "32002-1,31404-1",
    "Name": "Kõnnu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "32002-1",
    "SiriID": 5503,
    "Lat": 5946353,
    "Lng": 2570623,
    "Stops": "32001-1,32201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "31402-1",
    "SiriID": 9562,
    "Lat": 5946216,
    "Lng": 2564008,
    "Stops": "30004-1,31404-1",
    "Name": "Loksa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "31401-1",
    "SiriID": 9563,
    "Lat": 5946250,
    "Lng": 2563558,
    "Stops": "30004-1,30006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28456-1",
    "SiriID": 5385,
    "Lat": 5944402,
    "Lng": 2535733,
    "Stops": "28455-1,28706-1",
    "Name": "Valkla tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28455-1",
    "SiriID": 5386,
    "Lat": 5944405,
    "Lng": 2535206,
    "Stops": "28456-1,28454-1,28202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "28414-1",
    "SiriID": 5447,
    "Lat": 5947902,
    "Lng": 2533328,
    "Stops": "28416-1,28451-1,26801-1",
    "Name": "Valkla hooldekodu",
    "Info": "",
    "Street": "Valkla-Haapse",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29002-1",
    "SiriID": 7495,
    "Lat": 5945363,
    "Lng": 2543411,
    "Stops": "29006-1,29107-1",
    "Name": "Kapa",
    "Info": "",
    "Street": "Vana-Narva mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29006-1",
    "SiriID": 5760,
    "Lat": 5945345,
    "Lng": 2543204,
    "Stops": "29001-1,29002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29107-1",
    "SiriID": 5761,
    "Lat": 5945629,
    "Lng": 2544626,
    "Stops": "29108-1,29002-1",
    "Name": "Käli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "29108-1",
    "SiriID": 5762,
    "Lat": 5945716,
    "Lng": 2544832,
    "Stops": "29107-1,29106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "34205-1",
    "SiriID": 7533,
    "Lat": 5964549,
    "Lng": 2570003,
    "Stops": "34207-1,34204-1,34301-1",
    "Name": "Viinistu tee",
    "Info": "",
    "Street": "Viinistu-Pärispea",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33704-1",
    "SiriID": 9566,
    "Lat": 5954101,
    "Lng": 2573677,
    "Stops": "33705-1,33702-1,32706-1",
    "Name": "Kotka",
    "Info": "",
    "Street": "Võsu-Kotka",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33705-1",
    "SiriID": 6008,
    "Lat": 5954085,
    "Lng": 2573731,
    "Stops": "33704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33802-1",
    "SiriID": 5514,
    "Lat": 5955197,
    "Lng": 2578887,
    "Stops": "33801-1,33804-1",
    "Name": "Vihasoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "33801-1",
    "SiriID": 5515,
    "Lat": 5955205,
    "Lng": 2578858,
    "Stops": "33802-1,33806-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35001-1",
    "SiriID": 5541,
    "Lat": 5957989,
    "Lng": 2572471,
    "Stops": "35008-1,35004-1,35101-1",
    "Name": "Loksa",
    "Info": "",
    "Street": "Liiapeksi-Loksa",
    "Area": "Loksa linn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35007-1",
    "SiriID": 5540,
    "Lat": 5957219,
    "Lng": 2572468,
    "Stops": "35008-1,34502-1",
    "Name": "Lõuna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35008-1",
    "SiriID": 9571,
    "Lat": 5957303,
    "Lng": 2572492,
    "Stops": "35007-1,35005-1,35001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35006-1",
    "SiriID": 5538,
    "Lat": 5957343,
    "Lng": 2571429,
    "Stops": "35005-1,32807-1",
    "Name": "Loksa-Nõmme",
    "Info": "",
    "Street": "Loksa-Hara",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35005-1",
    "SiriID": 5539,
    "Lat": 5957350,
    "Lng": 2571477,
    "Stops": "35006-1,35008-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35101-1",
    "SiriID": 5542,
    "Lat": 5958725,
    "Lng": 2570717,
    "Stops": "35102-1,35001-1",
    "Name": "Laevaremonditehas",
    "Info": "",
    "Street": "Loksa-Pärispea",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35102-1",
    "SiriID": 9567,
    "Lat": 5958746,
    "Lng": 2570729,
    "Stops": "35101-1,34106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35003-1",
    "SiriID": 9569,
    "Lat": 5958715,
    "Lng": 2573355,
    "Stops": "35004-1,34002-1",
    "Name": "Ravikeskus",
    "Info": "",
    "Street": "Loksa-Viinistu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "35004-1",
    "SiriID": 9570,
    "Lat": 5958650,
    "Lng": 2573371,
    "Stops": "35003-1,35001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19523-1",
    "SiriID": 11099,
    "Lat": 5947654,
    "Lng": 2501694,
    "Stops": "19524-1,19509-1",
    "Name": "Haigla",
    "Info": 2,
    "Street": 0,
    "Area": "Maardu linn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19524-1",
    "SiriID": 11100,
    "Lat": 5947657,
    "Lng": 2501754,
    "Stops": "19523-1,19521-1,19527-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20101-1",
    "SiriID": 2876,
    "Lat": 5948069,
    "Lng": 2494412,
    "Stops": "20103-1",
    "Name": "Kabelikivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19609-1",
    "SiriID": 14750,
    "Lat": 5945687,
    "Lng": 2499336,
    "Stops": "19610-1,19601-1",
    "Name": "Kombinaadi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19610-1",
    "SiriID": 14751,
    "Lat": 5945700,
    "Lng": 2499239,
    "Stops": "19609-1,19605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19611-1",
    "SiriID": 204,
    "Lat": 5945555,
    "Lng": 2500385,
    "Stops": "19612-1,47027-1",
    "Name": "Kroodi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19612-1",
    "SiriID": 205,
    "Lat": 5945564,
    "Lng": 2500357,
    "Stops": "19611-1,19602-1,19607-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19603-1",
    "SiriID": 14746,
    "Lat": 5945084,
    "Lng": 2498701,
    "Stops": "19604-1,19605-1",
    "Name": "Maardu järv",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19604-1",
    "SiriID": 14747,
    "Lat": 5944975,
    "Lng": 2498328,
    "Stops": "19603-1,47023-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19506-1",
    "SiriID": 11104,
    "Lat": 5947461,
    "Lng": 2501433,
    "Stops": "19505-1,19522-1",
    "Name": "Maardu linnavalitsus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19505-1",
    "SiriID": 11105,
    "Lat": 5947349,
    "Lng": 2501502,
    "Stops": "19506-1,19515-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47027-1",
    "SiriID": 14754,
    "Lat": 5945219,
    "Lng": 2501103,
    "Stops": "47026-1,19611-1",
    "Name": "Maardu mõisa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19702-1",
    "SiriID": 5585,
    "Lat": 5948339,
    "Lng": 2496867,
    "Stops": "19701-1,19703-1",
    "Name": "Muuga sadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19701-1",
    "SiriID": 5586,
    "Lat": 5948326,
    "Lng": 2496845,
    "Stops": "19702-1,19706-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19515-1",
    "SiriID": 11102,
    "Lat": 5947264,
    "Lng": 2501760,
    "Stops": "19513-1,19514-1,19505-1",
    "Name": "Ringi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19605-1",
    "SiriID": 14752,
    "Lat": 5945635,
    "Lng": 2498981,
    "Stops": "19606-1,19610-1,19603-1",
    "Name": "Üleoru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19606-1",
    "SiriID": 178,
    "Lat": 5945694,
    "Lng": 2498749,
    "Stops": "19605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19601-1",
    "SiriID": 202,
    "Lat": 5945630,
    "Lng": 2499675,
    "Stops": "19602-1,19609-1",
    "Name": "Fosforiidi",
    "Info": "",
    "Street": "Fosforiidi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19602-1",
    "SiriID": 203,
    "Lat": 5945636,
    "Lng": 2499694,
    "Stops": "19601-1,19612-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20102-1",
    "SiriID": 2877,
    "Lat": 5948142,
    "Lng": 2493275,
    "Stops": "20105-1,20103-1",
    "Name": "Kardheina tee",
    "Info": "",
    "Street": "Kallavere tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20105-1",
    "SiriID": 10524,
    "Lat": 5948134,
    "Lng": 2493231,
    "Stops": "20102-1,19906-1,20812-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19525-1",
    "SiriID": 11899,
    "Lat": 5948097,
    "Lng": 2502069,
    "Stops": "19503-1,19510-1,19519-1",
    "Name": "Kivi",
    "Info": "",
    "Street": "Kallavere-Ülgase",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19519-1",
    "SiriID": 5570,
    "Lat": 5948153,
    "Lng": 2502707,
    "Stops": "19520-1,19525-1",
    "Name": "Kütte",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19520-1",
    "SiriID": 5571,
    "Lat": 5948148,
    "Lng": 2502746,
    "Stops": "19519-1,24103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19522-1",
    "SiriID": 5566,
    "Lat": 5947545,
    "Lng": 2501832,
    "Stops": "19521-1,19506-1",
    "Name": "Haigla",
    "Info": "",
    "Street": "Keemikute tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19521-1",
    "SiriID": 5567,
    "Lat": 5947557,
    "Lng": 2501855,
    "Stops": "19522-1,19524-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19501-1",
    "SiriID": 5577,
    "Lat": 5948723,
    "Lng": 2502707,
    "Stops": "19502-1,24105-1",
    "Name": "Kallavere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19503-1",
    "SiriID": 5572,
    "Lat": 5948268,
    "Lng": 2501762,
    "Stops": "19504-1,19525-1",
    "Name": "Kallavere kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19504-1",
    "SiriID": 5573,
    "Lat": 5948399,
    "Lng": 2501867,
    "Stops": "19503-1,19507-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19608-1",
    "SiriID": 5560,
    "Lat": 5946337,
    "Lng": 2500415,
    "Stops": "19607-1,19518-1",
    "Name": "Kööba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19607-1",
    "SiriID": 5561,
    "Lat": 5946326,
    "Lng": 2500434,
    "Stops": "19608-1,19612-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19517-1",
    "SiriID": 5562,
    "Lat": 5946781,
    "Lng": 2501317,
    "Stops": "19518-1,19513-1",
    "Name": "Maardu kalmistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19518-1",
    "SiriID": 5563,
    "Lat": 5946699,
    "Lng": 2501184,
    "Stops": "19517-1,19608-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19510-1",
    "SiriID": 5568,
    "Lat": 5947990,
    "Lng": 2501611,
    "Stops": "19509-1,19511-1,19525-1",
    "Name": "Mäeotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19507-1",
    "SiriID": 5575,
    "Lat": 5948657,
    "Lng": 2502169,
    "Stops": "19502-1,19504-1",
    "Name": "Nurga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19509-1",
    "SiriID": 5569,
    "Lat": 5947864,
    "Lng": 2501640,
    "Stops": "19510-1,19527-1,19523-1",
    "Name": "Orumetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19513-1",
    "SiriID": 5564,
    "Lat": 5947226,
    "Lng": 2501787,
    "Stops": "19515-1,19517-1",
    "Name": "Ringi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19514-1",
    "SiriID": 5565,
    "Lat": 5947276,
    "Lng": 2501836,
    "Stops": "19515-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19502-1",
    "SiriID": 10529,
    "Lat": 5948790,
    "Lng": 2502275,
    "Stops": "19507-1,19501-1",
    "Name": "Kallavere",
    "Info": "",
    "Street": "Kellamäe tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20103-1",
    "SiriID": 10525,
    "Lat": 5948138,
    "Lng": 2494401,
    "Stops": "20101-1,20104-1,20102-1",
    "Name": "Kabelikivi",
    "Info": "",
    "Street": "Maardu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20104-1",
    "SiriID": 10526,
    "Lat": 5948173,
    "Lng": 2494529,
    "Stops": "20103-1,20004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19706-1",
    "SiriID": 11113,
    "Lat": 5947988,
    "Lng": 2496333,
    "Stops": "19705-1,19701-1",
    "Name": "Maardu veehoidla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19705-1",
    "SiriID": 11114,
    "Lat": 5948007,
    "Lng": 2496236,
    "Stops": "19706-1,20003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20004-1",
    "SiriID": 5587,
    "Lat": 5948101,
    "Lng": 2495420,
    "Stops": "20003-1,20104-1,20002-1",
    "Name": "Uus-Muuga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20003-1",
    "SiriID": 5588,
    "Lat": 5948077,
    "Lng": 2495587,
    "Stops": "20004-1,19705-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19901-1",
    "SiriID": 2872,
    "Lat": 5947678,
    "Lng": 2491898,
    "Stops": "19902-1,18620-1",
    "Name": "Muuga aedlinn",
    "Info": "",
    "Street": "Muuga tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19905-1",
    "SiriID": 10522,
    "Lat": 5947753,
    "Lng": 2492075,
    "Stops": "19902-1,19904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19904-1",
    "SiriID": 2878,
    "Lat": 5947919,
    "Lng": 2492417,
    "Stops": "19906-1,19905-1",
    "Name": "Pirnipuu puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19906-1",
    "SiriID": 10523,
    "Lat": 5947934,
    "Lng": 2492491,
    "Stops": "19904-1,20105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19526-1",
    "SiriID": 166,
    "Lat": 5947834,
    "Lng": 2501957,
    "Stops": "19527-1",
    "Name": "Noorte",
    "Info": "",
    "Street": "Noorte",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19527-1",
    "SiriID": 167,
    "Lat": 5947837,
    "Lng": 2501944,
    "Stops": "19526-1,19509-1,19524-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19703-1",
    "SiriID": 5583,
    "Lat": 5948039,
    "Lng": 2498270,
    "Stops": "19704-1,19702-1",
    "Name": "Kroodi oja",
    "Info": "",
    "Street": "Orumetsa tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19704-1",
    "SiriID": 5584,
    "Lat": 5948027,
    "Lng": 2498269,
    "Stops": "19703-1,24002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19511-1",
    "SiriID": 11101,
    "Lat": 5947937,
    "Lng": 2501378,
    "Stops": "19510-1,24004-1",
    "Name": "Orumetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19105-1",
    "SiriID": 1590,
    "Lat": 5945101,
    "Lng": 2492231,
    "Stops": "19108-1,14308-1",
    "Name": "Iru elektrijaam",
    "Info": "",
    "Street": "Saha-Loo tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19107-1",
    "SiriID": 11110,
    "Lat": 5945527,
    "Lng": 2491959,
    "Stops": "19108-1,19102-1",
    "Name": "Iru küla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19108-1",
    "SiriID": 11111,
    "Lat": 5945521,
    "Lng": 2491943,
    "Stops": "19107-1,47101-1,19105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19403-1",
    "SiriID": 2945,
    "Lat": 5945438,
    "Lng": 2495753,
    "Stops": "19402-1,19401-1",
    "Name": "Koplimäe",
    "Info": "",
    "Street": "Vana-Narva mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19402-1",
    "SiriID": 2946,
    "Lat": 5945450,
    "Lng": 2495757,
    "Stops": "19403-1,19302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19401-1",
    "SiriID": 2947,
    "Lat": 5945311,
    "Lng": 2496639,
    "Stops": "19404-1,19403-1",
    "Name": "Kärmu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19404-1",
    "SiriID": 11109,
    "Lat": 5945292,
    "Lng": 2496667,
    "Stops": "19401-1,47024-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19104-1",
    "SiriID": 2941,
    "Lat": 5945770,
    "Lng": 2493757,
    "Stops": "19103-1,19101-1",
    "Name": "Madikse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19103-1",
    "SiriID": 2942,
    "Lat": 5945758,
    "Lng": 2493861,
    "Stops": "19104-1,19301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19302-1",
    "SiriID": 2943,
    "Lat": 5945620,
    "Lng": 2494652,
    "Stops": "19301-1,19402-1",
    "Name": "Pähklamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19301-1",
    "SiriID": 2944,
    "Lat": 5945651,
    "Lng": 2494503,
    "Stops": "19302-1,19103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19101-1",
    "SiriID": 2939,
    "Lat": 5945984,
    "Lng": 2492823,
    "Stops": "19102-1,19104-1",
    "Name": "Vasar",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19102-1",
    "SiriID": 2940,
    "Lat": 5946045,
    "Lng": 2492608,
    "Stops": "19101-1,19107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20001-1",
    "SiriID": 2874,
    "Lat": 5947304,
    "Lng": 2494072,
    "Stops": "19903-1,20002-1",
    "Name": "Hiireherne tee",
    "Info": "",
    "Street": "Õunapuu pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19903-1",
    "SiriID": 2873,
    "Lat": 5947400,
    "Lng": 2493115,
    "Stops": "20001-1,19902-1",
    "Name": "Kirsipuu puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19902-1",
    "SiriID": 2871,
    "Lat": 5947699,
    "Lng": 2492068,
    "Stops": "19905-1,19901-1,19903-1",
    "Name": "Muuga aedlinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20002-1",
    "SiriID": 2875,
    "Lat": 5947495,
    "Lng": 2495111,
    "Stops": "20001-1,20004-1",
    "Name": "Õunapuu puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20006-1",
    "SiriID": 6110,
    "Lat": 0,
    "Lng": 0,
    "Stops": "",
    "Name": "Hiireherne tee",
    "Info": "",
    "Street": "Õunapuu puiestee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19907-1",
    "SiriID": 6109,
    "Lat": 0,
    "Lng": 0,
    "Stops": "",
    "Name": "Kirsipuu puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20005-1",
    "SiriID": 6108,
    "Lat": 0,
    "Lng": 0,
    "Stops": "",
    "Name": "Õunapuu puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22850-1",
    "SiriID": 13852,
    "Lat": 5927172,
    "Lng": 2413763,
    "Stops": "22851-1,22812-1",
    "Name": "Jahukundru",
    "Info": 4,
    "Street": 0,
    "Area": "Padise vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22851-1",
    "SiriID": 13853,
    "Lat": 5927068,
    "Lng": 2413937,
    "Stops": "22850-1,22828-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22853-1",
    "SiriID": 13436,
    "Lat": 5930291,
    "Lng": 2412524,
    "Stops": "22852-1,22866-1",
    "Name": "Jõesuu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22852-1",
    "SiriID": 13855,
    "Lat": 5930361,
    "Lng": 2412621,
    "Stops": "22853-1,22855-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22843-1",
    "SiriID": 11549,
    "Lat": 5926224,
    "Lng": 2389524,
    "Stops": "22844-1,22837-1",
    "Name": "Pedase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22844-1",
    "SiriID": 11550,
    "Lat": 5926218,
    "Lng": 2389479,
    "Stops": "22843-1,22846-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22849-1",
    "SiriID": 13193,
    "Lat": 5922815,
    "Lng": 2401302,
    "Stops": "22821-1,22804-1,22868-1",
    "Name": "Risti kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22845-1",
    "SiriID": 11551,
    "Lat": 5925891,
    "Lng": 2387660,
    "Stops": "22846-1,22858-1",
    "Name": "Vihterpalu mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22846-1",
    "SiriID": 11552,
    "Lat": 5925899,
    "Lng": 2387669,
    "Stops": "22845-1,22844-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22804-1",
    "SiriID": 4829,
    "Lat": 5923016,
    "Lng": 2399750,
    "Stops": "22803-1,22849-1",
    "Name": "Harju-Risti",
    "Info": "",
    "Street": "Harju-Risti - Rigudi - Võntküla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22803-1",
    "SiriID": 4830,
    "Lat": 5923010,
    "Lng": 2399732,
    "Stops": "22804-1,22836-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22807-1",
    "SiriID": 4825,
    "Lat": 5921814,
    "Lng": 2404399,
    "Stops": "22808-1,22863-1",
    "Name": "Kõmmaste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22808-1",
    "SiriID": 4826,
    "Lat": 5921797,
    "Lng": 2404423,
    "Stops": "22807-1,22817-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22813-1",
    "SiriID": 4839,
    "Lat": 5924935,
    "Lng": 2377455,
    "Stops": "22814-1,22831-1",
    "Name": "Maissoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22814-1",
    "SiriID": 4840,
    "Lat": 5924905,
    "Lng": 2377404,
    "Stops": "22813-1,22823-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22837-1",
    "SiriID": 4835,
    "Lat": 5924796,
    "Lng": 2392014,
    "Stops": "22838-1,22843-1",
    "Name": "Metskonna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22838-1",
    "SiriID": 4836,
    "Lat": 5924770,
    "Lng": 2392023,
    "Stops": "22837-1,22860-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22821-1",
    "SiriID": 4827,
    "Lat": 5922478,
    "Lng": 2402196,
    "Stops": "22822-1,22849-1",
    "Name": "Risti kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22822-1",
    "SiriID": 4828,
    "Lat": 5922458,
    "Lng": 2402238,
    "Stops": "22821-1,22863-1,22864-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22823-1",
    "SiriID": 4841,
    "Lat": 5921699,
    "Lng": 2374844,
    "Stops": "22824-1,22814-1",
    "Name": "Suurekivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22824-1",
    "SiriID": 4842,
    "Lat": 5921652,
    "Lng": 2374774,
    "Stops": "22823-1,5700453-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22831-1",
    "SiriID": 4837,
    "Lat": 5924897,
    "Lng": 2386027,
    "Stops": "22832-1,22813-1",
    "Name": "Vihterpalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22832-1",
    "SiriID": 4838,
    "Lat": 5924866,
    "Lng": 2386088,
    "Stops": "22831-1,22858-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22833-1",
    "SiriID": 4833,
    "Lat": 5923566,
    "Lng": 2394956,
    "Stops": "22834-1,22861-1",
    "Name": "Vilivalla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22834-1",
    "SiriID": 4834,
    "Lat": 5923540,
    "Lng": 2395018,
    "Stops": "22833-1,22835-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22835-1",
    "SiriID": 4831,
    "Lat": 5923278,
    "Lng": 2396742,
    "Stops": "22836-1,22834-1",
    "Name": "Ülamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22836-1",
    "SiriID": 4832,
    "Lat": 5923265,
    "Lng": 2396791,
    "Stops": "22835-1,22803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22858-1",
    "SiriID": 3418,
    "Lat": 5924950,
    "Lng": 2387911,
    "Stops": "22859-1,22832-1,22845-1",
    "Name": "Tamse",
    "Info": "",
    "Street": "HARJU-RISTI - RIGULDI - VÕNTKÜLA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22859-1",
    "SiriID": 5941,
    "Lat": 5924956,
    "Lng": 2388061,
    "Stops": "22858-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22860-1",
    "SiriID": 5942,
    "Lat": 5924110,
    "Lng": 2393553,
    "Stops": "22861-1,22838-1",
    "Name": "Vanika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22861-1",
    "SiriID": 5943,
    "Lat": 5924075,
    "Lng": 2393594,
    "Stops": "22860-1,22833-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22856-1",
    "SiriID": 3077,
    "Lat": 5920028,
    "Lng": 2409689,
    "Stops": "22857-1,22830-1",
    "Name": "Sõela",
    "Info": "",
    "Street": "KEILA - HAAPSALU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22857-1",
    "SiriID": 3408,
    "Lat": 5920121,
    "Lng": 2409684,
    "Stops": "22856-1,22818-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22801-1",
    "SiriID": 4847,
    "Lat": 5918227,
    "Lng": 2408536,
    "Stops": "22802-1,22871-1",
    "Name": "Audevälja",
    "Info": "",
    "Street": "Keila - Haapsalu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22802-1",
    "SiriID": 4848,
    "Lat": 5918323,
    "Lng": 2408625,
    "Stops": "22862-1,22801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22819-1",
    "SiriID": 4812,
    "Lat": 5922693,
    "Lng": 2416155,
    "Stops": "22839-1,22805-1",
    "Name": "Kasepere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22815-1",
    "SiriID": 4823,
    "Lat": 5921911,
    "Lng": 2410146,
    "Stops": "22816-1,22817-1",
    "Name": "Määra",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22816-1",
    "SiriID": 4824,
    "Lat": 5921967,
    "Lng": 2410295,
    "Stops": "22815-1,22869-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22818-1",
    "SiriID": 4843,
    "Lat": 5920940,
    "Lng": 2409483,
    "Stops": "22817-1,22857-1",
    "Name": "Määramäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22817-1",
    "SiriID": 4844,
    "Lat": 5921042,
    "Lng": 2409464,
    "Stops": "22818-1,22815-1,22808-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22847-1",
    "SiriID": 12460,
    "Lat": 5922446,
    "Lng": 2411678,
    "Stops": "22848-1,22869-1",
    "Name": "Oti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22848-1",
    "SiriID": 12461,
    "Lat": 5922503,
    "Lng": 2411944,
    "Stops": "22847-1,22806-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22805-1",
    "SiriID": 4821,
    "Lat": 5922740,
    "Lng": 2414629,
    "Stops": "22806-1,22819-1",
    "Name": "Padise",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22806-1",
    "SiriID": 4822,
    "Lat": 5922795,
    "Lng": 2414504,
    "Stops": "22805-1,22848-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22825-1",
    "SiriID": 4849,
    "Lat": 5915207,
    "Lng": 2407658,
    "Stops": "22826-1,5700212-1",
    "Name": "Suursoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22826-1",
    "SiriID": 4850,
    "Lat": 5915221,
    "Lng": 2407655,
    "Stops": "22825-1,22870-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22829-1",
    "SiriID": 4845,
    "Lat": 5919143,
    "Lng": 2409190,
    "Stops": "22830-1,22862-1,22872-1",
    "Name": "Türismaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22830-1",
    "SiriID": 4846,
    "Lat": 5919242,
    "Lng": 2409267,
    "Stops": "22829-1,22856-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22828-1",
    "SiriID": 4817,
    "Lat": 5925861,
    "Lng": 2415097,
    "Stops": "22827-1,22851-1",
    "Name": "Karilepa",
    "Info": "",
    "Street": "Paldiski - Padise",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22827-1",
    "SiriID": 4818,
    "Lat": 5925766,
    "Lng": 2415158,
    "Stops": "22828-1,22873-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22839-1",
    "SiriID": 9459,
    "Lat": 5922821,
    "Lng": 2416436,
    "Stops": "22820-1,22819-1,22809-1",
    "Name": "Kasepere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22809-1",
    "SiriID": 4815,
    "Lat": 5924503,
    "Lng": 2416949,
    "Stops": "22810-1,22839-1",
    "Name": "Liisküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22810-1",
    "SiriID": 4816,
    "Lat": 5924643,
    "Lng": 2416841,
    "Stops": "22809-1,22873-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22811-1",
    "SiriID": 4819,
    "Lat": 5928744,
    "Lng": 2412341,
    "Stops": "22812-1",
    "Name": "Madise",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22812-1",
    "SiriID": 4820,
    "Lat": 5928706,
    "Lng": 2412462,
    "Stops": "22811-1,22866-1,22850-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22906-1",
    "SiriID": 10356,
    "Lat": 5933442,
    "Lng": 2408591,
    "Stops": "22914-1,22911-1",
    "Name": "Paldiski sadam",
    "Info": "",
    "Street": 0,
    "Area": "Paldiski linn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22909-1",
    "SiriID": 10789,
    "Lat": 5934412,
    "Lng": 2406092,
    "Stops": "22910-1,22905-1",
    "Name": "Paldiski",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22910-1",
    "SiriID": 10790,
    "Lat": 5934393,
    "Lng": 2406114,
    "Stops": "22909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22911-1",
    "SiriID": 12464,
    "Lat": 5934046,
    "Lng": 2410232,
    "Stops": "22912-1,22906-1",
    "Name": "Leetse tee",
    "Info": "",
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22912-1",
    "SiriID": 12465,
    "Lat": 5934001,
    "Lng": 2410590,
    "Stops": "22911-1,22854-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22913-1",
    "SiriID": 90,
    "Lat": 5934184,
    "Lng": 2407929,
    "Stops": "22914-1,22917-1",
    "Name": "Rae põik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22914-1",
    "SiriID": 91,
    "Lat": 5934160,
    "Lng": 2408141,
    "Stops": "22913-1,22906-1",
    "Name": "Rae põik_likvideeritud",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21716-1",
    "SiriID": 4766,
    "Lat": 5934503,
    "Lng": 2418287,
    "Stops": "21717-1,47417-1",
    "Name": "Vanaranna tee/ Kersalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22904-1",
    "SiriID": 10349,
    "Lat": 5934740,
    "Lng": 2405783,
    "Stops": "22915-1,22903-1",
    "Name": "Kivi tänav/ Paldiski II",
    "Info": "",
    "Street": "Peetri tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22905-1",
    "SiriID": 10350,
    "Lat": 5934441,
    "Lng": 2406177,
    "Stops": "22909-1,22915-1,22916-1",
    "Name": "Raudteejaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22916-1",
    "SiriID": 5714,
    "Lat": 5934311,
    "Lng": 2407169,
    "Stops": "22917-1,22905-1",
    "Name": "Pentagoni",
    "Info": "",
    "Street": "Rae",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22917-1",
    "SiriID": 5715,
    "Lat": 5934275,
    "Lng": 2407265,
    "Stops": "22916-1,22913-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22903-1",
    "SiriID": 10348,
    "Lat": 5934995,
    "Lng": 2405883,
    "Stops": "22904-1,22919-1",
    "Name": "Rae tänav",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22915-1",
    "SiriID": 5713,
    "Lat": 5934747,
    "Lng": 2406200,
    "Stops": "22904-1,22905-1",
    "Name": "Tööstuse tänav",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22901-1",
    "SiriID": 4768,
    "Lat": 5935244,
    "Lng": 2405333,
    "Stops": "22919-1,22918-1",
    "Name": "Sadama tänav",
    "Info": "",
    "Street": "Sadama tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41116-1",
    "SiriID": 98,
    "Lat": 5936407,
    "Lng": 2507848,
    "Stops": "23053-1,41108-1,41101-1",
    "Name": "Aruküla lasteaed",
    "Info": 2,
    "Street": 0,
    "Area": "Raasiku vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41115-1",
    "SiriID": 13333,
    "Lat": 5937151,
    "Lng": 2507880,
    "Stops": "41105-1,41104-1,41103-1",
    "Name": "Aruküla raudteejaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23055-1",
    "SiriID": 14721,
    "Lat": 5935567,
    "Lng": 2507606,
    "Stops": "23056-1,23013-1",
    "Name": "Hellema",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23056-1",
    "SiriID": 14722,
    "Lat": 5935608,
    "Lng": 2507625,
    "Stops": "23055-1,23054-1,41110-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41315-1",
    "SiriID": 97,
    "Lat": 5938115,
    "Lng": 2499327,
    "Stops": "41313-1,43001-1,23146-1",
    "Name": "Kesa talu",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41117-1",
    "SiriID": 96,
    "Lat": 5940636,
    "Lng": 2507815,
    "Stops": "25303-1,41104-1",
    "Name": "Kurgla",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23059-1",
    "SiriID": 94,
    "Lat": 5927846,
    "Lng": 2513514,
    "Stops": "23029-1,23027-1",
    "Name": "Pikavere kool",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41017-1",
    "SiriID": 99,
    "Lat": 5936820,
    "Lng": 2518440,
    "Stops": "41007-1,41006-1",
    "Name": "Raasiku põhikool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23053-1",
    "SiriID": 13765,
    "Lat": 5936595,
    "Lng": 2508035,
    "Stops": "41116-1,41103-1",
    "Name": "Raasiku vallamaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23061-1",
    "SiriID": 177,
    "Lat": 5931865,
    "Lng": 2519996,
    "Stops": "23060-1,23041-1",
    "Name": "Rätla küla 1",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23060-1",
    "SiriID": 112,
    "Lat": 5932331,
    "Lng": 2522639,
    "Stops": "23061-1,38201-1",
    "Name": "Rätla küla 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41403-1",
    "SiriID": 95,
    "Lat": 5936841,
    "Lng": 2503623,
    "Stops": "41311-1,41401-1",
    "Name": "Suurekivi talu",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41012-1",
    "SiriID": 11054,
    "Lat": 5936978,
    "Lng": 2515241,
    "Stops": "41011-1,25501-1",
    "Name": "Tehase",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41011-1",
    "SiriID": 11055,
    "Lat": 5936953,
    "Lng": 2515243,
    "Stops": "41012-1,23033-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23054-1",
    "SiriID": 13759,
    "Lat": 5935821,
    "Lng": 2508150,
    "Stops": "23056-1,41107-1",
    "Name": "Waldorfi kool",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41105-1",
    "SiriID": 10857,
    "Lat": 5937203,
    "Lng": 2507949,
    "Stops": "41115-1",
    "Name": "Aruküla",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41104-1",
    "SiriID": 10858,
    "Lat": 5937208,
    "Lng": 2507739,
    "Stops": "41115-1,41117-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41314-1",
    "SiriID": 10861,
    "Lat": 5938355,
    "Lng": 2500309,
    "Stops": "41313-1,41312-1",
    "Name": "Kulli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41313-1",
    "SiriID": 10862,
    "Lat": 5938349,
    "Lng": 2500294,
    "Stops": "41314-1,41315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41004-1",
    "SiriID": 10853,
    "Lat": 5936575,
    "Lng": 2517377,
    "Stops": "41003-1",
    "Name": "Raasiku",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41003-1",
    "SiriID": 10854,
    "Lat": 5936557,
    "Lng": 2517487,
    "Stops": "41004-1,41005-1,41002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41103-1",
    "SiriID": 5198,
    "Lat": 5936906,
    "Lng": 2507980,
    "Stops": "41115-1,23053-1",
    "Name": "Aruküla",
    "Info": 2,
    "Street": "Aruküla-Kostivere",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41107-1",
    "SiriID": 5199,
    "Lat": 5936254,
    "Lng": 2508243,
    "Stops": "41108-1,23054-1",
    "Name": "Aruküla kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41108-1",
    "SiriID": 5200,
    "Lat": 5936290,
    "Lng": 2508270,
    "Stops": "41107-1,41116-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23025-1",
    "SiriID": 5209,
    "Lat": 5933711,
    "Lng": 2515322,
    "Stops": "23026-1,23049-1",
    "Name": "Peningi",
    "Info": 3,
    "Street": "Aruvalla-Jägala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23026-1",
    "SiriID": 5210,
    "Lat": 5933694,
    "Lng": 2515348,
    "Stops": "23025-1,23039-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23027-1",
    "SiriID": 5217,
    "Lat": 5928314,
    "Lng": 2515951,
    "Stops": "23028-1,23059-1",
    "Name": "Perila",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23028-1",
    "SiriID": 5218,
    "Lat": 5928326,
    "Lng": 2515947,
    "Stops": "23027-1,23048-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23030-1",
    "SiriID": 5219,
    "Lat": 5927655,
    "Lng": 2513351,
    "Stops": "23029-1,23057-1",
    "Name": "Pikavere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23029-1",
    "SiriID": 5220,
    "Lat": 5927655,
    "Lng": 2513476,
    "Stops": "23030-1,23059-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23034-1",
    "SiriID": 5308,
    "Lat": 5936085,
    "Lng": 2515579,
    "Stops": "23033-1,41001-1",
    "Name": "Puukooli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23033-1",
    "SiriID": 5309,
    "Lat": 5936082,
    "Lng": 2515519,
    "Stops": "23034-1,41011-1,23050-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41001-1",
    "SiriID": 5310,
    "Lat": 5936279,
    "Lng": 2517182,
    "Stops": "41002-1,23034-1",
    "Name": "Raasiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41002-1",
    "SiriID": 5311,
    "Lat": 5936281,
    "Lng": 2517471,
    "Stops": "41001-1,41003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23040-1",
    "SiriID": 5211,
    "Lat": 5931784,
    "Lng": 2515883,
    "Stops": "23039-1,23041-1",
    "Name": "Retimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23039-1",
    "SiriID": 5212,
    "Lat": 5931837,
    "Lng": 2515885,
    "Stops": "23040-1,23026-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23042-1",
    "SiriID": 5213,
    "Lat": 5930797,
    "Lng": 2516693,
    "Stops": "23041-1,23047-1",
    "Name": "Rätla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23041-1",
    "SiriID": 5214,
    "Lat": 5930992,
    "Lng": 2516517,
    "Stops": "23042-1,23040-1,23061-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41009-1",
    "SiriID": 5320,
    "Lat": 5938256,
    "Lng": 2519587,
    "Stops": "41010-1,41014-1,25501-1",
    "Name": "Sambu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41010-1",
    "SiriID": 5321,
    "Lat": 5938287,
    "Lng": 2519605,
    "Stops": "41009-1,25602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23048-1",
    "SiriID": 5215,
    "Lat": 5929778,
    "Lng": 2517276,
    "Stops": "23047-1,23028-1,23024-1",
    "Name": "Tasuja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23047-1",
    "SiriID": 5216,
    "Lat": 5929798,
    "Lng": 2517307,
    "Stops": "23048-1,23042-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23049-1",
    "SiriID": 5306,
    "Lat": 5935234,
    "Lng": 2514573,
    "Stops": "23050-1,23052-1,23025-1",
    "Name": "Tõhelgi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23050-1",
    "SiriID": 5307,
    "Lat": 5935283,
    "Lng": 2514578,
    "Stops": "23049-1,23033-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23057-1",
    "SiriID": 14842,
    "Lat": 5927530,
    "Lng": 2511586,
    "Stops": "23058-1,23030-1",
    "Name": "Veskimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23058-1",
    "SiriID": 14843,
    "Lat": 5927526,
    "Lng": 2511461,
    "Stops": "23057-1,23178-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41013-1",
    "SiriID": 5318,
    "Lat": 5937495,
    "Lng": 2519374,
    "Stops": "41014-1,41016-1",
    "Name": "Vildivabrik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41014-1",
    "SiriID": 5319,
    "Lat": 5937883,
    "Lng": 2519469,
    "Stops": "41009-1,41013-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41015-1",
    "SiriID": 5316,
    "Lat": 5936946,
    "Lng": 2519196,
    "Stops": "41016-1,41008-1",
    "Name": "Võidu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41016-1",
    "SiriID": 5317,
    "Lat": 5937082,
    "Lng": 2519292,
    "Stops": "41015-1,41013-1,38201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41112-1",
    "SiriID": 5189,
    "Lat": 5935921,
    "Lng": 2505807,
    "Stops": "41111-1,41109-1,41113-1",
    "Name": "Harju",
    "Info": 2,
    "Street": "LAGEDI - ARUKÜLA - PENINGI",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41111-1",
    "SiriID": 5190,
    "Lat": 5935961,
    "Lng": 2505711,
    "Stops": "41112-1,41402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23014-1",
    "SiriID": 5201,
    "Lat": 5935098,
    "Lng": 2508540,
    "Stops": "23013-1,23009-1",
    "Name": "Igavere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23013-1",
    "SiriID": 5202,
    "Lat": 5935127,
    "Lng": 2508512,
    "Stops": "23014-1,23055-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41109-1",
    "SiriID": 5191,
    "Lat": 5935724,
    "Lng": 2506724,
    "Stops": "41110-1,41112-1",
    "Name": "Aruküla tee",
    "Info": "",
    "Street": "Lagedi-Aruküla-Peningi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41110-1",
    "SiriID": 5192,
    "Lat": 5935711,
    "Lng": 2506756,
    "Stops": "41109-1,23056-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23010-1",
    "SiriID": 5203,
    "Lat": 5934710,
    "Lng": 2509925,
    "Stops": "23009-1,23017-1",
    "Name": "Ata",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23009-1",
    "SiriID": 5204,
    "Lat": 5934731,
    "Lng": 2509883,
    "Stops": "23010-1,23014-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41311-1",
    "SiriID": 5185,
    "Lat": 5936808,
    "Lng": 2502737,
    "Stops": "41312-1,41403-1,23146-1",
    "Name": "Jüri tee",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41312-1",
    "SiriID": 5186,
    "Lat": 5936843,
    "Lng": 2502734,
    "Stops": "41311-1,41314-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23018-1",
    "SiriID": 5205,
    "Lat": 5934395,
    "Lng": 2511164,
    "Stops": "23017-1,23051-1",
    "Name": "Kalesi",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23017-1",
    "SiriID": 5206,
    "Lat": 5934412,
    "Lng": 2511111,
    "Stops": "23018-1,23010-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41402-1",
    "SiriID": 5187,
    "Lat": 5936207,
    "Lng": 2504913,
    "Stops": "41401-1,41111-1",
    "Name": "Kangla",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41401-1",
    "SiriID": 5188,
    "Lat": 5936265,
    "Lng": 2504752,
    "Stops": "41402-1,41403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23051-1",
    "SiriID": 5207,
    "Lat": 5934442,
    "Lng": 2512147,
    "Stops": "23052-1,23018-1",
    "Name": "Tõhelgi tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23052-1",
    "SiriID": 5208,
    "Lat": 5934435,
    "Lng": 2512245,
    "Stops": "23051-1,23049-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41102-1",
    "SiriID": 5193,
    "Lat": 5936640,
    "Lng": 2507315,
    "Stops": "41101-1,41114-1",
    "Name": "Aedlinn",
    "Info": 2,
    "Street": "Männiku tee (Aruküla)",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41101-1",
    "SiriID": 5194,
    "Lat": 5936644,
    "Lng": 2507326,
    "Stops": "41102-1,41116-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41114-1",
    "SiriID": 5195,
    "Lat": 5936427,
    "Lng": 2506617,
    "Stops": "41113-1,41102-1",
    "Name": "Spordiväljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41113-1",
    "SiriID": 5196,
    "Lat": 5936387,
    "Lng": 2506531,
    "Stops": "41114-1,41112-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21458-1",
    "SiriID": 5226,
    "Lat": 5928241,
    "Lng": 2525431,
    "Stops": "21459-1,23031-1",
    "Name": "Laasi  tee",
    "Info": 3,
    "Street": "Perila-Jäneda",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21459-1",
    "SiriID": 5227,
    "Lat": 5928244,
    "Lng": 2525574,
    "Stops": "21458-1,21413-1",
    "Name": "Laasi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23024-1",
    "SiriID": 5221,
    "Lat": 5928294,
    "Lng": 2520374,
    "Stops": "23023-1,23048-1",
    "Name": "Liivaaugu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23023-1",
    "SiriID": 5222,
    "Lat": 5928278,
    "Lng": 2520437,
    "Stops": "23024-1,23032-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23032-1",
    "SiriID": 5223,
    "Lat": 5928317,
    "Lng": 2523673,
    "Stops": "23031-1,23023-1",
    "Name": "Puidutööstuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41007-1",
    "SiriID": 5314,
    "Lat": 5937019,
    "Lng": 2518474,
    "Stops": "41008-1,41017-1",
    "Name": "Raasiku rahvamaja",
    "Info": "",
    "Street": "Raasiku jaama tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41008-1",
    "SiriID": 5315,
    "Lat": 5937061,
    "Lng": 2518521,
    "Stops": "41007-1,41015-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41006-1",
    "SiriID": 5312,
    "Lat": 5936759,
    "Lng": 2517631,
    "Stops": "41005-1,41017-1",
    "Name": "Raasiku side",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "41005-1",
    "SiriID": 5313,
    "Lat": 5936741,
    "Lng": 2517583,
    "Stops": "41006-1,41003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43178-1",
    "SiriID": 220,
    "Lat": 5934261,
    "Lng": 2491021,
    "Stops": "43179-1,23198-1",
    "Name": "Aaviku",
    "Info": 2,
    "Street": 0,
    "Area": "Rae vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43179-1",
    "SiriID": 221,
    "Lat": 5934234,
    "Lng": 2491034,
    "Stops": "43178-1,23181-1,43181-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23920-1",
    "SiriID": 109,
    "Lat": 5935701,
    "Lng": 2492842,
    "Stops": "23121-1,23197-1",
    "Name": "ABB",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23930-1",
    "SiriID": 4956,
    "Lat": 5942164,
    "Lng": 2489959,
    "Stops": "23931-1,14003-1",
    "Name": "Arestimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23931-1",
    "SiriID": 4958,
    "Lat": 5942166,
    "Lng": 2490060,
    "Stops": "23930-1,43410-1,23938-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23915-1",
    "SiriID": 14045,
    "Lat": 5926653,
    "Lng": 2502008,
    "Stops": "23110-1,23109-1",
    "Name": "Ellaku",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23921-1",
    "SiriID": 104,
    "Lat": 5936828,
    "Lng": 2488318,
    "Stops": "23922-1",
    "Name": "Hilding Baltic",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23922-1",
    "SiriID": 108,
    "Lat": 5936867,
    "Lng": 2488159,
    "Stops": "23921-1,23927-1,43189-1",
    "Name": "Itella",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43162-1",
    "SiriID": 14836,
    "Lat": 5938466,
    "Lng": 2486199,
    "Stops": "43188-1,43187-1",
    "Name": "Jaani",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43188-1",
    "SiriID": 4566,
    "Lat": 5938448,
    "Lng": 2486217,
    "Stops": "43162-1,23919-1,43160-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43194-1",
    "SiriID": 4572,
    "Lat": 5938306,
    "Lng": 2482103,
    "Stops": "43195-1,23114-1,23934-1",
    "Name": "Järveküla kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43195-1",
    "SiriID": 4573,
    "Lat": 5938309,
    "Lng": 2482151,
    "Stops": "43194-1,23112-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23161-1",
    "SiriID": 4448,
    "Lat": 5935791,
    "Lng": 2489000,
    "Stops": "23928-1,23917-1",
    "Name": "Jüri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23928-1",
    "SiriID": 4932,
    "Lat": 5935872,
    "Lng": 2489322,
    "Stops": "23161-1,23185-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23911-1",
    "SiriID": 13140,
    "Lat": 5935612,
    "Lng": 2490841,
    "Stops": "23175-1,23185-1",
    "Name": "Jüri Gümnaasiumi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43193-1",
    "SiriID": 4570,
    "Lat": 5936276,
    "Lng": 2492405,
    "Stops": "43192-1,23165-1",
    "Name": "Jüri kirik likvideeritud",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43192-1",
    "SiriID": 4571,
    "Lat": 5936241,
    "Lng": 2492382,
    "Stops": "43193-1,23121-1,23119-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23199-1",
    "SiriID": 13121,
    "Lat": 5934902,
    "Lng": 2489425,
    "Stops": "23198-1,23186-1",
    "Name": "Jüri tehnopark",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23198-1",
    "SiriID": 13084,
    "Lat": 5934936,
    "Lng": 2490214,
    "Stops": "23199-1,43178-1",
    "Name": "Jüri-Aaviku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23197-1",
    "SiriID": 10258,
    "Lat": 5935783,
    "Lng": 2491866,
    "Stops": "23120-1,23920-1",
    "Name": "Kasemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23932-1",
    "SiriID": 5636,
    "Lat": 5934660,
    "Lng": 2496214,
    "Stops": "23154-1,23133-1",
    "Name": "Kesa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43180-1",
    "SiriID": 222,
    "Lat": 5932511,
    "Lng": 2492402,
    "Stops": "43181-1,23123-1",
    "Name": "Korba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43181-1",
    "SiriID": 223,
    "Lat": 5932519,
    "Lng": 2492409,
    "Stops": "43180-1,43179-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43197-1",
    "SiriID": 4575,
    "Lat": 5938793,
    "Lng": 2482404,
    "Stops": "43198-1,23114-1",
    "Name": "Krati",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43198-1",
    "SiriID": 4576,
    "Lat": 5938805,
    "Lng": 2482419,
    "Stops": "43197-1,23104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43182-1",
    "SiriID": 4254,
    "Lat": 5933255,
    "Lng": 2482425,
    "Stops": "22010-1,22009-1,23128-1",
    "Name": "Kurna tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23919-1",
    "SiriID": 65,
    "Lat": 5937524,
    "Lng": 2485635,
    "Stops": "47041-1,23126-1,43188-1",
    "Name": "Lehmja kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47041-1",
    "SiriID": 132,
    "Lat": 5937521,
    "Lng": 2485586,
    "Stops": "23919-1,23143-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23935-1",
    "SiriID": 5728,
    "Lat": 5937251,
    "Lng": 2481052,
    "Stops": "23936-1,23135-1",
    "Name": "Liiva tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23936-1",
    "SiriID": 5729,
    "Lat": 5937270,
    "Lng": 2481035,
    "Stops": "23935-1,23933-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43118-1",
    "SiriID": 14840,
    "Lat": 5940431,
    "Lng": 2494381,
    "Stops": "43119-1,43113-1,43112-1",
    "Name": "Linnu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43119-1",
    "SiriID": 14841,
    "Lat": 5940448,
    "Lng": 2494376,
    "Stops": "43118-1,43116-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43163-1",
    "SiriID": 14835,
    "Lat": 5938730,
    "Lng": 2485131,
    "Stops": "43187-1,23925-1",
    "Name": "Loopera",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43187-1",
    "SiriID": 4565,
    "Lat": 5938692,
    "Lng": 2485225,
    "Stops": "43163-1,43162-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23933-1",
    "SiriID": 5730,
    "Lat": 5937618,
    "Lng": 2481282,
    "Stops": "23934-1,23936-1",
    "Name": "Lõo tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23934-1",
    "SiriID": 5731,
    "Lat": 5937623,
    "Lng": 2481320,
    "Stops": "23933-1,43194-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23939-1",
    "SiriID": 6016,
    "Lat": 5939423,
    "Lng": 2482861,
    "Stops": "23103-1,23157-1",
    "Name": "Läike tee - nimeta",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23127-1",
    "SiriID": 4481,
    "Lat": 5933823,
    "Lng": 2484039,
    "Stops": "23128-1,23162-1",
    "Name": "Mõisaküla tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23136-1",
    "SiriID": 4577,
    "Lat": 5936490,
    "Lng": 2481593,
    "Stops": "23135-1,43161-1",
    "Name": "Orava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23135-1",
    "SiriID": 4578,
    "Lat": 5936521,
    "Lng": 2481597,
    "Stops": "23136-1,23935-1,43167-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43159-1",
    "SiriID": 13667,
    "Lat": 5939626,
    "Lng": 2480829,
    "Stops": "43164-1,43165-1,23158-1",
    "Name": "Peetri kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43164-1",
    "SiriID": 136,
    "Lat": 5939754,
    "Lng": 2480797,
    "Stops": "43159-1,43168-1,23117-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43169-1",
    "SiriID": 173,
    "Lat": 5929951,
    "Lng": 2491590,
    "Stops": "23156-1,22023-1",
    "Name": "Puraviku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23916-1",
    "SiriID": 14839,
    "Lat": 5935978,
    "Lng": 2488545,
    "Stops": "23917-1,23162-1,23924-1",
    "Name": "Põrguvälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23917-1",
    "SiriID": 14851,
    "Lat": 5935928,
    "Lng": 2488678,
    "Stops": "23916-1,23161-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43177-1",
    "SiriID": 216,
    "Lat": 5940642,
    "Lng": 2493294,
    "Stops": "43176-1,43117-1,43111-1",
    "Name": "Raadiojaama tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43176-1",
    "SiriID": 217,
    "Lat": 5940678,
    "Lng": 2493298,
    "Stops": "43177-1,43302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43171-1",
    "SiriID": 234,
    "Lat": 5938694,
    "Lng": 2494092,
    "Stops": "43155-1,43152-1,43173-1",
    "Name": "Rae Leivajõe AÜ",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43173-1",
    "SiriID": 235,
    "Lat": 5937629,
    "Lng": 2494903,
    "Stops": "43170-1,43171-1",
    "Name": "Rae Lootsi AÜ",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43160-1",
    "SiriID": 13439,
    "Lat": 5937951,
    "Lng": 2488680,
    "Stops": "43189-1,43188-1",
    "Name": "Rae mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43189-1",
    "SiriID": 4567,
    "Lat": 5937947,
    "Lng": 2488699,
    "Stops": "43160-1,43190-1,23922-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23133-1",
    "SiriID": 13445,
    "Lat": 5934754,
    "Lng": 2500293,
    "Stops": "23134-1,23932-1,43172-1",
    "Name": "Rae Seli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23134-1",
    "SiriID": 13446,
    "Lat": 5934762,
    "Lng": 2500301,
    "Stops": "23133-1,23146-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43170-1",
    "SiriID": 233,
    "Lat": 5937413,
    "Lng": 2494377,
    "Stops": "43173-1,23909-1,23166-1",
    "Name": "Rae Vaadi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43172-1",
    "SiriID": 232,
    "Lat": 5932532,
    "Lng": 2499795,
    "Stops": "23133-1,43175-1,43174-1",
    "Name": "Rae Vana-Üüdiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23923-1",
    "SiriID": 106,
    "Lat": 5936675,
    "Lng": 2486819,
    "Stops": "23914-1,23125-1",
    "Name": "Saku Metall",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23913-1",
    "SiriID": 13220,
    "Lat": 5936546,
    "Lng": 2487255,
    "Stops": "23914-1,23924-1",
    "Name": "Sinikivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23914-1",
    "SiriID": 13221,
    "Lat": 5936676,
    "Lng": 2487101,
    "Stops": "23923-1,23913-1,23927-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23925-1",
    "SiriID": 100,
    "Lat": 5938736,
    "Lng": 2484449,
    "Stops": "43186-1,43163-1",
    "Name": "Smarten Logistics",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43174-1",
    "SiriID": 230,
    "Lat": 5930196,
    "Lng": 2505357,
    "Stops": "23169-1,23189-1,43172-1",
    "Name": "Suursoo",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43185-1",
    "SiriID": 4356,
    "Lat": 5938567,
    "Lng": 2484019,
    "Stops": "43186-1,23101-1",
    "Name": "Talipihlaka",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43186-1",
    "SiriID": 4517,
    "Lat": 5938629,
    "Lng": 2484438,
    "Stops": "23925-1,43185-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23924-1",
    "SiriID": 105,
    "Lat": 5936528,
    "Lng": 2487740,
    "Stops": "23913-1,23916-1",
    "Name": "Trafotek",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43166-1",
    "SiriID": 175,
    "Lat": 5937202,
    "Lng": 2483425,
    "Stops": "43167-1,23144-1",
    "Name": "Turuveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43167-1",
    "SiriID": 176,
    "Lat": 5937179,
    "Lng": 2483443,
    "Stops": "43166-1,23135-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43161-1",
    "SiriID": 13678,
    "Lat": 5936092,
    "Lng": 2481198,
    "Stops": "23136-1,22006-1",
    "Name": "Uuesalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23148-1",
    "SiriID": 11530,
    "Lat": 5939417,
    "Lng": 2480593,
    "Stops": "43165-1,23187-1,23147-1",
    "Name": "Uusmaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43165-1",
    "SiriID": 137,
    "Lat": 5939425,
    "Lng": 2480584,
    "Stops": "23148-1,43159-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23910-1",
    "SiriID": 13209,
    "Lat": 5928539,
    "Lng": 2497627,
    "Stops": "23908-1,43408-1",
    "Name": "Vaida",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43408-1",
    "SiriID": 3309,
    "Lat": 5928200,
    "Lng": 2497922,
    "Stops": "23910-1,23189-1",
    "Name": "Vaida Põhikool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43410-1",
    "SiriID": 4581,
    "Lat": 5942192,
    "Lng": 2490456,
    "Stops": "43411-1,23931-1",
    "Name": "Vangla",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43411-1",
    "SiriID": 4804,
    "Lat": 5942187,
    "Lng": 2490532,
    "Stops": "43410-1,43401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23909-1",
    "SiriID": 10604,
    "Lat": 5936956,
    "Lng": 2495400,
    "Stops": "43170-1,23184-1",
    "Name": "Vaskjala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43406-1",
    "SiriID": 41,
    "Lat": 5924437,
    "Lng": 2500996,
    "Stops": "43405-1,23174-1",
    "Name": "Veeaugu",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43405-1",
    "SiriID": 42,
    "Lat": 5924506,
    "Lng": 2500993,
    "Stops": "43406-1,43403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23185-1",
    "SiriID": 3969,
    "Lat": 5935512,
    "Lng": 2489481,
    "Stops": "23186-1,23928-1,23911-1",
    "Name": "Veetorni",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23918-1",
    "SiriID": 14323,
    "Lat": 5943513,
    "Lng": 2492623,
    "Stops": "47012-1,25103-1,43402-1",
    "Name": "Veneküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43175-1",
    "SiriID": 231,
    "Lat": 5931873,
    "Lng": 2495441,
    "Stops": "23901-1,43172-1",
    "Name": "Veskitaguse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23926-1",
    "SiriID": 102,
    "Lat": 5939411,
    "Lng": 2492424,
    "Stops": "43152-1",
    "Name": "Via3L Kalmari",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23927-1",
    "SiriID": 103,
    "Lat": 5936850,
    "Lng": 2487511,
    "Stops": "23914-1,23922-1",
    "Name": "Via3L Sinikivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43190-1",
    "SiriID": 4568,
    "Lat": 5937432,
    "Lng": 2490395,
    "Stops": "43191-1,43189-1",
    "Name": "Viadukti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43191-1",
    "SiriID": 4569,
    "Lat": 5937388,
    "Lng": 2490440,
    "Stops": "43190-1,23165-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23147-1",
    "SiriID": 11529,
    "Lat": 5939160,
    "Lng": 2481259,
    "Stops": "43196-1,23148-1",
    "Name": "Vägeva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43196-1",
    "SiriID": 4574,
    "Lat": 5939154,
    "Lng": 2481271,
    "Stops": "23147-1,23113-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23128-1",
    "SiriID": 4482,
    "Lat": 5933380,
    "Lng": 2483262,
    "Stops": "43182-1,23127-1",
    "Name": "Väljamäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43157-1",
    "SiriID": 10865,
    "Lat": 5939975,
    "Lng": 2493170,
    "Stops": "43158-1,43117-1,43112-1",
    "Name": "Lagedi",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43158-1",
    "SiriID": 10866,
    "Lat": 5939931,
    "Lng": 2493230,
    "Stops": "43157-1,43153-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23160-1",
    "SiriID": 4475,
    "Lat": 5926185,
    "Lng": 2504465,
    "Stops": "23159-1,23170-1",
    "Name": "Lilleoru",
    "Info": 3,
    "Street": "Aruvalla-Jägala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23159-1",
    "SiriID": 4476,
    "Lat": 5926163,
    "Lng": 2504328,
    "Stops": "23160-1,23905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23169-1",
    "SiriID": 4477,
    "Lat": 5926936,
    "Lng": 2507922,
    "Stops": "23170-1,23177-1,43174-1",
    "Name": "Salu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23170-1",
    "SiriID": 4478,
    "Lat": 5926914,
    "Lng": 2507892,
    "Stops": "23169-1,23160-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23177-1",
    "SiriID": 4479,
    "Lat": 5927385,
    "Lng": 2509618,
    "Stops": "23178-1,23169-1",
    "Name": "Urvaste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23178-1",
    "SiriID": 4480,
    "Lat": 5927372,
    "Lng": 2509674,
    "Stops": "23177-1,23058-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43117-1",
    "SiriID": 10614,
    "Lat": 5940419,
    "Lng": 2492803,
    "Stops": "43177-1,43157-1",
    "Name": "Betooni",
    "Info": 2,
    "Street": "Betooni tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23193-1",
    "SiriID": 10494,
    "Lat": 5937993,
    "Lng": 2483719,
    "Stops": "23194-1,23196-1",
    "Name": "Assaku",
    "Info": "",
    "Street": "Järveküla-Jüri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23194-1",
    "SiriID": 10495,
    "Lat": 5938026,
    "Lng": 2483651,
    "Stops": "23193-1,23108-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23196-1",
    "SiriID": 10497,
    "Lat": 5937934,
    "Lng": 2484342,
    "Stops": "23193-1,23143-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23111-1",
    "SiriID": 4436,
    "Lat": 5938212,
    "Lng": 2483007,
    "Stops": "23112-1,23108-1",
    "Name": "Hundisepa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23112-1",
    "SiriID": 4437,
    "Lat": 5938207,
    "Lng": 2482922,
    "Stops": "23111-1,43195-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23113-1",
    "SiriID": 4434,
    "Lat": 5938606,
    "Lng": 2481573,
    "Stops": "23114-1,43196-1",
    "Name": "Järveküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23114-1",
    "SiriID": 4435,
    "Lat": 5938574,
    "Lng": 2481608,
    "Stops": "23113-1,43194-1,43197-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23115-1",
    "SiriID": 4483,
    "Lat": 5940418,
    "Lng": 2479226,
    "Stops": "23116-1,23118-1",
    "Name": "Järvesuu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23116-1",
    "SiriID": 4484,
    "Lat": 5940521,
    "Lng": 2479371,
    "Stops": "23115-1,13304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23117-1",
    "SiriID": 4428,
    "Lat": 5939978,
    "Lng": 2479088,
    "Stops": "23118-1,43164-1",
    "Name": "Järveveere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23118-1",
    "SiriID": 4429,
    "Lat": 5940039,
    "Lng": 2479054,
    "Stops": "23117-1,23115-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23188-1",
    "SiriID": 4432,
    "Lat": 5938981,
    "Lng": 2480394,
    "Stops": "23187-1",
    "Name": "Veski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23187-1",
    "SiriID": 4433,
    "Lat": 5939020,
    "Lng": 2480330,
    "Stops": "23188-1,23148-1,06608-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23120-1",
    "SiriID": 4489,
    "Lat": 5935930,
    "Lng": 2491784,
    "Stops": "23119-1,23197-1,23176-1",
    "Name": "Ehituse/Jüri",
    "Info": "",
    "Street": "Jüri-Aruküla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23119-1",
    "SiriID": 4490,
    "Lat": 5935933,
    "Lng": 2491928,
    "Stops": "23120-1,43192-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23121-1",
    "SiriID": 4491,
    "Lat": 5936013,
    "Lng": 2492934,
    "Stops": "23122-1,23920-1,43192-1",
    "Name": "Karba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23122-1",
    "SiriID": 4492,
    "Lat": 5936045,
    "Lng": 2493146,
    "Stops": "23121-1,23167-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23145-1",
    "SiriID": 4499,
    "Lat": 5936034,
    "Lng": 2499545,
    "Stops": "23146-1,23154-1",
    "Name": "Leivajõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23146-1",
    "SiriID": 4500,
    "Lat": 5936042,
    "Lng": 2499682,
    "Stops": "23145-1,23134-1,41311-1,41315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23153-1",
    "SiriID": 4497,
    "Lat": 5936116,
    "Lng": 2496443,
    "Stops": "23154-1,23183-1",
    "Name": "Pajupea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23154-1",
    "SiriID": 4498,
    "Lat": 5936106,
    "Lng": 2496457,
    "Stops": "23153-1,23932-1,23145-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23167-1",
    "SiriID": 4493,
    "Lat": 5936170,
    "Lng": 2493702,
    "Stops": "23168-1,23122-1",
    "Name": "Rae",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23168-1",
    "SiriID": 4494,
    "Lat": 5936168,
    "Lng": 2493739,
    "Stops": "23167-1,23184-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23175-1",
    "SiriID": 4487,
    "Lat": 5935810,
    "Lng": 2490750,
    "Stops": "23176-1,23911-1",
    "Name": "Tuule",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23176-1",
    "SiriID": 4488,
    "Lat": 5935825,
    "Lng": 2490861,
    "Stops": "23175-1,23120-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23183-1",
    "SiriID": 4495,
    "Lat": 5936126,
    "Lng": 2495021,
    "Stops": "23184-1,23153-1",
    "Name": "Vaskjala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23184-1",
    "SiriID": 4496,
    "Lat": 5936142,
    "Lng": 2494928,
    "Stops": "23183-1,23168-1,23909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23186-1",
    "SiriID": 4486,
    "Lat": 5935477,
    "Lng": 2489424,
    "Stops": "23185-1,23199-1",
    "Name": "Veetorni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23901-1",
    "SiriID": 10340,
    "Lat": 5931504,
    "Lng": 2493662,
    "Stops": "23156-1,43175-1,23124-1",
    "Name": "Patika",
    "Info": "",
    "Street": "Jüri-Vaida",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22010-1",
    "SiriID": 5063,
    "Lat": 5933285,
    "Lng": 2482376,
    "Stops": "43182-1,22044-1",
    "Name": "Kurna tee",
    "Info": "",
    "Street": "Kurtna - Tuhala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43002-1",
    "SiriID": 11116,
    "Lat": 5939121,
    "Lng": 2497848,
    "Stops": "43001-1,43006-1",
    "Name": "Arumäe",
    "Info": "",
    "Street": "Lagedi-Aruküla-Peningi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43001-1",
    "SiriID": 11117,
    "Lat": 5939096,
    "Lng": 2497899,
    "Stops": "43002-1,41315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43004-1",
    "SiriID": 11096,
    "Lat": 5939939,
    "Lng": 2495718,
    "Stops": "43003-1,43114-1",
    "Name": "Keskküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43003-1",
    "SiriID": 11097,
    "Lat": 5939912,
    "Lng": 2495742,
    "Stops": "43004-1,43005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43111-1",
    "SiriID": 4529,
    "Lat": 5940384,
    "Lng": 2493875,
    "Stops": "43112-1,43177-1",
    "Name": "Lagedi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43112-1",
    "SiriID": 4531,
    "Lat": 5940353,
    "Lng": 2493913,
    "Stops": "43111-1,43118-1,43157-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43113-1",
    "SiriID": 4534,
    "Lat": 5940212,
    "Lng": 2494349,
    "Stops": "43118-1,43114-1",
    "Name": "Lagedi kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43114-1",
    "SiriID": 4535,
    "Lat": 5940109,
    "Lng": 2494864,
    "Stops": "43113-1,43004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43005-1",
    "SiriID": 10595,
    "Lat": 5939514,
    "Lng": 2496562,
    "Stops": "43006-1,43003-1",
    "Name": "Tuulevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43006-1",
    "SiriID": 11095,
    "Lat": 5939437,
    "Lng": 2496652,
    "Stops": "43005-1,43002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43301-1",
    "SiriID": 4536,
    "Lat": 5941291,
    "Lng": 2492107,
    "Stops": "43302-1,43401-1,23937-1",
    "Name": "Ülejõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43302-1",
    "SiriID": 4537,
    "Lat": 5941273,
    "Lng": 2492147,
    "Stops": "43301-1,43176-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43155-1",
    "SiriID": 4505,
    "Lat": 5938561,
    "Lng": 2493383,
    "Stops": "43156-1,43171-1",
    "Name": "Külma",
    "Info": "",
    "Street": "Lagedi-Jüri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43156-1",
    "SiriID": 4506,
    "Lat": 5938436,
    "Lng": 2493280,
    "Stops": "43155-1,23141-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43151-1",
    "SiriID": 4507,
    "Lat": 5939391,
    "Lng": 2493450,
    "Stops": "43152-1,43154-1",
    "Name": "Lagedi haigla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43152-1",
    "SiriID": 4508,
    "Lat": 5939317,
    "Lng": 2493413,
    "Stops": "43151-1,23926-1,43171-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43153-1",
    "SiriID": 4509,
    "Lat": 5939770,
    "Lng": 2493330,
    "Stops": "43154-1,43158-1",
    "Name": "Lagedi kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43154-1",
    "SiriID": 4510,
    "Lat": 5939764,
    "Lng": 2493315,
    "Stops": "43153-1,43151-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23142-1",
    "SiriID": 4503,
    "Lat": 5938201,
    "Lng": 2492819,
    "Stops": "23141-1,23166-1",
    "Name": "Laiakivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23141-1",
    "SiriID": 4504,
    "Lat": 5938242,
    "Lng": 2492862,
    "Stops": "23142-1,43156-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23165-1",
    "SiriID": 4501,
    "Lat": 5937228,
    "Lng": 2492409,
    "Stops": "23166-1,43193-1,43191-1",
    "Name": "Pruuli tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23166-1",
    "SiriID": 4502,
    "Lat": 5937257,
    "Lng": 2492454,
    "Stops": "23165-1,23142-1,43170-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43115-1",
    "SiriID": 4532,
    "Lat": 5940751,
    "Lng": 2494583,
    "Stops": "43116-1,25101-1",
    "Name": "Lohu",
    "Info": "",
    "Street": "Lagedi-Kostivere",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43116-1",
    "SiriID": 4533,
    "Lat": 5940697,
    "Lng": 2494562,
    "Stops": "43115-1,43119-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23937-1",
    "SiriID": 6054,
    "Lat": 5941287,
    "Lng": 2490457,
    "Stops": "23938-1,43301-1",
    "Name": "Lennuradari",
    "Info": "",
    "Street": "Suur-Sõjamäe tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23938-1",
    "SiriID": 6055,
    "Lat": 5941395,
    "Lng": 2490229,
    "Stops": "23937-1,23931-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43401-1",
    "SiriID": 4538,
    "Lat": 5942085,
    "Lng": 2492055,
    "Stops": "43402-1,43411-1,43301-1",
    "Name": "Metsaveere",
    "Info": "",
    "Street": "Tallinna ringtee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43402-1",
    "SiriID": 4539,
    "Lat": 5942092,
    "Lng": 2492078,
    "Stops": "43401-1,23918-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23101-1",
    "SiriID": 4440,
    "Lat": 5938381,
    "Lng": 2483680,
    "Stops": "23108-1,23102-1,43185-1",
    "Name": "Allika",
    "Info": "",
    "Street": "Tartu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23102-1",
    "SiriID": 4441,
    "Lat": 5938560,
    "Lng": 2483350,
    "Stops": "23101-1,23104-1",
    "Name": "Allika-likvideeritud",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23103-1",
    "SiriID": 4438,
    "Lat": 5938974,
    "Lng": 2482902,
    "Stops": "23104-1,23939-1",
    "Name": "Annuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23104-1",
    "SiriID": 4439,
    "Lat": 5938946,
    "Lng": 2482878,
    "Stops": "23103-1,43198-1,23102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23108-1",
    "SiriID": 4443,
    "Lat": 5938325,
    "Lng": 2483642,
    "Stops": "23101-1,23194-1,23111-1",
    "Name": "Assaku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23123-1",
    "SiriID": 4452,
    "Lat": 5932444,
    "Lng": 2492158,
    "Stops": "23124-1,43180-1",
    "Name": "Kautjala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23124-1",
    "SiriID": 4453,
    "Lat": 5932406,
    "Lng": 2492139,
    "Stops": "23123-1,23901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23126-1",
    "SiriID": 4446,
    "Lat": 5936718,
    "Lng": 2486124,
    "Stops": "23125-1,23919-1",
    "Name": "Kungla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23125-1",
    "SiriID": 4447,
    "Lat": 5936572,
    "Lng": 2486293,
    "Stops": "23126-1,23923-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23143-1",
    "SiriID": 4444,
    "Lat": 5937643,
    "Lng": 2484957,
    "Stops": "23144-1,47041-1,23196-1",
    "Name": "Lehmja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23144-1",
    "SiriID": 4445,
    "Lat": 5937513,
    "Lng": 2484839,
    "Stops": "23143-1,43166-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23156-1",
    "SiriID": 4455,
    "Lat": 5931244,
    "Lng": 2493409,
    "Stops": "23901-1,43169-1,23171-1",
    "Name": "Patika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23157-1",
    "SiriID": 4425,
    "Lat": 5939752,
    "Lng": 2481947,
    "Stops": "23158-1,23939-1",
    "Name": "Peetri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23158-1",
    "SiriID": 4426,
    "Lat": 5939728,
    "Lng": 2481914,
    "Stops": "23157-1,43159-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23162-1",
    "SiriID": 4449,
    "Lat": 5935665,
    "Lng": 2487685,
    "Stops": "23916-1,23127-1",
    "Name": "Pildiküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23171-1",
    "SiriID": 4456,
    "Lat": 5929900,
    "Lng": 2495258,
    "Stops": "23172-1,23156-1",
    "Name": "Sillaotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23172-1",
    "SiriID": 4457,
    "Lat": 5929834,
    "Lng": 2495208,
    "Stops": "23171-1,23908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23181-1",
    "SiriID": 4450,
    "Lat": 5934071,
    "Lng": 2490196,
    "Stops": "23182-1,43179-1",
    "Name": "Vana-Aaviku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23182-1",
    "SiriID": 4451,
    "Lat": 5934051,
    "Lng": 2490175,
    "Stops": "23181-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23908-1",
    "SiriID": 10267,
    "Lat": 5928636,
    "Lng": 2497525,
    "Stops": "23910-1,23172-1",
    "Name": "Vaida",
    "Info": 3,
    "Street": "Vaida tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43403-1",
    "SiriID": 11119,
    "Lat": 5925410,
    "Lng": 2501721,
    "Stops": "43404-1,43405-1",
    "Name": "Aruvalla",
    "Info": "",
    "Street": "Vaida-Urge",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43404-1",
    "SiriID": 11120,
    "Lat": 5925503,
    "Lng": 2501720,
    "Stops": "43403-1,23906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23109-1",
    "SiriID": 4466,
    "Lat": 5926696,
    "Lng": 2501780,
    "Stops": "23915-1,23190-1",
    "Name": "Ellaku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23110-1",
    "SiriID": 4467,
    "Lat": 5926557,
    "Lng": 2501925,
    "Stops": "23915-1,23905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23905-1",
    "SiriID": 4468,
    "Lat": 5926053,
    "Lng": 2502017,
    "Stops": "23906-1,23110-1,23159-1",
    "Name": "Kasemetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23906-1",
    "SiriID": 4469,
    "Lat": 5925970,
    "Lng": 2501897,
    "Stops": "23905-1,43404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23173-1",
    "SiriID": 4470,
    "Lat": 5923642,
    "Lng": 2502141,
    "Stops": "23174-1,22144-1",
    "Name": "Tuhala tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23174-1",
    "SiriID": 4471,
    "Lat": 5923723,
    "Lng": 2501862,
    "Stops": "23173-1,43406-1,22127-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23189-1",
    "SiriID": 4464,
    "Lat": 5927460,
    "Lng": 2500671,
    "Stops": "23190-1,43408-1,43174-1",
    "Name": "Vaidasoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23190-1",
    "SiriID": 4465,
    "Lat": 5927374,
    "Lng": 2500792,
    "Stops": "23189-1,23109-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23842-1",
    "SiriID": 5683,
    "Lat": 5921558,
    "Lng": 2471683,
    "Stops": "7000716-1",
    "Name": "Metsanurga tinglik",
    "Info": "",
    "Street": 0,
    "Area": "Saku vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23281-1",
    "SiriID": 12667,
    "Lat": 5927356,
    "Lng": 2467681,
    "Stops": "23282-1,23258-1",
    "Name": "Kasemetsa",
    "Info": 2,
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23282-1",
    "SiriID": 12668,
    "Lat": 5927343,
    "Lng": 2467674,
    "Stops": "23281-1,23247-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23283-1",
    "SiriID": 12679,
    "Lat": 5923878,
    "Lng": 2468557,
    "Stops": "23284-1,23213-1",
    "Name": "Kiisa",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23284-1",
    "SiriID": 12680,
    "Lat": 5923902,
    "Lng": 2468575,
    "Stops": "23283-1,23212-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23203-1",
    "SiriID": 34000,
    "Lat": 5933891,
    "Lng": 2470174,
    "Stops": "23204-1,23225-1",
    "Name": "Männiku",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23204-1",
    "SiriID": 34249,
    "Lat": 5933867,
    "Lng": 2470144,
    "Stops": "23203-1,23226-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23291-1",
    "SiriID": 12940,
    "Lat": 5922145,
    "Lng": 2469549,
    "Stops": "23292-1,7000721-1,23241-1",
    "Name": "Roobuka",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23292-1",
    "SiriID": 12941,
    "Lat": 5922149,
    "Lng": 2469566,
    "Stops": "23291-1,23240-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23289-1",
    "SiriID": 12939,
    "Lat": 5930111,
    "Lng": 2467663,
    "Stops": "23271-1,23290-1",
    "Name": "Saku",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23290-1",
    "SiriID": 12942,
    "Lat": 5930141,
    "Lng": 2467698,
    "Stops": "23289-1,23253-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23814-1",
    "SiriID": 4272,
    "Lat": 5930281,
    "Lng": 2467250,
    "Stops": "23256-1,23271-1,23246-1",
    "Name": "Saku Gümnaasium",
    "Info": "",
    "Street": "Aiandi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23271-1",
    "SiriID": 9956,
    "Lat": 5930114,
    "Lng": 2467606,
    "Stops": "23289-1,23814-1",
    "Name": "Saku Raudteejaam",
    "Info": "",
    "Street": "Jaama tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23845-1",
    "SiriID": 5872,
    "Lat": 5931219,
    "Lng": 2462789,
    "Stops": "23846-1,23822-1,23838-1",
    "Name": "Nurmiko",
    "Info": "",
    "Street": "Juuliku tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23846-1",
    "SiriID": 5873,
    "Lat": 5931212,
    "Lng": 2462841,
    "Stops": "23845-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23206-1",
    "SiriID": 4983,
    "Lat": 5932783,
    "Lng": 2462044,
    "Stops": "23205-1,23843-1",
    "Name": "Jõenurme",
    "Info": "",
    "Street": "Jälgimäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23205-1",
    "SiriID": 4984,
    "Lat": 5932734,
    "Lng": 2462082,
    "Stops": "23206-1,23250-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23208-1",
    "SiriID": 4987,
    "Lat": 5931403,
    "Lng": 2460997,
    "Stops": "23207-1,23841-1",
    "Name": "Jälgimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23207-1",
    "SiriID": 4988,
    "Lat": 5931456,
    "Lng": 2461118,
    "Stops": "23208-1,23837-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23228-1",
    "SiriID": 4980,
    "Lat": 5933656,
    "Lng": 2462062,
    "Stops": "23227-1,23844-1,23823-1",
    "Name": "Paunmäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23227-1",
    "SiriID": 4982,
    "Lat": 5933761,
    "Lng": 2462099,
    "Stops": "23228-1,23396-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23249-1",
    "SiriID": 4985,
    "Lat": 5932049,
    "Lng": 2461889,
    "Stops": "23250-1,23837-1",
    "Name": "Topi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23250-1",
    "SiriID": 4986,
    "Lat": 5932135,
    "Lng": 2461996,
    "Stops": "23249-1,23205-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23822-1",
    "SiriID": 4299,
    "Lat": 5930941,
    "Lng": 2462648,
    "Stops": "23845-1,23820-1",
    "Name": "Murumäe",
    "Info": "",
    "Street": "Kanama-Saku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23839-1",
    "SiriID": 5706,
    "Lat": 5931864,
    "Lng": 2463109,
    "Stops": "23840-1",
    "Name": "Karjavärava",
    "Info": "",
    "Street": "Karjavärava Tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23840-1",
    "SiriID": 5707,
    "Lat": 5931848,
    "Lng": 2463100,
    "Stops": "23839-1,23838-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23260-1",
    "SiriID": 5001,
    "Lat": 5925573,
    "Lng": 2468697,
    "Stops": "23261-1,23211-1",
    "Name": "Leesi",
    "Info": 3,
    "Street": "Kasemetsa - Kiisa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23261-1",
    "SiriID": 5002,
    "Lat": 5925655,
    "Lng": 2468691,
    "Stops": "23260-1,23801-1,23248-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23247-1",
    "SiriID": 5003,
    "Lat": 5927010,
    "Lng": 2467838,
    "Stops": "23248-1,23282-1",
    "Name": "Nirgioru",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23248-1",
    "SiriID": 5004,
    "Lat": 5926922,
    "Lng": 2467876,
    "Stops": "23247-1,23261-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23824-1",
    "SiriID": 2475,
    "Lat": 5924255,
    "Lng": 2466526,
    "Stops": "23825-1,23212-1",
    "Name": "Revali",
    "Info": 3,
    "Street": "Kasemetsa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23825-1",
    "SiriID": 2587,
    "Lat": 5924266,
    "Lng": 2466501,
    "Stops": "23824-1,47313-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23239-1",
    "SiriID": 4086,
    "Lat": 5922847,
    "Lng": 2471370,
    "Stops": "23240-1,23220-1",
    "Name": "Kurtna tiigi",
    "Info": "",
    "Street": "Keskuse tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23240-1",
    "SiriID": 4087,
    "Lat": 5922837,
    "Lng": 2471340,
    "Stops": "23239-1,23292-1,23807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23294-1",
    "SiriID": 13363,
    "Lat": 5923556,
    "Lng": 2468402,
    "Stops": "23295-1,23214-1,23242-1",
    "Name": "Kiisa",
    "Info": "",
    "Street": "KIRDALU - KIISA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23295-1",
    "SiriID": 13364,
    "Lat": 5923552,
    "Lng": 2468451,
    "Stops": "23294-1,23272-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23272-1",
    "SiriID": 9968,
    "Lat": 5923453,
    "Lng": 2469046,
    "Stops": "23807-1,23295-1",
    "Name": "Kiisa kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23807-1",
    "SiriID": 4222,
    "Lat": 5923442,
    "Lng": 2469053,
    "Stops": "23272-1,23240-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23220-1",
    "SiriID": 4991,
    "Lat": 5922998,
    "Lng": 2471565,
    "Stops": "23219-1,23239-1",
    "Name": "Kurtna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23219-1",
    "SiriID": 4992,
    "Lat": 5923026,
    "Lng": 2471646,
    "Stops": "23220-1,23808-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23808-1",
    "SiriID": 4230,
    "Lat": 5923133,
    "Lng": 2471868,
    "Stops": "23219-1,23817-1",
    "Name": "Kurtna kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23826-1",
    "SiriID": 2593,
    "Lat": 5924468,
    "Lng": 2473283,
    "Stops": "23827-1,23217-1",
    "Name": "Lillemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23827-1",
    "SiriID": 2687,
    "Lat": 5924461,
    "Lng": 2473258,
    "Stops": "23826-1,23817-1,23802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23216-1",
    "SiriID": 5031,
    "Lat": 5924374,
    "Lng": 2475048,
    "Stops": "23215-1,23815-1",
    "Name": "Kirdalu",
    "Info": "",
    "Street": "Kirdalu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23215-1",
    "SiriID": 5032,
    "Lat": 5924380,
    "Lng": 2475059,
    "Stops": "23216-1,23217-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23262-1",
    "SiriID": 5029,
    "Lat": 5923318,
    "Lng": 2476672,
    "Stops": "23263-1",
    "Name": "Männiku/Tagadi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23263-1",
    "SiriID": 5030,
    "Lat": 5923331,
    "Lng": 2476634,
    "Stops": "23262-1,23815-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23815-1",
    "SiriID": 4289,
    "Lat": 5923360,
    "Lng": 2476481,
    "Stops": "23263-1,23812-1,23216-1",
    "Name": "Tagadi tee",
    "Info": "",
    "Street": "KIRDALU TEE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23837-1",
    "SiriID": 5634,
    "Lat": 5931718,
    "Lng": 2462105,
    "Stops": "23838-1,23249-1,23207-1",
    "Name": "Kadakamarja",
    "Info": 2,
    "Street": "Murimäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23838-1",
    "SiriID": 5635,
    "Lat": 5931722,
    "Lng": 2462176,
    "Stops": "23837-1,23840-1,23845-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23841-1",
    "SiriID": 5708,
    "Lat": 5931598,
    "Lng": 2460573,
    "Stops": "23208-1,23403-1",
    "Name": "Mõisavahe",
    "Info": "",
    "Street": "Mõisavahe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23296-1",
    "SiriID": 13226,
    "Lat": 5930910,
    "Lng": 2464137,
    "Stops": "23297-1,23821-1",
    "Name": "Männituka tee",
    "Info": "",
    "Street": "Nurme tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23297-1",
    "SiriID": 13227,
    "Lat": 5930903,
    "Lng": 2464192,
    "Stops": "23296-1,23818-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23298-1",
    "SiriID": 4293,
    "Lat": 5930087,
    "Lng": 2466212,
    "Stops": "23299-1,23234-1,23255-1",
    "Name": "Nurme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23299-1",
    "SiriID": 4294,
    "Lat": 5930084,
    "Lng": 2466152,
    "Stops": "23298-1,23233-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23820-1",
    "SiriID": 4297,
    "Lat": 5930890,
    "Lng": 2463345,
    "Stops": "23821-1,23822-1",
    "Name": "Nurmiko tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23821-1",
    "SiriID": 4298,
    "Lat": 5930881,
    "Lng": 2463351,
    "Stops": "23820-1,23223-1,23296-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23818-1",
    "SiriID": 4295,
    "Lat": 5930966,
    "Lng": 2464628,
    "Stops": "23819-1,23297-1",
    "Name": "Paju tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23819-1",
    "SiriID": 4296,
    "Lat": 5930951,
    "Lng": 2464652,
    "Stops": "23818-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23317-1",
    "SiriID": 4979,
    "Lat": 5933686,
    "Lng": 2461010,
    "Stops": "23318-1,23396-1",
    "Name": "Peoleo",
    "Info": "",
    "Street": "Pärnu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23843-1",
    "SiriID": 5871,
    "Lat": 5933330,
    "Lng": 2461993,
    "Stops": "23844-1,23206-1",
    "Name": "Joametsa",
    "Info": "",
    "Street": "Saku - Laagri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23844-1",
    "SiriID": 5874,
    "Lat": 5933359,
    "Lng": 2461948,
    "Stops": "23843-1,23228-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23285-1",
    "SiriID": 12709,
    "Lat": 5930253,
    "Lng": 2463856,
    "Stops": "23286-1,23224-1",
    "Name": "Kurvi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23286-1",
    "SiriID": 12710,
    "Lat": 5930218,
    "Lng": 2464036,
    "Stops": "23285-1,23231-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23224-1",
    "SiriID": 5025,
    "Lat": 5930754,
    "Lng": 2463393,
    "Stops": "23223-1,23285-1",
    "Name": "Murimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23223-1",
    "SiriID": 5026,
    "Lat": 5930830,
    "Lng": 2463320,
    "Stops": "23821-1,23224-1",
    "Name": "Murumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23231-1",
    "SiriID": 5023,
    "Lat": 5930033,
    "Lng": 2464936,
    "Stops": "23232-1,23286-1",
    "Name": "Pähklimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23279-1",
    "SiriID": 10723,
    "Lat": 5927407,
    "Lng": 2472016,
    "Stops": "23280-1",
    "Name": "Kajamaa",
    "Info": "",
    "Street": "Saku - Tõdva",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23280-1",
    "SiriID": 10724,
    "Lat": 5927395,
    "Lng": 2472043,
    "Stops": "23279-1,23804-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23257-1",
    "SiriID": 5005,
    "Lat": 5927530,
    "Lng": 2467276,
    "Stops": "23258-1,23230-1",
    "Name": "Kasemetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23258-1",
    "SiriID": 5006,
    "Lat": 5927500,
    "Lng": 2467442,
    "Stops": "23257-1,23281-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23803-1",
    "SiriID": 4187,
    "Lat": 5927026,
    "Lng": 2473093,
    "Stops": "23804-1,23222-1",
    "Name": "Palktare",
    "Info": "",
    "Street": "SAKU - TÕDVA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23804-1",
    "SiriID": 4191,
    "Lat": 5927030,
    "Lng": 2472977,
    "Stops": "23803-1,23805-1,23280-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23230-1",
    "SiriID": 5007,
    "Lat": 5928074,
    "Lng": 2466492,
    "Stops": "23229-1,23257-1",
    "Name": "Preeria",
    "Info": "",
    "Street": "Saku - Tõdva",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23229-1",
    "SiriID": 5008,
    "Lat": 5928130,
    "Lng": 2466484,
    "Stops": "23230-1,23278-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23232-1",
    "SiriID": 5024,
    "Lat": 5929967,
    "Lng": 2465125,
    "Stops": "23201-1,23231-1,23293-1",
    "Name": "Pähklimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23236-1",
    "SiriID": 5009,
    "Lat": 5929455,
    "Lng": 2465624,
    "Stops": "23293-1,23278-1",
    "Name": "Saku Kingu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23293-1",
    "SiriID": 10455,
    "Lat": 5929519,
    "Lng": 2465574,
    "Stops": "23236-1,23232-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23816-1",
    "SiriID": 4290,
    "Lat": 5922990,
    "Lng": 2473570,
    "Stops": "23817-1,23813-1",
    "Name": "Kurtna vana kool",
    "Info": 3,
    "Street": "TAGADI - KURTNA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23817-1",
    "SiriID": 4291,
    "Lat": 5922994,
    "Lng": 2473555,
    "Stops": "23816-1,23808-1,23827-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23812-1",
    "SiriID": 4238,
    "Lat": 5922788,
    "Lng": 2475383,
    "Stops": "23813-1,23815-1,23243-1",
    "Name": "Tagadi pood",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23813-1",
    "SiriID": 4239,
    "Lat": 5922788,
    "Lng": 2475366,
    "Stops": "23812-1,23816-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23217-1",
    "SiriID": 5033,
    "Lat": 5925028,
    "Lng": 2474354,
    "Stops": "23218-1,23215-1,23826-1",
    "Name": "Kiisa tee",
    "Info": "",
    "Street": "Tallinn - Rapla - Türi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23218-1",
    "SiriID": 5034,
    "Lat": 5925059,
    "Lng": 2474344,
    "Stops": "23217-1,23252-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23222-1",
    "SiriID": 5037,
    "Lat": 5928110,
    "Lng": 2474110,
    "Stops": "23221-1,23803-1",
    "Name": "Lokuti",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23221-1",
    "SiriID": 5038,
    "Lat": 5928167,
    "Lng": 2474132,
    "Stops": "23222-1,23254-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23237-1",
    "SiriID": 5041,
    "Lat": 5930685,
    "Lng": 2474340,
    "Stops": "23238-1,23253-1,23245-1",
    "Name": "Saku tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23238-1",
    "SiriID": 5042,
    "Lat": 5930802,
    "Lng": 2474451,
    "Stops": "23237-1,22028-1,23210-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23244-1",
    "SiriID": 5027,
    "Lat": 5921406,
    "Lng": 2477688,
    "Stops": "23243-1,23287-1",
    "Name": "Tagadi",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23243-1",
    "SiriID": 5028,
    "Lat": 5921438,
    "Lng": 2477698,
    "Stops": "23244-1,23812-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23287-1",
    "SiriID": 12711,
    "Lat": 5920048,
    "Lng": 2478321,
    "Stops": "23288-1,23244-1",
    "Name": "Traksi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23288-1",
    "SiriID": 12712,
    "Lat": 5920035,
    "Lng": 2478364,
    "Stops": "23287-1,7000707-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23254-1",
    "SiriID": 5039,
    "Lat": 5928872,
    "Lng": 2473941,
    "Stops": "23253-1,23221-1",
    "Name": "Vetka",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23253-1",
    "SiriID": 5040,
    "Lat": 5929044,
    "Lng": 2473879,
    "Stops": "23254-1,23237-1,23290-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23210-1",
    "SiriID": 5013,
    "Lat": 5932507,
    "Lng": 2469071,
    "Stops": "23209-1,23245-1,23811-1,23238-1",
    "Name": "Karjääri",
    "Info": "",
    "Street": "Tallinn - Saku - Laagri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23209-1",
    "SiriID": 5014,
    "Lat": 5932537,
    "Lng": 2469105,
    "Stops": "23210-1,23226-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23226-1",
    "SiriID": 5011,
    "Lat": 5933854,
    "Lng": 2470045,
    "Stops": "23204-1,23209-1",
    "Name": "Männiku jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23225-1",
    "SiriID": 5012,
    "Lat": 5933974,
    "Lng": 2470158,
    "Stops": "23203-1,06101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23234-1",
    "SiriID": 5019,
    "Lat": 5930044,
    "Lng": 2466199,
    "Stops": "23233-1,23298-1,23277-1",
    "Name": "Saku keskuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23233-1",
    "SiriID": 5020,
    "Lat": 5930055,
    "Lng": 2466130,
    "Stops": "23299-1,23234-1,23202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23202-1",
    "SiriID": 5021,
    "Lat": 5930042,
    "Lng": 2465252,
    "Stops": "23201-1,23233-1",
    "Name": "Salu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23201-1",
    "SiriID": 5022,
    "Lat": 5930035,
    "Lng": 2465245,
    "Stops": "23202-1,23232-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23246-1",
    "SiriID": 5015,
    "Lat": 5931131,
    "Lng": 2468090,
    "Stops": "23245-1,23814-1",
    "Name": "Tammemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23245-1",
    "SiriID": 5016,
    "Lat": 5931139,
    "Lng": 2468144,
    "Stops": "23246-1,23210-1,23237-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23256-1",
    "SiriID": 5017,
    "Lat": 5930225,
    "Lng": 2466881,
    "Stops": "23255-1,23814-1",
    "Name": "Õlletehase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23255-1",
    "SiriID": 5018,
    "Lat": 5930234,
    "Lng": 2466868,
    "Stops": "23256-1,23298-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23275-1",
    "SiriID": 9963,
    "Lat": 5929600,
    "Lng": 2466034,
    "Stops": "23276-1,23235-1",
    "Name": "Teaduse",
    "Info": "",
    "Street": "Teaduse tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23276-1",
    "SiriID": 9964,
    "Lat": 5929612,
    "Lng": 2466038,
    "Stops": "23275-1,23277-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23277-1",
    "SiriID": 9965,
    "Lat": 5929698,
    "Lng": 2466176,
    "Stops": "23276-1,23234-1",
    "Name": "Saku Tehnika",
    "Info": "",
    "Street": "Tehnika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23235-1",
    "SiriID": 5010,
    "Lat": 5929401,
    "Lng": 2465918,
    "Stops": "23278-1,23275-1",
    "Name": "Tiigi",
    "Info": "",
    "Street": "Tiigi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23278-1",
    "SiriID": 9960,
    "Lat": 5929362,
    "Lng": 2465848,
    "Stops": "23235-1,23236-1,23229-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23212-1",
    "SiriID": 4999,
    "Lat": 5924344,
    "Lng": 2468023,
    "Stops": "23211-1,23284-1,23824-1",
    "Name": "Kiisa",
    "Info": 3,
    "Street": "Tõdva - Hageri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23211-1",
    "SiriID": 5000,
    "Lat": 5924412,
    "Lng": 2468034,
    "Stops": "23212-1,23260-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23214-1",
    "SiriID": 4997,
    "Lat": 5923597,
    "Lng": 2468282,
    "Stops": "23213-1,23294-1",
    "Name": "Kiisa raudteejaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23213-1",
    "SiriID": 4998,
    "Lat": 5923659,
    "Lng": 2468260,
    "Stops": "23214-1,23283-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23801-1",
    "SiriID": 4196,
    "Lat": 5925666,
    "Lng": 2470381,
    "Stops": "23802-1,23261-1",
    "Name": "Kõrnumäe",
    "Info": "",
    "Street": "TÕDVA - HAGERI",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23802-1",
    "SiriID": 4203,
    "Lat": 5925658,
    "Lng": 2470559,
    "Stops": "23801-1,23805-1,23827-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23805-1",
    "SiriID": 4192,
    "Lat": 5926743,
    "Lng": 2472962,
    "Stops": "23806-1,23804-1,23802-1",
    "Name": "Proosa",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23806-1",
    "SiriID": 4195,
    "Lat": 5926733,
    "Lng": 2473027,
    "Stops": "23805-1,23252-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23836-1",
    "SiriID": 4993,
    "Lat": 5921907,
    "Lng": 2466948,
    "Stops": "23835-1,7000072-1,7000703-1",
    "Name": "Soonurme",
    "Info": 3,
    "Street": "Tõdva - Hageri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23835-1",
    "SiriID": 4994,
    "Lat": 5921940,
    "Lng": 2467006,
    "Stops": "23836-1,23241-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23241-1",
    "SiriID": 4995,
    "Lat": 5922661,
    "Lng": 2467514,
    "Stops": "23242-1,23835-1,23291-1",
    "Name": "Standard",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23242-1",
    "SiriID": 4996,
    "Lat": 5922717,
    "Lng": 2467569,
    "Stops": "23241-1,23294-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23252-1",
    "SiriID": 5035,
    "Lat": 5926674,
    "Lng": 2474102,
    "Stops": "23251-1,23806-1,23218-1",
    "Name": "Traani",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23251-1",
    "SiriID": 5036,
    "Lat": 5926712,
    "Lng": 2474124,
    "Stops": "23252-1,22033-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23823-1",
    "SiriID": 4300,
    "Lat": 5933531,
    "Lng": 2463394,
    "Stops": "23228-1,23811-1",
    "Name": "Kodusalu tee",
    "Info": "",
    "Street": "Tänassilma tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23811-1",
    "SiriID": 4237,
    "Lat": 5933645,
    "Lng": 2465036,
    "Stops": "23810-1,23823-1,23210-1",
    "Name": "Lodjapuu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23810-1",
    "SiriID": 4236,
    "Lat": 5934182,
    "Lng": 2464949,
    "Stops": "23809-1,23811-1",
    "Name": "Kungla tee",
    "Info": "",
    "Street": "Tänassilma-Laagri tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23809-1",
    "SiriID": 4235,
    "Lat": 5934523,
    "Lng": 2464478,
    "Stops": "23810-1,20821-1",
    "Name": "Metsavaimu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23424-1",
    "SiriID": 10746,
    "Lat": 5932481,
    "Lng": 2454746,
    "Stops": "23406-1,47357-1,23423-1",
    "Name": "Saue",
    "Info": "",
    "Street": "-",
    "Area": "Saue linn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23425-1",
    "SiriID": 10747,
    "Lat": 5932507,
    "Lng": 2454882,
    "Stops": "23406-1,23405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23409-1",
    "SiriID": 4971,
    "Lat": 5932583,
    "Lng": 2455374,
    "Stops": "23410-1,23405-1",
    "Name": "Kuuseheki",
    "Info": "",
    "Street": "Kuuseheki tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23410-1",
    "SiriID": 4972,
    "Lat": 5932593,
    "Lng": 2455387,
    "Stops": "23409-1,23402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23401-1",
    "SiriID": 4973,
    "Lat": 5932541,
    "Lng": 2456038,
    "Stops": "23402-1,23417-1",
    "Name": "Noortekeskus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23406-1",
    "SiriID": 4968,
    "Lat": 5932467,
    "Lng": 2454861,
    "Stops": "23425-1,23424-1",
    "Name": "Saue jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23405-1",
    "SiriID": 4969,
    "Lat": 5932485,
    "Lng": 2455022,
    "Stops": "23425-1,23409-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23428-1",
    "SiriID": 47,
    "Lat": 5932016,
    "Lng": 2455922,
    "Stops": "23418-1",
    "Name": "Saue kool",
    "Info": "",
    "Street": "Nurmesalu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23402-1",
    "SiriID": 4974,
    "Lat": 5932577,
    "Lng": 2456028,
    "Stops": "23401-1,23410-1",
    "Name": "Noortekeskus",
    "Info": "",
    "Street": "Tule tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23404-1",
    "SiriID": 4964,
    "Lat": 5931725,
    "Lng": 2456938,
    "Stops": "23403-1,23414-1",
    "Name": "Paulig",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23403-1",
    "SiriID": 4965,
    "Lat": 5931709,
    "Lng": 2456960,
    "Stops": "23404-1,23419-1,23841-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23418-1",
    "SiriID": 4975,
    "Lat": 5932306,
    "Lng": 2456390,
    "Stops": "23417-1,23413-1,23428-1",
    "Name": "Pärnasalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23417-1",
    "SiriID": 12983,
    "Lat": 5932342,
    "Lng": 2456354,
    "Stops": "23418-1,23401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23419-1",
    "SiriID": 9615,
    "Lat": 5931290,
    "Lng": 2456279,
    "Stops": "23421-1,23420-1,23403-1",
    "Name": "Saue tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23421-1",
    "SiriID": 10404,
    "Lat": 5931298,
    "Lng": 2456230,
    "Stops": "23419-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23414-1",
    "SiriID": 4976,
    "Lat": 5931973,
    "Lng": 2456806,
    "Stops": "23413-1,23404-1",
    "Name": "Tule",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23413-1",
    "SiriID": 4977,
    "Lat": 5932016,
    "Lng": 2456800,
    "Stops": "23414-1,23418-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23422-1",
    "SiriID": 10801,
    "Lat": 5932033,
    "Lng": 2454203,
    "Stops": "23423-1,23408-1",
    "Name": "Laastu",
    "Info": "",
    "Street": "Tõkke tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23423-1",
    "SiriID": 10802,
    "Lat": 5932101,
    "Lng": 2454281,
    "Stops": "23422-1,23424-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23408-1",
    "SiriID": 4966,
    "Lat": 5931841,
    "Lng": 2453980,
    "Stops": "23407-1,23422-1",
    "Name": "Rauna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23407-1",
    "SiriID": 4967,
    "Lat": 5931745,
    "Lng": 2453873,
    "Stops": "23408-1,23427-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23426-1",
    "SiriID": 12977,
    "Lat": 5931429,
    "Lng": 2453544,
    "Stops": "23427-1,23416-1",
    "Name": "Tõkke",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23427-1",
    "SiriID": 12984,
    "Lat": 5931430,
    "Lng": 2453554,
    "Stops": "23426-1,23415-1,23407-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21980-1",
    "SiriID": 5895,
    "Lat": 5919747,
    "Lng": 2438190,
    "Stops": "21981-1,21926-1,21907-1",
    "Name": "Anniko tee",
    "Info": 4,
    "Street": 0,
    "Area": "Saue vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21981-1",
    "SiriID": 5913,
    "Lat": 5919760,
    "Lng": 2438170,
    "Stops": "21980-1,21975-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22742-1",
    "SiriID": 11434,
    "Lat": 5905702,
    "Lng": 2418026,
    "Stops": "22741-1,22754-1",
    "Name": "Antsuküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21978-1",
    "SiriID": 5894,
    "Lat": 5916884,
    "Lng": 2449954,
    "Stops": "21979-1,47350-1",
    "Name": "Ehala",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21979-1",
    "SiriID": 5912,
    "Lat": 5916879,
    "Lng": 2449984,
    "Stops": "21978-1,21991-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22741-1",
    "SiriID": 9847,
    "Lat": 5905611,
    "Lng": 2416971,
    "Stops": "22742-1,22705-1",
    "Name": "Ellamaa raudteejaam",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47337-1",
    "SiriID": 4654,
    "Lat": 5923940,
    "Lng": 2451811,
    "Stops": "23348-1,23382-1,47339-1",
    "Name": "Karjavahe tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21952-1",
    "SiriID": 10505,
    "Lat": 5913467,
    "Lng": 2449609,
    "Stops": "21947-1,21912-1,21987-1",
    "Name": "Kernu koolimaja",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21914-1",
    "SiriID": 4901,
    "Lat": 5915478,
    "Lng": 2450498,
    "Stops": "21913-1,21909-1",
    "Name": "Kernu mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21913-1",
    "SiriID": 4902,
    "Lat": 5915508,
    "Lng": 2450511,
    "Stops": "21914-1,21990-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21984-1",
    "SiriID": 5899,
    "Lat": 5920343,
    "Lng": 2426226,
    "Stops": "21985-1,21918-1",
    "Name": "Kibuna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21985-1",
    "SiriID": 5915,
    "Lat": 5920342,
    "Lng": 2426186,
    "Stops": "21984-1,22756-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21920-1",
    "SiriID": 4899,
    "Lat": 5914380,
    "Lng": 2454324,
    "Stops": "21919-1,7000406-1",
    "Name": "Kirikla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21919-1",
    "SiriID": 4900,
    "Lat": 5914331,
    "Lng": 2454105,
    "Stops": "21920-1,21909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21968-1",
    "SiriID": 5901,
    "Lat": 5918631,
    "Lng": 2432283,
    "Stops": "21969-1,21966-1",
    "Name": "Kivisilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21969-1",
    "SiriID": 5907,
    "Lat": 5918636,
    "Lng": 2432277,
    "Stops": "21968-1,21957-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21972-1",
    "SiriID": 5890,
    "Lat": 5918010,
    "Lng": 2433936,
    "Stops": "21973-1,21908-1,21974-1",
    "Name": "Kullapa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21973-1",
    "SiriID": 5909,
    "Lat": 5918008,
    "Lng": 2433911,
    "Stops": "21972-1,21966-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22754-1",
    "SiriID": 3623,
    "Lat": 5907480,
    "Lng": 2424063,
    "Stops": "22755-1,22738-1,22713-1,22742-1",
    "Name": "Kultuurimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22755-1",
    "SiriID": 4088,
    "Lat": 5907510,
    "Lng": 2424046,
    "Stops": "22754-1,22736-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47342-1",
    "SiriID": 13181,
    "Lat": 5935149,
    "Lng": 2461049,
    "Stops": "20818-1,23355-1",
    "Name": "Laagri alevik",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23360-1",
    "SiriID": 10181,
    "Lat": 5934163,
    "Lng": 2461433,
    "Stops": "23396-1,23397-1,23375-1",
    "Name": "Laagri market",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47313-1",
    "SiriID": 4090,
    "Lat": 5924351,
    "Lng": 2464870,
    "Stops": "47314-1,47312-1,23825-1",
    "Name": "Männi Saue",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47314-1",
    "SiriID": 4091,
    "Lat": 5924346,
    "Lng": 2464856,
    "Stops": "47313-1,47315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22759-1",
    "SiriID": 5903,
    "Lat": 5919123,
    "Lng": 2426974,
    "Stops": "22716-1,21915-1",
    "Name": "Männimaa",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22743-1",
    "SiriID": 12541,
    "Lat": 5910377,
    "Lng": 2431018,
    "Stops": "22751-1",
    "Name": "Nissi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22758-1",
    "SiriID": 5854,
    "Lat": 5910852,
    "Lng": 2431431,
    "Stops": "22717-1",
    "Name": "Nissi koolimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22745-1",
    "SiriID": 12557,
    "Lat": 5907595,
    "Lng": 2433620,
    "Stops": "22746-1,22747-1,22702-1",
    "Name": "Nurme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22746-1",
    "SiriID": 12559,
    "Lat": 5907580,
    "Lng": 2433678,
    "Stops": "22745-1,7000396-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21974-1",
    "SiriID": 5891,
    "Lat": 5919794,
    "Lng": 2435223,
    "Stops": "21975-1,21972-1",
    "Name": "Oru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21975-1",
    "SiriID": 5910,
    "Lat": 5919802,
    "Lng": 2435224,
    "Stops": "21974-1,21963-1,21981-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21961-1",
    "SiriID": 5896,
    "Lat": 5921056,
    "Lng": 2436614,
    "Stops": "21962-1",
    "Name": "Pihelga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21962-1",
    "SiriID": 5904,
    "Lat": 5921047,
    "Lng": 2436609,
    "Stops": "21961-1,21963-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21966-1",
    "SiriID": 5902,
    "Lat": 5918025,
    "Lng": 2432540,
    "Stops": "21967-1,21968-1,21973-1",
    "Name": "Põlde",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21967-1",
    "SiriID": 5906,
    "Lat": 5918022,
    "Lng": 2432513,
    "Stops": "21966-1,21954-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21963-1",
    "SiriID": 5897,
    "Lat": 5921089,
    "Lng": 2435464,
    "Stops": "21964-1,21962-1,21975-1",
    "Name": "Rabaäärse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21964-1",
    "SiriID": 5905,
    "Lat": 5921095,
    "Lng": 2435459,
    "Stops": "21963-1,21982-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21965-1",
    "SiriID": 5900,
    "Lat": 5921386,
    "Lng": 2428762,
    "Stops": "21956-1,21917-1",
    "Name": "Raudteejaama tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22732-1",
    "SiriID": 4883,
    "Lat": 5911974,
    "Lng": 2431132,
    "Stops": "22753-1,22730-1",
    "Name": "Riisipere jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21942-1",
    "SiriID": 4911,
    "Lat": 5919251,
    "Lng": 2443794,
    "Stops": "21941-1,21943-1",
    "Name": "Ruila kool",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21982-1",
    "SiriID": 5898,
    "Lat": 5921024,
    "Lng": 2434347,
    "Stops": "21983-1,21964-1",
    "Name": "Sarapiku",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21983-1",
    "SiriID": 5914,
    "Lat": 5921030,
    "Lng": 2434328,
    "Stops": "21982-1,21951-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21976-1",
    "SiriID": 5892,
    "Lat": 5919838,
    "Lng": 2431398,
    "Stops": "21977-1,21970-1",
    "Name": "Tipi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21977-1",
    "SiriID": 5911,
    "Lat": 5919836,
    "Lng": 2431370,
    "Stops": "21976-1,21958-1,21916-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47312-1",
    "SiriID": 4089,
    "Lat": 5924880,
    "Lng": 2464699,
    "Stops": "47313-1",
    "Name": "Trelli tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22752-1",
    "SiriID": 14259,
    "Lat": 5908268,
    "Lng": 2424214,
    "Stops": "22735-1,22711-1",
    "Name": "Turba kool",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47335-1",
    "SiriID": 3651,
    "Lat": 5934414,
    "Lng": 2461255,
    "Stops": "23375-1,20817-1",
    "Name": "Urda jaam",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22747-1",
    "SiriID": 12560,
    "Lat": 5906651,
    "Lng": 2431887,
    "Stops": "22748-1,22745-1",
    "Name": "Varese",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22748-1",
    "SiriID": 12561,
    "Lat": 5906616,
    "Lng": 2431760,
    "Stops": "22747-1,22713-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21970-1",
    "SiriID": 5893,
    "Lat": 5920276,
    "Lng": 2431778,
    "Stops": "21971-1,21976-1",
    "Name": "Ülevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21971-1",
    "SiriID": 5908,
    "Lat": 5920293,
    "Lng": 2431762,
    "Stops": "21970-1,21951-1,21955-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21953-1",
    "SiriID": 12303,
    "Lat": 5915793,
    "Lng": 2431738,
    "Stops": "21954-1,22710-1,22704-1",
    "Name": "Jaanika",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21954-1",
    "SiriID": 12304,
    "Lat": 5915798,
    "Lng": 2431752,
    "Stops": "21953-1,21967-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21955-1",
    "SiriID": 12297,
    "Lat": 5921439,
    "Lng": 2429360,
    "Stops": "21956-1,21971-1",
    "Name": "Kibuna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21956-1",
    "SiriID": 12299,
    "Lat": 5921467,
    "Lng": 2429344,
    "Stops": "21955-1,21965-1,23515-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21957-1",
    "SiriID": 12301,
    "Lat": 5919479,
    "Lng": 2431515,
    "Stops": "21958-1,21969-1",
    "Name": "Laitse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21958-1",
    "SiriID": 12302,
    "Lat": 5919516,
    "Lng": 2431493,
    "Stops": "21957-1,21927-1,21977-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23372-1",
    "SiriID": 14,
    "Lat": 5933750,
    "Lng": 2459126,
    "Stops": "23373-1",
    "Name": "Padula",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23373-1",
    "SiriID": 15,
    "Lat": 5933788,
    "Lng": 2459284,
    "Stops": "23372-1,23380-1,23318-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22744-1",
    "SiriID": 12305,
    "Lat": 5911946,
    "Lng": 2431211,
    "Stops": "22753-1",
    "Name": "Riisipere",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22753-1",
    "SiriID": 18,
    "Lat": 5911964,
    "Lng": 2431224,
    "Stops": "22744-1,22732-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23375-1",
    "SiriID": 34250,
    "Lat": 5934358,
    "Lng": 2461258,
    "Stops": "23374-1,47335-1,23360-1",
    "Name": "Urda",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23374-1",
    "SiriID": 13,
    "Lat": 5934349,
    "Lng": 2461206,
    "Stops": "23375-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23363-1",
    "SiriID": 10750,
    "Lat": 5931158,
    "Lng": 2448537,
    "Stops": "23364-1,23333-1",
    "Name": "Valingu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23364-1",
    "SiriID": 10751,
    "Lat": 5931140,
    "Lng": 2448353,
    "Stops": "23363-1,47326-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47339-1",
    "SiriID": 4656,
    "Lat": 5923484,
    "Lng": 2453576,
    "Stops": "47316-1,47337-1",
    "Name": "Kiviveski tee",
    "Info": 3,
    "Street": "Altküla tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47316-1",
    "SiriID": 4106,
    "Lat": 5923080,
    "Lng": 2453725,
    "Stops": "47320-1,47339-1",
    "Name": "Orava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47320-1",
    "SiriID": 4107,
    "Lat": 5923077,
    "Lng": 2453752,
    "Stops": "47316-1,47309-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47321-1",
    "SiriID": 4108,
    "Lat": 5922703,
    "Lng": 2454048,
    "Stops": "47309-1,47304-1",
    "Name": "Taga-Orava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47309-1",
    "SiriID": 4109,
    "Lat": 5922710,
    "Lng": 2454070,
    "Stops": "47321-1,47320-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23367-1",
    "SiriID": 4699,
    "Lat": 5940285,
    "Lng": 2454769,
    "Stops": "23366-1,23370-1",
    "Name": "Laabi",
    "Info": 2,
    "Street": "Harku-Rannamõisa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23366-1",
    "SiriID": 4700,
    "Lat": 5940314,
    "Lng": 2454781,
    "Stops": "23367-1,23394-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23361-1",
    "SiriID": 12695,
    "Lat": 5939017,
    "Lng": 2455681,
    "Stops": "23362-1,23306-1,23305-1",
    "Name": "Vatsla tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23362-1",
    "SiriID": 12696,
    "Lat": 5939043,
    "Lng": 2455692,
    "Stops": "23361-1,23370-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23377-1",
    "SiriID": 14455,
    "Lat": 5933149,
    "Lng": 2454855,
    "Stops": "23378-1,47356-1",
    "Name": "Haavasalu",
    "Info": "",
    "Street": "Hüüru - Alliku - Saue",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23378-1",
    "SiriID": 14456,
    "Lat": 5933162,
    "Lng": 2454886,
    "Stops": "23377-1,47331-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47332-1",
    "SiriID": 4409,
    "Lat": 5934205,
    "Lng": 2455603,
    "Stops": "47333-1,23388-1",
    "Name": "Seltsimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47333-1",
    "SiriID": 4410,
    "Lat": 5934204,
    "Lng": 2455590,
    "Stops": "47332-1,47344-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47330-1",
    "SiriID": 4407,
    "Lat": 5933370,
    "Lng": 2455011,
    "Stops": "47331-1,47344-1",
    "Name": "Suurevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47331-1",
    "SiriID": 4408,
    "Lat": 5933369,
    "Lng": 2454996,
    "Stops": "47330-1,23378-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23388-1",
    "SiriID": 50,
    "Lat": 5935654,
    "Lng": 2455624,
    "Stops": "23389-1,23390-1,47332-1",
    "Name": "Uus-Klaokse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23389-1",
    "SiriID": 51,
    "Lat": 5935668,
    "Lng": 2455599,
    "Stops": "23388-1,23387-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47343-1",
    "SiriID": 5647,
    "Lat": 5927486,
    "Lng": 2452181,
    "Stops": "47347-1,23341-1",
    "Name": "Forelli",
    "Info": "",
    "Street": "JÕGISOO II",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47347-1",
    "SiriID": 5734,
    "Lat": 5927472,
    "Lng": 2452140,
    "Stops": "47343-1,23311-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47322-1",
    "SiriID": 4399,
    "Lat": 5929942,
    "Lng": 2453522,
    "Stops": "47323-1,23415-1",
    "Name": "Liidumäe",
    "Info": "",
    "Street": "KANAMA - JÕGISOO",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47323-1",
    "SiriID": 4400,
    "Lat": 5929942,
    "Lng": 2453505,
    "Stops": "47322-1,47324-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47324-1",
    "SiriID": 4401,
    "Lat": 5929337,
    "Lng": 2452685,
    "Stops": "47325-1,47323-1,23339-1",
    "Name": "Mullaste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47325-1",
    "SiriID": 4402,
    "Lat": 5929336,
    "Lng": 2452672,
    "Stops": "47324-1,23384-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23344-1",
    "SiriID": 9344,
    "Lat": 5926189,
    "Lng": 2445386,
    "Stops": "23345-1,23346-1",
    "Name": "Karjatse",
    "Info": 3,
    "Street": "Keila - Ääsmäe",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23345-1",
    "SiriID": 9345,
    "Lat": 5926228,
    "Lng": 2445368,
    "Stops": "23344-1,23328-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23346-1",
    "SiriID": 9342,
    "Lat": 5925600,
    "Lng": 2445515,
    "Stops": "23347-1,23344-1,47349-1",
    "Name": "Koolitare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23347-1",
    "SiriID": 9343,
    "Lat": 5925569,
    "Lng": 2445510,
    "Stops": "23346-1,23321-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23322-1",
    "SiriID": 4941,
    "Lat": 5924107,
    "Lng": 2446571,
    "Stops": "23321-1,47355-1",
    "Name": "Pällu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23321-1",
    "SiriID": 4942,
    "Lat": 5924150,
    "Lng": 2446498,
    "Stops": "23322-1,23347-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23328-1",
    "SiriID": 4951,
    "Lat": 5927586,
    "Lng": 2445193,
    "Stops": "23327-1,23345-1",
    "Name": "Tuula",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23327-1",
    "SiriID": 4952,
    "Lat": 5927623,
    "Lng": 2445197,
    "Stops": "23328-1,47348-1,21832-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21987-1",
    "SiriID": 6000,
    "Lat": 5912585,
    "Lng": 2448650,
    "Stops": "21986-1,21952-1",
    "Name": "Loigu",
    "Info": 4,
    "Street": "KERNU TEE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21986-1",
    "SiriID": 6001,
    "Lat": 5912581,
    "Lng": 2448626,
    "Stops": "21987-1,21906-1,21939-1",
    "Name": "",
    "Info": "",
    "Street": "Kernu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23315-1",
    "SiriID": 4712,
    "Lat": 5936191,
    "Lng": 2448990,
    "Stops": "23316-1,23342-1,23319-1",
    "Name": "Kindluse",
    "Info": 2,
    "Street": "Kiia - Vääna-Viti",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23316-1",
    "SiriID": 4713,
    "Lat": 5936188,
    "Lng": 2449018,
    "Stops": "23315-1,23336-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23342-1",
    "SiriID": 4710,
    "Lat": 5936804,
    "Lng": 2447994,
    "Stops": "23343-1,23315-1",
    "Name": "Uuevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23343-1",
    "SiriID": 4711,
    "Lat": 5936824,
    "Lng": 2447979,
    "Stops": "23342-1,21560-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23349-1",
    "SiriID": 9339,
    "Lat": 5922630,
    "Lng": 2459640,
    "Stops": "23338-1,47306-1,7000706-1",
    "Name": "Kullaliiva",
    "Info": 3,
    "Street": "Kiisa - Maidla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23338-1",
    "SiriID": 4096,
    "Lat": 5922630,
    "Lng": 2459611,
    "Stops": "23349-1,23350-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23350-1",
    "SiriID": 9337,
    "Lat": 5922348,
    "Lng": 2459194,
    "Stops": "23351-1,23338-1",
    "Name": "Maidla külakeskus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23351-1",
    "SiriID": 9338,
    "Lat": 5922341,
    "Lng": 2459190,
    "Stops": "23350-1,47311-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23398-1",
    "SiriID": 4101,
    "Lat": 5921200,
    "Lng": 2457967,
    "Stops": "47317-1,23325-1,23399-1",
    "Name": "Maidla mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47306-1",
    "SiriID": 4094,
    "Lat": 5923468,
    "Lng": 2461030,
    "Stops": "47307-1,23349-1,47301-1",
    "Name": "Ojari",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47307-1",
    "SiriID": 4095,
    "Lat": 5923469,
    "Lng": 2461045,
    "Stops": "47306-1,47315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47317-1",
    "SiriID": 4099,
    "Lat": 5922225,
    "Lng": 2458818,
    "Stops": "47311-1,23398-1",
    "Name": "Otsa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47311-1",
    "SiriID": 4100,
    "Lat": 5922226,
    "Lng": 2458857,
    "Stops": "47317-1,23351-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47315-1",
    "SiriID": 4092,
    "Lat": 5923832,
    "Lng": 2464262,
    "Stops": "23307-1,47314-1,47307-1",
    "Name": "Urumarja tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23307-1",
    "SiriID": 4093,
    "Lat": 5923823,
    "Lng": 2464287,
    "Stops": "47315-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23380-1",
    "SiriID": 14454,
    "Lat": 5934415,
    "Lng": 2459206,
    "Stops": "23373-1,23393-1",
    "Name": "Koidu",
    "Info": 2,
    "Street": "Koidu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23381-1",
    "SiriID": 14212,
    "Lat": 5935038,
    "Lng": 2461959,
    "Stops": "20816-1,20824-1,20817-1",
    "Name": "Laagri kool",
    "Info": "",
    "Street": "Kooli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23354-1",
    "SiriID": 9475,
    "Lat": 5935352,
    "Lng": 2460759,
    "Stops": "23355-1,04503-1,47341-1",
    "Name": "Metsa",
    "Info": "",
    "Street": "Laagri - Harku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23355-1",
    "SiriID": 9476,
    "Lat": 5935332,
    "Lng": 2460779,
    "Stops": "23354-1,47342-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21590-1",
    "SiriID": 9469,
    "Lat": 5937300,
    "Lng": 2458460,
    "Stops": "23376-1,23352-1",
    "Name": "Tammemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23352-1",
    "SiriID": 9471,
    "Lat": 5936730,
    "Lng": 2459075,
    "Stops": "23353-1,21590-1",
    "Name": "Tammetalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23353-1",
    "SiriID": 9472,
    "Lat": 5936688,
    "Lng": 2459109,
    "Stops": "23352-1,23356-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23356-1",
    "SiriID": 9473,
    "Lat": 5935937,
    "Lng": 2460197,
    "Stops": "23357-1,23353-1",
    "Name": "Tuulemurru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23357-1",
    "SiriID": 9474,
    "Lat": 5935888,
    "Lng": 2460226,
    "Stops": "23356-1,04504-1,47341-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23390-1",
    "SiriID": 52,
    "Lat": 5935479,
    "Lng": 2457322,
    "Stops": "23391-1,23388-1",
    "Name": "Karuallika",
    "Info": "",
    "Street": "Laagri - Hüüru",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23391-1",
    "SiriID": 53,
    "Lat": 5935460,
    "Lng": 2457324,
    "Stops": "23390-1,47340-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23392-1",
    "SiriID": 54,
    "Lat": 5934975,
    "Lng": 2459772,
    "Stops": "23393-1,47340-1",
    "Name": "Koru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23393-1",
    "SiriID": 55,
    "Lat": 5934968,
    "Lng": 2459796,
    "Stops": "23392-1,23380-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21918-1",
    "SiriID": 4923,
    "Lat": 5920954,
    "Lng": 2427698,
    "Stops": "21917-1,21984-1,21915-1",
    "Name": "Luige",
    "Info": 4,
    "Street": "Laitse - Kibuna",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21917-1",
    "SiriID": 4924,
    "Lat": 5920974,
    "Lng": 2427695,
    "Stops": "21918-1,21965-1,23512-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21951-1",
    "SiriID": 10516,
    "Lat": 5920888,
    "Lng": 2433483,
    "Stops": "21983-1,21971-1",
    "Name": "Vikerkaare",
    "Info": "",
    "Street": "Laitse aiandusühistute tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21925-1",
    "SiriID": 4927,
    "Lat": 5919496,
    "Lng": 2439455,
    "Stops": "21926-1,21929-1",
    "Name": "Laitse loss",
    "Info": "",
    "Street": "Munalaskme - Laitse",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21926-1",
    "SiriID": 4928,
    "Lat": 5919529,
    "Lng": 2439355,
    "Stops": "21925-1,21980-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21928-1",
    "SiriID": 4925,
    "Lat": 5919679,
    "Lng": 2432074,
    "Stops": "21927-1",
    "Name": "Laitse seltsimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21927-1",
    "SiriID": 4926,
    "Lat": 5919683,
    "Lng": 2432027,
    "Stops": "21928-1,21958-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21916-1",
    "SiriID": 4921,
    "Lat": 5919549,
    "Lng": 2429095,
    "Stops": "21915-1,21977-1",
    "Name": "Munalaskme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21915-1",
    "SiriID": 4922,
    "Lat": 5919552,
    "Lng": 2429072,
    "Stops": "21916-1,22759-1,21918-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22719-1",
    "SiriID": 4876,
    "Lat": 5910467,
    "Lng": 2431231,
    "Stops": "22720-1,22751-1,22701-1",
    "Name": "Nissi kauplus",
    "Info": "",
    "Street": "Nissi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22720-1",
    "SiriID": 4877,
    "Lat": 5910480,
    "Lng": 2431209,
    "Stops": "22719-1,22718-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23301-1",
    "SiriID": 4722,
    "Lat": 5939166,
    "Lng": 2458014,
    "Stops": "21594-1,23302-1",
    "Name": "Bioloogia",
    "Info": 2,
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23302-1",
    "SiriID": 4723,
    "Lat": 5939200,
    "Lng": 2458304,
    "Stops": "23301-1,23329-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23305-1",
    "SiriID": 4720,
    "Lat": 5938909,
    "Lng": 2455600,
    "Stops": "23361-1,23310-1",
    "Name": "Harku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23306-1",
    "SiriID": 4721,
    "Lat": 5938946,
    "Lng": 2455819,
    "Stops": "23361-1,47224-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23309-1",
    "SiriID": 4718,
    "Lat": 5937846,
    "Lng": 2453792,
    "Stops": "23310-1,23323-1",
    "Name": "Hüüru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23310-1",
    "SiriID": 4719,
    "Lat": 5937872,
    "Lng": 2453853,
    "Stops": "23309-1,23305-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23319-1",
    "SiriID": 4707,
    "Lat": 5934488,
    "Lng": 2448090,
    "Stops": "23320-1,23315-1",
    "Name": "Pihuoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23320-1",
    "SiriID": 4709,
    "Lat": 5934353,
    "Lng": 2448073,
    "Stops": "23319-1,21557-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23324-1",
    "SiriID": 4716,
    "Lat": 5936719,
    "Lng": 2451616,
    "Stops": "23323-1,47338-1",
    "Name": "Pühaküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23323-1",
    "SiriID": 4717,
    "Lat": 5936748,
    "Lng": 2451801,
    "Stops": "23324-1,23309-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23329-1",
    "SiriID": 4724,
    "Lat": 5939537,
    "Lng": 2459334,
    "Stops": "23330-1,23302-1",
    "Name": "Uuemäe",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23336-1",
    "SiriID": 4714,
    "Lat": 5936169,
    "Lng": 2449738,
    "Stops": "23335-1,23316-1",
    "Name": "Vääna-Posti",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23335-1",
    "SiriID": 4715,
    "Lat": 5936276,
    "Lng": 2450006,
    "Stops": "23336-1,47338-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47326-1",
    "SiriID": 4403,
    "Lat": 5930903,
    "Lng": 2447930,
    "Stops": "47327-1,23364-1,47328-1",
    "Name": "Suurekivi",
    "Info": "",
    "Street": "Puiestee tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47327-1",
    "SiriID": 4404,
    "Lat": 5930906,
    "Lng": 2447915,
    "Stops": "47326-1,21508-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23304-1",
    "SiriID": 4937,
    "Lat": 5921798,
    "Lng": 2449501,
    "Stops": "23303-1,21935-1,23340-1",
    "Name": "Esku",
    "Info": 3,
    "Street": "Pärnu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23303-1",
    "SiriID": 4938,
    "Lat": 5921847,
    "Lng": 2449505,
    "Stops": "23304-1,23369-1,47304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23312-1",
    "SiriID": 4945,
    "Lat": 5926725,
    "Lng": 2451538,
    "Stops": "23311-1,47349-1",
    "Name": "Jõgisoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23311-1",
    "SiriID": 4946,
    "Lat": 5926847,
    "Lng": 2451727,
    "Stops": "23312-1,47347-1,47302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23314-1",
    "SiriID": 4962,
    "Lat": 5930996,
    "Lng": 2457003,
    "Stops": "23313-1,23420-1",
    "Name": "Kanama",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23313-1",
    "SiriID": 4963,
    "Lat": 5930861,
    "Lng": 2456897,
    "Stops": "23314-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21946-1",
    "SiriID": 9274,
    "Lat": 5913518,
    "Lng": 2449932,
    "Stops": "21912-1,21947-1",
    "Name": "Kernu",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21947-1",
    "SiriID": 9275,
    "Lat": 5913409,
    "Lng": 2449904,
    "Stops": "21946-1,21952-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21910-1",
    "SiriID": 4897,
    "Lat": 5914561,
    "Lng": 2450570,
    "Stops": "21909-1,21911-1",
    "Name": "Kohatu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21909-1",
    "SiriID": 4898,
    "Lat": 5914586,
    "Lng": 2450580,
    "Stops": "21910-1,21914-1,21919-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20817-1",
    "SiriID": 1619,
    "Lat": 5934727,
    "Lng": 2462352,
    "Stops": "20819-1,23381-1,47335-1",
    "Name": "Laagri",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20819-1",
    "SiriID": 3089,
    "Lat": 5934710,
    "Lng": 2462420,
    "Stops": "20817-1,20822-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23339-1",
    "SiriID": 4949,
    "Lat": 5928537,
    "Lng": 2453458,
    "Stops": "23358-1,23264-1,47324-1",
    "Name": "Laiavainu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23358-1",
    "SiriID": 4950,
    "Lat": 5928550,
    "Lng": 2453546,
    "Stops": "23339-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21932-1",
    "SiriID": 4903,
    "Lat": 5918223,
    "Lng": 2450104,
    "Stops": "21931-1,47351-1",
    "Name": "Metsanurga",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21931-1",
    "SiriID": 4904,
    "Lat": 5918263,
    "Lng": 2450114,
    "Stops": "21932-1,21904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20821-1",
    "SiriID": 1622,
    "Lat": 5935072,
    "Lng": 2462794,
    "Stops": "20822-1,04505-1,23809-1",
    "Name": "Nõlvaku",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20822-1",
    "SiriID": 1623,
    "Lat": 5935041,
    "Lng": 2462721,
    "Stops": "20821-1,20820-1,20819-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23318-1",
    "SiriID": 4978,
    "Lat": 5933567,
    "Lng": 2460721,
    "Stops": "23317-1,23373-1",
    "Name": "Peoleo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21940-1",
    "SiriID": 4893,
    "Lat": 5911702,
    "Lng": 2447792,
    "Stops": "21939-1,7000233-1",
    "Name": "Pärtli tee",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21939-1",
    "SiriID": 4894,
    "Lat": 5911728,
    "Lng": 2447853,
    "Stops": "21940-1,21986-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21945-1",
    "SiriID": 4905,
    "Lat": 5920273,
    "Lng": 2449758,
    "Stops": "23340-1,21921-1",
    "Name": "Suurenõmme",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23340-1",
    "SiriID": 4906,
    "Lat": 5920314,
    "Lng": 2449767,
    "Stops": "21945-1,23304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23341-1",
    "SiriID": 4947,
    "Lat": 5927692,
    "Lng": 2452405,
    "Stops": "23264-1,47343-1",
    "Name": "Uku tee",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23264-1",
    "SiriID": 4948,
    "Lat": 5927826,
    "Lng": 2452638,
    "Stops": "23341-1,23339-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47344-1",
    "SiriID": 5648,
    "Lat": 5934204,
    "Lng": 2455500,
    "Stops": "47333-1,47330-1",
    "Name": "Kurvi tee",
    "Info": "",
    "Street": "Püha - Alliku",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23386-1",
    "SiriID": 56,
    "Lat": 5935802,
    "Lng": 2453140,
    "Stops": "23387-1,47338-1",
    "Name": "Püha",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23387-1",
    "SiriID": 57,
    "Lat": 5935795,
    "Lng": 2453144,
    "Stops": "23386-1,23389-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47338-1",
    "SiriID": 4653,
    "Lat": 5936413,
    "Lng": 2451458,
    "Stops": "23324-1,23335-1,23386-1",
    "Name": "Roosi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21906-1",
    "SiriID": 4891,
    "Lat": 5912790,
    "Lng": 2447104,
    "Stops": "21905-1,21986-1",
    "Name": "Haiba",
    "Info": 4,
    "Street": "Riisipere - Kernu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21905-1",
    "SiriID": 4892,
    "Lat": 5912760,
    "Lng": 2446951,
    "Stops": "21906-1,21937-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21912-1",
    "SiriID": 4895,
    "Lat": 5913600,
    "Lng": 2449824,
    "Stops": "21911-1,21946-1,21952-1",
    "Name": "Kernu kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21911-1",
    "SiriID": 4896,
    "Lat": 5913613,
    "Lng": 2449849,
    "Stops": "21912-1,21910-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21934-1",
    "SiriID": 4887,
    "Lat": 5912197,
    "Lng": 2441171,
    "Stops": "21933-1,22733-1",
    "Name": "Miilimaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21933-1",
    "SiriID": 4888,
    "Lat": 5912191,
    "Lng": 2441188,
    "Stops": "21934-1,21938-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21938-1",
    "SiriID": 4889,
    "Lat": 5912511,
    "Lng": 2443589,
    "Stops": "21937-1,21933-1",
    "Name": "Mõnuste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21937-1",
    "SiriID": 4890,
    "Lat": 5912508,
    "Lng": 2443636,
    "Stops": "21938-1,21905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22734-1",
    "SiriID": 4880,
    "Lat": 5912394,
    "Lng": 2436183,
    "Stops": "22733-1,22709-1",
    "Name": "Saida",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22733-1",
    "SiriID": 4881,
    "Lat": 5912391,
    "Lng": 2436201,
    "Stops": "22734-1,21934-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22701-1",
    "SiriID": 4874,
    "Lat": 5909587,
    "Lng": 2434077,
    "Stops": "22702-1,22719-1",
    "Name": "Alema",
    "Info": "",
    "Street": "Riisipere - Nurme",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22702-1",
    "SiriID": 4875,
    "Lat": 5909345,
    "Lng": 2434225,
    "Stops": "22701-1,22745-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22729-1",
    "SiriID": 4872,
    "Lat": 5911895,
    "Lng": 2430791,
    "Stops": "22730-1,22717-1",
    "Name": "Jaama tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22730-1",
    "SiriID": 4873,
    "Lat": 5911901,
    "Lng": 2430803,
    "Stops": "22729-1,22732-1,22722-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22751-1",
    "SiriID": 9197,
    "Lat": 5910376,
    "Lng": 2431039,
    "Stops": "22743-1,22719-1",
    "Name": "Nissi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22718-1",
    "SiriID": 4878,
    "Lat": 5910830,
    "Lng": 2431358,
    "Stops": "22717-1,22720-1",
    "Name": "Nissi kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22717-1",
    "SiriID": 4879,
    "Lat": 5910853,
    "Lng": 2431383,
    "Stops": "22758-1,22718-1,22729-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22703-1",
    "SiriID": 4917,
    "Lat": 5916478,
    "Lng": 2425298,
    "Stops": "22704-1",
    "Name": "Aude",
    "Info": "",
    "Street": "Riisipere - Vasalemma",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22704-1",
    "SiriID": 4918,
    "Lat": 5916489,
    "Lng": 2425327,
    "Stops": "22703-1,22715-1,21953-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22715-1",
    "SiriID": 4920,
    "Lat": 5918476,
    "Lng": 2426475,
    "Stops": "22757-1,22704-1",
    "Name": "Munalaskme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22716-1",
    "SiriID": 4919,
    "Lat": 5919129,
    "Lng": 2426943,
    "Stops": "22759-1,22756-1",
    "Name": "Männimaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22756-1",
    "SiriID": 164,
    "Lat": 5919114,
    "Lng": 2426157,
    "Stops": "22757-1,22716-1,21985-1",
    "Name": "Sepu tee",
    "Info": "",
    "Street": "RIISIPERE - VASALEMMA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22757-1",
    "SiriID": 239,
    "Lat": 5919036,
    "Lng": 2426174,
    "Stops": "22756-1,22715-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21989-1",
    "SiriID": 6004,
    "Lat": 5919389,
    "Lng": 2445267,
    "Stops": "21988-1,21941-1",
    "Name": "Kasekännu",
    "Info": 3,
    "Street": "RUILA - LAITSE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21988-1",
    "SiriID": 6005,
    "Lat": 5919406,
    "Lng": 2445340,
    "Stops": "21989-1,21922-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21922-1",
    "SiriID": 4909,
    "Lat": 5919844,
    "Lng": 2446795,
    "Stops": "21950-1,21921-1,21988-1",
    "Name": "Kääbastemägi",
    "Info": "",
    "Street": "Ruila - Laitse",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21950-1",
    "SiriID": 9271,
    "Lat": 5919854,
    "Lng": 2446803,
    "Stops": "21922-1,21960-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21930-1",
    "SiriID": 4929,
    "Lat": 5919367,
    "Lng": 2439811,
    "Stops": "21929-1,21944-1",
    "Name": "Laitse tee",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21929-1",
    "SiriID": 4930,
    "Lat": 5919367,
    "Lng": 2439788,
    "Stops": "21930-1,21925-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21941-1",
    "SiriID": 4912,
    "Lat": 5919220,
    "Lng": 2443963,
    "Stops": "21942-1,21989-1",
    "Name": "Ruila kool",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47340-1",
    "SiriID": 4851,
    "Lat": 5935257,
    "Lng": 2459918,
    "Stops": "47341-1,23392-1,23391-1",
    "Name": "Veskirahva",
    "Info": 2,
    "Street": "Saku - Laagri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47341-1",
    "SiriID": 4856,
    "Lat": 5935379,
    "Lng": 2460009,
    "Stops": "47340-1,23354-1,23357-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47357-1",
    "SiriID": 6006,
    "Lat": 5932576,
    "Lng": 2454732,
    "Stops": "47356-1,23424-1",
    "Name": "Suurevälja põik",
    "Info": "",
    "Street": "Suurevälja tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47356-1",
    "SiriID": 6007,
    "Lat": 5932622,
    "Lng": 2454751,
    "Stops": "47357-1,23377-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23397-1",
    "SiriID": 208,
    "Lat": 5934158,
    "Lng": 2461631,
    "Stops": "23360-1",
    "Name": "Urda",
    "Info": "",
    "Street": "TALLINN - PÄRNU - IKLA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23412-1",
    "SiriID": 4960,
    "Lat": 5931205,
    "Lng": 2456000,
    "Stops": "23420-1,23411-1",
    "Name": "Kiviloo",
    "Info": "",
    "Street": "Tallinna ringtee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23411-1",
    "SiriID": 12979,
    "Lat": 5931129,
    "Lng": 2455625,
    "Stops": "23412-1,23415-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23420-1",
    "SiriID": 9951,
    "Lat": 5931232,
    "Lng": 2456252,
    "Stops": "23419-1,23412-1,23314-1",
    "Name": "Saue tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23416-1",
    "SiriID": 4955,
    "Lat": 5931390,
    "Lng": 2453410,
    "Stops": "23426-1,47336-1",
    "Name": "Tõkke",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23415-1",
    "SiriID": 4957,
    "Lat": 5931340,
    "Lng": 2453665,
    "Stops": "23427-1,23411-1,47322-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23334-1",
    "SiriID": 4953,
    "Lat": 5931704,
    "Lng": 2450185,
    "Stops": "23333-1,47334-1",
    "Name": "Valingu/ Pähklisalu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23333-1",
    "SiriID": 4954,
    "Lat": 5931710,
    "Lng": 2449981,
    "Stops": "23334-1,23363-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22713-1",
    "SiriID": 4864,
    "Lat": 5904475,
    "Lng": 2426104,
    "Stops": "22754-1,22748-1",
    "Name": "Lehetu",
    "Info": 4,
    "Street": "Turba - Lehetu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22737-1",
    "SiriID": 4862,
    "Lat": 5907532,
    "Lng": 2424336,
    "Stops": "22738-1",
    "Name": "Turba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22738-1",
    "SiriID": 12552,
    "Lat": 5907525,
    "Lng": 2424319,
    "Stops": "22737-1,22754-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22735-1",
    "SiriID": 4860,
    "Lat": 5908057,
    "Lng": 2423611,
    "Stops": "22736-1,22739-1,22752-1",
    "Name": "Turba kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22736-1",
    "SiriID": 4861,
    "Lat": 5908029,
    "Lng": 2423630,
    "Stops": "22735-1,22755-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47354-1",
    "SiriID": 5765,
    "Lat": 5924554,
    "Lng": 2448803,
    "Stops": "47355-1,47349-1",
    "Name": "Hindreku",
    "Info": 3,
    "Street": "Tuula tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47349-1",
    "SiriID": 2679,
    "Lat": 5925705,
    "Lng": 2448405,
    "Stops": "47354-1,23346-1,23312-1",
    "Name": "Laane",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47355-1",
    "SiriID": 5766,
    "Lat": 5924325,
    "Lng": 2448691,
    "Stops": "23359-1,47354-1,47346-1,23322-1",
    "Name": "Rehe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47348-1",
    "SiriID": 5735,
    "Lat": 5927673,
    "Lng": 2445785,
    "Stops": "23327-1,23385-1",
    "Name": "Tuula seltsimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23384-1",
    "SiriID": 58,
    "Lat": 5929408,
    "Lng": 2449872,
    "Stops": "23385-1,47325-1,47329-1",
    "Name": "Aila",
    "Info": 2,
    "Street": "VALINGU - JÕGISOO",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23385-1",
    "SiriID": 59,
    "Lat": 5929382,
    "Lng": 2449867,
    "Stops": "23384-1,47348-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47334-1",
    "SiriID": 4411,
    "Lat": 5931917,
    "Lng": 2451211,
    "Stops": "47336-1,47308-1,23334-1",
    "Name": "Jalaka tee",
    "Info": "",
    "Street": "Vanamõisa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47336-1",
    "SiriID": 4420,
    "Lat": 5931916,
    "Lng": 2451258,
    "Stops": "47334-1,23416-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47308-1",
    "SiriID": 4116,
    "Lat": 5932246,
    "Lng": 2451138,
    "Stops": "23379-1,23383-1,47334-1",
    "Name": "Tuuleveski",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23379-1",
    "SiriID": 4117,
    "Lat": 5932247,
    "Lng": 2451123,
    "Stops": "47308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23383-1",
    "SiriID": 46,
    "Lat": 5932500,
    "Lng": 2451391,
    "Stops": "47308-1",
    "Name": "Vanamõisa mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23371-1",
    "SiriID": 13359,
    "Lat": 5939593,
    "Lng": 2453559,
    "Stops": "23370-1,21591-1",
    "Name": "Kodasema",
    "Info": "",
    "Street": "Vatsla tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23370-1",
    "SiriID": 13360,
    "Lat": 5939552,
    "Lng": 2453658,
    "Stops": "23371-1,23367-1,23362-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21591-1",
    "SiriID": 10369,
    "Lat": 5940198,
    "Lng": 2450486,
    "Stops": "23371-1,21561-1",
    "Name": "Vatsla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20818-1",
    "SiriID": 1620,
    "Lat": 5935163,
    "Lng": 2461199,
    "Stops": "20824-1,47342-1",
    "Name": "Laagri alevik",
    "Info": "",
    "Street": "Veskitammi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20824-1",
    "SiriID": 13183,
    "Lat": 5935164,
    "Lng": 2461323,
    "Stops": "20818-1,23381-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20816-1",
    "SiriID": 1618,
    "Lat": 5935137,
    "Lng": 2462143,
    "Stops": "20820-1,23381-1",
    "Name": "Rahnu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20820-1",
    "SiriID": 1621,
    "Lat": 5935123,
    "Lng": 2462355,
    "Stops": "20816-1,20822-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47302-1",
    "SiriID": 4113,
    "Lat": 5926554,
    "Lng": 2453279,
    "Stops": "47303-1,23311-1",
    "Name": "Karu tee",
    "Info": 3,
    "Street": "Voore tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47303-1",
    "SiriID": 4114,
    "Lat": 5926546,
    "Lng": 2453288,
    "Stops": "47302-1,47301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47301-1",
    "SiriID": 4112,
    "Lat": 5925799,
    "Lng": 2458025,
    "Stops": "47303-1,47306-1",
    "Name": "Voore mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23359-1",
    "SiriID": 9686,
    "Lat": 5924334,
    "Lng": 2449045,
    "Stops": "47355-1,23365-1",
    "Name": "Harutee",
    "Info": "",
    "Street": "Ääsmäe",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23365-1",
    "SiriID": 9752,
    "Lat": 5924289,
    "Lng": 2449519,
    "Stops": "23359-1,23308-1,23382-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21902-1",
    "SiriID": 4935,
    "Lat": 5921857,
    "Lng": 2443659,
    "Stops": "21901-1,21943-1",
    "Name": "Allikmäe",
    "Info": "",
    "Street": "Ääsmäe - Haapsalu - Rohuküla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21901-1",
    "SiriID": 4936,
    "Lat": 5921881,
    "Lng": 2443701,
    "Stops": "21902-1,21936-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22706-1",
    "SiriID": 4858,
    "Lat": 5904750,
    "Lng": 2417418,
    "Stops": "22705-1,22726-1",
    "Name": "Ellamaa",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22705-1",
    "SiriID": 4859,
    "Lat": 5904758,
    "Lng": 2417448,
    "Stops": "22706-1,22741-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22710-1",
    "SiriID": 4884,
    "Lat": 5913964,
    "Lng": 2433019,
    "Stops": "22709-1,21953-1",
    "Name": "Hürjapea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22709-1",
    "SiriID": 4885,
    "Lat": 5913941,
    "Lng": 2432985,
    "Stops": "22710-1,22722-1,22734-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22712-1",
    "SiriID": 4866,
    "Lat": 5910018,
    "Lng": 2425224,
    "Stops": "22711-1,22749-1",
    "Name": "Kivitammi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22711-1",
    "SiriID": 4867,
    "Lat": 5909957,
    "Lng": 2425220,
    "Stops": "22712-1,22752-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21908-1",
    "SiriID": 4915,
    "Lat": 5917668,
    "Lng": 2436274,
    "Stops": "21907-1,21972-1",
    "Name": "Rallipargi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21907-1",
    "SiriID": 4916,
    "Lat": 5917729,
    "Lng": 2436609,
    "Stops": "21908-1,21980-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22723-1",
    "SiriID": 4854,
    "Lat": 5900036,
    "Lng": 2410741,
    "Stops": "22724-1,5700337-1",
    "Name": "Rehemäe",
    "Info": 5,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22724-1",
    "SiriID": 4855,
    "Lat": 5900035,
    "Lng": 2410791,
    "Stops": "22723-1,22725-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22725-1",
    "SiriID": 4852,
    "Lat": 5902844,
    "Lng": 2415262,
    "Stops": "22726-1,22724-1",
    "Name": "Riiaküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22726-1",
    "SiriID": 4853,
    "Lat": 5902854,
    "Lng": 2415305,
    "Stops": "22725-1,22706-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22721-1",
    "SiriID": 4870,
    "Lat": 5912304,
    "Lng": 2430352,
    "Stops": "22722-1,22727-1",
    "Name": "Riisipere",
    "Info": 4,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22722-1",
    "SiriID": 4871,
    "Lat": 5912307,
    "Lng": 2430401,
    "Stops": "22721-1,22730-1,22709-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22727-1",
    "SiriID": 4868,
    "Lat": 5911895,
    "Lng": 2428164,
    "Stops": "22728-1,22721-1",
    "Name": "Riisipere mõis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22728-1",
    "SiriID": 4869,
    "Lat": 5911871,
    "Lng": 2428051,
    "Stops": "22727-1,22750-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21944-1",
    "SiriID": 4933,
    "Lat": 5919780,
    "Lng": 2441144,
    "Stops": "21943-1,21930-1",
    "Name": "Ruila tee",
    "Info": 3,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21943-1",
    "SiriID": 4934,
    "Lat": 5919792,
    "Lng": 2441303,
    "Stops": "21944-1,21942-1,21902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22739-1",
    "SiriID": 9172,
    "Lat": 5908241,
    "Lng": 2423303,
    "Stops": "22740-1,22735-1",
    "Name": "Turba",
    "Info": 4,
    "Street": "ÄÄSMÄE - HAAPSALU - ROHUKÜLA",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22740-1",
    "SiriID": 9173,
    "Lat": 5908233,
    "Lng": 2423263,
    "Stops": "22739-1",
    "Name": "",
    "Info": "",
    "Street": "Ääsmäe - Haapsalu - Rohuküla",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22749-1",
    "SiriID": 12555,
    "Lat": 5911362,
    "Lng": 2426177,
    "Stops": "22750-1,22712-1",
    "Name": "Vilumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22750-1",
    "SiriID": 12556,
    "Lat": 5911380,
    "Lng": 2426299,
    "Stops": "22749-1,22728-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23308-1",
    "SiriID": 4943,
    "Lat": 5923965,
    "Lng": 2449571,
    "Stops": "47318-1,23365-1,47346-1",
    "Name": "Harutee",
    "Info": 3,
    "Street": "Ääsmäe - Hageri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23326-1",
    "SiriID": 4939,
    "Lat": 5921526,
    "Lng": 2455452,
    "Stops": "23325-1,47305-1",
    "Name": "Kuristo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23325-1",
    "SiriID": 4940,
    "Lat": 5921533,
    "Lng": 2455463,
    "Stops": "23326-1,23398-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47304-1",
    "SiriID": 4104,
    "Lat": 5922067,
    "Lng": 2453229,
    "Stops": "47305-1,47321-1,23303-1",
    "Name": "Külakõrtsu",
    "Info": "",
    "Street": "ÄÄSMÄE - HAGERI",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47305-1",
    "SiriID": 4105,
    "Lat": 5922055,
    "Lng": 2453237,
    "Stops": "47304-1,23326-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23348-1",
    "SiriID": 9330,
    "Lat": 5923407,
    "Lng": 2450399,
    "Stops": "47310-1,47337-1",
    "Name": "Reedi",
    "Info": "",
    "Street": "Ääsmäe - Hageri",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47310-1",
    "SiriID": 4110,
    "Lat": 5923728,
    "Lng": 2449903,
    "Stops": "47318-1,23348-1",
    "Name": "Saksa",
    "Info": "",
    "Street": "ÄÄSMÄE - HAGERI",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47318-1",
    "SiriID": 4111,
    "Lat": 5923732,
    "Lng": 2449911,
    "Stops": "47310-1,23308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23399-1",
    "SiriID": 4102,
    "Lat": 5919794,
    "Lng": 2459852,
    "Stops": "47319-1,23398-1",
    "Name": "Ülesoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47319-1",
    "SiriID": 4103,
    "Lat": 5919797,
    "Lng": 2459866,
    "Stops": "23399-1,7000700-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21904-1",
    "SiriID": 4907,
    "Lat": 5918671,
    "Lng": 2448159,
    "Stops": "21903-1,21931-1",
    "Name": "Allika",
    "Info": "",
    "Street": "Ääsmäe - Kernu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21903-1",
    "SiriID": 4908,
    "Lat": 5918736,
    "Lng": 2448059,
    "Stops": "21904-1,21921-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47345-1",
    "SiriID": 5732,
    "Lat": 5923900,
    "Lng": 2448741,
    "Stops": "47346-1,23368-1",
    "Name": "Harutee",
    "Info": "",
    "Street": "ÄÄSMÄE - KERNU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47346-1",
    "SiriID": 5733,
    "Lat": 5923904,
    "Lng": 2448752,
    "Stops": "47345-1,47355-1,23308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21991-1",
    "SiriID": 6002,
    "Lat": 5916288,
    "Lng": 2450229,
    "Stops": "21990-1,21979-1",
    "Name": "Järveotsa",
    "Info": "",
    "Street": "Ääsmäe - Kernu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21990-1",
    "SiriID": 6003,
    "Lat": 5916251,
    "Lng": 2450233,
    "Stops": "21991-1,21913-1",
    "Name": "",
    "Info": "",
    "Street": "ÄÄSMÄE - KERNU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21921-1",
    "SiriID": 4910,
    "Lat": 5919782,
    "Lng": 2446989,
    "Stops": "21922-1,21903-1,21945-1",
    "Name": "Kääbastemägi",
    "Info": "",
    "Street": "Ääsmäe - Kernu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21960-1",
    "SiriID": 14849,
    "Lat": 5919921,
    "Lng": 2446898,
    "Stops": "21950-1,47352-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21936-1",
    "SiriID": 4913,
    "Lat": 5921768,
    "Lng": 2446928,
    "Stops": "21935-1,21901-1",
    "Name": "Muusika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21935-1",
    "SiriID": 4914,
    "Lat": 5921757,
    "Lng": 2446935,
    "Stops": "21936-1,47353-1,23304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47352-1",
    "SiriID": 5767,
    "Lat": 5920599,
    "Lng": 2446504,
    "Stops": "47353-1,21960-1",
    "Name": "Sinilille",
    "Info": "",
    "Street": "ÄÄSMÄE - KERNU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47353-1",
    "SiriID": 5768,
    "Lat": 5920616,
    "Lng": 2446477,
    "Stops": "47352-1,21935-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23368-1",
    "SiriID": 13356,
    "Lat": 5923164,
    "Lng": 2448121,
    "Stops": "23369-1,47345-1",
    "Name": "Toominga",
    "Info": "",
    "Street": "Ääsmäe - Kernu",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23369-1",
    "SiriID": 13357,
    "Lat": 5923149,
    "Lng": 2448105,
    "Stops": "23368-1,23303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47350-1",
    "SiriID": 5769,
    "Lat": 5917216,
    "Lng": 2449565,
    "Stops": "47351-1,21978-1",
    "Name": "Torupilli",
    "Info": "",
    "Street": "ÄÄSMÄE - KERNU",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47351-1",
    "SiriID": 5770,
    "Lat": 5917219,
    "Lng": 2449548,
    "Stops": "47350-1,21932-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23382-1",
    "SiriID": 14481,
    "Lat": 5924541,
    "Lng": 2450446,
    "Stops": "23365-1,47337-1",
    "Name": "Ääsmäe kool",
    "Info": "",
    "Street": "ÄÄSMÄE TEE",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47328-1",
    "SiriID": 4405,
    "Lat": 5930520,
    "Lng": 2447904,
    "Stops": "47329-1,47326-1",
    "Name": "Ööbiku",
    "Info": 2,
    "Street": "Ööbiku tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "47329-1",
    "SiriID": 4406,
    "Lat": 5930516,
    "Lng": 2447896,
    "Stops": "47328-1,23384-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23396-1",
    "SiriID": 207,
    "Lat": 5934093,
    "Lng": 2461467,
    "Stops": "23360-1,23227-1,23317-1",
    "Name": "Urda",
    "Info": "",
    "Street": "TALLINN - PÄRNU - IKLA",
    "Area": "Saue vald, Laagri alevik",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00123-1",
    "SiriID": 12953,
    "Lat": 5943384,
    "Lng": 2456078,
    "Stops": "21552-1,21504-1",
    "Name": "Keskpäeva tee",
    "Info": 0,
    "Street": 0,
    "Area": "Tallinna linn, Haabersti",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00141-1",
    "SiriID": 4149,
    "Lat": 5942676,
    "Lng": 2465500,
    "Stops": "00140-1,00309-1,00308-1",
    "Name": "Mõisa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01902-1",
    "SiriID": 850,
    "Lat": 5940469,
    "Lng": 2463420,
    "Stops": "01901-1,01904-1",
    "Name": "Astangu",
    "Info": "",
    "Street": "Astangu tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01901-1",
    "SiriID": 179,
    "Lat": 5940452,
    "Lng": 2463400,
    "Stops": "01902-1,01906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01903-1",
    "SiriID": 851,
    "Lat": 5940497,
    "Lng": 2463896,
    "Stops": "01904-1,01608-1,03601-1",
    "Name": "Harku-Kadaka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01904-1",
    "SiriID": 852,
    "Lat": 5940460,
    "Lng": 2463762,
    "Stops": "01903-1,01902-1,03609-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01905-1",
    "SiriID": 5737,
    "Lat": 5940494,
    "Lng": 2462722,
    "Stops": "01906-1,01602-1",
    "Name": "Jalami",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01906-1",
    "SiriID": 5738,
    "Lat": 5940475,
    "Lng": 2462897,
    "Stops": "01905-1,01901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00310-1",
    "SiriID": 131,
    "Lat": 5941633,
    "Lng": 2465641,
    "Stops": "01403-1,02113-1",
    "Name": "Meistri",
    "Info": "",
    "Street": "Ehitajate tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02114-1",
    "SiriID": 4014,
    "Lat": 5942140,
    "Lng": 2464953,
    "Stops": "02115-1,01004-1,00701-1",
    "Name": "Päevalille",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02115-1",
    "SiriID": 4016,
    "Lat": 5942055,
    "Lng": 2465025,
    "Stops": "02114-1,00802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02112-1",
    "SiriID": 4012,
    "Lat": 5941820,
    "Lng": 2465376,
    "Stops": "02113-1,00802-1",
    "Name": "Rebasemägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02113-1",
    "SiriID": 4013,
    "Lat": 5941720,
    "Lng": 2465465,
    "Stops": "02112-1,00801-1,00310-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01607-1",
    "SiriID": 845,
    "Lat": 5940715,
    "Lng": 2463963,
    "Stops": "01608-1,01609-1",
    "Name": "Järveotsa kool",
    "Info": "",
    "Street": "Järveotsa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01608-1",
    "SiriID": 846,
    "Lat": 5940695,
    "Lng": 2464037,
    "Stops": "01607-1,01903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01601-1",
    "SiriID": 839,
    "Lat": 5940911,
    "Lng": 2463241,
    "Stops": "01609-1,01610-1",
    "Name": "Järveotsa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01609-1",
    "SiriID": 847,
    "Lat": 5940893,
    "Lng": 2463229,
    "Stops": "01601-1,01604-1,01607-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03608-1",
    "SiriID": 4316,
    "Lat": 5939793,
    "Lng": 2463841,
    "Stops": "03609-1,03604-1",
    "Name": "Rehe",
    "Info": "",
    "Street": "Kadaka tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03609-1",
    "SiriID": 4318,
    "Lat": 5939891,
    "Lng": 2464169,
    "Stops": "03608-1,03602-1,01904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00101-1",
    "SiriID": 785,
    "Lat": 5945905,
    "Lng": 2457330,
    "Stops": "00102-1,00103-1",
    "Name": "Kakumäe",
    "Info": "",
    "Street": "Kakumäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00102-1",
    "SiriID": 786,
    "Lat": 5945926,
    "Lng": 2457318,
    "Stops": "00101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00111-1",
    "SiriID": 796,
    "Lat": 5944205,
    "Lng": 2459492,
    "Stops": "00129-1,00127-1",
    "Name": "Kakumäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00112-1",
    "SiriID": 797,
    "Lat": 5944292,
    "Lng": 2459407,
    "Stops": "00129-1,00131-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00124-1",
    "SiriID": 794,
    "Lat": 5944871,
    "Lng": 2459005,
    "Stops": "00110-1,00130-1",
    "Name": "Landi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00110-1",
    "SiriID": 795,
    "Lat": 5944878,
    "Lng": 2459003,
    "Stops": "00124-1,00109-1,00107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00130-1",
    "SiriID": 4138,
    "Lat": 5944705,
    "Lng": 2459121,
    "Stops": "00131-1,00124-1",
    "Name": "Räime",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00131-1",
    "SiriID": 4139,
    "Lat": 5944676,
    "Lng": 2459104,
    "Stops": "00130-1,00112-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00103-1",
    "SiriID": 787,
    "Lat": 5945572,
    "Lng": 2457786,
    "Stops": "00104-1,00101-1",
    "Name": "Soolahe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00104-1",
    "SiriID": 788,
    "Lat": 5945567,
    "Lng": 2457797,
    "Stops": "00103-1,00106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00107-1",
    "SiriID": 791,
    "Lat": 5945156,
    "Lng": 2458456,
    "Stops": "00108-1,00110-1",
    "Name": "Sooranna tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00108-1",
    "SiriID": 792,
    "Lat": 5945193,
    "Lng": 2458391,
    "Stops": "00107-1,00105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00105-1",
    "SiriID": 789,
    "Lat": 5945371,
    "Lng": 2458077,
    "Stops": "00106-1,00108-1",
    "Name": "Teeotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00106-1",
    "SiriID": 790,
    "Lat": 5945377,
    "Lng": 2458070,
    "Stops": "00105-1,00104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00127-1",
    "SiriID": 4135,
    "Lat": 5943978,
    "Lng": 2459749,
    "Stops": "00128-1,00111-1",
    "Name": "Tõnu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00128-1",
    "SiriID": 4136,
    "Lat": 5943934,
    "Lng": 2459772,
    "Stops": "00127-1,00126-1,00118-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00113-1",
    "SiriID": 798,
    "Lat": 5943612,
    "Lng": 2460118,
    "Stops": "00126-1,00117-1,00125-1",
    "Name": "Vismeistri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00126-1",
    "SiriID": 4134,
    "Lat": 5943648,
    "Lng": 2460096,
    "Stops": "00113-1,00128-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00307-1",
    "SiriID": 814,
    "Lat": 5942405,
    "Lng": 2464389,
    "Stops": "00307-2,00701-1",
    "Name": "Haabersti ring",
    "Info": "",
    "Street": "Klooga mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00307-2",
    "SiriID": 5879,
    "Lat": 5942415,
    "Lng": 2464332,
    "Stops": "00307-3,00307-1,00602-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00307-3",
    "SiriID": 5880,
    "Lat": 5942423,
    "Lng": 2464294,
    "Stops": "00307-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00602-1",
    "SiriID": 5881,
    "Lat": 5942399,
    "Lng": 2464217,
    "Stops": "00602-2,00306-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00602-2",
    "SiriID": 5882,
    "Lat": 5942394,
    "Lng": 2464250,
    "Stops": "00602-1,00602-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00602-3",
    "SiriID": 5883,
    "Lat": 5942387,
    "Lng": 2464293,
    "Stops": "00602-2,00307-2,00903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00306-1",
    "SiriID": 813,
    "Lat": 5942655,
    "Lng": 2463247,
    "Stops": "00601-1,00305-1,00602-1",
    "Name": "Sõba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00601-1",
    "SiriID": 819,
    "Lat": 5942645,
    "Lng": 2463231,
    "Stops": "00306-1,00302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02001-1",
    "SiriID": 172,
    "Lat": 5940461,
    "Lng": 2461551,
    "Stops": "02002-1,02003-1",
    "Name": "Kotermaa",
    "Info": "",
    "Street": "Kotermaa tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02002-1",
    "SiriID": 5736,
    "Lat": 5940517,
    "Lng": 2461643,
    "Stops": "02001-1,01603-1,20813-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02003-1",
    "SiriID": 5924,
    "Lat": 5940267,
    "Lng": 2461327,
    "Stops": "02004-1,02001-1",
    "Name": "Moonalao",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02004-1",
    "SiriID": 5925,
    "Lat": 5940249,
    "Lng": 2461289,
    "Stops": "02003-1,21587-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00109-1",
    "SiriID": 793,
    "Lat": 5944881,
    "Lng": 2459042,
    "Stops": "00110-1,00502-1",
    "Name": "Landi",
    "Info": "",
    "Street": "Landi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00305-1",
    "SiriID": 812,
    "Lat": 5942807,
    "Lng": 2463340,
    "Stops": "00306-1,00304-1",
    "Name": "Tanuma",
    "Info": "",
    "Street": "Lõuka tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00140-1",
    "SiriID": 4148,
    "Lat": 5942747,
    "Lng": 2465513,
    "Stops": "00141-1,00139-1",
    "Name": "Mõisa",
    "Info": "",
    "Street": "Mõisa",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00308-1",
    "SiriID": 815,
    "Lat": 5942488,
    "Lng": 2465125,
    "Stops": "00308-2,00141-1",
    "Name": "Haabersti",
    "Info": "",
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00701-1",
    "SiriID": 821,
    "Lat": 5942370,
    "Lng": 2464863,
    "Stops": "00701-2,02114-1,00307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00308-2",
    "SiriID": 5877,
    "Lat": 5942478,
    "Lng": 2465077,
    "Stops": "00308-3,00308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00308-3",
    "SiriID": 5878,
    "Lat": 5942469,
    "Lng": 2465038,
    "Stops": "00308-2,00701-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00701-2",
    "SiriID": 5885,
    "Lat": 5942380,
    "Lng": 2464901,
    "Stops": "00701-1,00308-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08601-1",
    "SiriID": 1126,
    "Lat": 5942953,
    "Lng": 2469245,
    "Stops": "08602-1,07901-1",
    "Name": "Humala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08602-1",
    "SiriID": 1127,
    "Lat": 5942987,
    "Lng": 2469144,
    "Stops": "08601-1,08702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01602-1",
    "SiriID": 840,
    "Lat": 5940760,
    "Lng": 2462296,
    "Stops": "01603-1,01605-1,01905-1",
    "Name": "Järvekalda",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01603-1",
    "SiriID": 841,
    "Lat": 5940767,
    "Lng": 2462284,
    "Stops": "01602-1,02002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01604-1",
    "SiriID": 842,
    "Lat": 5940907,
    "Lng": 2462967,
    "Stops": "01605-1,01609-1",
    "Name": "Järveotsa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01605-1",
    "SiriID": 843,
    "Lat": 5940885,
    "Lng": 2462843,
    "Stops": "01604-1,01602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08701-1",
    "SiriID": 1128,
    "Lat": 5942778,
    "Lng": 2467261,
    "Stops": "08801-1,00702-1",
    "Name": "Looga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08801-1",
    "SiriID": 1130,
    "Lat": 5942822,
    "Lng": 2467334,
    "Stops": "08701-1,08802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08702-1",
    "SiriID": 1129,
    "Lat": 5942868,
    "Lng": 2468091,
    "Stops": "08802-1,08602-1,08206-1",
    "Name": "Mustjõe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08802-1",
    "SiriID": 1131,
    "Lat": 5942893,
    "Lng": 2467970,
    "Stops": "08702-1,08801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21586-1",
    "SiriID": 9477,
    "Lat": 5939895,
    "Lng": 2460296,
    "Stops": "21587-1,23330-1",
    "Name": "Paemurru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21587-1",
    "SiriID": 9478,
    "Lat": 5939930,
    "Lng": 2460462,
    "Stops": "21586-1,02004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07901-1",
    "SiriID": 1110,
    "Lat": 5942929,
    "Lng": 2469814,
    "Stops": "07902-1,08601-1",
    "Name": "Pirni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07902-1",
    "SiriID": 1111,
    "Lat": 5942935,
    "Lng": 2469978,
    "Stops": "07901-1,07906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00309-1",
    "SiriID": 816,
    "Lat": 5942643,
    "Lng": 2465805,
    "Stops": "00702-1,00141-1",
    "Name": "Zoo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00702-1",
    "SiriID": 822,
    "Lat": 5942621,
    "Lng": 2465889,
    "Stops": "00309-1,08701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23330-1",
    "SiriID": 4725,
    "Lat": 5939595,
    "Lng": 2459567,
    "Stops": "23329-1,21586-1",
    "Name": "Uuemäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00403-1",
    "SiriID": 13786,
    "Lat": 5942883,
    "Lng": 2462016,
    "Stops": "00404-1,00401-1",
    "Name": "Pikaliiva",
    "Info": "",
    "Street": "Rannamõisa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00404-1",
    "SiriID": 13787,
    "Lat": 5942921,
    "Lng": 2461929,
    "Stops": "00403-1,00301-1,00121-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00120-1",
    "SiriID": 3389,
    "Lat": 5943004,
    "Lng": 2461210,
    "Stops": "00121-1,00116-1,00122-1",
    "Name": "Printsu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00121-1",
    "SiriID": 3390,
    "Lat": 5942990,
    "Lng": 2461288,
    "Stops": "00120-1,00404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00401-1",
    "SiriID": 817,
    "Lat": 5942820,
    "Lng": 2462542,
    "Stops": "00501-1,00300-1,00403-1",
    "Name": "Räga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00501-1",
    "SiriID": 818,
    "Lat": 5942810,
    "Lng": 2462591,
    "Stops": "00401-1,00311-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00303-1",
    "SiriID": 810,
    "Lat": 5943435,
    "Lng": 2462926,
    "Stops": "00136-1,00135-1,00299-1",
    "Name": "Hälli",
    "Info": "",
    "Street": "Vabaõhumuuseumi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00136-1",
    "SiriID": 4144,
    "Lat": 5943337,
    "Lng": 2463090,
    "Stops": "00303-1,00304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00129-1",
    "SiriID": 4137,
    "Lat": 5944269,
    "Lng": 2459528,
    "Stops": "00112-1,00111-1,00114-1",
    "Name": "Kakumäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00114-1",
    "SiriID": 799,
    "Lat": 5944681,
    "Lng": 2460446,
    "Stops": "00132-1,00129-1",
    "Name": "Lesta",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00132-1",
    "SiriID": 4140,
    "Lat": 5944727,
    "Lng": 2460575,
    "Stops": "00114-1,00502-1,00201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00201-1",
    "SiriID": 805,
    "Lat": 5944492,
    "Lng": 2461246,
    "Stops": "00133-1,00132-1",
    "Name": "Merirahu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00133-1",
    "SiriID": 4141,
    "Lat": 5944457,
    "Lng": 2461328,
    "Stops": "00201-1,00202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00202-1",
    "SiriID": 806,
    "Lat": 5944007,
    "Lng": 2462310,
    "Stops": "00134-1,00133-1,00122-1,09006-1",
    "Name": "Paljandi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00134-1",
    "SiriID": 4142,
    "Lat": 5943934,
    "Lng": 2462418,
    "Stops": "00202-1,00203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00304-1",
    "SiriID": 811,
    "Lat": 5943117,
    "Lng": 2463709,
    "Stops": "00137-1,00305-1,00136-1",
    "Name": "Rocca al Mare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00137-1",
    "SiriID": 4145,
    "Lat": 5943087,
    "Lng": 2463869,
    "Stops": "00304-1,00138-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00138-1",
    "SiriID": 4146,
    "Lat": 5943026,
    "Lng": 2465105,
    "Stops": "00139-1,00137-1",
    "Name": "Rocca al Mare kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00139-1",
    "SiriID": 4147,
    "Lat": 5943018,
    "Lng": 2465139,
    "Stops": "00138-1,00140-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00203-1",
    "SiriID": 807,
    "Lat": 5943722,
    "Lng": 2462730,
    "Stops": "00135-1,00134-1",
    "Name": "Õismäe raba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00135-1",
    "SiriID": 4143,
    "Lat": 5943693,
    "Lng": 2462726,
    "Stops": "00203-1,00303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00115-1",
    "SiriID": 800,
    "Lat": 5943824,
    "Lng": 2457258,
    "Stops": "47039-1",
    "Name": "Tiskre",
    "Info": "",
    "Street": "Vahepere",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00122-1",
    "SiriID": 4056,
    "Lat": 5943564,
    "Lng": 2461243,
    "Stops": "00125-1,00120-1,00202-1",
    "Name": "Alemaa",
    "Info": "",
    "Street": "Vana-Rannamõisa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00125-1",
    "SiriID": 4133,
    "Lat": 5943620,
    "Lng": 2461100,
    "Stops": "00122-1,00113-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00302-1",
    "SiriID": 809,
    "Lat": 5942756,
    "Lng": 2462931,
    "Stops": "00311-1,00601-1",
    "Name": "Pikaliiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00311-1",
    "SiriID": 13430,
    "Lat": 5942825,
    "Lng": 2462769,
    "Stops": "00501-1,00302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00300-1",
    "SiriID": 4131,
    "Lat": 5942917,
    "Lng": 2462579,
    "Stops": "00401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00301-1",
    "SiriID": 808,
    "Lat": 5943165,
    "Lng": 2462185,
    "Stops": "00299-1,00404-1",
    "Name": "Tuleraua",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00299-1",
    "SiriID": 4132,
    "Lat": 5943189,
    "Lng": 2462175,
    "Stops": "00301-1,00303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00801-1",
    "SiriID": 823,
    "Lat": 5941683,
    "Lng": 2465260,
    "Stops": "02113-1",
    "Name": "Karikakra",
    "Info": "",
    "Street": "Õismäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00802-1",
    "SiriID": 824,
    "Lat": 5941821,
    "Lng": 2465122,
    "Stops": "02112-1,02115-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01401-1",
    "SiriID": 837,
    "Lat": 5941122,
    "Lng": 2464768,
    "Stops": "01701-1,01301-1,01606-1",
    "Name": "Kullerkupu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01701-1",
    "SiriID": 848,
    "Lat": 5941102,
    "Lng": 2464775,
    "Stops": "01401-1,03506-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01001-1",
    "SiriID": 830,
    "Lat": 5941576,
    "Lng": 2463969,
    "Stops": "01002-1,01102-1",
    "Name": "Meelespea",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01002-1",
    "SiriID": 831,
    "Lat": 5941646,
    "Lng": 2463966,
    "Stops": "01001-1,01003-1,00902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01402-1",
    "SiriID": 5846,
    "Lat": 5941553,
    "Lng": 2465547,
    "Stops": "01403-1,01302-1",
    "Name": "Meistri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01403-1",
    "SiriID": 5847,
    "Lat": 5941542,
    "Lng": 2465624,
    "Stops": "01402-1,00310-1,02102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01301-1",
    "SiriID": 835,
    "Lat": 5941328,
    "Lng": 2465295,
    "Stops": "01302-1,01401-1",
    "Name": "Nurmenuku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01302-1",
    "SiriID": 836,
    "Lat": 5941402,
    "Lng": 2465407,
    "Stops": "01301-1,01402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01004-1",
    "SiriID": 240,
    "Lat": 5942046,
    "Lng": 2464684,
    "Stops": "00903-1,00911-1,02114-1",
    "Name": "Päevalille",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01102-1",
    "SiriID": 833,
    "Lat": 5941375,
    "Lng": 2464023,
    "Stops": "01103-1,01001-1",
    "Name": "Rukkilille",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01103-1",
    "SiriID": 834,
    "Lat": 5941339,
    "Lng": 2464028,
    "Stops": "01102-1,01606-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00901-1",
    "SiriID": 825,
    "Lat": 5941944,
    "Lng": 2464545,
    "Stops": "00911-1,00902-2",
    "Name": "Sinilille",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00911-1",
    "SiriID": 826,
    "Lat": 5941945,
    "Lng": 2464590,
    "Stops": "00901-1,01004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00902-1",
    "SiriID": 827,
    "Lat": 5941922,
    "Lng": 2464261,
    "Stops": "00902-2,01002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00903-1",
    "SiriID": 828,
    "Lat": 5942043,
    "Lng": 2464478,
    "Stops": "00902-2,01004-1,00602-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00902-2",
    "SiriID": 13011,
    "Lat": 5941983,
    "Lng": 2464419,
    "Stops": "00903-1,00901-1,00902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "00604-1",
    "SiriID": 9659,
    "Lat": 5941650,
    "Lng": 2463590,
    "Stops": "01003-1,01104-1",
    "Name": "Sõudebaasi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01003-1",
    "SiriID": 9660,
    "Lat": 5941743,
    "Lng": 2463693,
    "Stops": "00604-1,01002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01101-1",
    "SiriID": 832,
    "Lat": 5941281,
    "Lng": 2463785,
    "Stops": "01610-1,01606-1",
    "Name": "Väike-Õismäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01606-1",
    "SiriID": 844,
    "Lat": 5941224,
    "Lng": 2463969,
    "Stops": "01101-1,01103-1,01401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01104-1",
    "SiriID": 13089,
    "Lat": 5941319,
    "Lng": 2463446,
    "Stops": "01610-1,00604-1",
    "Name": "Väike-Õismäe parkla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01610-1",
    "SiriID": 13090,
    "Lat": 5941275,
    "Lng": 2463655,
    "Stops": "01101-1,01104-1,01601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "id123360",
    "SiriID": 30,
    "Lat": 5957134,
    "Lng": 2475791,
    "Stops": "23637-1",
    "Name": "Aegna sadam",
    "Info": "",
    "Street": 0,
    "Area": "Tallinna linn, Kesklinn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21219-1",
    "SiriID": 4334,
    "Lat": 5943342,
    "Lng": 2475064,
    "Stops": "21218-1,21204-1,12002-1",
    "Name": "Estonia",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21218-1",
    "SiriID": 10379,
    "Lat": 5943328,
    "Lng": 2475070,
    "Stops": "21219-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10610-1",
    "SiriID": 33,
    "Lat": 5944785,
    "Lng": 2474997,
    "Stops": "10608-1,10604-1",
    "Name": "Kalasadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "50020-3",
    "SiriID": 3098,
    "Lat": 5943551,
    "Lng": 2475507,
    "Stops": "21206-2,21211-1,21208-1,21201-1",
    "Name": "Kaubamaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11312-1",
    "SiriID": 11,
    "Lat": 5942039,
    "Lng": 2473866,
    "Stops": "11313-1,11202-1",
    "Name": "Kitseküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11313-1",
    "SiriID": 12,
    "Lat": 5941995,
    "Lng": 2474018,
    "Stops": "11307-1,11312-1,07408-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12216-1",
    "SiriID": 1311,
    "Lat": 5943652,
    "Lng": 2476064,
    "Stops": "12217-1,12213-1,12211-2",
    "Name": "Maneeži",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12217-1",
    "SiriID": 1312,
    "Lat": 5943605,
    "Lng": 2476041,
    "Stops": "12216-1,12218-1,12220-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12213-1",
    "SiriID": 1307,
    "Lat": 5943720,
    "Lng": 2476100,
    "Stops": "12214-2,12216-1",
    "Name": "Pronksi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12215-1",
    "SiriID": 1310,
    "Lat": 5943721,
    "Lng": 2476219,
    "Stops": "12214-1,12212-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07511-1",
    "SiriID": 34124,
    "Lat": 5941288,
    "Lng": 2474595,
    "Stops": "07512-1,07407-1",
    "Name": "Tallinn-Väike",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07512-1",
    "SiriID": 34251,
    "Lat": 5941312,
    "Lng": 2474623,
    "Stops": "07511-1,07501-1,07301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11307-1",
    "SiriID": 1253,
    "Lat": 5942023,
    "Lng": 2474053,
    "Stops": "11313-1,11311-1",
    "Name": "Vana-Lõuna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11309-1",
    "SiriID": 1255,
    "Lat": 5942164,
    "Lng": 2474450,
    "Stops": "11311-2,11306-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11311-1",
    "SiriID": 1256,
    "Lat": 5942164,
    "Lng": 2474394,
    "Stops": "11311-2,11304-1,11307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11311-2",
    "SiriID": 3312,
    "Lat": 5942148,
    "Lng": 2474421,
    "Stops": "11311-1,11309-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07506-1",
    "SiriID": 72,
    "Lat": 5941919,
    "Lng": 2475413,
    "Stops": "12909-1,13201-1",
    "Name": "Varre",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11202-1",
    "SiriID": 1244,
    "Lat": 5942130,
    "Lng": 2473715,
    "Stops": "11312-1,06904-1",
    "Name": "Virmalise",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12201-1",
    "SiriID": 1295,
    "Lat": 5943617,
    "Lng": 2475760,
    "Stops": "21209-2,21209-1",
    "Name": "A. Laikmaa",
    "Info": "",
    "Street": "A.Laikmaa tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12203-1",
    "SiriID": 1297,
    "Lat": 5943671,
    "Lng": 2475754,
    "Stops": "21209-2,12222-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21209-2",
    "SiriID": 1636,
    "Lat": 5943642,
    "Lng": 2475725,
    "Stops": "21215-1,12201-1,12203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21209-1",
    "SiriID": 3603,
    "Lat": 5943591,
    "Lng": 2475726,
    "Stops": "21212-1,12201-1,12220-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21221-1",
    "SiriID": 10402,
    "Lat": 5943509,
    "Lng": 2475776,
    "Stops": "12305-1,21223-1,21220-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21223-1",
    "SiriID": 6056,
    "Lat": 5943454,
    "Lng": 2475786,
    "Stops": "21221-1,21222-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13101-1",
    "SiriID": 1352,
    "Lat": 5943075,
    "Lng": 2475874,
    "Stops": "12301-1,13102-1",
    "Name": "Hotell Olümpia",
    "Info": "",
    "Street": "A.Lauteri tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11901-1",
    "SiriID": 1276,
    "Lat": 5943865,
    "Lng": 2478353,
    "Stops": "11801-1,11902-1,11508-1",
    "Name": "Kadriorg",
    "Info": "",
    "Street": "A.Weizenbergi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11902-1",
    "SiriID": 1277,
    "Lat": 5943874,
    "Lng": 2477736,
    "Stops": "11903-2,11901-1,11501-1",
    "Name": "L. Koidula",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12501-1",
    "SiriID": 1325,
    "Lat": 5942576,
    "Lng": 2474817,
    "Stops": "12502-1,11308-1,12607-1",
    "Name": "Veerenni",
    "Info": "",
    "Street": "DP plats",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11201-1",
    "SiriID": 1243,
    "Lat": 5942880,
    "Lng": 2473160,
    "Stops": "11102-1,11103-1",
    "Name": "Koidu",
    "Info": "",
    "Street": "Endla tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11103-1",
    "SiriID": 1242,
    "Lat": 5943022,
    "Lng": 2473629,
    "Stops": "12405-1,11201-1",
    "Name": "Tõnismägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21201-1",
    "SiriID": 1627,
    "Lat": 5943460,
    "Lng": 2475235,
    "Stops": "21203-2,21207-1,50020-3",
    "Name": "Estonia",
    "Info": "",
    "Street": "Estonia pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12001-1",
    "SiriID": 1280,
    "Lat": 5943281,
    "Lng": 2474713,
    "Stops": "12002-1",
    "Name": "Vabaduse väljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12002-1",
    "SiriID": 1281,
    "Lat": 5943299,
    "Lng": 2474721,
    "Stops": "12001-1,12002-2,21219-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12002-2",
    "SiriID": 1282,
    "Lat": 5943304,
    "Lng": 2474642,
    "Stops": "12007-1,12002-1,12006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12901-1",
    "SiriID": 1341,
    "Lat": 5942683,
    "Lng": 2477097,
    "Stops": "11601-1,12903-1,12902-1",
    "Name": "Bussijaam",
    "Info": "",
    "Street": "Filtri tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12902-1",
    "SiriID": 1342,
    "Lat": 5942284,
    "Lng": 2477095,
    "Stops": "12907-1,12905-1,12901-1",
    "Name": "Juhkentali",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12907-1",
    "SiriID": 14340,
    "Lat": 5942126,
    "Lng": 2476924,
    "Stops": "13007-1,12902-1,12908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12909-1",
    "SiriID": 4274,
    "Lat": 5941906,
    "Lng": 2475412,
    "Stops": "07506-1,13303-1,12911-1",
    "Name": "Varre",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12906-1",
    "SiriID": 14384,
    "Lat": 5942122,
    "Lng": 2476395,
    "Stops": "12908-1",
    "Name": "Veskiposti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12908-1",
    "SiriID": 14385,
    "Lat": 5942084,
    "Lng": 2476441,
    "Stops": "12906-1,12907-1,12910-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11507-1",
    "SiriID": 5926,
    "Lat": 5943219,
    "Lng": 2478226,
    "Stops": "11508-1,11506-1",
    "Name": "Vesivärava",
    "Info": "",
    "Street": "Gonsiori",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11508-1",
    "SiriID": 5927,
    "Lat": 5943253,
    "Lng": 2478202,
    "Stops": "11507-1,11501-1,11901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11401-1",
    "SiriID": 1257,
    "Lat": 5943435,
    "Lng": 2476788,
    "Stops": "11403-1,11402-1,12308-1",
    "Name": "Gonsiori",
    "Info": "",
    "Street": "Gonsiori tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11402-1",
    "SiriID": 1258,
    "Lat": 5943398,
    "Lng": 2476995,
    "Stops": "11705-2,11401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12220-3",
    "SiriID": 1314,
    "Lat": 5943548,
    "Lng": 2475959,
    "Stops": "12305-3,12220-2,12217-1",
    "Name": "Kivisilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12305-1",
    "SiriID": 1318,
    "Lat": 5943535,
    "Lng": 2475839,
    "Stops": "12305-2,12220-1,21221-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12305-2",
    "SiriID": 1319,
    "Lat": 5943531,
    "Lng": 2475867,
    "Stops": "12305-1,12220-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12220-2",
    "SiriID": 3102,
    "Lat": 5943555,
    "Lng": 2475897,
    "Stops": "12305-2,12220-3,12220-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12220-1",
    "SiriID": 3103,
    "Lat": 5943563,
    "Lng": 2475830,
    "Stops": "12305-1,12220-2,21209-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12305-3",
    "SiriID": 10403,
    "Lat": 5943517,
    "Lng": 2475970,
    "Stops": "12220-3,12307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11501-1",
    "SiriID": 1259,
    "Lat": 5943315,
    "Lng": 2477774,
    "Stops": "11502-1,11508-1,11902-1",
    "Name": "Laulupeo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11502-1",
    "SiriID": 1260,
    "Lat": 5943307,
    "Lng": 2477711,
    "Stops": "11501-1,11504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12204-1",
    "SiriID": 1298,
    "Lat": 5943750,
    "Lng": 2475700,
    "Stops": "12207-1,12209-1",
    "Name": "Hobujaama",
    "Info": "",
    "Street": "Hobujaama tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12206-1",
    "SiriID": 1300,
    "Lat": 5943802,
    "Lng": 2475741,
    "Stops": "12207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12207-1",
    "SiriID": 1301,
    "Lat": 5943761,
    "Lng": 2475733,
    "Stops": "12204-1,12206-1,12221-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11403-1",
    "SiriID": 3724,
    "Lat": 5943342,
    "Lng": 2476782,
    "Stops": "11404-1,11705-2,11401-1",
    "Name": "Gonsiori",
    "Info": "",
    "Street": "J.Kunderi",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11404-1",
    "SiriID": 4594,
    "Lat": 5943332,
    "Lng": 2476771,
    "Stops": "11403-1,11702-2,12302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11705-1",
    "SiriID": 11452,
    "Lat": 5943288,
    "Lng": 2476996,
    "Stops": "11705-2,11701-2",
    "Name": "Keskturg",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11705-2",
    "SiriID": 11453,
    "Lat": 5943326,
    "Lng": 2476887,
    "Stops": "11403-1,11705-1,11402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11503-1",
    "SiriID": 4592,
    "Lat": 5943090,
    "Lng": 2477591,
    "Stops": "11504-1,11603-1,11701-1",
    "Name": "Laulupeo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11504-1",
    "SiriID": 4593,
    "Lat": 5943099,
    "Lng": 2477608,
    "Stops": "11503-1,11502-1,11505-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11801-1",
    "SiriID": 1272,
    "Lat": 5944012,
    "Lng": 2478559,
    "Stops": "11803-1,11901-1",
    "Name": "J. Poska",
    "Info": "",
    "Street": "J.Poska tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11601-1",
    "SiriID": 1261,
    "Lat": 5942679,
    "Lng": 2477312,
    "Stops": "11611-1,12901-1",
    "Name": "Bussijaam",
    "Info": "",
    "Street": "Juhkentali tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13102-1",
    "SiriID": 1353,
    "Lat": 5942893,
    "Lng": 2475874,
    "Stops": "13101-1,12504-1,12904-1",
    "Name": "Hotell Olümpia",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12903-1",
    "SiriID": 1343,
    "Lat": 5942793,
    "Lng": 2476540,
    "Stops": "12904-1,12901-1,11706-1",
    "Name": "Püssirohu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12904-1",
    "SiriID": 1344,
    "Lat": 5942781,
    "Lng": 2476492,
    "Stops": "12903-1,13102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13601-1",
    "SiriID": 2300,
    "Lat": 5940628,
    "Lng": 2481604,
    "Stops": "13604-1",
    "Name": "Mõigu",
    "Info": "",
    "Street": "Juhtme tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12905-1",
    "SiriID": 1345,
    "Lat": 5942161,
    "Lng": 2477381,
    "Stops": "13007-1,12902-1",
    "Name": "Filtri tee",
    "Info": "",
    "Street": "Järvevana tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13007-1",
    "SiriID": 1351,
    "Lat": 5942074,
    "Lng": 2477166,
    "Stops": "12907-1,12905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13009-1",
    "SiriID": 4052,
    "Lat": 5942263,
    "Lng": 2478577,
    "Stops": "13401-1",
    "Name": "Järvevana",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07504-1",
    "SiriID": 70,
    "Lat": 5941449,
    "Lng": 2476057,
    "Stops": "07505-1,12910-1",
    "Name": "Renniotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07505-1",
    "SiriID": 71,
    "Lat": 5941296,
    "Lng": 2475794,
    "Stops": "07504-1,07503-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13604-1",
    "SiriID": 1372,
    "Lat": 5940802,
    "Lng": 2481109,
    "Stops": "13605-1,13601-1",
    "Name": "Kaabli",
    "Info": "",
    "Street": "Kaabli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13605-1",
    "SiriID": 1373,
    "Lat": 5940794,
    "Lng": 2481090,
    "Stops": "13604-1,13602-1,23151-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13602-1",
    "SiriID": 154,
    "Lat": 5940821,
    "Lng": 2480799,
    "Stops": "13304-1,13605-1",
    "Name": "Kanali tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12605-1",
    "SiriID": 1333,
    "Lat": 5943148,
    "Lng": 2473952,
    "Stops": "12003-3,12405-1,12604-1",
    "Name": "Tõnismägi",
    "Info": "",
    "Street": "Kaarli pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12003-1",
    "SiriID": 1283,
    "Lat": 5943251,
    "Lng": 2474267,
    "Stops": "12003-2,12402-1",
    "Name": "Vabaduse väljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12003-2",
    "SiriID": 1284,
    "Lat": 5943235,
    "Lng": 2474206,
    "Stops": "12003-1,12003-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12003-3",
    "SiriID": 1285,
    "Lat": 5943217,
    "Lng": 2474137,
    "Stops": "12003-2,12605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21206-1",
    "SiriID": 1631,
    "Lat": 5943437,
    "Lng": 2475576,
    "Stops": "21206-3,21203-1",
    "Name": "Kaubamaja",
    "Info": "",
    "Street": "Kaubamaja tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21206-2",
    "SiriID": 1632,
    "Lat": 5943500,
    "Lng": 2475569,
    "Stops": "21220-1,50020-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21206-3",
    "SiriID": 1633,
    "Lat": 5943456,
    "Lng": 2475575,
    "Stops": "21222-1,21206-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21220-1",
    "SiriID": 3610,
    "Lat": 5943477,
    "Lng": 2475606,
    "Stops": "21222-1,21206-2,21221-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21222-1",
    "SiriID": 14902,
    "Lat": 5943454,
    "Lng": 2475610,
    "Stops": "21206-3,21220-1,21223-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11505-1",
    "SiriID": 5844,
    "Lat": 5943076,
    "Lng": 2478126,
    "Stops": "11506-1,11504-1,11608-1",
    "Name": "J.Vilmsi",
    "Info": "",
    "Street": "Laagna tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11506-1",
    "SiriID": 5845,
    "Lat": 5943145,
    "Lng": 2478381,
    "Stops": "11507-1,11505-1,14804-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11611-1",
    "SiriID": 33183,
    "Lat": 5942712,
    "Lng": 2477374,
    "Stops": "11601-1,11602-1",
    "Name": "Tallinna bussijaam",
    "Info": "",
    "Street": "Lastekodu tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07501-1",
    "SiriID": 1089,
    "Lat": 5941335,
    "Lng": 2474839,
    "Stops": "07512-1,07502-1",
    "Name": "Tallinn-Väike",
    "Info": "",
    "Street": "Leete tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12301-1",
    "SiriID": 1315,
    "Lat": 5943102,
    "Lng": 2476046,
    "Stops": "13103-1,13101-1",
    "Name": "Hotell Olümpia",
    "Info": "",
    "Street": "Liivalaia tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13103-1",
    "SiriID": 1354,
    "Lat": 5943111,
    "Lng": 2476120,
    "Stops": "12301-1,12309-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12504-1",
    "SiriID": 1328,
    "Lat": 5942880,
    "Lng": 2475253,
    "Stops": "12503-1,13102-1",
    "Name": "Keskhaigla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12503-1",
    "SiriID": 1327,
    "Lat": 5942806,
    "Lng": 2474938,
    "Stops": "12607-1,12504-1",
    "Name": "Liivalaia",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11608-1",
    "SiriID": 4319,
    "Lat": 5942767,
    "Lng": 2477861,
    "Stops": "11610-1,11505-1",
    "Name": "Bussijaam",
    "Info": "",
    "Street": "Lubja tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11610-1",
    "SiriID": 11608,
    "Lat": 5942752,
    "Lng": 2477859,
    "Stops": "11608-1,11607-1,11605-2",
    "Name": "Lubja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07502-1",
    "SiriID": 1090,
    "Lat": 5941278,
    "Lng": 2475255,
    "Stops": "07501-1,07503-1",
    "Name": "Auru",
    "Info": "",
    "Street": "Luite tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07503-1",
    "SiriID": 1091,
    "Lat": 5941486,
    "Lng": 2475517,
    "Stops": "07508-1,07505-1,07502-1",
    "Name": "Luite",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10508-1",
    "SiriID": 4590,
    "Lat": 5944106,
    "Lng": 2475323,
    "Stops": "10507-1,10607-1",
    "Name": "Kanuti",
    "Info": "",
    "Street": "Mere pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12103-1",
    "SiriID": 1293,
    "Lat": 5943762,
    "Lng": 2475321,
    "Stops": "12104-1",
    "Name": "Mere puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12104-1",
    "SiriID": 1294,
    "Lat": 5943756,
    "Lng": 2475337,
    "Stops": "12103-1,12210-1,21208-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12209-1",
    "SiriID": 1303,
    "Lat": 5943831,
    "Lng": 2475407,
    "Stops": "12210-1,12204-1,10507-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12210-1",
    "SiriID": 1304,
    "Lat": 5943782,
    "Lng": 2475364,
    "Stops": "12104-1,12209-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10607-1",
    "SiriID": 9119,
    "Lat": 5944215,
    "Lng": 2475297,
    "Stops": "10508-1,10601-1",
    "Name": "Merekeskus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12803-1",
    "SiriID": 4077,
    "Lat": 5944326,
    "Lng": 2480566,
    "Stops": "12802-1,15113-1,17801-1",
    "Name": "Oruvärava",
    "Info": "",
    "Street": "Narva maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10702-1",
    "SiriID": 1230,
    "Lat": 5943830,
    "Lng": 2476981,
    "Stops": "10702-2,12308-1,10701-2",
    "Name": "F. R. Kreutzwaldi",
    "Info": "",
    "Street": "Narva mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10702-2",
    "SiriID": 1231,
    "Lat": 5943839,
    "Lng": 2477034,
    "Stops": "10702-1,10704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12211-1",
    "SiriID": 1305,
    "Lat": 5943711,
    "Lng": 2475862,
    "Stops": "12211-2,12222-1",
    "Name": "Hobujaama",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12211-2",
    "SiriID": 3402,
    "Lat": 5943711,
    "Lng": 2475866,
    "Stops": "12211-1,12216-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12223-1",
    "SiriID": 3604,
    "Lat": 5943726,
    "Lng": 2475833,
    "Stops": "12222-1,12221-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12222-1",
    "SiriID": 3605,
    "Lat": 5943723,
    "Lng": 2475838,
    "Stops": "12223-1,12211-1,12203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12221-1",
    "SiriID": 3606,
    "Lat": 5943740,
    "Lng": 2475819,
    "Stops": "12223-1,12207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11802-1",
    "SiriID": 1273,
    "Lat": 5944097,
    "Lng": 2478642,
    "Stops": "11803-1",
    "Name": "J. Poska",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11803-2",
    "SiriID": 1274,
    "Lat": 5944098,
    "Lng": 2478701,
    "Stops": "11803-1,12801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11803-1",
    "SiriID": 1275,
    "Lat": 5944083,
    "Lng": 2478666,
    "Stops": "11802-1,11803-2,11801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12801-1",
    "SiriID": 1339,
    "Lat": 5944355,
    "Lng": 2480015,
    "Stops": "12802-1,11803-2",
    "Name": "Oru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12802-1",
    "SiriID": 1340,
    "Lat": 5944335,
    "Lng": 2480241,
    "Stops": "12801-1,12803-1,12702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12212-1",
    "SiriID": 1306,
    "Lat": 5943746,
    "Lng": 2476375,
    "Stops": "12308-1,12215-1",
    "Name": "Pronksi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12214-1",
    "SiriID": 1308,
    "Lat": 5943712,
    "Lng": 2476218,
    "Stops": "12215-1,12214-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12214-2",
    "SiriID": 1309,
    "Lat": 5943711,
    "Lng": 2476158,
    "Stops": "12214-1,12213-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10703-1",
    "SiriID": 1232,
    "Lat": 5943850,
    "Lng": 2477155,
    "Stops": "10704-1,11903-1",
    "Name": "Tallinna Ülikool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10704-1",
    "SiriID": 1233,
    "Lat": 5943843,
    "Lng": 2477141,
    "Stops": "10703-1,10702-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11903-2",
    "SiriID": 1278,
    "Lat": 5943910,
    "Lng": 2477716,
    "Stops": "11902-1,11903-1",
    "Name": "Uus-Sadama",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11903-1",
    "SiriID": 1279,
    "Lat": 5943901,
    "Lng": 2477643,
    "Stops": "11903-2,10703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11602-1",
    "SiriID": 1262,
    "Lat": 5942796,
    "Lng": 2477531,
    "Stops": "11604-1,11611-1",
    "Name": "Bussijaam",
    "Info": "",
    "Street": "Odra tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10901-1",
    "SiriID": 1236,
    "Lat": 5943491,
    "Lng": 2473149,
    "Stops": "10903-2,11003-1",
    "Name": "Hotell Tallinn",
    "Info": "",
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11001-1",
    "SiriID": 1237,
    "Lat": 5943460,
    "Lng": 2472604,
    "Stops": "11002-1,11003-1",
    "Name": "Tehnika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12701-1",
    "SiriID": 1335,
    "Lat": 5944839,
    "Lng": 2480297,
    "Stops": "12702-1,12703-1",
    "Name": "Lauluväljak",
    "Info": "",
    "Street": "Pirita tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12702-1",
    "SiriID": 1336,
    "Lat": 5944816,
    "Lng": 2480330,
    "Stops": "12701-1,12802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12703-1",
    "SiriID": 1337,
    "Lat": 5945177,
    "Lng": 2480693,
    "Stops": "12704-1,12701-1",
    "Name": "Maarjamägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12704-1",
    "SiriID": 1338,
    "Lat": 5945293,
    "Lng": 2480824,
    "Stops": "12703-1,12706-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12706-1",
    "SiriID": 5921,
    "Lat": 5945648,
    "Lng": 2481028,
    "Stops": "12705-1,12704-1",
    "Name": "Mälestusvälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12308-1",
    "SiriID": 1321,
    "Lat": 5943686,
    "Lng": 2476449,
    "Stops": "12212-1,11401-1,10702-1",
    "Name": "Pronksi",
    "Info": "",
    "Street": "Pronksi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10503-1",
    "SiriID": 1223,
    "Lat": 5944347,
    "Lng": 2475011,
    "Stops": "10602-1,10603-1,10605-1",
    "Name": "Linnahall",
    "Info": "",
    "Street": "Põhja pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07404-1",
    "SiriID": 1082,
    "Lat": 5940608,
    "Lng": 2473291,
    "Stops": "07405-1,07403-1",
    "Name": "Hallivanamehe",
    "Info": "",
    "Street": "Pärnu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07405-1",
    "SiriID": 1083,
    "Lat": 5940564,
    "Lng": 2473206,
    "Stops": "07404-1,07207-1,06803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06801-1",
    "SiriID": 1053,
    "Lat": 5939998,
    "Lng": 2472803,
    "Stops": "06803-1,06805-1,06802-1",
    "Name": "Järve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06803-1",
    "SiriID": 10470,
    "Lat": 5940162,
    "Lng": 2472831,
    "Stops": "06804-1,06801-1,07405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07401-1",
    "SiriID": 1079,
    "Lat": 5940968,
    "Lng": 2473665,
    "Stops": "07304-1,07402-1",
    "Name": "Kalev",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07402-1",
    "SiriID": 1080,
    "Lat": 5940982,
    "Lng": 2473606,
    "Stops": "07401-1,07403-1,07406-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07403-1",
    "SiriID": 1081,
    "Lat": 5940921,
    "Lng": 2473565,
    "Stops": "07402-1,07404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07304-1",
    "SiriID": 237,
    "Lat": 5940966,
    "Lng": 2473701,
    "Stops": "07401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12601-1",
    "SiriID": 1329,
    "Lat": 5942908,
    "Lng": 2474352,
    "Stops": "12602-1,12005-1",
    "Name": "Kosmos",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12602-1",
    "SiriID": 1330,
    "Lat": 5942889,
    "Lng": 2474334,
    "Stops": "12604-1,12601-1,12607-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12603-1",
    "SiriID": 1331,
    "Lat": 5942823,
    "Lng": 2474232,
    "Stops": "12606-1,12604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12604-1",
    "SiriID": 1332,
    "Lat": 5942895,
    "Lng": 2474310,
    "Stops": "12602-1,12603-1,12605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12606-1",
    "SiriID": 238,
    "Lat": 5942797,
    "Lng": 2474271,
    "Stops": "12603-1,11207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07301-1",
    "SiriID": 1076,
    "Lat": 5941495,
    "Lng": 2473992,
    "Stops": "07408-1,07407-1,07512-1",
    "Name": "Tallinn-Väike",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07407-1",
    "SiriID": 1086,
    "Lat": 5941444,
    "Lng": 2473943,
    "Stops": "07303-1,07301-1,07511-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07408-1",
    "SiriID": 1087,
    "Lat": 5941544,
    "Lng": 2473956,
    "Stops": "07301-1,11313-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12004-1",
    "SiriID": 1286,
    "Lat": 5943388,
    "Lng": 2474661,
    "Stops": "12403-1,12007-1",
    "Name": "Vabaduse väljak",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12005-1",
    "SiriID": 1287,
    "Lat": 5943202,
    "Lng": 2474585,
    "Stops": "12006-1,12601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12006-1",
    "SiriID": 1288,
    "Lat": 5943271,
    "Lng": 2474588,
    "Stops": "12002-2,12005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12007-1",
    "SiriID": 1289,
    "Lat": 5943322,
    "Lng": 2474579,
    "Stops": "12002-2,12004-1,12401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12403-1",
    "SiriID": 1324,
    "Lat": 5943396,
    "Lng": 2474633,
    "Stops": "12004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11301-1",
    "SiriID": 1247,
    "Lat": 5942374,
    "Lng": 2474287,
    "Stops": "11305-1,11303-1,11306-1",
    "Name": "Vineeri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11302-1",
    "SiriID": 1248,
    "Lat": 5942466,
    "Lng": 2474269,
    "Stops": "11302-2,11207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11303-1",
    "SiriID": 1249,
    "Lat": 5942288,
    "Lng": 2474234,
    "Stops": "11304-1,11301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11304-1",
    "SiriID": 1250,
    "Lat": 5942232,
    "Lng": 2474239,
    "Stops": "11303-1,11311-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11305-1",
    "SiriID": 1251,
    "Lat": 5942456,
    "Lng": 2474263,
    "Stops": "11302-2,11301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11302-2",
    "SiriID": 5703,
    "Lat": 5942463,
    "Lng": 2474269,
    "Stops": "11302-1,11305-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12101-1",
    "SiriID": 1290,
    "Lat": 5943618,
    "Lng": 2475149,
    "Stops": "12101-2,21208-1",
    "Name": "Viru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12101-2",
    "SiriID": 1291,
    "Lat": 5943606,
    "Lng": 2475113,
    "Stops": "12101-1,21207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12102-1",
    "SiriID": 1292,
    "Lat": 5943547,
    "Lng": 2474967,
    "Stops": "12101-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21207-1",
    "SiriID": 1634,
    "Lat": 5943576,
    "Lng": 2475095,
    "Stops": "12101-3,12101-2,21201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21208-1",
    "SiriID": 1635,
    "Lat": 5943630,
    "Lng": 2475226,
    "Stops": "12101-1,12104-1,50020-3",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12101-3",
    "SiriID": 4341,
    "Lat": 5943580,
    "Lng": 2475036,
    "Stops": "21207-1,12102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06802-1",
    "SiriID": 1054,
    "Lat": 5939767,
    "Lng": 2472609,
    "Stops": "06801-1,06402-1",
    "Name": "Virve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10507-1",
    "SiriID": 4589,
    "Lat": 5944077,
    "Lng": 2475330,
    "Stops": "10508-1,12209-1",
    "Name": "Kanuti",
    "Info": "",
    "Street": "Rannamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10601-1",
    "SiriID": 1225,
    "Lat": 5944332,
    "Lng": 2475033,
    "Stops": "10602-1,10607-1",
    "Name": "Linnahall",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10602-1",
    "SiriID": 1226,
    "Lat": 5944342,
    "Lng": 2475007,
    "Stops": "10503-1,10601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12307-1",
    "SiriID": 5501,
    "Lat": 5943373,
    "Lng": 2476063,
    "Stops": "12309-1,12305-3",
    "Name": "Tornimäe",
    "Info": "",
    "Street": "Rävala pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12309-1",
    "SiriID": 40,
    "Lat": 5943319,
    "Lng": 2476136,
    "Stops": "12303-1,12307-1,13103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10500-1",
    "SiriID": 5870,
    "Lat": 5944390,
    "Lng": 2475488,
    "Stops": "10603-2,10606-1",
    "Name": "Linnahall",
    "Info": "",
    "Street": "Sadama",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "id33185",
    "SiriID": 33185,
    "Lat": 5944470,
    "Lng": 2476127,
    "Stops": "id33184,10606-1",
    "Name": "Tallinna reisisadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "id33184",
    "SiriID": 33184,
    "Lat": 5944471,
    "Lng": 2476192,
    "Stops": "id33185,10701-4",
    "Name": "Tallinna sadama a-Term.",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10606-1",
    "SiriID": 1228,
    "Lat": 5944461,
    "Lng": 2476014,
    "Stops": "10609-1,id33185,10500-1",
    "Name": "Reisisadam",
    "Info": "",
    "Street": "Sadama tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10609-1",
    "SiriID": 13603,
    "Lat": 5944421,
    "Lng": 2476061,
    "Stops": "10606-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11102-1",
    "SiriID": 1241,
    "Lat": 5942919,
    "Lng": 2472950,
    "Stops": "11201-1,11101-1,11203-1",
    "Name": "Koidu",
    "Info": "",
    "Street": "Suur-Ameerika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13603-1",
    "SiriID": 155,
    "Lat": 5940837,
    "Lng": 2480627,
    "Stops": "13304-1,13606-1",
    "Name": "Kanali tee",
    "Info": "",
    "Street": "Tartu maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13304-1",
    "SiriID": 3220,
    "Lat": 5940778,
    "Lng": 2480649,
    "Stops": "13603-1,13602-1,23116-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "43168-1",
    "SiriID": 206,
    "Lat": 5940266,
    "Lng": 2481273,
    "Stops": "23152-1,43164-1",
    "Name": "Oomi",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11603-1",
    "SiriID": 1263,
    "Lat": 5942891,
    "Lng": 2477532,
    "Stops": "11605-1,11604-1,11503-1",
    "Name": "Autobussijaam - ei kasuta",
    "Info": 0,
    "Street": "Tartu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11605-1",
    "SiriID": 1265,
    "Lat": 5942880,
    "Lng": 2477509,
    "Stops": "11603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11604-1",
    "SiriID": 1264,
    "Lat": 5942857,
    "Lng": 2477622,
    "Stops": "11606-2,11603-1,11602-1",
    "Name": "Bussijaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11606-2",
    "SiriID": 11607,
    "Lat": 5942828,
    "Lng": 2477670,
    "Stops": "11603-2,11604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11605-2",
    "SiriID": 11609,
    "Lat": 5942754,
    "Lng": 2477773,
    "Stops": "11610-1,11603-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11603-2",
    "SiriID": 11610,
    "Lat": 5942816,
    "Lng": 2477695,
    "Stops": "11606-2,11605-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11701-1",
    "SiriID": 1268,
    "Lat": 5943039,
    "Lng": 2477164,
    "Stops": "11703-1,11503-1",
    "Name": "Keskturg",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11702-1",
    "SiriID": 1269,
    "Lat": 5943132,
    "Lng": 2476936,
    "Stops": "11704-1,11703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11703-1",
    "SiriID": 1270,
    "Lat": 5943097,
    "Lng": 2477001,
    "Stops": "11702-1,11701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11704-1",
    "SiriID": 1271,
    "Lat": 5943154,
    "Lng": 2476884,
    "Stops": "11701-2,11702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11707-1",
    "SiriID": 11605,
    "Lat": 5943182,
    "Lng": 2476779,
    "Stops": "11702-2,11706-1,11701-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11702-2",
    "SiriID": 11606,
    "Lat": 5943192,
    "Lng": 2476757,
    "Stops": "11707-1,11404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11706-1",
    "SiriID": 11611,
    "Lat": 5943160,
    "Lng": 2476755,
    "Stops": "11707-1,12903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11701-2",
    "SiriID": 11612,
    "Lat": 5943163,
    "Lng": 2476849,
    "Stops": "11704-1,11707-1,11705-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13516-1",
    "SiriID": 5726,
    "Lat": 5941646,
    "Lng": 2479659,
    "Stops": "13413-3",
    "Name": "Lennujaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13005-1",
    "SiriID": 1349,
    "Lat": 5941963,
    "Lng": 2479161,
    "Stops": "13006-1",
    "Name": "Lindakivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13006-1",
    "SiriID": 1350,
    "Lat": 5941953,
    "Lng": 2479254,
    "Stops": "13005-1,13012-1,13514-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11607-1",
    "SiriID": 1267,
    "Lat": 5942730,
    "Lng": 2477888,
    "Stops": "11610-1,13008-1",
    "Name": "Lubja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23152-1",
    "SiriID": 4430,
    "Lat": 5940434,
    "Lng": 2481063,
    "Stops": "23151-1,43168-1",
    "Name": "Mõigu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23151-1",
    "SiriID": 4431,
    "Lat": 5940489,
    "Lng": 2481052,
    "Stops": "23152-1,13605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12302-1",
    "SiriID": 1316,
    "Lat": 5943354,
    "Lng": 2476246,
    "Stops": "12303-1,11404-1",
    "Name": "Paberi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12303-1",
    "SiriID": 1317,
    "Lat": 5943353,
    "Lng": 2476231,
    "Stops": "12302-1,12309-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13606-1",
    "SiriID": 12742,
    "Lat": 5941124,
    "Lng": 2480213,
    "Stops": "13603-1,13413-1",
    "Name": "Pühamägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13001-1",
    "SiriID": 1346,
    "Lat": 5942531,
    "Lng": 2478336,
    "Stops": "13008-1,14807-1",
    "Name": "Sossimägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13002-1",
    "SiriID": 1347,
    "Lat": 5942448,
    "Lng": 2478432,
    "Stops": "14807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13008-1",
    "SiriID": 1348,
    "Lat": 5942547,
    "Lng": 2478186,
    "Stops": "13001-1,11607-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21203-2",
    "SiriID": 1628,
    "Lat": 5943386,
    "Lng": 2475259,
    "Stops": "21203-1,21217-1,21201-1",
    "Name": "Estonia",
    "Info": "",
    "Street": "Teatri väljak",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21204-1",
    "SiriID": 1629,
    "Lat": 5943352,
    "Lng": 2475195,
    "Stops": "21217-2,21219-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21205-1",
    "SiriID": 1630,
    "Lat": 5943390,
    "Lng": 2475177,
    "Stops": "21217-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21203-1",
    "SiriID": 14969,
    "Lat": 5943368,
    "Lng": 2475282,
    "Stops": "21203-2,21217-3,21206-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-1",
    "SiriID": 4324,
    "Lat": 5943366,
    "Lng": 2475188,
    "Stops": "21217-2,21205-1,21203-2",
    "Name": "Estonia 1",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-2",
    "SiriID": 10378,
    "Lat": 5943356,
    "Lng": 2475201,
    "Stops": "21204-1,21217-3,21217-1",
    "Name": "Estonia 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-3",
    "SiriID": 4325,
    "Lat": 5943349,
    "Lng": 2475216,
    "Stops": "21217-4,21217-2,21203-1",
    "Name": "Estonia 3",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-4",
    "SiriID": 4326,
    "Lat": 5943342,
    "Lng": 2475226,
    "Stops": "21217-3,21217-5",
    "Name": "Estonia 4",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-5",
    "SiriID": 4327,
    "Lat": 5943335,
    "Lng": 2475237,
    "Stops": "21217-4,21217-6",
    "Name": "Estonia 5",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-6",
    "SiriID": 4328,
    "Lat": 5943327,
    "Lng": 2475248,
    "Stops": "21217-7,21217-5",
    "Name": "Estonia 6",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21217-7",
    "SiriID": 10397,
    "Lat": 5943320,
    "Lng": 2475258,
    "Stops": "21217-6",
    "Name": "Estonia 7",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11101-1",
    "SiriID": 1240,
    "Lat": 5942934,
    "Lng": 2472501,
    "Stops": "11102-1,08904-1",
    "Name": "Endla",
    "Info": "",
    "Street": "Tehnika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11203-1",
    "SiriID": 1245,
    "Lat": 5942606,
    "Lng": 2472793,
    "Stops": "11205-1,11102-1",
    "Name": "Lilleküla jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11002-1",
    "SiriID": 1238,
    "Lat": 5943374,
    "Lng": 2472367,
    "Stops": "08910-1,11001-1",
    "Name": "Tehnika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07302-1",
    "SiriID": 1077,
    "Lat": 5941341,
    "Lng": 2473677,
    "Stops": "07409-1,07104-2",
    "Name": "Tallinn-Väike",
    "Info": "",
    "Street": "Tondi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07303-1",
    "SiriID": 1078,
    "Lat": 5941433,
    "Lng": 2473849,
    "Stops": "07409-1,07407-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07409-1",
    "SiriID": 1088,
    "Lat": 5941419,
    "Lng": 2473841,
    "Stops": "07303-1,07302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07406-2",
    "SiriID": 1085,
    "Lat": 5941121,
    "Lng": 2473415,
    "Stops": "07406-1,07411-1,07402-1",
    "Name": "Tondi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10905-1",
    "SiriID": 1772,
    "Lat": 5943306,
    "Lng": 2473439,
    "Stops": "10904-1,12405-1",
    "Name": "A. Adamsoni",
    "Info": "",
    "Street": "Toompuiestee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21102-1",
    "SiriID": 1981,
    "Lat": 5943970,
    "Lng": 2473758,
    "Stops": "21104-1,21103-1,21107-2",
    "Name": "Balti jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21103-1",
    "SiriID": 1982,
    "Lat": 5943988,
    "Lng": 2473786,
    "Stops": "21102-1,21107-1,21105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21104-1",
    "SiriID": 1983,
    "Lat": 5943956,
    "Lng": 2473790,
    "Stops": "21102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10902-1",
    "SiriID": 1769,
    "Lat": 5943682,
    "Lng": 2473333,
    "Stops": "10903-1,21107-2",
    "Name": "Hotell Tallinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10903-1",
    "SiriID": 1770,
    "Lat": 5943547,
    "Lng": 2473280,
    "Stops": "10903-2,10902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10904-1",
    "SiriID": 1771,
    "Lat": 5943427,
    "Lng": 2473352,
    "Stops": "10903-2,10905-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10903-2",
    "SiriID": 14641,
    "Lat": 5943524,
    "Lng": 2473274,
    "Stops": "10903-1,10901-1,10904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12405-1",
    "SiriID": 1773,
    "Lat": 5943210,
    "Lng": 2473629,
    "Stops": "10905-1,12605-1,11103-1",
    "Name": "Tõnismägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10701-1",
    "SiriID": 1229,
    "Lat": 5944283,
    "Lng": 2476493,
    "Stops": "10701-4,10701-3,10701-2",
    "Name": "Reisisadam D-terminal",
    "Info": "",
    "Street": "Uus-Sadama",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10701-2",
    "SiriID": 4097,
    "Lat": 5944266,
    "Lng": 2476545,
    "Stops": "10701-1,10705-1,10702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10701-3",
    "SiriID": 4098,
    "Lat": 5944270,
    "Lng": 2476475,
    "Stops": "10701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10701-4",
    "SiriID": 10102,
    "Lat": 5944283,
    "Lng": 2476494,
    "Stops": "10701-1,id33184",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10705-1",
    "SiriID": 13583,
    "Lat": 5944306,
    "Lng": 2476665,
    "Stops": "10706-1,10701-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10706-1",
    "SiriID": 5983,
    "Lat": 5944310,
    "Lng": 2476675,
    "Stops": "10705-1,id33424",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "id33424",
    "SiriID": 33424,
    "Lat": 5944334,
    "Lng": 2476692,
    "Stops": "10706-1",
    "Name": "Tallinna sadama d-Term.",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12218-1",
    "SiriID": 1313,
    "Lat": 5943560,
    "Lng": 2476103,
    "Stops": "12217-1",
    "Name": "Maneeži",
    "Info": "",
    "Street": "V.Reimani tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12401-1",
    "SiriID": 1322,
    "Lat": 5943321,
    "Lng": 2474426,
    "Stops": "12402-1,12007-1",
    "Name": "Vabaduse väljak",
    "Info": "",
    "Street": "Vabaduse väljak",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12402-1",
    "SiriID": 1323,
    "Lat": 5943323,
    "Lng": 2474397,
    "Stops": "12401-1,12003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11308-1",
    "SiriID": 1254,
    "Lat": 5942492,
    "Lng": 2474769,
    "Stops": "12501-1,13302-1",
    "Name": "Veerenni",
    "Info": "",
    "Street": "Vana-Lõuna tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11306-1",
    "SiriID": 1252,
    "Lat": 5942280,
    "Lng": 2474592,
    "Stops": "11309-1,11301-1,13302-1,13202-1",
    "Name": "Vineeri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07508-1",
    "SiriID": 75,
    "Lat": 5941616,
    "Lng": 2475396,
    "Stops": "12912-1,07503-1",
    "Name": "Veduri",
    "Info": "",
    "Street": "Veerenni",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12607-1",
    "SiriID": 1334,
    "Lat": 5942755,
    "Lng": 2474838,
    "Stops": "12503-1,12501-1,12602-1",
    "Name": "Liivalaia",
    "Info": "",
    "Street": "Veerenni tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13201-1",
    "SiriID": 1355,
    "Lat": 5941964,
    "Lng": 2475139,
    "Stops": "13301-1,13303-1,07506-1",
    "Name": "Varre",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13303-1",
    "SiriID": 1359,
    "Lat": 5941827,
    "Lng": 2475182,
    "Stops": "13201-1,12909-1,07507-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12502-1",
    "SiriID": 1326,
    "Lat": 5942558,
    "Lng": 2474899,
    "Stops": "12501-1",
    "Name": "Veerenni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13302-1",
    "SiriID": 1358,
    "Lat": 5942418,
    "Lng": 2474944,
    "Stops": "11308-1,11306-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13202-1",
    "SiriID": 1356,
    "Lat": 5942117,
    "Lng": 2475080,
    "Stops": "13301-1,11306-1",
    "Name": "Õilme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13301-1",
    "SiriID": 1357,
    "Lat": 5942043,
    "Lng": 2475094,
    "Stops": "13202-1,13201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07507-1",
    "SiriID": 76,
    "Lat": 5941669,
    "Lng": 2475321,
    "Stops": "12912-1,13303-1",
    "Name": "Veduri",
    "Info": "",
    "Street": "Viadukti tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12912-1",
    "SiriID": 4277,
    "Lat": 5941665,
    "Lng": 2475316,
    "Stops": "07507-1,07508-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12910-1",
    "SiriID": 4275,
    "Lat": 5941759,
    "Lng": 2475954,
    "Stops": "12911-1,07504-1,12908-1",
    "Name": "Viadukti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12911-1",
    "SiriID": 4276,
    "Lat": 5941763,
    "Lng": 2475888,
    "Stops": "12910-1,12909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21211-1",
    "SiriID": 3890,
    "Lat": 5943580,
    "Lng": 2475640,
    "Stops": "21212-1,50020-3",
    "Name": "Viru keskus 1",
    "Info": "",
    "Street": "Viru keskus",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21212-1",
    "SiriID": 3891,
    "Lat": 5943595,
    "Lng": 2475667,
    "Stops": "21213-1,21211-1,21209-1",
    "Name": "Viru keskus 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21213-1",
    "SiriID": 3892,
    "Lat": 5943605,
    "Lng": 2475644,
    "Stops": "21212-1,21214-1",
    "Name": "Viru keskus 3",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21214-1",
    "SiriID": 3893,
    "Lat": 5943621,
    "Lng": 2475672,
    "Stops": "21213-1,21215-1",
    "Name": "Viru keskus 4",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21215-1",
    "SiriID": 3894,
    "Lat": 5943647,
    "Lng": 2475669,
    "Stops": "21216-1,21214-1,21209-2",
    "Name": "Viru keskus 5",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21216-1",
    "SiriID": 3895,
    "Lat": 5943667,
    "Lng": 2475665,
    "Stops": "21215-1",
    "Name": "Viru keskus 6",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11204-1",
    "SiriID": 1246,
    "Lat": 5942500,
    "Lng": 2472978,
    "Stops": "11206-1",
    "Name": "Lilleküla jaam",
    "Info": "",
    "Street": "Väike-Ameerika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11207-1",
    "SiriID": 14701,
    "Lat": 5942667,
    "Lng": 2474168,
    "Stops": "12606-1,11302-1",
    "Name": "Uus Maailm",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06805-1",
    "SiriID": 10415,
    "Lat": 5940062,
    "Lng": 2472457,
    "Stops": "06804-1,06801-1,07209-1",
    "Name": "Järve",
    "Info": "",
    "Street": 0,
    "Area": "Tallinna linn, Kristiine",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06804-1",
    "SiriID": 10416,
    "Lat": 5940120,
    "Lng": 2472523,
    "Stops": "06805-1,06803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11205-1",
    "SiriID": 10413,
    "Lat": 5942565,
    "Lng": 2472732,
    "Stops": "11203-1,11206-1",
    "Name": "Lilleküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11206-1",
    "SiriID": 10414,
    "Lat": 5942461,
    "Lng": 2472836,
    "Stops": "11204-1,11205-1,07808-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07410-1",
    "SiriID": 10409,
    "Lat": 5941094,
    "Lng": 2473342,
    "Stops": "07411-1,07103-1",
    "Name": "Tondi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07411-1",
    "SiriID": 10410,
    "Lat": 5941111,
    "Lng": 2473367,
    "Stops": "07410-1,07406-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02604-1",
    "SiriID": 876,
    "Lat": 5940296,
    "Lng": 2470772,
    "Stops": "02603-1,07201-1",
    "Name": "Retke tee",
    "Info": "",
    "Street": "A.H.Tammsaare tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07209-1",
    "SiriID": 1074,
    "Lat": 5940234,
    "Lng": 2471850,
    "Stops": "07210-1,06805-1",
    "Name": "Sõjakooli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07210-1",
    "SiriID": 1075,
    "Lat": 5940249,
    "Lng": 2471723,
    "Stops": "07209-1,07204-1,07201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07905-1",
    "SiriID": 1114,
    "Lat": 5942829,
    "Lng": 2470912,
    "Stops": "08901-1,08903-1",
    "Name": "Hipodroom",
    "Info": "",
    "Street": "Endla tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08901-1",
    "SiriID": 1132,
    "Lat": 5942898,
    "Lng": 2470719,
    "Stops": "08902-1,07905-1,07906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08903-1",
    "SiriID": 1134,
    "Lat": 5942768,
    "Lng": 2471370,
    "Stops": "07905-1,08905-1",
    "Name": "Mooni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08904-1",
    "SiriID": 1135,
    "Lat": 5942747,
    "Lng": 2472195,
    "Stops": "08906-1,07809-1,11101-1",
    "Name": "Taksopark",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08905-1",
    "SiriID": 1136,
    "Lat": 5942777,
    "Lng": 2471901,
    "Stops": "08906-1,08903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08201-1",
    "SiriID": 1119,
    "Lat": 5941694,
    "Lng": 2467974,
    "Stops": "02105-1,08204-1,08203-1",
    "Name": "Laki",
    "Info": "",
    "Street": "Kadaka tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07803-1",
    "SiriID": 1104,
    "Lat": 5942579,
    "Lng": 2471954,
    "Stops": "07805-1,07809-1",
    "Name": "Koskla",
    "Info": "",
    "Street": "Koskla tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06904-1",
    "SiriID": 1058,
    "Lat": 5942106,
    "Lng": 2472964,
    "Stops": "06905-1,06903-1,11202-1",
    "Name": "Kotka",
    "Info": "",
    "Street": "Kotka tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06905-1",
    "SiriID": 1059,
    "Lat": 5942038,
    "Lng": 2472980,
    "Stops": "06904-1,06901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06901-1",
    "SiriID": 1055,
    "Lat": 5941534,
    "Lng": 2473233,
    "Stops": "06901-2,06905-1",
    "Name": "Ööbiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07104-1",
    "SiriID": 1065,
    "Lat": 5941461,
    "Lng": 2473251,
    "Stops": "06901-2,07104-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07104-2",
    "SiriID": 5771,
    "Lat": 5941221,
    "Lng": 2473351,
    "Stops": "07406-1,07302-1,07104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08204-1",
    "SiriID": 1122,
    "Lat": 5942010,
    "Lng": 2468350,
    "Stops": "08206-1,08201-1,08205-1",
    "Name": "Forelli",
    "Info": "",
    "Street": "Laki tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08206-1",
    "SiriID": 1124,
    "Lat": 5942036,
    "Lng": 2468362,
    "Stops": "08204-1,08702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07205-1",
    "SiriID": 1070,
    "Lat": 5941097,
    "Lng": 2471607,
    "Stops": "07001-1,07101-1",
    "Name": "Nõmme tee",
    "Info": "",
    "Street": "Linnu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07706-1",
    "SiriID": 1099,
    "Lat": 5941137,
    "Lng": 2471459,
    "Stops": "07001-1,07707-1,07702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07702-1",
    "SiriID": 1095,
    "Lat": 5941264,
    "Lng": 2470884,
    "Stops": "07703-1,07704-1,07706-1",
    "Name": "Vilja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07703-1",
    "SiriID": 1096,
    "Lat": 5941259,
    "Lng": 2470873,
    "Stops": "07702-1,07709-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07101-1",
    "SiriID": 1062,
    "Lat": 5940910,
    "Lng": 2472482,
    "Stops": "07206-1,07205-1",
    "Name": "Västriku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07206-1",
    "SiriID": 1071,
    "Lat": 5940886,
    "Lng": 2472517,
    "Stops": "07101-1,07207-1,07102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08101-1",
    "SiriID": 1117,
    "Lat": 5941482,
    "Lng": 2469138,
    "Stops": "08202-1,08102-1,07709-1",
    "Name": "Aiandi",
    "Info": "",
    "Street": "Mustamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07906-1",
    "SiriID": 1115,
    "Lat": 5942840,
    "Lng": 2470343,
    "Stops": "08901-1,07902-1,07904-1",
    "Name": "Hipodroom",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08102-1",
    "SiriID": 1118,
    "Lat": 5941759,
    "Lng": 2469394,
    "Stops": "08205-1,08101-1,07705-1",
    "Name": "Löwenruh",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08205-1",
    "SiriID": 1123,
    "Lat": 5941790,
    "Lng": 2469383,
    "Stops": "08102-1,08302-1,08204-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08001-1",
    "SiriID": 1116,
    "Lat": 5942219,
    "Lng": 2469833,
    "Stops": "08302-1,07903-1,07601-1",
    "Name": "Marja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08302-1",
    "SiriID": 1125,
    "Lat": 5942127,
    "Lng": 2469693,
    "Stops": "08001-1,08205-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07903-1",
    "SiriID": 1112,
    "Lat": 5942527,
    "Lng": 2470106,
    "Stops": "07904-1,08001-1",
    "Name": "Välja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07904-1",
    "SiriID": 1113,
    "Lat": 5942540,
    "Lng": 2470077,
    "Stops": "07903-1,07906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06903-1",
    "SiriID": 1057,
    "Lat": 5942174,
    "Lng": 2472466,
    "Stops": "07806-1,07808-1,06904-1",
    "Name": "Hauka",
    "Info": "",
    "Street": "Nõmme tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07806-1",
    "SiriID": 1107,
    "Lat": 5942075,
    "Lng": 2472354,
    "Stops": "06903-1,06902-1,07802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06902-1",
    "SiriID": 1056,
    "Lat": 5941840,
    "Lng": 2472177,
    "Stops": "07602-1,07806-1",
    "Name": "Koolimaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07602-1",
    "SiriID": 1093,
    "Lat": 5941745,
    "Lng": 2472066,
    "Stops": "06902-1,07002-1,07801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07001-1",
    "SiriID": 1060,
    "Lat": 5941165,
    "Lng": 2471574,
    "Stops": "07706-1,07205-1,07708-1",
    "Name": "Nõmme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07707-1",
    "SiriID": 1100,
    "Lat": 5941031,
    "Lng": 2471426,
    "Stops": "07706-1,07202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07002-1",
    "SiriID": 1061,
    "Lat": 5941500,
    "Lng": 2471874,
    "Stops": "07708-1,07602-1",
    "Name": "Räägu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07708-1",
    "SiriID": 1101,
    "Lat": 5941433,
    "Lng": 2471788,
    "Stops": "07002-1,07001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07202-1",
    "SiriID": 1067,
    "Lat": 5940574,
    "Lng": 2471013,
    "Stops": "07701-1,07204-1,02301-1,07707-1",
    "Name": "Tüve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07701-1",
    "SiriID": 1094,
    "Lat": 5940521,
    "Lng": 2470920,
    "Stops": "07202-1,02605-1,02302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08902-1",
    "SiriID": 1133,
    "Lat": 5942988,
    "Lng": 2470637,
    "Stops": "08901-1,08907-1",
    "Name": "Hipodroom",
    "Info": "",
    "Street": "Paldiski mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08907-1",
    "SiriID": 1138,
    "Lat": 5943126,
    "Lng": 2471241,
    "Stops": "08908-1,08902-1",
    "Name": "Lille",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08908-1",
    "SiriID": 1139,
    "Lat": 5943214,
    "Lng": 2471359,
    "Stops": "08907-1,10001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08909-1",
    "SiriID": 1140,
    "Lat": 5943335,
    "Lng": 2471944,
    "Stops": "08910-1,10001-1,08912-1",
    "Name": "Ristiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08910-1",
    "SiriID": 1141,
    "Lat": 5943379,
    "Lng": 2472107,
    "Stops": "08909-1,11002-1,10005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06701-1",
    "SiriID": 1050,
    "Lat": 5939714,
    "Lng": 2471033,
    "Stops": "06704-1,06702-1",
    "Name": "Energia",
    "Info": "",
    "Street": "Rahumäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06702-1",
    "SiriID": 1051,
    "Lat": 5939663,
    "Lng": 2470986,
    "Stops": "06701-1,06705-1,06203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06704-1",
    "SiriID": 3843,
    "Lat": 5939770,
    "Lng": 2471054,
    "Stops": "06701-1,06703-1,02701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06703-1",
    "SiriID": 1052,
    "Lat": 5940028,
    "Lng": 2471106,
    "Stops": "07201-1,06704-1",
    "Name": "Tuisu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07201-1",
    "SiriID": 1066,
    "Lat": 5940140,
    "Lng": 2471112,
    "Stops": "06703-1,02604-1,07210-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07804-1",
    "SiriID": 1105,
    "Lat": 5942459,
    "Lng": 2471737,
    "Stops": "07805-1,07802-1",
    "Name": "Koskla",
    "Info": "",
    "Street": "Sõpruse pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07805-1",
    "SiriID": 1106,
    "Lat": 5942576,
    "Lng": 2471795,
    "Stops": "07803-1,07804-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07704-1",
    "SiriID": 1097,
    "Lat": 5941336,
    "Lng": 2470824,
    "Stops": "07702-1,07705-1",
    "Name": "Linnu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07705-1",
    "SiriID": 1098,
    "Lat": 5941459,
    "Lng": 2470867,
    "Stops": "07704-1,07601-1,08102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07601-1",
    "SiriID": 1092,
    "Lat": 5941932,
    "Lng": 2471243,
    "Stops": "07801-1,07705-1,08001-1",
    "Name": "Tedre",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07801-1",
    "SiriID": 1102,
    "Lat": 5941978,
    "Lng": 2471316,
    "Stops": "07601-1,07802-1,07602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07802-1",
    "SiriID": 1103,
    "Lat": 5942272,
    "Lng": 2471539,
    "Stops": "07804-1,07801-1,07806-1",
    "Name": "Tihase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06901-2",
    "SiriID": 3668,
    "Lat": 5941522,
    "Lng": 2473186,
    "Stops": "06901-1,07104-1",
    "Name": "Ööbiku",
    "Info": "",
    "Street": "Tedre",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06705-1",
    "SiriID": 3842,
    "Lat": 5939550,
    "Lng": 2471556,
    "Stops": "06702-1,06305-1",
    "Name": "Järve haigla",
    "Info": "",
    "Street": "Tervise tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07203-1",
    "SiriID": 1068,
    "Lat": 5940530,
    "Lng": 2471786,
    "Stops": "07204-1,07208-1",
    "Name": "Marsi",
    "Info": "",
    "Street": "Tondi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07204-1",
    "SiriID": 1069,
    "Lat": 5940494,
    "Lng": 2471651,
    "Stops": "07203-1,07210-1,07202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07102-1",
    "SiriID": 1063,
    "Lat": 5941050,
    "Lng": 2473147,
    "Stops": "07103-1,07206-1",
    "Name": "Tondi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07103-1",
    "SiriID": 1064,
    "Lat": 5941103,
    "Lng": 2473226,
    "Stops": "07410-1,07102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07406-1",
    "SiriID": 1084,
    "Lat": 5941132,
    "Lng": 2473406,
    "Stops": "07406-2,07104-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07207-1",
    "SiriID": 1072,
    "Lat": 5940789,
    "Lng": 2472484,
    "Stops": "07208-1,07206-1,07405-1",
    "Name": "Västriku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07208-1",
    "SiriID": 1073,
    "Lat": 5940781,
    "Lng": 2472360,
    "Stops": "07207-1,07203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07807-1",
    "SiriID": 1108,
    "Lat": 5942428,
    "Lng": 2472465,
    "Stops": "07808-1,07809-1",
    "Name": "Lilleküla jaam",
    "Info": "",
    "Street": "Tulika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07808-1",
    "SiriID": 1109,
    "Lat": 5942392,
    "Lng": 2472517,
    "Stops": "07807-1,11206-1,06903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08906-1",
    "SiriID": 1137,
    "Lat": 5942801,
    "Lng": 2472033,
    "Stops": "08905-1,08904-1,08911-1",
    "Name": "Taksopark",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07809-1",
    "SiriID": 14380,
    "Lat": 5942636,
    "Lng": 2472140,
    "Stops": "07803-1,08904-1,07807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08911-1",
    "SiriID": 1142,
    "Lat": 5943062,
    "Lng": 2471818,
    "Stops": "08912-1,08906-1",
    "Name": "Ülase",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08912-1",
    "SiriID": 1143,
    "Lat": 5943088,
    "Lng": 2471820,
    "Stops": "08911-1,08909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16111-1",
    "SiriID": 14429,
    "Lat": 5944216,
    "Lng": 2485441,
    "Stops": "16107-1,16106-1",
    "Name": "Lendtähe",
    "Info": "",
    "Street": 0,
    "Area": "Tallinna linn, Lasnamäe",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16107-1",
    "SiriID": 14431,
    "Lat": 5944158,
    "Lng": 2485082,
    "Stops": "16111-1,15704-1,16001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13012-1",
    "SiriID": 4553,
    "Lat": 5942057,
    "Lng": 2479301,
    "Stops": "13006-1,13404-1",
    "Name": "Seisukoht",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "id93598",
    "SiriID": 21,
    "Lat": 5941648,
    "Lng": 2479964,
    "Stops": "13407-1,13408-1",
    "Name": "Tallinna lennujaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16108-1",
    "SiriID": 14432,
    "Lat": 5944342,
    "Lng": 2485946,
    "Stops": "16112-1,16106-1",
    "Name": "Tähesaju tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16112-1",
    "SiriID": 14433,
    "Lat": 5944375,
    "Lng": 2486183,
    "Stops": "16108-1,16008-1,16907-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13709-1",
    "SiriID": 10872,
    "Lat": 5942306,
    "Lng": 2481916,
    "Stops": "13710-1,14704-1",
    "Name": "Vesse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13710-1",
    "SiriID": 10873,
    "Lat": 5942300,
    "Lng": 2481913,
    "Stops": "13709-1,13701-1,13502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13411-1",
    "SiriID": 10877,
    "Lat": 5942338,
    "Lng": 2479508,
    "Stops": "13403-1,14808-1",
    "Name": "Ülemiste",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13412-1",
    "SiriID": 10878,
    "Lat": 5942330,
    "Lng": 2479581,
    "Stops": "13403-1,13414-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14503-1",
    "SiriID": 1400,
    "Lat": 5942632,
    "Lng": 2485092,
    "Stops": "14504-1,14501-1",
    "Name": "Betooni",
    "Info": "",
    "Street": "Betooni tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14504-1",
    "SiriID": 1401,
    "Lat": 5942588,
    "Lng": 2485099,
    "Stops": "14503-1,13901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13901-1",
    "SiriID": 1386,
    "Lat": 5942401,
    "Lng": 2485646,
    "Stops": "13902-1,14504-1,14001-1",
    "Name": "Betooni põik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13902-1",
    "SiriID": 1387,
    "Lat": 5942380,
    "Lng": 2485649,
    "Stops": "13901-1,13803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14501-1",
    "SiriID": 1398,
    "Lat": 5943054,
    "Lng": 2484805,
    "Stops": "14502-1,14503-1",
    "Name": "Gaasi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14502-1",
    "SiriID": 1399,
    "Lat": 5943086,
    "Lng": 2484761,
    "Stops": "14501-1,14405-1,14604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14001-1",
    "SiriID": 1388,
    "Lat": 5942624,
    "Lng": 2486666,
    "Stops": "14002-1,13901-1",
    "Name": "Sõstramäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14002-1",
    "SiriID": 1389,
    "Lat": 5942630,
    "Lng": 2486749,
    "Stops": "14001-1,14003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16503-1",
    "SiriID": 1488,
    "Lat": 5945294,
    "Lng": 2490529,
    "Stops": "16510-1,16512-2",
    "Name": "Iru hooldekodu",
    "Info": "",
    "Street": "Hooldekodu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16510-1",
    "SiriID": 213,
    "Lat": 5945296,
    "Lng": 2490532,
    "Stops": "16503-1,47102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16512-1",
    "SiriID": 5968,
    "Lat": 5945066,
    "Lng": 2489783,
    "Stops": "16512-2,16513-2,16506-1",
    "Name": "Kuuskivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16512-2",
    "SiriID": 5975,
    "Lat": 5945067,
    "Lng": 2489794,
    "Stops": "16512-1,16503-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15603-1",
    "SiriID": 1458,
    "Lat": 5943487,
    "Lng": 2483045,
    "Stops": "15308-1,15606-1,15605-1",
    "Name": "J. Smuuli tee",
    "Info": "",
    "Street": "J.Smuuli tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15606-1",
    "SiriID": 3674,
    "Lat": 5943328,
    "Lng": 2483136,
    "Stops": "15603-1,15302-1,14603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15308-1",
    "SiriID": 1453,
    "Lat": 5943582,
    "Lng": 2482900,
    "Stops": "15602-1,15603-1",
    "Name": "Kalevipoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15602-1",
    "SiriID": 1457,
    "Lat": 5943683,
    "Lng": 2482854,
    "Stops": "15308-1,15501-1,15307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15201-1",
    "SiriID": 1443,
    "Lat": 5944180,
    "Lng": 2482284,
    "Stops": "17601-1,15307-1",
    "Name": "Liikuri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17601-1",
    "SiriID": 1511,
    "Lat": 5944344,
    "Lng": 2482215,
    "Stops": "15201-1,17602-1,17802-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13806-1",
    "SiriID": 12992,
    "Lat": 5941695,
    "Lng": 2484472,
    "Stops": "13801-1,13808-1",
    "Name": "Ruunaoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14406-1",
    "SiriID": 1397,
    "Lat": 5943054,
    "Lng": 2483457,
    "Stops": "14603-1,14601-1",
    "Name": "Sõjamäe raba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14603-1",
    "SiriID": 1404,
    "Lat": 5943052,
    "Lng": 2483396,
    "Stops": "14406-1,15606-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14606-1",
    "SiriID": 12993,
    "Lat": 5942853,
    "Lng": 2483569,
    "Stops": "14608-1,14607-1",
    "Name": "Vesse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14607-1",
    "SiriID": 12995,
    "Lat": 5942697,
    "Lng": 2483782,
    "Stops": "14606-1,13704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14608-1",
    "SiriID": 13083,
    "Lat": 5942888,
    "Lng": 2483702,
    "Stops": "14609-1,14606-1",
    "Name": "Vesse parkla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14609-1",
    "SiriID": 13198,
    "Lat": 5942906,
    "Lng": 2483806,
    "Stops": "14602-1,14608-1,14605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16102-1",
    "SiriID": 1473,
    "Lat": 5944503,
    "Lng": 2487747,
    "Stops": "16109-1,16201-1",
    "Name": "K. Kärberi",
    "Info": "",
    "Street": "K.Kärberi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16103-1",
    "SiriID": 1474,
    "Lat": 5944535,
    "Lng": 2487959,
    "Stops": "16109-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16109-1",
    "SiriID": 13695,
    "Lat": 5944482,
    "Lng": 2487903,
    "Stops": "16103-1,16102-1,16110-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16303-1",
    "SiriID": 1483,
    "Lat": 5944748,
    "Lng": 2489134,
    "Stops": "16501-1,16302-1,16304-1",
    "Name": "Priisle",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16501-1",
    "SiriID": 1486,
    "Lat": 5944781,
    "Lng": 2489321,
    "Stops": "16303-1,16502-1,16506-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16506-1",
    "SiriID": 4029,
    "Lat": 5944732,
    "Lng": 2489711,
    "Stops": "16505-1,16501-1,16512-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16104-1",
    "SiriID": 1475,
    "Lat": 5944635,
    "Lng": 2488588,
    "Stops": "16301-1,16105-1,16802-1",
    "Name": "Reinu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16301-1",
    "SiriID": 1481,
    "Lat": 5944638,
    "Lng": 2488651,
    "Stops": "16104-1,16403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13511-1",
    "SiriID": 19,
    "Lat": 5942085,
    "Lng": 2479939,
    "Stops": "13512-1",
    "Name": "Ülemiste linnak",
    "Info": "",
    "Street": "Keevise tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13512-1",
    "SiriID": 254,
    "Lat": 5942074,
    "Lng": 2479956,
    "Stops": "13511-1,13507-1,13514-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14402-1",
    "SiriID": 1394,
    "Lat": 5943575,
    "Lng": 2485807,
    "Stops": "16002-1,14401-1",
    "Name": "Kuuli",
    "Info": "",
    "Street": "Kuuli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16002-1",
    "SiriID": 1469,
    "Lat": 5943644,
    "Lng": 2485806,
    "Stops": "14402-1,16010-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16206-1",
    "SiriID": 4008,
    "Lat": 5944300,
    "Lng": 2488151,
    "Stops": "16206-2,16110-1,16209-1",
    "Name": "Kiviku",
    "Info": "",
    "Street": "Laagna tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16110-1",
    "SiriID": 4019,
    "Lat": 5944363,
    "Lng": 2488089,
    "Stops": "16105-1,16206-1,16109-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16206-2",
    "SiriID": 4022,
    "Lat": 5944311,
    "Lng": 2488244,
    "Stops": "16206-1,16105-1,16207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16105-1",
    "SiriID": 4037,
    "Lat": 5944380,
    "Lng": 2488189,
    "Stops": "16110-1,16206-2,16104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15501-1",
    "SiriID": 1454,
    "Lat": 5943985,
    "Lng": 2483077,
    "Stops": "15601-1,15602-1,15502-1",
    "Name": "Kotka kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15601-1",
    "SiriID": 1456,
    "Lat": 5943951,
    "Lng": 2483268,
    "Stops": "15501-1,15702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15105-1",
    "SiriID": 1437,
    "Lat": 5943526,
    "Lng": 2479820,
    "Stops": "15100-1,15106-1",
    "Name": "Kumu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15106-1",
    "SiriID": 1438,
    "Lat": 5943473,
    "Lng": 2479915,
    "Stops": "15105-1,15104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15702-1",
    "SiriID": 1462,
    "Lat": 5944037,
    "Lng": 2484323,
    "Stops": "15801-1,15601-1",
    "Name": "Laagna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15801-1",
    "SiriID": 1465,
    "Lat": 5944002,
    "Lng": 2484479,
    "Stops": "15702-1,15704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15111-1",
    "SiriID": 13026,
    "Lat": 5943562,
    "Lng": 2480640,
    "Stops": "15312-1,15108-1",
    "Name": "Mehaanikakool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16101-1",
    "SiriID": 1472,
    "Lat": 5944316,
    "Lng": 2487191,
    "Stops": "16201-1,16008-1",
    "Name": "Mustakivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16201-1",
    "SiriID": 1476,
    "Lat": 5944288,
    "Lng": 2487581,
    "Stops": "16210-1,16101-1,16102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15202-1",
    "SiriID": 1444,
    "Lat": 5943868,
    "Lng": 2481715,
    "Stops": "15203-1",
    "Name": "Paesilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15203-1",
    "SiriID": 1445,
    "Lat": 5943821,
    "Lng": 2481744,
    "Stops": "15305-1,15202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16505-1",
    "SiriID": 4033,
    "Lat": 5944652,
    "Lng": 2489653,
    "Stops": "16506-1,16404-1",
    "Name": "Priisle",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16404-1",
    "SiriID": 4541,
    "Lat": 5944656,
    "Lng": 2489850,
    "Stops": "16605-1,16505-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16403-1",
    "SiriID": 4026,
    "Lat": 5944475,
    "Lng": 2488949,
    "Stops": "16304-1,16401-1,16301-1",
    "Name": "Rauna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16304-1",
    "SiriID": 4035,
    "Lat": 5944534,
    "Lng": 2489062,
    "Stops": "16403-1,16303-1,16601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16106-1",
    "SiriID": 14254,
    "Lat": 5944130,
    "Lng": 2485843,
    "Stops": "16006-1,16108-1,16111-1,16009-1",
    "Name": "Sabatähe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16006-1",
    "SiriID": 3846,
    "Lat": 5944134,
    "Lng": 2486183,
    "Stops": "16106-1,16008-1",
    "Name": "Taevakivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16008-1",
    "SiriID": 12120,
    "Lat": 5944232,
    "Lng": 2486490,
    "Stops": "16006-1,16007-1,16112-1,16101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13407-1",
    "SiriID": 1367,
    "Lat": 5941622,
    "Lng": 2479887,
    "Stops": "13413-2,id93598",
    "Name": "Lennujaam",
    "Info": "",
    "Street": "Lennujaama tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13408-1",
    "SiriID": 1980,
    "Lat": 5941699,
    "Lng": 2479888,
    "Stops": "13413-3,id93598,13513-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13413-3",
    "SiriID": 14852,
    "Lat": 5941649,
    "Lng": 2479832,
    "Stops": "13413-2,13408-1,13516-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13413-2",
    "SiriID": 14853,
    "Lat": 5941631,
    "Lng": 2479833,
    "Stops": "13413-3,13413-1,13407-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13413-1",
    "SiriID": 14854,
    "Lat": 5941611,
    "Lng": 2479833,
    "Stops": "13413-2,13606-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13513-1",
    "SiriID": 708,
    "Lat": 5941814,
    "Lng": 2479954,
    "Stops": "13515-1,13513-2,13408-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13514-1",
    "SiriID": 5720,
    "Lat": 5941843,
    "Lng": 2479845,
    "Stops": "13513-2,13512-1,13006-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13513-2",
    "SiriID": 5721,
    "Lat": 5941821,
    "Lng": 2479905,
    "Stops": "13513-1,13514-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13515-1",
    "SiriID": 5722,
    "Lat": 5941820,
    "Lng": 2479956,
    "Stops": "13513-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15701-1",
    "SiriID": 1461,
    "Lat": 5944401,
    "Lng": 2483974,
    "Stops": "17401-1,15704-1",
    "Name": "Loitsu",
    "Info": "",
    "Street": "Liikuri tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17401-1",
    "SiriID": 1508,
    "Lat": 5944422,
    "Lng": 2483930,
    "Stops": "15701-1,15502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15502-1",
    "SiriID": 1455,
    "Lat": 5944322,
    "Lng": 2482953,
    "Stops": "17602-1,15501-1,17401-1",
    "Name": "Vana-Kuuli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17001-1",
    "SiriID": 1503,
    "Lat": 5944946,
    "Lng": 2486820,
    "Stops": "17002-1,16908-1",
    "Name": "Kuristiku",
    "Info": "",
    "Street": "Linnamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17002-1",
    "SiriID": 1504,
    "Lat": 5944956,
    "Lng": 2486948,
    "Stops": "17001-1,16905-1,16801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16908-1",
    "SiriID": 6019,
    "Lat": 5944844,
    "Lng": 2486403,
    "Stops": "16906-1,17001-1",
    "Name": "Linnamäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16801-1",
    "SiriID": 1496,
    "Lat": 5944953,
    "Lng": 2488037,
    "Stops": "16802-1,16704-1,17002-1",
    "Name": "Priisle kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16802-1",
    "SiriID": 1497,
    "Lat": 5944932,
    "Lng": 2488098,
    "Stops": "16801-1,16104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13504-1",
    "SiriID": 124,
    "Lat": 5942145,
    "Lng": 2480205,
    "Stops": "13507-1,13505-1",
    "Name": "Dvigatel",
    "Info": "",
    "Street": "Lõõtsa tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13505-1",
    "SiriID": 125,
    "Lat": 5942236,
    "Lng": 2480231,
    "Stops": "13406-1,13504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16202-1",
    "SiriID": 1477,
    "Lat": 5943925,
    "Lng": 2487470,
    "Stops": "16203-1,16213-1",
    "Name": "Mahtra",
    "Info": "",
    "Street": "Mahtra tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16203-1",
    "SiriID": 1478,
    "Lat": 5943945,
    "Lng": 2487618,
    "Stops": "16202-1,16204-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16210-1",
    "SiriID": 6058,
    "Lat": 5944212,
    "Lng": 2487704,
    "Stops": "16201-1,16204-1",
    "Name": "Mustakivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14801-1",
    "SiriID": 1418,
    "Lat": 5942997,
    "Lng": 2478653,
    "Stops": "14802-1,14803-1",
    "Name": "Majaka",
    "Info": "",
    "Street": "Majaka tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14802-1",
    "SiriID": 1419,
    "Lat": 5942996,
    "Lng": 2478656,
    "Stops": "14801-1,15101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14803-1",
    "SiriID": 1420,
    "Lat": 5942989,
    "Lng": 2478634,
    "Stops": "14804-1,14801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14804-1",
    "SiriID": 1421,
    "Lat": 5942990,
    "Lng": 2478632,
    "Stops": "14803-1,11506-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14901-1",
    "SiriID": 1424,
    "Lat": 5942656,
    "Lng": 2479662,
    "Stops": "14902-1,14903-1",
    "Name": "Majaka põik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14902-1",
    "SiriID": 1425,
    "Lat": 5942651,
    "Lng": 2479640,
    "Stops": "14901-1,14805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14903-1",
    "SiriID": 1426,
    "Lat": 5942617,
    "Lng": 2479783,
    "Stops": "14904-1,14901-1,14905-1",
    "Name": "Pae",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14805-1",
    "SiriID": 1422,
    "Lat": 5942811,
    "Lng": 2479212,
    "Stops": "14810-1,14902-1",
    "Name": "Sikupilli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14806-1",
    "SiriID": 1423,
    "Lat": 5942806,
    "Lng": 2479187,
    "Stops": "14809-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14809-1",
    "SiriID": 3679,
    "Lat": 5942805,
    "Lng": 2479190,
    "Stops": "14806-1,14810-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14810-1",
    "SiriID": 4320,
    "Lat": 5942812,
    "Lng": 2479209,
    "Stops": "14805-1,14809-1,15101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17201-1",
    "SiriID": 4053,
    "Lat": 5944786,
    "Lng": 2486040,
    "Stops": "16907-1",
    "Name": "Kurina",
    "Info": "",
    "Street": "Mustakivi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16906-1",
    "SiriID": 5615,
    "Lat": 5944848,
    "Lng": 2486223,
    "Stops": "16907-1,16908-1,16901-1",
    "Name": "Linnamäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16907-1",
    "SiriID": 5758,
    "Lat": 5944781,
    "Lng": 2486215,
    "Stops": "16906-1,17201-1,16112-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16901-1",
    "SiriID": 1498,
    "Lat": 5945044,
    "Lng": 2486073,
    "Stops": "17103-1,16906-1,16902-1",
    "Name": "Läänemere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17103-1",
    "SiriID": 1507,
    "Lat": 5945040,
    "Lng": 2486019,
    "Stops": "16901-1,17102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16213-1",
    "SiriID": 13618,
    "Lat": 5943758,
    "Lng": 2487236,
    "Stops": "16208-1,16202-1,16011-1",
    "Name": "Mahtra",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16007-1",
    "SiriID": 3847,
    "Lat": 5944055,
    "Lng": 2486640,
    "Stops": "16012-1,16008-1",
    "Name": "Mustakivisilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16504-1",
    "SiriID": 1489,
    "Lat": 5946019,
    "Lng": 2490472,
    "Stops": "16508-1",
    "Name": "Iru",
    "Info": "",
    "Street": "Narva mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16902-1",
    "SiriID": 1499,
    "Lat": 5945403,
    "Lng": 2486190,
    "Stops": "16903-1,16901-1",
    "Name": "Jussi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16903-1",
    "SiriID": 1500,
    "Lat": 5945411,
    "Lng": 2486266,
    "Stops": "16902-1,16905-1,18301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17101-1",
    "SiriID": 1505,
    "Lat": 5945131,
    "Lng": 2484782,
    "Stops": "17102-1,17507-1",
    "Name": "Katleri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17102-1",
    "SiriID": 1506,
    "Lat": 5945122,
    "Lng": 2484953,
    "Stops": "17101-1,17103-1,15703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16703-1",
    "SiriID": 1494,
    "Lat": 5945483,
    "Lng": 2487905,
    "Stops": "16704-1,18304-1,16904-1",
    "Name": "Kelluka tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16704-1",
    "SiriID": 1495,
    "Lat": 5945474,
    "Lng": 2487934,
    "Stops": "16703-1,16702-1,16801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16904-1",
    "SiriID": 1501,
    "Lat": 5945502,
    "Lng": 2487129,
    "Stops": "16905-1,16703-1,18302-1",
    "Name": "Kose tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16905-1",
    "SiriID": 1502,
    "Lat": 5945486,
    "Lng": 2486933,
    "Stops": "16904-1,16903-1,17002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17801-1",
    "SiriID": 1513,
    "Lat": 5944434,
    "Lng": 2481334,
    "Stops": "17802-1,12803-1",
    "Name": "Lasnamägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17802-1",
    "SiriID": 1514,
    "Lat": 5944502,
    "Lng": 2481560,
    "Stops": "17801-1,17601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17504-1",
    "SiriID": 13727,
    "Lat": 5945219,
    "Lng": 2483831,
    "Stops": "17507-1,17506-1,18001-1",
    "Name": "Loopealse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17507-1",
    "SiriID": 13728,
    "Lat": 5945178,
    "Lng": 2483969,
    "Stops": "17504-1,17101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17506-1",
    "SiriID": 13328,
    "Lat": 5945111,
    "Lng": 2483336,
    "Stops": "17503-1,17504-1,18103-1",
    "Name": "Meeliku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17503-1",
    "SiriID": 13329,
    "Lat": 5945072,
    "Lng": 2483306,
    "Stops": "17506-1,17502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17501-1",
    "SiriID": 1509,
    "Lat": 5944820,
    "Lng": 2482699,
    "Stops": "17502-1,17602-1",
    "Name": "Paevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17502-1",
    "SiriID": 1510,
    "Lat": 5944841,
    "Lng": 2482869,
    "Stops": "17501-1,17503-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16701-1",
    "SiriID": 1492,
    "Lat": 5945485,
    "Lng": 2488508,
    "Stops": "16702-1,16511-1",
    "Name": "Tondi mägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16208-1",
    "SiriID": 13617,
    "Lat": 5943701,
    "Lng": 2487238,
    "Stops": "16213-1,14309-1",
    "Name": "Mahtra",
    "Info": "",
    "Street": "Osmussaare tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16003-1",
    "SiriID": 1470,
    "Lat": 5943554,
    "Lng": 2486399,
    "Stops": "16004-1,14302-1",
    "Name": "Osmussaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16004-1",
    "SiriID": 1471,
    "Lat": 5943587,
    "Lng": 2486653,
    "Stops": "16005-1,16003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15604-1",
    "SiriID": 1459,
    "Lat": 5943551,
    "Lng": 2483673,
    "Stops": "14404-1,15605-1",
    "Name": "P. Pinna",
    "Info": "",
    "Street": "P.Pinna tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14707-1",
    "SiriID": 1412,
    "Lat": 5943011,
    "Lng": 2480735,
    "Stops": "15010-1,15110-1",
    "Name": "Kiive",
    "Info": "",
    "Street": "Pae tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15109-1",
    "SiriID": 1441,
    "Lat": 5942984,
    "Lng": 2480634,
    "Stops": "15010-1,15104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14907-1",
    "SiriID": 1430,
    "Lat": 5942686,
    "Lng": 2480122,
    "Stops": "14905-1",
    "Name": "Pae",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15306-1",
    "SiriID": 1451,
    "Lat": 5943777,
    "Lng": 2481895,
    "Stops": "15305-1,15307-1,15303-1",
    "Name": "Paesilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15303-1",
    "SiriID": 1448,
    "Lat": 5943564,
    "Lng": 2481897,
    "Stops": "15304-1,15306-1",
    "Name": "Uus-Pae",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15304-1",
    "SiriID": 1449,
    "Lat": 5943563,
    "Lng": 2481826,
    "Stops": "15303-1,15310-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15305-1",
    "SiriID": 1450,
    "Lat": 5943794,
    "Lng": 2481773,
    "Stops": "15203-1,15306-1",
    "Name": "Paesilla",
    "Info": "",
    "Street": "Paekaare tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15307-1",
    "SiriID": 1452,
    "Lat": 5943855,
    "Lng": 2481988,
    "Stops": "15306-1,15201-1,15602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15314-1",
    "SiriID": 6064,
    "Lat": 5943565,
    "Lng": 2481385,
    "Stops": "15315_1,15311-1",
    "Name": "Võidujooksu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15101-1",
    "SiriID": 1433,
    "Lat": 5943072,
    "Lng": 2479219,
    "Stops": "15102-1,14810-1,14802-1",
    "Name": "Asunduse",
    "Info": "",
    "Street": "Pallasti tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15102-1",
    "SiriID": 1434,
    "Lat": 5943101,
    "Lng": 2479267,
    "Stops": "15101-1,15103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15103-1",
    "SiriID": 1435,
    "Lat": 5943328,
    "Lng": 2479722,
    "Stops": "15104-1,15102-1",
    "Name": "Pallasti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15104-1",
    "SiriID": 1436,
    "Lat": 5943348,
    "Lng": 2479785,
    "Stops": "15103-1,15106-1,15109-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14807-1",
    "SiriID": 3394,
    "Lat": 5942524,
    "Lng": 2478517,
    "Stops": "13002-1,13001-1,13010-1",
    "Name": "Peterburi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14003-1",
    "SiriID": 1390,
    "Lat": 5942826,
    "Lng": 2486962,
    "Stops": "14004-1,14002-1,23930-1",
    "Name": "Paneeli",
    "Info": "",
    "Street": "Paneeli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14004-1",
    "SiriID": 1391,
    "Lat": 5942839,
    "Lng": 2486964,
    "Stops": "14003-1,14303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14302-1",
    "SiriID": 1392,
    "Lat": 5943359,
    "Lng": 2486562,
    "Stops": "14303-1,16003-1,14301-1",
    "Name": "Pikamägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14303-1",
    "SiriID": 1393,
    "Lat": 5943353,
    "Lng": 2486593,
    "Stops": "14302-1,16005-1,14004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14505-1",
    "SiriID": 10791,
    "Lat": 5943148,
    "Lng": 2484910,
    "Stops": "14405-1,14401-1",
    "Name": "Gaasi",
    "Info": "",
    "Street": "Peterburi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14405-1",
    "SiriID": 10792,
    "Lat": 5943164,
    "Lng": 2484848,
    "Stops": "14505-1,14502-1,14403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14711-1",
    "SiriID": 1416,
    "Lat": 5942713,
    "Lng": 2482017,
    "Stops": "14712-1,14704-1,14709-1",
    "Name": "Jüriöö park",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14712-1",
    "SiriID": 1417,
    "Lat": 5942686,
    "Lng": 2482142,
    "Stops": "14711-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14808-1",
    "SiriID": 3395,
    "Lat": 5942507,
    "Lng": 2479489,
    "Stops": "13414-1,13411-1",
    "Name": "Kivimurru",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14301-1",
    "SiriID": 7158,
    "Lat": 5943325,
    "Lng": 2486066,
    "Stops": "14401-1,14302-1",
    "Name": "Kuuli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14401-1",
    "SiriID": 10342,
    "Lat": 5943347,
    "Lng": 2485874,
    "Stops": "14301-1,14402-1,14505-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14305-1",
    "SiriID": 10797,
    "Lat": 5944105,
    "Lng": 2489799,
    "Stops": "14310-1",
    "Name": "Lagedi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14310-1",
    "SiriID": 10798,
    "Lat": 5944115,
    "Lng": 2489745,
    "Stops": "14305-1,16602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14904-1",
    "SiriID": 1427,
    "Lat": 5942570,
    "Lng": 2479719,
    "Stops": "14903-1,13414-2",
    "Name": "Pae",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14905-1",
    "SiriID": 1428,
    "Lat": 5942620,
    "Lng": 2480102,
    "Stops": "14906-1,14907-1,14903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14906-1",
    "SiriID": 1429,
    "Lat": 5942586,
    "Lng": 2480173,
    "Stops": "14905-1,13405-1,14908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13010-1",
    "SiriID": 13012,
    "Lat": 5942416,
    "Lng": 2478808,
    "Stops": "13401-1,14807-1",
    "Name": "Peterburi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16005-1",
    "SiriID": 10793,
    "Lat": 5943542,
    "Lng": 2486854,
    "Stops": "14309-1,16004-1,14303-1",
    "Name": "Pikamägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14309-1",
    "SiriID": 10794,
    "Lat": 5943514,
    "Lng": 2486986,
    "Stops": "16005-1,16208-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16604-1",
    "SiriID": 10795,
    "Lat": 5944345,
    "Lng": 2490880,
    "Stops": "14308-1,16603-1",
    "Name": "Pirita jõgi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14604-1",
    "SiriID": 9815,
    "Lat": 5942949,
    "Lng": 2484058,
    "Stops": "14605-1,14502-1",
    "Name": "Silde",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14605-1",
    "SiriID": 9816,
    "Lat": 5942941,
    "Lng": 2484011,
    "Stops": "14604-1,14609-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14701-1",
    "SiriID": 1405,
    "Lat": 5942682,
    "Lng": 2481387,
    "Stops": "14702-1,14705-1",
    "Name": "Suur-Paala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14702-1",
    "SiriID": 1407,
    "Lat": 5942670,
    "Lng": 2481422,
    "Stops": "14701-1,14703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14703-1",
    "SiriID": 1408,
    "Lat": 5942681,
    "Lng": 2481464,
    "Stops": "14702-1,14704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14704-1",
    "SiriID": 1409,
    "Lat": 5942667,
    "Lng": 2481591,
    "Stops": "14703-1,14711-1,13709-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14601-1",
    "SiriID": 1402,
    "Lat": 5942982,
    "Lng": 2483755,
    "Stops": "14602-1,14406-1",
    "Name": "Vesse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14602-1",
    "SiriID": 1403,
    "Lat": 5942945,
    "Lng": 2483745,
    "Stops": "14601-1,14609-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14705-1",
    "SiriID": 1410,
    "Lat": 5942629,
    "Lng": 2480956,
    "Stops": "14706-1,14701-1,13501-1",
    "Name": "Väike-Paala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14706-1",
    "SiriID": 1411,
    "Lat": 5942646,
    "Lng": 2480806,
    "Stops": "14909-1,14705-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14908-1",
    "SiriID": 1431,
    "Lat": 5942638,
    "Lng": 2480720,
    "Stops": "14909-1,14906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14909-1",
    "SiriID": 1432,
    "Lat": 5942655,
    "Lng": 2480768,
    "Stops": "14706-1,14908-1,15010-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14306-1",
    "SiriID": 10799,
    "Lat": 5943926,
    "Lng": 2488758,
    "Stops": "14307-1,16207-1",
    "Name": "Väo",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14307-1",
    "SiriID": 10800,
    "Lat": 5943930,
    "Lng": 2488914,
    "Stops": "14306-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16513-1",
    "SiriID": 5969,
    "Lat": 5945077,
    "Lng": 2489706,
    "Stops": "16513-2,16502-1",
    "Name": "Kuuskivi",
    "Info": "",
    "Street": "Priisle tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16513-2",
    "SiriID": 5972,
    "Lat": 5945075,
    "Lng": 2489712,
    "Stops": "16513-1,16512-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16509-1",
    "SiriID": 13431,
    "Lat": 5945249,
    "Lng": 2489046,
    "Stops": "16511-1,16502-1",
    "Name": "Reidumägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16511-1",
    "SiriID": 13432,
    "Lat": 5945262,
    "Lng": 2488976,
    "Stops": "16509-1,16701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16702-1",
    "SiriID": 1493,
    "Lat": 5945452,
    "Lng": 2488412,
    "Stops": "16701-1,16704-1",
    "Name": "Tondi mägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14407-1",
    "SiriID": 85,
    "Lat": 5943570,
    "Lng": 2484345,
    "Stops": "15802-1,14404-1",
    "Name": "Lasnamäe turg",
    "Info": "",
    "Street": "Punane tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16011-1",
    "SiriID": 6061,
    "Lat": 5943998,
    "Lng": 2486642,
    "Stops": "16012-1,16213-1",
    "Name": "Mustakivisilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16012-1",
    "SiriID": 6062,
    "Lat": 5944016,
    "Lng": 2486620,
    "Stops": "16011-1,16007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14404-1",
    "SiriID": 1396,
    "Lat": 5943495,
    "Lng": 2483821,
    "Stops": "15604-1,14407-1",
    "Name": "P. Pinna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15605-1",
    "SiriID": 1460,
    "Lat": 5943465,
    "Lng": 2483473,
    "Stops": "15604-1,15603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14708-1",
    "SiriID": 1413,
    "Lat": 5943199,
    "Lng": 2481400,
    "Stops": "15110-1,15301-1",
    "Name": "Paala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15110-1",
    "SiriID": 1442,
    "Lat": 5943211,
    "Lng": 2481311,
    "Stops": "14708-1,15313-1,14707-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14710-1",
    "SiriID": 1415,
    "Lat": 5943291,
    "Lng": 2482476,
    "Stops": "15302-1,14709-1",
    "Name": "Paekaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15302-1",
    "SiriID": 1447,
    "Lat": 5943342,
    "Lng": 2482702,
    "Stops": "14710-1,15606-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14403-1",
    "SiriID": 1395,
    "Lat": 5943642,
    "Lng": 2484784,
    "Stops": "15802-1,15803-1,14405-1",
    "Name": "Pikri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15802-1",
    "SiriID": 1466,
    "Lat": 5943635,
    "Lng": 2484596,
    "Stops": "14403-1,14407-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "14709-1",
    "SiriID": 1414,
    "Lat": 5943232,
    "Lng": 2481973,
    "Stops": "15301-1,14710-1,14711-1",
    "Name": "Punane",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15301-1",
    "SiriID": 1446,
    "Lat": 5943238,
    "Lng": 2481849,
    "Stops": "14709-1,14708-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16009-1",
    "SiriID": 6059,
    "Lat": 5943863,
    "Lng": 2485964,
    "Stops": "16010-1,16106-1",
    "Name": "Taevakivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16010-1",
    "SiriID": 6060,
    "Lat": 5943855,
    "Lng": 2485816,
    "Stops": "16009-1,16002-1,15803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16204-1",
    "SiriID": 1479,
    "Lat": 5944141,
    "Lng": 2487840,
    "Stops": "16205-1,16210-1,16203-1",
    "Name": "Raadiku",
    "Info": "",
    "Street": "Raadiku tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16205-1",
    "SiriID": 1480,
    "Lat": 5944146,
    "Lng": 2488034,
    "Stops": "16209-1,16204-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16209-1",
    "SiriID": 13620,
    "Lat": 5944214,
    "Lng": 2488136,
    "Stops": "16205-1,16206-1,16207-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16207-1",
    "SiriID": 4015,
    "Lat": 5944218,
    "Lng": 2488338,
    "Stops": "16209-1,16206-2,14306-1,16401-1",
    "Name": "Raadikusilla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13405-1",
    "SiriID": 1364,
    "Lat": 5942318,
    "Lng": 2480161,
    "Stops": "13406-1,14906-1",
    "Name": "Dvigatel",
    "Info": "",
    "Street": "Suur-Sõjamäe tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13406-1",
    "SiriID": 1365,
    "Lat": 5942296,
    "Lng": 2480311,
    "Stops": "13505-1,13405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13401-1",
    "SiriID": 4050,
    "Lat": 5942293,
    "Lng": 2478878,
    "Stops": "13402-1,13010-1,13009-1",
    "Name": "Järvevana",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13402-1",
    "SiriID": 4051,
    "Lat": 5942271,
    "Lng": 2478911,
    "Stops": "13401-1,13404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13501-1",
    "SiriID": 1368,
    "Lat": 5942304,
    "Lng": 2481177,
    "Stops": "13502-1,14705-1",
    "Name": "Kaubajaama",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13502-1",
    "SiriID": 1369,
    "Lat": 5942285,
    "Lng": 2481199,
    "Stops": "13501-1,13510-1,13710-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13503-1",
    "SiriID": 1370,
    "Lat": 5942212,
    "Lng": 2482092,
    "Stops": "13701-1,13702-1",
    "Name": "Kesk-Sõjamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13701-1",
    "SiriID": 1374,
    "Lat": 5942236,
    "Lng": 2482114,
    "Stops": "13503-1,13710-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13807-1",
    "SiriID": 4232,
    "Lat": 5941569,
    "Lng": 2485379,
    "Stops": "13808-1,13803-1",
    "Name": "Nuia",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13808-1",
    "SiriID": 4233,
    "Lat": 5941587,
    "Lng": 2485140,
    "Stops": "13807-1,13806-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13702-1",
    "SiriID": 1375,
    "Lat": 5942105,
    "Lng": 2482538,
    "Stops": "13703-1,13503-1",
    "Name": "Orto",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13703-1",
    "SiriID": 1376,
    "Lat": 5942080,
    "Lng": 2482578,
    "Stops": "13702-1,13704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13803-1",
    "SiriID": 1383,
    "Lat": 5941586,
    "Lng": 2485865,
    "Stops": "13804-1,13807-1,13902-1",
    "Name": "Raudbetooni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13804-1",
    "SiriID": 1384,
    "Lat": 5941567,
    "Lng": 2486108,
    "Stops": "13803-1,13805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13801-1",
    "SiriID": 1381,
    "Lat": 5941588,
    "Lng": 2484328,
    "Stops": "13802-1,13806-1,13707-1",
    "Name": "Ruunaoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13802-1",
    "SiriID": 1382,
    "Lat": 5941565,
    "Lng": 2484427,
    "Stops": "13801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13706-1",
    "SiriID": 1379,
    "Lat": 5941681,
    "Lng": 2483822,
    "Stops": "13707-1,13705-1",
    "Name": "Suur-Sõjamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13707-1",
    "SiriID": 1380,
    "Lat": 5941647,
    "Lng": 2483879,
    "Stops": "13706-1,13801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13805-1",
    "SiriID": 1385,
    "Lat": 5941613,
    "Lng": 2486524,
    "Stops": "13804-1",
    "Name": "Sõjamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13704-1",
    "SiriID": 1377,
    "Lat": 5941930,
    "Lng": 2483074,
    "Stops": "13705-1,13703-1,14607-1",
    "Name": "Tubaka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13705-1",
    "SiriID": 1378,
    "Lat": 5941905,
    "Lng": 2483109,
    "Stops": "13704-1,13706-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13403-1",
    "SiriID": 1362,
    "Lat": 5942316,
    "Lng": 2479516,
    "Stops": "13411-1,13404-1,13412-1",
    "Name": "Ülemiste jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13404-1",
    "SiriID": 1363,
    "Lat": 5942297,
    "Lng": 2479460,
    "Stops": "13403-1,13012-1,13402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16302-1",
    "SiriID": 1482,
    "Lat": 5944877,
    "Lng": 2489081,
    "Stops": "16502-1,16303-1",
    "Name": "Ussimäe tee",
    "Info": "",
    "Street": "Ussimäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16502-1",
    "SiriID": 1487,
    "Lat": 5944912,
    "Lng": 2489177,
    "Stops": "16302-1,16501-1,16513-1,16509-1",
    "Name": "",
    "Info": "",
    "Street": "Ussimäe-Kärberi ühendus",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15112-1",
    "SiriID": 13199,
    "Lat": 5944029,
    "Lng": 2480691,
    "Stops": "15113-1,15108-1",
    "Name": "Hundikuristiku",
    "Info": "",
    "Street": "Valge tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15113-1",
    "SiriID": 13200,
    "Lat": 5944073,
    "Lng": 2480795,
    "Stops": "15112-1,12803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15100-1",
    "SiriID": 10808,
    "Lat": 5943554,
    "Lng": 2479841,
    "Stops": "15105-1,15204-1",
    "Name": "Kumu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15204-1",
    "SiriID": 10809,
    "Lat": 5943604,
    "Lng": 2479871,
    "Stops": "15100-1,15107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15107-1",
    "SiriID": 1439,
    "Lat": 5943799,
    "Lng": 2480207,
    "Stops": "15108-1,15204-1",
    "Name": "Uuslinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15108-1",
    "SiriID": 1440,
    "Lat": 5943807,
    "Lng": 2480239,
    "Stops": "15107-1,15111-1,15112-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13506-1",
    "SiriID": 126,
    "Lat": 5942027,
    "Lng": 2480294,
    "Stops": "13507-1,13509-1",
    "Name": "Lõõtsa",
    "Info": "",
    "Street": "Valukoja tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13507-1",
    "SiriID": 127,
    "Lat": 5942082,
    "Lng": 2480232,
    "Stops": "13506-1,13504-1,13512-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13508-1",
    "SiriID": 128,
    "Lat": 5942031,
    "Lng": 2480981,
    "Stops": "13509-1,13510-1",
    "Name": "Valukoja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13509-1",
    "SiriID": 129,
    "Lat": 5942036,
    "Lng": 2480890,
    "Stops": "13508-1,13506-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17602-1",
    "SiriID": 1512,
    "Lat": 5944374,
    "Lng": 2482827,
    "Stops": "15502-1,17601-1,17501-1",
    "Name": "Vana-Kuuli",
    "Info": "",
    "Street": "Vana-Kuuli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15803-1",
    "SiriID": 1467,
    "Lat": 5943820,
    "Lng": 2485132,
    "Stops": "16001-1,14403-1,16010-1",
    "Name": "Varraku",
    "Info": "",
    "Street": "Varraku tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16001-1",
    "SiriID": 1468,
    "Lat": 5943878,
    "Lng": 2485124,
    "Stops": "15803-1,16107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15703-1",
    "SiriID": 1463,
    "Lat": 5944311,
    "Lng": 2484724,
    "Stops": "15704-1,17102-1",
    "Name": "Virbi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15704-1",
    "SiriID": 1464,
    "Lat": 5944291,
    "Lng": 2484710,
    "Stops": "15703-1,16107-1,15801-1,15701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15310-1",
    "SiriID": 13592,
    "Lat": 5943483,
    "Lng": 2481466,
    "Stops": "15313-1,15304-1",
    "Name": "Uus-Pae",
    "Info": "",
    "Street": "Võidujooksu tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15313-1",
    "SiriID": 1266,
    "Lat": 5943475,
    "Lng": 2481451,
    "Stops": "15310-1,15315_1,15110-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15311-1",
    "SiriID": 5653,
    "Lat": 5943568,
    "Lng": 2481225,
    "Stops": "15312-1,15314-1",
    "Name": "Võidujooksu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15312-1",
    "SiriID": 5654,
    "Lat": 5943602,
    "Lng": 2481168,
    "Stops": "15311-1,15111-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15315_1",
    "SiriID": 6065,
    "Lat": 5943517,
    "Lng": 2481357,
    "Stops": "15314-1,15313-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "15010-1",
    "SiriID": 2187,
    "Lat": 5942981,
    "Lng": 2480715,
    "Stops": "14707-1,15109-1,14909-1",
    "Name": "Kiive",
    "Info": "",
    "Street": "Väike-Paala",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13510-1",
    "SiriID": 130,
    "Lat": 5942207,
    "Lng": 2481099,
    "Stops": "13502-1,13508-1",
    "Name": "Kaubajaama",
    "Info": "",
    "Street": "Ääsi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13414-1",
    "SiriID": 4266,
    "Lat": 5942488,
    "Lng": 2479689,
    "Stops": "13414-2,14808-1,13412-1",
    "Name": "Ülemiste jaam",
    "Info": "",
    "Street": "Ülemiste tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "13414-2",
    "SiriID": 4267,
    "Lat": 5942489,
    "Lng": 2479696,
    "Stops": "13414-1,14904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16605-1",
    "SiriID": 4031,
    "Lat": 5944588,
    "Lng": 2489810,
    "Stops": "16404-1,16603-1",
    "Name": "Priisle",
    "Info": "",
    "Street": "Ümera tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16601-1",
    "SiriID": 1490,
    "Lat": 5944490,
    "Lng": 2489665,
    "Stops": "16602-1,16603-1,16304-1",
    "Name": "Seli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16602-1",
    "SiriID": 1491,
    "Lat": 5944429,
    "Lng": 2489672,
    "Stops": "16601-1,14310-1,16402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16603-1",
    "SiriID": 3678,
    "Lat": 5944497,
    "Lng": 2489803,
    "Stops": "16601-1,16605-1,16604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16401-1",
    "SiriID": 1484,
    "Lat": 5944360,
    "Lng": 2489001,
    "Stops": "16402-1,16403-1,16207-1",
    "Name": "Ümera",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16402-1",
    "SiriID": 1485,
    "Lat": 5944354,
    "Lng": 2489059,
    "Stops": "16401-1,16602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02303-1",
    "SiriID": 5839,
    "Lat": 5940785,
    "Lng": 2468922,
    "Stops": "02109-1,02111-1",
    "Name": "Mustamäe tee",
    "Info": "",
    "Street": "A. H. Tammsaare tee",
    "Area": "Tallinna linn, Mustamäe",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03104-1",
    "SiriID": 904,
    "Lat": 5941049,
    "Lng": 2466565,
    "Stops": "02101-1,01501-1",
    "Name": "Autobussikoondis",
    "Info": "",
    "Street": "A.H.Tammsaare tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03205-1",
    "SiriID": 912,
    "Lat": 5940886,
    "Lng": 2468274,
    "Stops": "02503-1,03203-1",
    "Name": "Karjavälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02108-1",
    "SiriID": 860,
    "Lat": 5940993,
    "Lng": 2467364,
    "Stops": "03203-1,02104-1",
    "Name": "Lakiotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03203-1",
    "SiriID": 910,
    "Lat": 5940957,
    "Lng": 2467487,
    "Stops": "02108-1,03205-1,03202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02109-1",
    "SiriID": 861,
    "Lat": 5940798,
    "Lng": 2468690,
    "Stops": "02303-1,02503-1,02107-1",
    "Name": "Mustamäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02605-1",
    "SiriID": 877,
    "Lat": 5940361,
    "Lng": 2470660,
    "Stops": "02603-1,07701-1,02601-1",
    "Name": "Retke tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02111-1",
    "SiriID": 863,
    "Lat": 5940695,
    "Lng": 2469344,
    "Stops": "03206-1,02303-1",
    "Name": "Sipelga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03206-1",
    "SiriID": 913,
    "Lat": 5940637,
    "Lng": 2469520,
    "Stops": "02111-1,02601-1,02302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03505-1",
    "SiriID": 927,
    "Lat": 5940335,
    "Lng": 2465627,
    "Stops": "01802-1,03101-1,03503-2",
    "Name": "Kadaka",
    "Info": "",
    "Street": "Akadeemia tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01801-1",
    "SiriID": 3110,
    "Lat": 5940466,
    "Lng": 2465491,
    "Stops": "03101-1,01802-1,01803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02801-1",
    "SiriID": 881,
    "Lat": 5939700,
    "Lng": 2466864,
    "Stops": "02802-1,02906-1,02905-1",
    "Name": "Keemia",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02802-1",
    "SiriID": 882,
    "Lat": 5939705,
    "Lng": 2466805,
    "Stops": "02801-1,02807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03404-1",
    "SiriID": 920,
    "Lat": 5939968,
    "Lng": 2466084,
    "Stops": "03501-1,03502-1",
    "Name": "Mustamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03501-1",
    "SiriID": 922,
    "Lat": 5939956,
    "Lng": 2466084,
    "Stops": "03404-1,03504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03502-1",
    "SiriID": 923,
    "Lat": 5940005,
    "Lng": 2465946,
    "Stops": "03404-1,03503-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03503-1",
    "SiriID": 924,
    "Lat": 5940103,
    "Lng": 2465810,
    "Stops": "03503-2,03405-1,03502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03503-2",
    "SiriID": 925,
    "Lat": 5940113,
    "Lng": 2465774,
    "Stops": "03503-1,03505-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01803-1",
    "SiriID": 3753,
    "Lat": 5940555,
    "Lng": 2465317,
    "Stops": "03506-1,01801-1",
    "Name": "Pöörise",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03506-1",
    "SiriID": 4586,
    "Lat": 5940614,
    "Lng": 2465190,
    "Stops": "01803-1,01701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03504-1",
    "SiriID": 926,
    "Lat": 5939761,
    "Lng": 2466328,
    "Stops": "02807-1,03501-1",
    "Name": "Raja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03107-1",
    "SiriID": 907,
    "Lat": 5940176,
    "Lng": 2466069,
    "Stops": "03405-1,03401-1",
    "Name": "Akadeemia tee",
    "Info": "",
    "Street": "E.Vilde tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03405-1",
    "SiriID": 921,
    "Lat": 5940132,
    "Lng": 2465998,
    "Stops": "03107-1,03503-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03106-1",
    "SiriID": 906,
    "Lat": 5940368,
    "Lng": 2466590,
    "Stops": "03401-1,03105-1",
    "Name": "Kaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03401-1",
    "SiriID": 917,
    "Lat": 5940336,
    "Lng": 2466452,
    "Stops": "03106-1,03107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02402-1",
    "SiriID": 867,
    "Lat": 5940308,
    "Lng": 2468743,
    "Stops": "02502-1,02403-1,03407-1",
    "Name": "Lehola",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02502-1",
    "SiriID": 871,
    "Lat": 5940319,
    "Lng": 2468757,
    "Stops": "02402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03202-1",
    "SiriID": 909,
    "Lat": 5940481,
    "Lng": 2467531,
    "Stops": "03304-1,03303-1,03203-1",
    "Name": "Szolnok",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03304-1",
    "SiriID": 916,
    "Lat": 5940482,
    "Lng": 2467690,
    "Stops": "03202-1,03406-1,03201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02102-1",
    "SiriID": 854,
    "Lat": 5941168,
    "Lng": 2466345,
    "Stops": "02101-1,01403-1",
    "Name": "Autobussikoondis",
    "Info": "",
    "Street": "Ehitajate tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03105-1",
    "SiriID": 905,
    "Lat": 5940469,
    "Lng": 2466874,
    "Stops": "03204-1,03303-1,03106-1",
    "Name": "E. Vilde tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03204-1",
    "SiriID": 911,
    "Lat": 5940540,
    "Lng": 2466860,
    "Stops": "03105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03303-1",
    "SiriID": 915,
    "Lat": 5940330,
    "Lng": 2466965,
    "Stops": "03402-1,03105-1,03202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03402-1",
    "SiriID": 918,
    "Lat": 5940313,
    "Lng": 2466947,
    "Stops": "03303-1,03403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03302-1",
    "SiriID": 914,
    "Lat": 5940029,
    "Lng": 2467115,
    "Stops": "02905-1,03403-1",
    "Name": "Männi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03403-1",
    "SiriID": 919,
    "Lat": 5940088,
    "Lng": 2467046,
    "Stops": "03302-1,03402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02808-1",
    "SiriID": 888,
    "Lat": 5939487,
    "Lng": 2467323,
    "Stops": "02906-1,02804-1",
    "Name": "Tehnikaülikool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02906-1",
    "SiriID": 894,
    "Lat": 5939528,
    "Lng": 2467334,
    "Stops": "02808-1,02904-1,02801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02907-1",
    "SiriID": 895,
    "Lat": 5939545,
    "Lng": 2468387,
    "Stops": "02908-1",
    "Name": "J. Sütiste tee",
    "Info": "",
    "Street": "J.Sütiste tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02908-1",
    "SiriID": 3940,
    "Lat": 5939548,
    "Lng": 2468356,
    "Stops": "02907-1,03001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02703-1",
    "SiriID": 880,
    "Lat": 5939695,
    "Lng": 2469906,
    "Stops": "03004-1,02702-1",
    "Name": "Tervise",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03004-1",
    "SiriID": 900,
    "Lat": 5939707,
    "Lng": 2469844,
    "Stops": "02703-1,03003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03602-1",
    "SiriID": 929,
    "Lat": 5939969,
    "Lng": 2464591,
    "Stops": "03601-1,03609-1",
    "Name": "Kassi",
    "Info": "",
    "Street": "Kadaka pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03603-1",
    "SiriID": 930,
    "Lat": 5939467,
    "Lng": 2464428,
    "Stops": "03604-1,03605-1",
    "Name": "Mäepealse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03604-1",
    "SiriID": 931,
    "Lat": 5939460,
    "Lng": 2464397,
    "Stops": "03603-1,03608-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08202-1",
    "SiriID": 1120,
    "Lat": 5941432,
    "Lng": 2468943,
    "Stops": "08203-1,02106-1,08101-1",
    "Name": "Aiandi",
    "Info": "",
    "Street": "Kadaka tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "08203-1",
    "SiriID": 1121,
    "Lat": 5941467,
    "Lng": 2468887,
    "Stops": "08202-1,08201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01501-1",
    "SiriID": 838,
    "Lat": 5940985,
    "Lng": 2466270,
    "Stops": "03104-1,03102-1",
    "Name": "Autobussikoondis",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02103-1",
    "SiriID": 855,
    "Lat": 5941401,
    "Lng": 2467238,
    "Stops": "02110-1,02105-1",
    "Name": "Joostimägi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02110-1",
    "SiriID": 862,
    "Lat": 5941378,
    "Lng": 2467153,
    "Stops": "02103-1,02104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03101-1",
    "SiriID": 901,
    "Lat": 5940440,
    "Lng": 2465646,
    "Stops": "01801-1,03505-1,03102-1",
    "Name": "Kadaka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03102-1",
    "SiriID": 902,
    "Lat": 5940533,
    "Lng": 2465791,
    "Stops": "03101-1,01501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "01802-1",
    "SiriID": 3399,
    "Lat": 5940366,
    "Lng": 2465453,
    "Stops": "03505-1,01801-1,03601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02101-1",
    "SiriID": 853,
    "Lat": 5941118,
    "Lng": 2466614,
    "Stops": "03104-1,02102-1,02104-1",
    "Name": "Kadaka Selver",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02104-1",
    "SiriID": 856,
    "Lat": 5941260,
    "Lng": 2466862,
    "Stops": "02110-1,02101-1,02108-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03601-1",
    "SiriID": 928,
    "Lat": 5940103,
    "Lng": 2464833,
    "Stops": "03602-1,01802-1,01903-1",
    "Name": "Kassi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02105-1",
    "SiriID": 857,
    "Lat": 5941650,
    "Lng": 2467841,
    "Stops": "08201-1,02103-1",
    "Name": "Laki",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02901-1",
    "SiriID": 889,
    "Lat": 5939919,
    "Lng": 2468023,
    "Stops": "02902-1,02404-1,02903-1",
    "Name": "Keskuse",
    "Info": "",
    "Street": "Keskuse tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02902-1",
    "SiriID": 890,
    "Lat": 5939999,
    "Lng": 2467953,
    "Stops": "02901-1,03406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02905-1",
    "SiriID": 893,
    "Lat": 5939991,
    "Lng": 2467195,
    "Stops": "03302-1,02904-1,02801-1",
    "Name": "Männi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02503-1",
    "SiriID": 872,
    "Lat": 5940707,
    "Lng": 2468366,
    "Stops": "03201-1,03205-1,02109-1",
    "Name": "A. H. Tammsaare tee",
    "Info": "",
    "Street": "Mustamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03201-1",
    "SiriID": 908,
    "Lat": 5940682,
    "Lng": 2468309,
    "Stops": "02503-1,03304-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02106-1",
    "SiriID": 858,
    "Lat": 5941382,
    "Lng": 2468993,
    "Stops": "08202-1,02201-1",
    "Name": "Aiandi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03406-1",
    "SiriID": 5922,
    "Lat": 5940299,
    "Lng": 2467894,
    "Stops": "03407-1,03304-1,02902-1",
    "Name": "Kesk-Mustamäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03407-1",
    "SiriID": 5923,
    "Lat": 5940311,
    "Lng": 2467950,
    "Stops": "03406-1,02402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02107-1",
    "SiriID": 859,
    "Lat": 5941066,
    "Lng": 2468700,
    "Stops": "02201-1,02109-1",
    "Name": "Sääse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02201-1",
    "SiriID": 864,
    "Lat": 5941132,
    "Lng": 2468821,
    "Stops": "02107-1,02106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03606-1",
    "SiriID": 68,
    "Lat": 5939487,
    "Lng": 2465229,
    "Stops": "03607-1,02805-1",
    "Name": "Kivinuka",
    "Info": "",
    "Street": "Mäepealse tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03607-1",
    "SiriID": 69,
    "Lat": 5939502,
    "Lng": 2465060,
    "Stops": "03606-1,03605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03605-1",
    "SiriID": 67,
    "Lat": 5939436,
    "Lng": 2464463,
    "Stops": "03603-1,03607-1,03802-1",
    "Name": "Mäepealse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02807-1",
    "SiriID": 887,
    "Lat": 5939680,
    "Lng": 2466373,
    "Stops": "03504-1,02802-1,02805-1",
    "Name": "Raja",
    "Info": "",
    "Street": "Raja tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02805-1",
    "SiriID": 885,
    "Lat": 5939299,
    "Lng": 2466296,
    "Stops": "02806-1,02807-1,03606-1",
    "Name": "Trummi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02806-1",
    "SiriID": 886,
    "Lat": 5939237,
    "Lng": 2466346,
    "Stops": "02805-1,02803-1,04704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02701-1",
    "SiriID": 878,
    "Lat": 5939894,
    "Lng": 2470589,
    "Stops": "02702-1,06704-1",
    "Name": "Lastehaigla",
    "Info": "",
    "Street": "Retke tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02702-1",
    "SiriID": 879,
    "Lat": 5939902,
    "Lng": 2470570,
    "Stops": "02701-1,02703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02602-1",
    "SiriID": 874,
    "Lat": 5940243,
    "Lng": 2470656,
    "Stops": "02603-1",
    "Name": "Retke tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02603-1",
    "SiriID": 875,
    "Lat": 5940277,
    "Lng": 2470664,
    "Stops": "02602-1,02604-1,02605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02903-1",
    "SiriID": 891,
    "Lat": 5939799,
    "Lng": 2467658,
    "Stops": "02904-1,02901-1",
    "Name": "Ehitajate tee",
    "Info": "",
    "Street": "Sõpruse pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02904-1",
    "SiriID": 892,
    "Lat": 5939804,
    "Lng": 2467523,
    "Stops": "02903-1,02905-1,02906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02501-1",
    "SiriID": 870,
    "Lat": 5940275,
    "Lng": 2469749,
    "Stops": "02601-1,03003-1",
    "Name": "Lepistiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02601-1",
    "SiriID": 873,
    "Lat": 5940312,
    "Lng": 2469886,
    "Stops": "02501-1,03206-1,02605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02404-1",
    "SiriID": 869,
    "Lat": 5939893,
    "Lng": 2468232,
    "Stops": "03002-1,02901-1",
    "Name": "Liivaku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03001-1",
    "SiriID": 897,
    "Lat": 5939885,
    "Lng": 2468427,
    "Stops": "03002-1,02403-1,02908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03002-1",
    "SiriID": 898,
    "Lat": 5939890,
    "Lng": 2468361,
    "Stops": "03001-1,02404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "07709-1",
    "SiriID": 10806,
    "Lat": 5941252,
    "Lng": 2470710,
    "Stops": "07703-1,02301-1,08101-1",
    "Name": "Linnu tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02301-1",
    "SiriID": 865,
    "Lat": 5940836,
    "Lng": 2470411,
    "Stops": "02302-1,07202-1,07709-1",
    "Name": "Siili",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02302-1",
    "SiriID": 866,
    "Lat": 5940773,
    "Lng": 2470305,
    "Stops": "02301-1,07701-1,03206-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02403-1",
    "SiriID": 868,
    "Lat": 5939976,
    "Lng": 2468885,
    "Stops": "03003-1,03001-1,02402-1",
    "Name": "Vambola",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03003-1",
    "SiriID": 899,
    "Lat": 5939971,
    "Lng": 2469021,
    "Stops": "02403-1,02501-1,03004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02803-1",
    "SiriID": 883,
    "Lat": 5939247,
    "Lng": 2467281,
    "Stops": "02804-1,02806-1",
    "Name": "Üliõpilaste tee",
    "Info": "",
    "Street": "Üliõpilaste tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "02804-1",
    "SiriID": 884,
    "Lat": 5939259,
    "Lng": 2467334,
    "Stops": "02803-1,02808-1,05403-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04804-1",
    "SiriID": 10734,
    "Lat": 5938295,
    "Lng": 2466998,
    "Stops": "04802-1,04704-1,04803-1",
    "Name": "Hiiu",
    "Info": "",
    "Street": 0,
    "Area": "Tallinna linn, Nõmme",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04805-1",
    "SiriID": 10735,
    "Lat": 5938321,
    "Lng": 2467124,
    "Stops": "04802-1,04801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05902-2",
    "SiriID": 12930,
    "Lat": 5937991,
    "Lng": 2471450,
    "Stops": "05902-1",
    "Name": "Karusmarja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05008-1",
    "SiriID": 10738,
    "Lat": 5937761,
    "Lng": 2465763,
    "Stops": "05009-1,05001-1,05006-1",
    "Name": "Kivimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05009-1",
    "SiriID": 10739,
    "Lat": 5937676,
    "Lng": 2465609,
    "Stops": "05008-1,05003-1,05102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04408-1",
    "SiriID": 10744,
    "Lat": 5935433,
    "Lng": 2462756,
    "Stops": "04409-1,04505-1",
    "Name": "Laagri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04409-1",
    "SiriID": 10745,
    "Lat": 5935500,
    "Lng": 2462786,
    "Stops": "04408-1,04406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05007-1",
    "SiriID": 980,
    "Lat": 5937611,
    "Lng": 2464903,
    "Stops": "05004-1,05003-1,03707-1",
    "Name": "Laane",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06412-1",
    "SiriID": 34125,
    "Lat": 5938748,
    "Lng": 2472299,
    "Stops": "06413-1,06406-1",
    "Name": "Liiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06413-1",
    "SiriID": 34252,
    "Lat": 5938801,
    "Lng": 2472351,
    "Stops": "06412-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05408-1",
    "SiriID": 10732,
    "Lat": 5938634,
    "Lng": 2468761,
    "Stops": "05409-1,05401-1,05407-1",
    "Name": "Nõmme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05409-1",
    "SiriID": 10733,
    "Lat": 5938604,
    "Lng": 2468633,
    "Stops": "05408-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04105-1",
    "SiriID": 10741,
    "Lat": 5936936,
    "Lng": 2464087,
    "Stops": "04106-1,04103-1,04203-1",
    "Name": "Pääsküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04106-1",
    "SiriID": 10742,
    "Lat": 5936961,
    "Lng": 2464186,
    "Stops": "04105-1,04104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04204-1",
    "SiriID": 14651,
    "Lat": 5936810,
    "Lng": 2464985,
    "Stops": "04202-1",
    "Name": "Pääsküla Gümnaasium",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06306-1",
    "SiriID": 10729,
    "Lat": 5938897,
    "Lng": 2470459,
    "Stops": "06301-1,06302-1",
    "Name": "Rahumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06307-1",
    "SiriID": 10730,
    "Lat": 5938855,
    "Lng": 2470234,
    "Stops": "06202-1,06302-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03708-1",
    "SiriID": 101,
    "Lat": 5938257,
    "Lng": 2463367,
    "Stops": "03705-1",
    "Name": "Smarten Kalda7C",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05201-1",
    "SiriID": 986,
    "Lat": 5937652,
    "Lng": 2467761,
    "Stops": "05203-1,05202-1,05204-1",
    "Name": "Hiiu",
    "Info": "",
    "Street": "Hiiu tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05204-1",
    "SiriID": 989,
    "Lat": 5937943,
    "Lng": 2467528,
    "Stops": "05201-1,04803-1",
    "Name": "Metsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04801-1",
    "SiriID": 971,
    "Lat": 5938305,
    "Lng": 2467247,
    "Stops": "04805-1,04701-1",
    "Name": "Raudtee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03707-1",
    "SiriID": 937,
    "Lat": 5937926,
    "Lng": 2463703,
    "Stops": "03703-1,03704-1,05007-1",
    "Name": "Hommiku",
    "Info": "",
    "Street": "Hommiku tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03703-1",
    "SiriID": 933,
    "Lat": 5937949,
    "Lng": 2463679,
    "Stops": "03707-1,03705-1",
    "Name": "",
    "Info": "",
    "Street": "Kadaka pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03704-1",
    "SiriID": 934,
    "Lat": 5937893,
    "Lng": 2463641,
    "Stops": "03707-1,03702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03705-1",
    "SiriID": 935,
    "Lat": 5938219,
    "Lng": 2463807,
    "Stops": "03706-1,03708-1,03703-1",
    "Name": "Kalda",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03706-1",
    "SiriID": 936,
    "Lat": 5938242,
    "Lng": 2463829,
    "Stops": "03705-1,03801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03802-1",
    "SiriID": 939,
    "Lat": 5939049,
    "Lng": 2464240,
    "Stops": "03803-1,03605-1",
    "Name": "Nõva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03803-1",
    "SiriID": 940,
    "Lat": 5938975,
    "Lng": 2464185,
    "Stops": "03802-1,03801-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04101-1",
    "SiriID": 943,
    "Lat": 5937024,
    "Lng": 2463194,
    "Stops": "04605-1,04102-1",
    "Name": "Pääsküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04605-1",
    "SiriID": 960,
    "Lat": 5936952,
    "Lng": 2463148,
    "Stops": "04101-1,04607-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04601-1",
    "SiriID": 956,
    "Lat": 5936567,
    "Lng": 2462951,
    "Stops": "04602-1,04604-1",
    "Name": "Rohula",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04602-1",
    "SiriID": 957,
    "Lat": 5936528,
    "Lng": 2462924,
    "Stops": "04601-1,04405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04002-1",
    "SiriID": 942,
    "Lat": 5937347,
    "Lng": 2463353,
    "Stops": "04102-1,04001-1",
    "Name": "Sireli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04102-1",
    "SiriID": 944,
    "Lat": 5937285,
    "Lng": 2463330,
    "Stops": "04002-1,04101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03702-1",
    "SiriID": 932,
    "Lat": 5937599,
    "Lng": 2463499,
    "Stops": "04001-1,03704-1",
    "Name": "Õitse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04001-1",
    "SiriID": 941,
    "Lat": 5937538,
    "Lng": 2463456,
    "Stops": "03702-1,04002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05901-1",
    "SiriID": 1008,
    "Lat": 5938028,
    "Lng": 2471770,
    "Stops": "05902-1,06411-1",
    "Name": "Karusmarja",
    "Info": "",
    "Street": "Männiku tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05902-1",
    "SiriID": 1009,
    "Lat": 5937989,
    "Lng": 2471748,
    "Stops": "05901-1,05902-2,05903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06104-1",
    "SiriID": 1016,
    "Lat": 5936999,
    "Lng": 2471754,
    "Stops": "06105-1,06102-1",
    "Name": "Kauge",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06105-1",
    "SiriID": 1017,
    "Lat": 5937162,
    "Lng": 2471713,
    "Stops": "06104-1,06106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06406-1",
    "SiriID": 1035,
    "Lat": 5938828,
    "Lng": 2471926,
    "Stops": "06407-1,06412-1,06401-1",
    "Name": "Liiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06408-1",
    "SiriID": 1037,
    "Lat": 5938692,
    "Lng": 2471807,
    "Stops": "06409-1,06410-1",
    "Name": "Liiva jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06409-1",
    "SiriID": 1038,
    "Lat": 5938707,
    "Lng": 2471791,
    "Stops": "06408-1,06407-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06101-1",
    "SiriID": 1014,
    "Lat": 5936656,
    "Lng": 2471801,
    "Stops": "06102-1,23225-1",
    "Name": "Männiku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06102-1",
    "SiriID": 1015,
    "Lat": 5936694,
    "Lng": 2471768,
    "Stops": "06101-1,06104-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05903-1",
    "SiriID": 1010,
    "Lat": 5937791,
    "Lng": 2471786,
    "Stops": "05904-1,05902-1",
    "Name": "P. Kerese",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05904-1",
    "SiriID": 1011,
    "Lat": 5937715,
    "Lng": 2471772,
    "Stops": "05903-1,05905-1,05907-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06106-1",
    "SiriID": 1018,
    "Lat": 5937343,
    "Lng": 2471812,
    "Stops": "06107-1,06105-1",
    "Name": "Pihlaka",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06107-1",
    "SiriID": 1019,
    "Lat": 5937443,
    "Lng": 2471784,
    "Stops": "06106-1,05905-1,05908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06401-1",
    "SiriID": 1030,
    "Lat": 5939105,
    "Lng": 2472157,
    "Stops": "06403-1,06406-1",
    "Name": "Risti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06410-1",
    "SiriID": 1039,
    "Lat": 5938508,
    "Lng": 2471762,
    "Stops": "06411-1,06408-1,06303-1",
    "Name": "Värava",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06411-1",
    "SiriID": 1040,
    "Lat": 5938434,
    "Lng": 2471738,
    "Stops": "06410-1,05901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04501-1",
    "SiriID": 3926,
    "Lat": 5936136,
    "Lng": 2461380,
    "Stops": "04502-1,04405-1",
    "Name": "Laaniku",
    "Info": "",
    "Street": "Möldre tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04502-1",
    "SiriID": 3933,
    "Lat": 5936084,
    "Lng": 2461285,
    "Stops": "04501-1,04504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04503-1",
    "SiriID": 3934,
    "Lat": 5935620,
    "Lng": 2461096,
    "Stops": "04504-1,23354-1",
    "Name": "Turbasambla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04504-1",
    "SiriID": 3935,
    "Lat": 5935687,
    "Lng": 2461121,
    "Stops": "04503-1,04502-1,23357-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05105-1",
    "SiriID": 985,
    "Lat": 5937539,
    "Lng": 2467281,
    "Stops": "05203-1,05101-1",
    "Name": "Pargi",
    "Info": "",
    "Street": "Pargi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04803-1",
    "SiriID": 973,
    "Lat": 5938004,
    "Lng": 2466834,
    "Stops": "05005-1,04804-1,05204-1",
    "Name": "Põllu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04701-1",
    "SiriID": 962,
    "Lat": 5938438,
    "Lng": 2467297,
    "Stops": "04702-1,04801-1",
    "Name": "Hiiu jaam",
    "Info": "",
    "Street": "Pärnu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04702-1",
    "SiriID": 963,
    "Lat": 5938458,
    "Lng": 2467320,
    "Stops": "04701-1,05405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06404-1",
    "SiriID": 1033,
    "Lat": 5938974,
    "Lng": 2471098,
    "Stops": "06405-1,06407-1",
    "Name": "Kirde",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06405-1",
    "SiriID": 1034,
    "Lat": 5938976,
    "Lng": 2471077,
    "Stops": "06404-1,06204-1,06301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05001-1",
    "SiriID": 974,
    "Lat": 5937868,
    "Lng": 2465534,
    "Stops": "05002-1,05008-1,04706-1",
    "Name": "Kivimäe jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05002-1",
    "SiriID": 975,
    "Lat": 5937844,
    "Lng": 2465443,
    "Stops": "05001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06305-1",
    "SiriID": 10692,
    "Lat": 5939147,
    "Lng": 2471904,
    "Stops": "06403-1,06705-1",
    "Name": "Kurni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05003-1",
    "SiriID": 976,
    "Lat": 5937618,
    "Lng": 2464985,
    "Stops": "05007-1,05009-1",
    "Name": "Laane",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05004-1",
    "SiriID": 977,
    "Lat": 5937579,
    "Lng": 2464889,
    "Stops": "05007-1,04107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05401-1",
    "SiriID": 990,
    "Lat": 5938746,
    "Lng": 2468674,
    "Stops": "05408-1,05402-1",
    "Name": "Nõmme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05402-1",
    "SiriID": 991,
    "Lat": 5938781,
    "Lng": 2468300,
    "Stops": "05403-1,05401-1,05404-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04403-1",
    "SiriID": 954,
    "Lat": 5935971,
    "Lng": 2463040,
    "Stops": "04405-1,04401-1",
    "Name": "Pärnu maantee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04405-1",
    "SiriID": 955,
    "Lat": 5936000,
    "Lng": 2462988,
    "Stops": "04403-1,04602-1,04501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04103-1",
    "SiriID": 945,
    "Lat": 5936951,
    "Lng": 2463830,
    "Stops": "04607-1,04105-1",
    "Name": "Pääsküla jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04607-1",
    "SiriID": 961,
    "Lat": 5936864,
    "Lng": 2463692,
    "Stops": "04103-1,04605-1,04603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06301-1",
    "SiriID": 1026,
    "Lat": 5938870,
    "Lng": 2470481,
    "Stops": "06306-1,06405-1",
    "Name": "Rahumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06302-1",
    "SiriID": 1027,
    "Lat": 5938869,
    "Lng": 2470372,
    "Stops": "06306-1,06307-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06402-1",
    "SiriID": 1031,
    "Lat": 5939261,
    "Lng": 2472377,
    "Stops": "06403-1,06501-1,06802-1",
    "Name": "Risti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06403-1",
    "SiriID": 1032,
    "Lat": 5939198,
    "Lng": 2472088,
    "Stops": "06401-1,06305-1,06402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05501-1",
    "SiriID": 997,
    "Lat": 5938638,
    "Lng": 2469108,
    "Stops": "05406-1,05502-1",
    "Name": "Roheline",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05502-1",
    "SiriID": 998,
    "Lat": 5938654,
    "Lng": 2469237,
    "Stops": "05501-1,06202-1,05702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04603-1",
    "SiriID": 958,
    "Lat": 5936576,
    "Lng": 2463253,
    "Stops": "04604-1,04302-1,04607-1",
    "Name": "Rohula",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04604-1",
    "SiriID": 959,
    "Lat": 5936528,
    "Lng": 2463133,
    "Stops": "04603-1,04601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05005-1",
    "SiriID": 978,
    "Lat": 5938147,
    "Lng": 2466414,
    "Stops": "05006-1,04704-1,04803-1",
    "Name": "Salve",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05006-1",
    "SiriID": 979,
    "Lat": 5938131,
    "Lng": 2466340,
    "Stops": "05005-1,04703-1,05008-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04505-1",
    "SiriID": 5739,
    "Lat": 5935347,
    "Lng": 2462933,
    "Stops": "04406-1,04408-1,20821-1",
    "Name": "Sillaotsa",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04401-1",
    "SiriID": 952,
    "Lat": 5935603,
    "Lng": 2463109,
    "Stops": "04402-1,04403-1",
    "Name": "Vana-Pääsküla",
    "Info": 0,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04402-1",
    "SiriID": 953,
    "Lat": 5935563,
    "Lng": 2463056,
    "Stops": "04401-1,04406-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04406-1",
    "SiriID": 13358,
    "Lat": 5935449,
    "Lng": 2463017,
    "Stops": "04505-1,04402-1,04409-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05404-1",
    "SiriID": 993,
    "Lat": 5938608,
    "Lng": 2467751,
    "Stops": "05405-1,05402-1",
    "Name": "Võsu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05405-1",
    "SiriID": 994,
    "Lat": 5938616,
    "Lng": 2467728,
    "Stops": "05404-1,04702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04104-1",
    "SiriID": 13201,
    "Lat": 5937284,
    "Lng": 2464338,
    "Stops": "04107-1,04106-1",
    "Name": "Värsi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04107-1",
    "SiriID": 13202,
    "Lat": 5937302,
    "Lng": 2464390,
    "Stops": "04104-1,05004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06203-1",
    "SiriID": 1024,
    "Lat": 5939223,
    "Lng": 2470859,
    "Stops": "06204-1,06702-1",
    "Name": "Kalmistu",
    "Info": "",
    "Street": "Rahumäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06204-1",
    "SiriID": 1025,
    "Lat": 5939189,
    "Lng": 2470847,
    "Stops": "06203-1,06405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06201-1",
    "SiriID": 1022,
    "Lat": 5938928,
    "Lng": 2470232,
    "Stops": "06202-1",
    "Name": "Rahumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05406-1",
    "SiriID": 995,
    "Lat": 5938697,
    "Lng": 2469114,
    "Stops": "05407-1,05501-1",
    "Name": "Kõue",
    "Info": "",
    "Street": "Raudtee tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05407-1",
    "SiriID": 996,
    "Lat": 5938690,
    "Lng": 2469007,
    "Stops": "05406-1,05408-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06202-1",
    "SiriID": 1023,
    "Lat": 5938871,
    "Lng": 2470191,
    "Stops": "06307-1,06201-1,05502-1",
    "Name": "Rahumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04802-1",
    "SiriID": 972,
    "Lat": 5938296,
    "Lng": 2467113,
    "Stops": "04805-1,04804-1",
    "Name": "Raudtee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05403-1",
    "SiriID": 992,
    "Lat": 5938878,
    "Lng": 2468330,
    "Stops": "05402-1,02804-1",
    "Name": "Nõmme",
    "Info": "",
    "Street": "Turu plats",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "03801-1",
    "SiriID": 938,
    "Lat": 5938717,
    "Lng": 2464105,
    "Stops": "04709-1,03803-1,03706-1",
    "Name": "Kadaka puiestee",
    "Info": "",
    "Street": "Tähetorni tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04709-1",
    "SiriID": 970,
    "Lat": 5938712,
    "Lng": 2464172,
    "Stops": "03801-1,04707-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04707-1",
    "SiriID": 968,
    "Lat": 5938651,
    "Lng": 2464639,
    "Stops": "04708-1,04709-1",
    "Name": "Tähetorni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04708-1",
    "SiriID": 969,
    "Lat": 5938648,
    "Lng": 2464660,
    "Stops": "04707-1,04705-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04705-1",
    "SiriID": 966,
    "Lat": 5938406,
    "Lng": 2465209,
    "Stops": "04706-1,04708-1",
    "Name": "Vääna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05706-1",
    "SiriID": 1006,
    "Lat": 5938307,
    "Lng": 2470572,
    "Stops": "05707-1,06304-1",
    "Name": "Haava",
    "Info": "",
    "Street": "Vabaduse pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05707-1",
    "SiriID": 1007,
    "Lat": 5938261,
    "Lng": 2470436,
    "Stops": "05706-1,05701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05202-1",
    "SiriID": 987,
    "Lat": 5937600,
    "Lng": 2467868,
    "Stops": "05201-1,05602-1",
    "Name": "Hiiu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05203-1",
    "SiriID": 988,
    "Lat": 5937585,
    "Lng": 2467716,
    "Stops": "05201-1,05105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05103-1",
    "SiriID": 983,
    "Lat": 5937028,
    "Lng": 2465808,
    "Stops": "05104-1,05102-1",
    "Name": "Hõimu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05104-1",
    "SiriID": 984,
    "Lat": 5937016,
    "Lng": 2465737,
    "Stops": "05103-1,04201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05101-1",
    "SiriID": 981,
    "Lat": 5937367,
    "Lng": 2466720,
    "Stops": "05102-1,05105-1",
    "Name": "J. V. Jannseni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05102-1",
    "SiriID": 982,
    "Lat": 5937293,
    "Lng": 2466460,
    "Stops": "05101-1,05103-1,05009-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05601-1",
    "SiriID": 999,
    "Lat": 5937759,
    "Lng": 2468654,
    "Stops": "05602-1,05705-1",
    "Name": "Kaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05602-1",
    "SiriID": 1000,
    "Lat": 5937752,
    "Lng": 2468545,
    "Stops": "05601-1,05202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06407-1",
    "SiriID": 1036,
    "Lat": 5938823,
    "Lng": 2471827,
    "Stops": "06406-1,06409-1,06404-1",
    "Name": "Liiva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04301-1",
    "SiriID": 949,
    "Lat": 5936490,
    "Lng": 2463820,
    "Stops": "04302-1,04203-1",
    "Name": "Naaritsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04302-1",
    "SiriID": 950,
    "Lat": 5936482,
    "Lng": 2463642,
    "Stops": "04301-1,04603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05704-1",
    "SiriID": 1004,
    "Lat": 5937947,
    "Lng": 2469520,
    "Stops": "05705-1,05702-1",
    "Name": "Nurme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05705-1",
    "SiriID": 1005,
    "Lat": 5937914,
    "Lng": 2469354,
    "Stops": "05704-1,05601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06303-1",
    "SiriID": 1028,
    "Lat": 5938569,
    "Lng": 2471223,
    "Stops": "06304-1,06410-1",
    "Name": "Näituse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06304-1",
    "SiriID": 1029,
    "Lat": 5938531,
    "Lng": 2471101,
    "Stops": "06303-1,05706-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04203-1",
    "SiriID": 948,
    "Lat": 5936586,
    "Lng": 2464267,
    "Stops": "04303-1,04301-1,04105-1",
    "Name": "Raba",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04303-1",
    "SiriID": 951,
    "Lat": 5936602,
    "Lng": 2464380,
    "Stops": "04203-1,04202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05701-1",
    "SiriID": 1001,
    "Lat": 5938088,
    "Lng": 2470038,
    "Stops": "05703-1,05707-1",
    "Name": "Valdeku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05702-1",
    "SiriID": 1002,
    "Lat": 5938049,
    "Lng": 2469884,
    "Stops": "05703-1,05704-1,05502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04201-1",
    "SiriID": 946,
    "Lat": 5936775,
    "Lng": 2465187,
    "Stops": "04202-1,05104-1",
    "Name": "Vikerkaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04202-1",
    "SiriID": 947,
    "Lat": 5936751,
    "Lng": 2465039,
    "Stops": "04204-1,04201-1,04303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05906-1",
    "SiriID": 1013,
    "Lat": 5937771,
    "Lng": 2470651,
    "Stops": "06109-1,06108-1",
    "Name": "Astri",
    "Info": "",
    "Street": "Valdeku tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06109-1",
    "SiriID": 1021,
    "Lat": 5937792,
    "Lng": 2470528,
    "Stops": "05906-1,05703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06601-1",
    "SiriID": 1041,
    "Lat": 5937562,
    "Lng": 2472597,
    "Stops": "06602-1,05907-1",
    "Name": "Kalmu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06602-1",
    "SiriID": 1042,
    "Lat": 5937557,
    "Lng": 2472706,
    "Stops": "06601-1,06603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05905-1",
    "SiriID": 1012,
    "Lat": 5937572,
    "Lng": 2471579,
    "Stops": "06108-1,06107-1,05904-1",
    "Name": "Kraavi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06108-1",
    "SiriID": 1020,
    "Lat": 5937626,
    "Lng": 2471281,
    "Stops": "05905-1,05906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06603-1",
    "SiriID": 1043,
    "Lat": 5937589,
    "Lng": 2473355,
    "Stops": "06604-1,06602-1",
    "Name": "Liiva kalmistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06604-1",
    "SiriID": 1044,
    "Lat": 5937588,
    "Lng": 2473388,
    "Stops": "06603-1,06605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06605-1",
    "SiriID": 1045,
    "Lat": 5937627,
    "Lng": 2474294,
    "Stops": "06606-1,06604-1",
    "Name": "Raku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06606-1",
    "SiriID": 1046,
    "Lat": 5937621,
    "Lng": 2474393,
    "Stops": "06605-1,06608-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05703-1",
    "SiriID": 1003,
    "Lat": 5938029,
    "Lng": 2470017,
    "Stops": "05701-1,05702-1,06109-1",
    "Name": "Valdeku",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05907-1",
    "SiriID": 5748,
    "Lat": 5937560,
    "Lng": 2472043,
    "Stops": "05908-1,05904-1,06601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "05908-1",
    "SiriID": 5749,
    "Lat": 5937557,
    "Lng": 2472039,
    "Stops": "05907-1,06107-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06607-1",
    "SiriID": 115,
    "Lat": 5937612,
    "Lng": 2474670,
    "Stops": "06608-1,06609-1",
    "Name": "Raku",
    "Info": "",
    "Street": "Viljandi maantee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06608-1",
    "SiriID": 116,
    "Lat": 5937702,
    "Lng": 2474613,
    "Stops": "06607-1,06606-1,23187-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06609-1",
    "SiriID": 1047,
    "Lat": 5936749,
    "Lng": 2475649,
    "Stops": "06610-1,06607-1",
    "Name": "Kaarla",
    "Info": "",
    "Street": "Viljandi mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06610-1",
    "SiriID": 1048,
    "Lat": 5936705,
    "Lng": 2475705,
    "Stops": "06609-1,06611-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06611-1",
    "SiriID": 1049,
    "Lat": 5936267,
    "Lng": 2476415,
    "Stops": "06612-1,06610-1",
    "Name": "Raudalu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06612-1",
    "SiriID": 10448,
    "Lat": 5936239,
    "Lng": 2476375,
    "Stops": "06611-1,06613-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06613-1",
    "SiriID": 12985,
    "Lat": 5936170,
    "Lng": 2476499,
    "Stops": "06612-1,22020-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06501-1",
    "SiriID": 12117,
    "Lat": 5939161,
    "Lng": 2472842,
    "Stops": "06502-1,06402-1",
    "Name": "Viljandi maantee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "06502-1",
    "SiriID": 12118,
    "Lat": 5939142,
    "Lng": 2472929,
    "Stops": "06501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04703-1",
    "SiriID": 964,
    "Lat": 5938316,
    "Lng": 2466216,
    "Stops": "04704-1,05006-1,04706-1",
    "Name": "Pidu",
    "Info": "",
    "Street": "Vääna tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04704-1",
    "SiriID": 965,
    "Lat": 5938332,
    "Lng": 2466450,
    "Stops": "04703-1,05005-1,04804-1,02806-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "04706-1",
    "SiriID": 967,
    "Lat": 5938368,
    "Lng": 2465381,
    "Stops": "04705-1,04703-1,05001-1",
    "Name": "Vääna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18304-1",
    "SiriID": 1535,
    "Lat": 5945691,
    "Lng": 2488023,
    "Stops": "16703-1,18303-1",
    "Name": "Helmiku tee",
    "Info": "",
    "Street": "Kelluka tee",
    "Area": "Tallinna linn, Pirita",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18503-1",
    "SiriID": 1538,
    "Lat": 5946860,
    "Lng": 2483999,
    "Stops": "18504-1",
    "Name": "Kalmuse tee",
    "Info": "",
    "Street": "Kloostrimetsa tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18504-1",
    "SiriID": 1539,
    "Lat": 5946846,
    "Lng": 2483869,
    "Stops": "18503-1,18505-1,18502-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18603-1",
    "SiriID": 1548,
    "Lat": 5947129,
    "Lng": 2487693,
    "Stops": "18604-1,18601-1",
    "Name": "Kloostrimetsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18604-1",
    "SiriID": 1549,
    "Lat": 5947126,
    "Lng": 2487714,
    "Stops": "18603-1,18605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18601-1",
    "SiriID": 1546,
    "Lat": 5946825,
    "Lng": 2486728,
    "Stops": "18602-1,18603-1,18303-1,18904-1",
    "Name": "Metsakalmistu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18602-1",
    "SiriID": 1547,
    "Lat": 5946792,
    "Lng": 2486614,
    "Stops": "18601-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18605-1",
    "SiriID": 1550,
    "Lat": 5947251,
    "Lng": 2488414,
    "Stops": "18606-1,18604-1",
    "Name": "Teletorn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18606-1",
    "SiriID": 1551,
    "Lat": 5947256,
    "Lng": 2488489,
    "Stops": "18605-1,18611-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18201-1",
    "SiriID": 1527,
    "Lat": 5945917,
    "Lng": 2484627,
    "Stops": "18202-1,18203-1",
    "Name": "Haljas tee",
    "Info": "",
    "Street": "Kose tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18202-1",
    "SiriID": 1528,
    "Lat": 5945912,
    "Lng": 2484616,
    "Stops": "18201-1,18001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18205-1",
    "SiriID": 1531,
    "Lat": 5945798,
    "Lng": 2486105,
    "Stops": "18301-1,18204-1",
    "Name": "Kose",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18203-1",
    "SiriID": 1529,
    "Lat": 5945866,
    "Lng": 2485079,
    "Stops": "18204-1,18201-1",
    "Name": "Lükati tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18204-1",
    "SiriID": 1530,
    "Lat": 5945828,
    "Lng": 2485381,
    "Stops": "18203-1,18205-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17903-1",
    "SiriID": 1517,
    "Lat": 5945861,
    "Lng": 2482012,
    "Stops": "18101-1,17910-1,18102-1",
    "Name": "Saare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18101-1",
    "SiriID": 1524,
    "Lat": 5945850,
    "Lng": 2482001,
    "Stops": "17903-1,12705-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17906-1",
    "SiriID": 1520,
    "Lat": 5945896,
    "Lng": 2483524,
    "Stops": "18001-1,17905-1",
    "Name": "Sarapuu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18001-1",
    "SiriID": 1523,
    "Lat": 5945893,
    "Lng": 2483552,
    "Stops": "17906-1,18202-1,17504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17904-1",
    "SiriID": 1518,
    "Lat": 5945775,
    "Lng": 2482514,
    "Stops": "18102-1,18103-1",
    "Name": "Tamme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18102-1",
    "SiriID": 1525,
    "Lat": 5945782,
    "Lng": 2482379,
    "Stops": "17904-1,17903-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17905-1",
    "SiriID": 1519,
    "Lat": 5945838,
    "Lng": 2483098,
    "Stops": "18103-1,17906-1",
    "Name": "Urva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18103-1",
    "SiriID": 1526,
    "Lat": 5945813,
    "Lng": 2482979,
    "Stops": "17905-1,17904-1,17506-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18808-1",
    "SiriID": 12027,
    "Lat": 5948675,
    "Lng": 2484129,
    "Stops": "18804-1,18801-1,18910-1",
    "Name": "Kaasiku",
    "Info": "",
    "Street": "Merivälja tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18804-1",
    "SiriID": 12028,
    "Lat": 5948548,
    "Lng": 2484177,
    "Stops": "18808-1,18901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18510-1",
    "SiriID": 1545,
    "Lat": 5948074,
    "Lng": 2484042,
    "Stops": "18901-1,18902-1,18508-1",
    "Name": "Mähe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18901-1",
    "SiriID": 1572,
    "Lat": 5948139,
    "Lng": 2484091,
    "Stops": "18510-1,18804-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18501-1",
    "SiriID": 1536,
    "Lat": 5946672,
    "Lng": 2483266,
    "Stops": "18502-1,17908-1",
    "Name": "Pirita",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18502-1",
    "SiriID": 1537,
    "Lat": 5946721,
    "Lng": 2483382,
    "Stops": "18501-1,18504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18507-1",
    "SiriID": 1542,
    "Lat": 5947364,
    "Lng": 2483701,
    "Stops": "18509-1,18506-1",
    "Name": "Randvere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18508-1",
    "SiriID": 1543,
    "Lat": 5947462,
    "Lng": 2483804,
    "Stops": "18509-1,18510-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18505-1",
    "SiriID": 1540,
    "Lat": 5947034,
    "Lng": 2483491,
    "Stops": "18506-1,18504-1",
    "Name": "Supluse puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18506-1",
    "SiriID": 1541,
    "Lat": 5947109,
    "Lng": 2483570,
    "Stops": "18505-1,18507-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18619-1",
    "SiriID": 1564,
    "Lat": 5947294,
    "Lng": 2491241,
    "Stops": "18620-1,18618-1",
    "Name": "Käära",
    "Info": "",
    "Street": "Muuga tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18620-1",
    "SiriID": 1565,
    "Lat": 5947323,
    "Lng": 2491341,
    "Stops": "18619-1,19901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18617-1",
    "SiriID": 1562,
    "Lat": 5946943,
    "Lng": 2490873,
    "Stops": "18618-1,18616-1",
    "Name": "Ristaia",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18618-1",
    "SiriID": 1563,
    "Lat": 5946954,
    "Lng": 2490891,
    "Stops": "18617-1,18619-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17901-1",
    "SiriID": 1515,
    "Lat": 5946066,
    "Lng": 2481761,
    "Stops": "17910-1",
    "Name": "Lillepi",
    "Info": "",
    "Street": "Pirita tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17902-1",
    "SiriID": 1516,
    "Lat": 5946075,
    "Lng": 2481995,
    "Stops": "17910-1,17909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17910-1",
    "SiriID": 14391,
    "Lat": 5946053,
    "Lng": 2481897,
    "Stops": "17902-1,17901-1,17903-1",
    "Name": "Lillepi park",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "12705-1",
    "SiriID": 5920,
    "Lat": 5945745,
    "Lng": 2481190,
    "Stops": "12706-1,18101-1",
    "Name": "Mälestusvälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17907-1",
    "SiriID": 1521,
    "Lat": 5946357,
    "Lng": 2482710,
    "Stops": "17908-1,17909-1",
    "Name": "Rummu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17908-1",
    "SiriID": 1522,
    "Lat": 5946430,
    "Lng": 2482934,
    "Stops": "17907-1,18501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "17909-1",
    "SiriID": 14379,
    "Lat": 5946217,
    "Lng": 2482490,
    "Stops": "17907-1,17902-1",
    "Name": "Rummukõrtsi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16508-1",
    "SiriID": 11581,
    "Lat": 5946076,
    "Lng": 2490515,
    "Stops": "16504-1,16507-1",
    "Name": "Iru",
    "Info": "",
    "Street": "Pärnamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18624-1",
    "SiriID": 10103,
    "Lat": 5948401,
    "Lng": 2488451,
    "Stops": "18625-1,18610-1",
    "Name": "Kõlviku tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18625-1",
    "SiriID": 10104,
    "Lat": 5948424,
    "Lng": 2488442,
    "Stops": "18624-1,18608-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18615-1",
    "SiriID": 1560,
    "Lat": 5947018,
    "Lng": 2490215,
    "Stops": "18616-1,18614-1",
    "Name": "Laiaküla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18616-1",
    "SiriID": 1561,
    "Lat": 5946919,
    "Lng": 2490281,
    "Stops": "18615-1,18617-1,16507-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18607-1",
    "SiriID": 1552,
    "Lat": 5948624,
    "Lng": 2488220,
    "Stops": "18608-1",
    "Name": "Liilia tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18608-1",
    "SiriID": 1553,
    "Lat": 5948625,
    "Lng": 2488226,
    "Stops": "18607-1,18625-1,19008-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19008-1",
    "SiriID": 3097,
    "Lat": 5949042,
    "Lng": 2488012,
    "Stops": "19007-1,19006-1,18608-1",
    "Name": "Mähe aedlinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18611-1",
    "SiriID": 1556,
    "Lat": 5947452,
    "Lng": 2489646,
    "Stops": "18613-1,18606-1,18609-1",
    "Name": "Pärnamäe tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18612-1",
    "SiriID": 1557,
    "Lat": 5947295,
    "Lng": 2489841,
    "Stops": "18614-1,18613-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18613-1",
    "SiriID": 1558,
    "Lat": 5947414,
    "Lng": 2489703,
    "Stops": "18611-1,18612-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18614-1",
    "SiriID": 1559,
    "Lat": 5947246,
    "Lng": 2489889,
    "Stops": "18612-1,18615-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18609-1",
    "SiriID": 1554,
    "Lat": 5948050,
    "Lng": 2489078,
    "Stops": "18623-1,18611-1",
    "Name": "Äigrumäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18610-1",
    "SiriID": 1555,
    "Lat": 5948102,
    "Lng": 2489007,
    "Stops": "18623-1,18624-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19003-1",
    "SiriID": 1581,
    "Lat": 5948837,
    "Lng": 2487088,
    "Stops": "19004-1,19002-1",
    "Name": "Aianduse tee",
    "Info": "",
    "Street": "Randvere tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19004-1",
    "SiriID": 1582,
    "Lat": 5948849,
    "Lng": 2487154,
    "Stops": "19003-1,19009-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18902-1",
    "SiriID": 1573,
    "Lat": 5947853,
    "Lng": 2484894,
    "Stops": "18903-1,18510-1",
    "Name": "Hämar tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18903-1",
    "SiriID": 1574,
    "Lat": 5947951,
    "Lng": 2485093,
    "Stops": "18902-1,18904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18906-1",
    "SiriID": 1577,
    "Lat": 5948343,
    "Lng": 2485972,
    "Stops": "18907-1,18905-1",
    "Name": "Küüvitsa tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18907-1",
    "SiriID": 1578,
    "Lat": 5948402,
    "Lng": 2486055,
    "Stops": "18906-1,19010-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18904-1",
    "SiriID": 1575,
    "Lat": 5948013,
    "Lng": 2485461,
    "Stops": "18905-1,18903-1,18601-1",
    "Name": "Mähe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18905-1",
    "SiriID": 1576,
    "Lat": 5948027,
    "Lng": 2485507,
    "Stops": "18904-1,18906-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19005-1",
    "SiriID": 1583,
    "Lat": 5949106,
    "Lng": 2488062,
    "Stops": "19006-1,20805-1",
    "Name": "Mähe aedlinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19006-1",
    "SiriID": 1584,
    "Lat": 5949099,
    "Lng": 2488066,
    "Stops": "19005-1,19008-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19007-1",
    "SiriID": 1585,
    "Lat": 5949074,
    "Lng": 2487911,
    "Stops": "19008-1,19009-1,23701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19001-1",
    "SiriID": 1579,
    "Lat": 5948700,
    "Lng": 2486525,
    "Stops": "19010-1,19002-1,18908-1",
    "Name": "Oblika tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19002-1",
    "SiriID": 1580,
    "Lat": 5948710,
    "Lng": 2486632,
    "Stops": "19001-1,19003-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19010-1",
    "SiriID": 14590,
    "Lat": 5948662,
    "Lng": 2486509,
    "Stops": "19001-1,18907-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "19009-1",
    "SiriID": 3993,
    "Lat": 5948969,
    "Lng": 2487560,
    "Stops": "19007-1,19004-1",
    "Name": "Pojengi tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18509-1",
    "SiriID": 1544,
    "Lat": 5947406,
    "Lng": 2483858,
    "Stops": "18508-1,18507-1",
    "Name": "Randvere tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18801-1",
    "SiriID": 1566,
    "Lat": 5948949,
    "Lng": 2483548,
    "Stops": "18802-1,18808-1",
    "Name": "Aia tee",
    "Info": "",
    "Street": "Ranna tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18802-1",
    "SiriID": 1567,
    "Lat": 5948993,
    "Lng": 2483482,
    "Stops": "18801-1,18803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18805-1",
    "SiriID": 1570,
    "Lat": 5949663,
    "Lng": 2482798,
    "Stops": "18809-1,18807-1",
    "Name": "Merivälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18806-1",
    "SiriID": 1571,
    "Lat": 5949711,
    "Lng": 2482865,
    "Stops": "18809-1,23655-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18809-1",
    "SiriID": 3664,
    "Lat": 5949668,
    "Lng": 2482831,
    "Stops": "18805-1,18806-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18803-1",
    "SiriID": 1568,
    "Lat": 5949181,
    "Lng": 2482975,
    "Stops": "18807-1,18802-1",
    "Name": "Tuule tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18807-1",
    "SiriID": 1569,
    "Lat": 5949239,
    "Lng": 2482920,
    "Stops": "18803-1,18805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18908-1",
    "SiriID": 10684,
    "Lat": 5948860,
    "Lng": 2485869,
    "Stops": "19001-1,18910-1",
    "Name": "Kastevarre tee",
    "Info": "",
    "Street": "Ranniku tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18910-1",
    "SiriID": 10682,
    "Lat": 5948908,
    "Lng": 2485012,
    "Stops": "18908-1,18808-1,23654-1",
    "Name": "Merivälja pansion",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18301-1",
    "SiriID": 1532,
    "Lat": 5945803,
    "Lng": 2486239,
    "Stops": "18205-1,16903-1,18302-1",
    "Name": "Kose",
    "Info": "",
    "Street": "Vabaõhukooli tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18303-1",
    "SiriID": 1534,
    "Lat": 5946193,
    "Lng": 2487480,
    "Stops": "18302-1,18304-1,18601-1",
    "Name": "Metsakooli tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18302-1",
    "SiriID": 1533,
    "Lat": 5945995,
    "Lng": 2486971,
    "Stops": "18303-1,18301-1,16904-1",
    "Name": "Nurmiku tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "18623-1",
    "SiriID": 3099,
    "Lat": 5948104,
    "Lng": 2489062,
    "Stops": "18610-1,18609-1",
    "Name": "Äigrumäe",
    "Info": "",
    "Street": "Äigrumäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10608-1",
    "SiriID": 13593,
    "Lat": 5944633,
    "Lng": 2475187,
    "Stops": "10610-1,10603-2,10605-1",
    "Name": "Linnahall",
    "Info": "",
    "Street": 0,
    "Area": "Tallinna linn, Põhja-Tallinn",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10603-2",
    "SiriID": 5868,
    "Lat": 5944402,
    "Lng": 2475181,
    "Stops": "10603-1,10500-1,10608-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10615-1",
    "SiriID": 4560,
    "Lat": 5945095,
    "Lng": 2472175,
    "Stops": "10616-1,09811-1",
    "Name": "Miinisadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21106-1",
    "SiriID": 10137,
    "Lat": 5944050,
    "Lng": 2473695,
    "Stops": "21107-1,10801-1,10802-1",
    "Name": "Tallinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21107-1",
    "SiriID": 10138,
    "Lat": 5944022,
    "Lng": 2473722,
    "Stops": "21106-1,21103-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21107-2",
    "SiriID": 80,
    "Lat": 5943965,
    "Lng": 2473601,
    "Stops": "21102-1,10902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09801-1",
    "SiriID": 1182,
    "Lat": 5945005,
    "Lng": 2471431,
    "Stops": "09802-1,09810-1",
    "Name": "Angerja",
    "Info": "",
    "Street": "Erika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09810-1",
    "SiriID": 1191,
    "Lat": 5945202,
    "Lng": 2471671,
    "Stops": "09811-1,09812-1,09801-1",
    "Name": "Erika",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10604-1",
    "SiriID": 4554,
    "Lat": 5944641,
    "Lng": 2474673,
    "Stops": "10605-1,10610-1",
    "Name": "Kalarand",
    "Info": "",
    "Street": "Kalaranna tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10605-1",
    "SiriID": 4555,
    "Lat": 5944577,
    "Lng": 2474746,
    "Stops": "10604-1,10608-1,10503-1,10501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10611-1",
    "SiriID": 4556,
    "Lat": 5945027,
    "Lng": 2473630,
    "Stops": "10612-1,10614-1",
    "Name": "Lennusadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10612-1",
    "SiriID": 4557,
    "Lat": 5944985,
    "Lng": 2473787,
    "Stops": "10611-1,10305-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10616-1",
    "SiriID": 4561,
    "Lat": 5945080,
    "Lng": 2472308,
    "Stops": "10615-1,10302-1",
    "Name": "Miinisadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10613-1",
    "SiriID": 4558,
    "Lat": 5945115,
    "Lng": 2472722,
    "Stops": "10614-1,10302-1",
    "Name": "Noblessneri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10614-1",
    "SiriID": 4559,
    "Lat": 5945102,
    "Lng": 2472792,
    "Stops": "10613-1,10611-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09602-1",
    "SiriID": 1174,
    "Lat": 5944811,
    "Lng": 2469660,
    "Stops": "09505-1,09606-1",
    "Name": "Kari",
    "Info": "",
    "Street": "Kari tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10003-1",
    "SiriID": 1203,
    "Lat": 5943985,
    "Lng": 2470941,
    "Stops": "10201-1,10004-1",
    "Name": "Kolde puiestee",
    "Info": "",
    "Street": "Kolde pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09903-1",
    "SiriID": 1196,
    "Lat": 5944119,
    "Lng": 2471317,
    "Stops": "10004-1,09904-1",
    "Name": "Maisi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10004-1",
    "SiriID": 1204,
    "Lat": 5944085,
    "Lng": 2471325,
    "Stops": "09903-1,10003-1,10008-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09802-1",
    "SiriID": 1183,
    "Lat": 5945006,
    "Lng": 2470975,
    "Stops": "09912-1,09803-1,09801-1",
    "Name": "Angerja",
    "Info": "",
    "Street": "Kopli tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09803-1",
    "SiriID": 1184,
    "Lat": 5945032,
    "Lng": 2470946,
    "Stops": "09908-1,09802-1,09809-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09908-1",
    "SiriID": 3976,
    "Lat": 5945040,
    "Lng": 2470894,
    "Stops": "09803-1,09913-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09912-1",
    "SiriID": 3982,
    "Lat": 5944995,
    "Lng": 2470969,
    "Stops": "09802-1,09704-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10801-1",
    "SiriID": 1234,
    "Lat": 5944109,
    "Lng": 2473788,
    "Stops": "10009-1,21106-1",
    "Name": "Balti jaam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21101-1",
    "SiriID": 1624,
    "Lat": 5944105,
    "Lng": 2473843,
    "Stops": "10009-1,21105-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10009-1",
    "SiriID": 3979,
    "Lat": 5944117,
    "Lng": 2473819,
    "Stops": "21101-1,10801-1,10506-2",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09004-1",
    "SiriID": 1147,
    "Lat": 5946167,
    "Lng": 2466672,
    "Stops": "09006-1",
    "Name": "Kopli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09005-1",
    "SiriID": 1148,
    "Lat": 5946121,
    "Lng": 2466793,
    "Stops": "09006-1,09007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09006-1",
    "SiriID": 1149,
    "Lat": 5946116,
    "Lng": 2466765,
    "Stops": "09005-1,09004-1,00202-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09007-1",
    "SiriID": 1150,
    "Lat": 5946127,
    "Lng": 2466917,
    "Stops": "09007-2,09005-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09007-2",
    "SiriID": 1151,
    "Lat": 5946105,
    "Lng": 2466963,
    "Stops": "09007-1,09001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10404-1",
    "SiriID": 5658,
    "Lat": 5944604,
    "Lng": 2471738,
    "Stops": "10405-1,09909-1",
    "Name": "Krulli",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10405-1",
    "SiriID": 5659,
    "Lat": 5944625,
    "Lng": 2471702,
    "Stops": "10404-1,09907-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09106-1",
    "SiriID": 1157,
    "Lat": 5945511,
    "Lng": 2468961,
    "Stops": "09405-1,09502-1",
    "Name": "Maleva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09107-1",
    "SiriID": 1158,
    "Lat": 5945513,
    "Lng": 2468907,
    "Stops": "09405-1,09914-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09914-1",
    "SiriID": 3984,
    "Lat": 5945499,
    "Lng": 2468821,
    "Stops": "09107-1,09102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09405-1",
    "SiriID": 5692,
    "Lat": 5945509,
    "Lng": 2468924,
    "Stops": "09107-1,09106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09101-1",
    "SiriID": 1152,
    "Lat": 5945731,
    "Lng": 2467660,
    "Stops": "09109-1,09105-1",
    "Name": "Marati",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09108-1",
    "SiriID": 1774,
    "Lat": 5945810,
    "Lng": 2467552,
    "Stops": "09110-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09109-1",
    "SiriID": 3666,
    "Lat": 5945783,
    "Lng": 2467583,
    "Stops": "09110-1,09101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09110-1",
    "SiriID": 5690,
    "Lat": 5945804,
    "Lng": 2467541,
    "Stops": "09108-1,09109-1,09002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10803-1",
    "SiriID": 5656,
    "Lat": 5944193,
    "Lng": 2472910,
    "Stops": "10804-1,10015-1",
    "Name": "Salme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10804-1",
    "SiriID": 5657,
    "Lat": 5944209,
    "Lng": 2472867,
    "Stops": "10803-1,10401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09001-1",
    "SiriID": 1144,
    "Lat": 5946045,
    "Lng": 2467007,
    "Stops": "09007-2,09008-1",
    "Name": "Sepa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09002-1",
    "SiriID": 1145,
    "Lat": 5945953,
    "Lng": 2467216,
    "Stops": "09008-1,09110-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09003-1",
    "SiriID": 1146,
    "Lat": 5945979,
    "Lng": 2467187,
    "Stops": "09008-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09008-1",
    "SiriID": 5691,
    "Lat": 5945974,
    "Lng": 2467175,
    "Stops": "09003-1,09002-1,09001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09102-1",
    "SiriID": 1153,
    "Lat": 5945466,
    "Lng": 2468277,
    "Stops": "09104-1,09914-1",
    "Name": "Sirbi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09103-1",
    "SiriID": 1154,
    "Lat": 5945504,
    "Lng": 2468129,
    "Stops": "09104-1,09105-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09104-1",
    "SiriID": 1155,
    "Lat": 5945489,
    "Lng": 2468169,
    "Stops": "09103-1,09102-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09105-1",
    "SiriID": 1156,
    "Lat": 5945526,
    "Lng": 2468097,
    "Stops": "09103-1,09101-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09804-1",
    "SiriID": 1185,
    "Lat": 5945306,
    "Lng": 2470464,
    "Stops": "09913-1,09805-1",
    "Name": "Sitsi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09805-1",
    "SiriID": 1186,
    "Lat": 5945332,
    "Lng": 2470439,
    "Stops": "09804-1,09806-1,09702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09913-1",
    "SiriID": 3983,
    "Lat": 5945293,
    "Lng": 2470456,
    "Stops": "09804-1,09908-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10403-1",
    "SiriID": 1220,
    "Lat": 5944165,
    "Lng": 2473218,
    "Stops": "10015-1,10802-1",
    "Name": "Telliskivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10802-1",
    "SiriID": 1235,
    "Lat": 5944160,
    "Lng": 2473275,
    "Stops": "10403-1,21106-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10015-1",
    "SiriID": 3981,
    "Lat": 5944168,
    "Lng": 2473194,
    "Stops": "10403-1,10803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10401-1",
    "SiriID": 1218,
    "Lat": 5944399,
    "Lng": 2472246,
    "Stops": "09911-1,10804-1",
    "Name": "Volta",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10402-1",
    "SiriID": 1219,
    "Lat": 5944421,
    "Lng": 2472213,
    "Stops": "09911-1,09909-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09911-1",
    "SiriID": 3980,
    "Lat": 5944418,
    "Lng": 2472241,
    "Stops": "10402-1,10401-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10506-2",
    "SiriID": 111,
    "Lat": 5944295,
    "Lng": 2473819,
    "Stops": "10306-1,10009-1,10801-2",
    "Name": "Kalamaja",
    "Info": "",
    "Street": "Kotzebue tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09505-1",
    "SiriID": 1172,
    "Lat": 5944877,
    "Lng": 2469632,
    "Stops": "09602-1,09504-1,09501-1",
    "Name": "Kari",
    "Info": "",
    "Street": "Madala tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09501-1",
    "SiriID": 1168,
    "Lat": 5944856,
    "Lng": 2469161,
    "Stops": "09601-1,09505-1",
    "Name": "Randla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09307-1",
    "SiriID": 14382,
    "Lat": 5946370,
    "Lng": 2470162,
    "Stops": "09308-1,09305-1",
    "Name": "Paagi",
    "Info": "",
    "Street": "Paljassaare põik",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09308-1",
    "SiriID": 14383,
    "Lat": 5946356,
    "Lng": 2470183,
    "Stops": "09307-1,09303-1,09310-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09305-1",
    "SiriID": 1163,
    "Lat": 5946497,
    "Lng": 2469958,
    "Stops": "09307-1",
    "Name": "Paljassaare põik",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09301-1",
    "SiriID": 1159,
    "Lat": 5945589,
    "Lng": 2469916,
    "Stops": "09302-1,09703-1,09503-1",
    "Name": "Lahesuu",
    "Info": "",
    "Street": "Paljassaare tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09302-1",
    "SiriID": 1160,
    "Lat": 5945615,
    "Lng": 2469941,
    "Stops": "09301-1,09303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09309-1",
    "SiriID": 5649,
    "Lat": 5946985,
    "Lng": 2471721,
    "Stops": "09310-1,09306-1",
    "Name": "Liisu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09310-1",
    "SiriID": 5650,
    "Lat": 5946926,
    "Lng": 2471643,
    "Stops": "09309-1,09308-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09303-1",
    "SiriID": 1161,
    "Lat": 5946004,
    "Lng": 2470344,
    "Stops": "09304-1,09308-1,09302-1",
    "Name": "Paljassaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09304-1",
    "SiriID": 1162,
    "Lat": 5945993,
    "Lng": 2470436,
    "Stops": "09303-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09306-1",
    "SiriID": 13154,
    "Lat": 5947168,
    "Lng": 2472063,
    "Stops": "09309-1",
    "Name": "Pikakari",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09502-1",
    "SiriID": 1169,
    "Lat": 5945466,
    "Lng": 2468976,
    "Stops": "09403-1,09106-1",
    "Name": "Maleva",
    "Info": "",
    "Street": "Pelguranna tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09604-1",
    "SiriID": 1176,
    "Lat": 5944612,
    "Lng": 2469940,
    "Stops": "09607-1,09606-1,09603-1",
    "Name": "Puhangu",
    "Info": "",
    "Street": "Puhangu tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09605-1",
    "SiriID": 1177,
    "Lat": 5944410,
    "Lng": 2468813,
    "Stops": "09601-1,09603-1",
    "Name": "Supelranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09603-1",
    "SiriID": 1175,
    "Lat": 5944395,
    "Lng": 2469468,
    "Stops": "09604-1,09605-1",
    "Name": "Vihuri",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10801-2",
    "SiriID": 4584,
    "Lat": 5944213,
    "Lng": 2474157,
    "Stops": "21105-6,10505-1,10506-2",
    "Name": "Balti jaam",
    "Info": "",
    "Street": "Põhja pst",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10603-1",
    "SiriID": 1227,
    "Lat": 5944382,
    "Lng": 2475125,
    "Stops": "10603-2,10503-1",
    "Name": "Linnahall",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10501-1",
    "SiriID": 1221,
    "Lat": 5944370,
    "Lng": 2474407,
    "Stops": "10502-1,10605-1",
    "Name": "Põhja puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10502-1",
    "SiriID": 1222,
    "Lat": 5944347,
    "Lng": 2474369,
    "Stops": "10504-1,10501-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10504-1",
    "SiriID": 1224,
    "Lat": 5944365,
    "Lng": 2474334,
    "Stops": "10502-1,09910-1,10505-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10505-1",
    "SiriID": 110,
    "Lat": 5944352,
    "Lng": 2474256,
    "Stops": "10504-1,10801-2",
    "Name": "",
    "Info": "",
    "Street": "Põhja puiestee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09910-1",
    "SiriID": 3978,
    "Lat": 5944395,
    "Lng": 2474344,
    "Stops": "10504-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09601-1",
    "SiriID": 1173,
    "Lat": 5944684,
    "Lng": 2469106,
    "Stops": "09501-1,09605-1",
    "Name": "Pelguranna",
    "Info": "",
    "Street": "Randla tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-7",
    "SiriID": 1626,
    "Lat": 5944108,
    "Lng": 2474072,
    "Stops": "21105-6",
    "Name": "Balti jaam",
    "Info": "",
    "Street": "Rannamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-1",
    "SiriID": 9115,
    "Lat": 5944059,
    "Lng": 2473887,
    "Stops": "21105-2,21103-1",
    "Name": "Balti jaam 1",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-2",
    "SiriID": 9111,
    "Lat": 5944081,
    "Lng": 2473874,
    "Stops": "21105-1,21101-1,21105-3",
    "Name": "Balti jaam 2",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-3",
    "SiriID": 9112,
    "Lat": 5944095,
    "Lng": 2473926,
    "Stops": "21105-4,21105-2",
    "Name": "Balti jaam 3",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-4",
    "SiriID": 9113,
    "Lat": 5944108,
    "Lng": 2473976,
    "Stops": "21105-5,21105-3",
    "Name": "Balti jaam 4",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-5",
    "SiriID": 9114,
    "Lat": 5944115,
    "Lng": 2474000,
    "Stops": "21105-4,21105-6",
    "Name": "Balti jaam 5",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "21105-6",
    "SiriID": 1625,
    "Lat": 5944123,
    "Lng": 2474030,
    "Stops": "21105-5,21105-7,10801-2",
    "Name": "Balti jaam 6",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10007-1",
    "SiriID": 1207,
    "Lat": 5943766,
    "Lng": 2471748,
    "Stops": "10008-1,10006-1,10002-1",
    "Name": "Härjapea",
    "Info": "",
    "Street": "Ristiku tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10008-1",
    "SiriID": 1208,
    "Lat": 5943842,
    "Lng": 2471674,
    "Stops": "10007-1,10004-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09904-1",
    "SiriID": 1197,
    "Lat": 5944159,
    "Lng": 2471336,
    "Stops": "09905-1,09903-1,09906-1",
    "Name": "Maisi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09905-1",
    "SiriID": 1198,
    "Lat": 5944157,
    "Lng": 2471360,
    "Stops": "09904-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09906-1",
    "SiriID": 1199,
    "Lat": 5944524,
    "Lng": 2471095,
    "Stops": "09907-1,09904-1,09902-1",
    "Name": "Rukki",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09907-1",
    "SiriID": 1200,
    "Lat": 5944573,
    "Lng": 2471091,
    "Stops": "09906-1,09704-1,10405-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09704-1",
    "SiriID": 1181,
    "Lat": 5944797,
    "Lng": 2471139,
    "Stops": "09912-1,09907-1",
    "Name": "Standard",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10005-1",
    "SiriID": 1205,
    "Lat": 5943544,
    "Lng": 2472077,
    "Stops": "10006-1,08910-1",
    "Name": "Telliskivi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10006-1",
    "SiriID": 1206,
    "Lat": 5943609,
    "Lng": 2472016,
    "Stops": "10005-1,10007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09404-1",
    "SiriID": 1167,
    "Lat": 5944941,
    "Lng": 2469903,
    "Stops": "09701-1,09401-1",
    "Name": "Niidi",
    "Info": "",
    "Street": "Sitsi tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09701-1",
    "SiriID": 1178,
    "Lat": 5944954,
    "Lng": 2469942,
    "Stops": "09404-1,09703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09702-1",
    "SiriID": 1179,
    "Lat": 5945309,
    "Lng": 2470323,
    "Stops": "09703-1,09805-1",
    "Name": "Sitsi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09703-1",
    "SiriID": 1180,
    "Lat": 5945313,
    "Lng": 2470305,
    "Stops": "09702-1,09301-1,09701-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10305-1",
    "SiriID": 1216,
    "Lat": 5944604,
    "Lng": 2473813,
    "Stops": "10306-1,10612-1",
    "Name": "Kalamaja",
    "Info": "",
    "Street": "Soo tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09606-1",
    "SiriID": 156,
    "Lat": 5944707,
    "Lng": 2470033,
    "Stops": "09604-1,09602-1",
    "Name": "Ehte",
    "Info": "",
    "Street": "Sõle tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09607-1",
    "SiriID": 157,
    "Lat": 5944592,
    "Lng": 2470108,
    "Stops": "09604-1,09902-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09901-1",
    "SiriID": 1194,
    "Lat": 5944019,
    "Lng": 2470737,
    "Stops": "10201-1,10202-1",
    "Name": "Kolde puiestee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10201-1",
    "SiriID": 1210,
    "Lat": 5943937,
    "Lng": 2470788,
    "Stops": "09901-1,10003-1,10002-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09402-1",
    "SiriID": 1165,
    "Lat": 5945196,
    "Lng": 2469400,
    "Stops": "09503-1,09504-1",
    "Name": "Lõime",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09503-1",
    "SiriID": 1170,
    "Lat": 5945235,
    "Lng": 2469316,
    "Stops": "09402-1,09403-1,09301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09401-1",
    "SiriID": 1164,
    "Lat": 5944930,
    "Lng": 2469793,
    "Stops": "09504-1,09404-1",
    "Name": "Madala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09504-1",
    "SiriID": 1171,
    "Lat": 5944932,
    "Lng": 2469760,
    "Stops": "09401-1,09505-1,09402-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09403-1",
    "SiriID": 1166,
    "Lat": 5945449,
    "Lng": 2469031,
    "Stops": "09502-1,09503-1",
    "Name": "Maleva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09902-1",
    "SiriID": 1195,
    "Lat": 5944311,
    "Lng": 2470431,
    "Stops": "10202-1,09607-1,09906-1",
    "Name": "Nisu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10202-1",
    "SiriID": 1211,
    "Lat": 5944275,
    "Lng": 2470435,
    "Stops": "09902-1,09901-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10001-1",
    "SiriID": 1201,
    "Lat": 5943311,
    "Lng": 2471573,
    "Stops": "08908-1,08909-1,10101-1",
    "Name": "Sõle",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10002-1",
    "SiriID": 1202,
    "Lat": 5943605,
    "Lng": 2471172,
    "Stops": "10101-1,10007-1,10201-1",
    "Name": "Ädala",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10101-1",
    "SiriID": 1209,
    "Lat": 5943546,
    "Lng": 2471205,
    "Stops": "10002-1,10001-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "11003-1",
    "SiriID": 1239,
    "Lat": 5943620,
    "Lng": 2472806,
    "Stops": "11001-1,10901-1",
    "Name": "Kelmiküla",
    "Info": "",
    "Street": "Tehnika tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09811-1",
    "SiriID": 1192,
    "Lat": 5945221,
    "Lng": 2471850,
    "Stops": "09810-1,10615-1",
    "Name": "Erika",
    "Info": "",
    "Street": "Tööstuse tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09812-1",
    "SiriID": 1193,
    "Lat": 5945297,
    "Lng": 2471698,
    "Stops": "09810-1,09808-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10306-1",
    "SiriID": 1217,
    "Lat": 5944464,
    "Lng": 2473711,
    "Stops": "10305-1,10506-2",
    "Name": "Kalamaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09808-1",
    "SiriID": 1189,
    "Lat": 5945364,
    "Lng": 2471262,
    "Stops": "09809-1,09812-1",
    "Name": "Karjamaa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09809-1",
    "SiriID": 1190,
    "Lat": 5945380,
    "Lng": 2471167,
    "Stops": "09808-1,09807-1,09803-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10303-1",
    "SiriID": 1214,
    "Lat": 5944765,
    "Lng": 2472887,
    "Stops": "10304-1,09909-1",
    "Name": "Kungla",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10304-1",
    "SiriID": 1215,
    "Lat": 5944772,
    "Lng": 2472891,
    "Stops": "10303-1,10301-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09806-1",
    "SiriID": 1187,
    "Lat": 5945378,
    "Lng": 2470511,
    "Stops": "09805-1,09807-1",
    "Name": "Tööstuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09807-1",
    "SiriID": 1188,
    "Lat": 5945394,
    "Lng": 2470623,
    "Stops": "09806-1,09809-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10301-1",
    "SiriID": 1212,
    "Lat": 5944901,
    "Lng": 2472569,
    "Stops": "10302-1,10304-1",
    "Name": "Volta",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "10302-1",
    "SiriID": 1213,
    "Lat": 5944986,
    "Lng": 2472418,
    "Stops": "10616-1,10301-1,10613-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "09909-1",
    "SiriID": 3977,
    "Lat": 5944463,
    "Lng": 2472220,
    "Stops": "10402-1,10404-1,10303-1",
    "Name": "",
    "Info": "",
    "Street": "Volta tn",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23512-1",
    "SiriID": 14184,
    "Lat": 5923071,
    "Lng": 2425142,
    "Stops": "23502-1,23505-1,21917-1",
    "Name": "Vasalemma kool",
    "Info": 4,
    "Street": 0,
    "Area": "Vasalemma vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23508-1",
    "SiriID": 4809,
    "Lat": 5924692,
    "Lng": 2424154,
    "Stops": "23501-1",
    "Name": "Ämari",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23509-1",
    "SiriID": 12294,
    "Lat": 5923844,
    "Lng": 2429894,
    "Stops": "23510-1,23513-1",
    "Name": "Vasalemma",
    "Info": "",
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23510-1",
    "SiriID": 12295,
    "Lat": 5923863,
    "Lng": 2429935,
    "Stops": "23509-1,23516-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "22820-1",
    "SiriID": 4814,
    "Lat": 5922755,
    "Lng": 2416567,
    "Stops": "22839-1,23519-1",
    "Name": "Kasepere",
    "Info": "",
    "Street": "Keila - Haapsalu mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23501-1",
    "SiriID": 4806,
    "Lat": 5923127,
    "Lng": 2424928,
    "Stops": "23502-1,23508-1,23520-1",
    "Name": "Murrangu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23502-1",
    "SiriID": 4807,
    "Lat": 5923110,
    "Lng": 2425031,
    "Stops": "23501-1,23512-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23503-1",
    "SiriID": 4810,
    "Lat": 5923108,
    "Lng": 2420312,
    "Stops": "23504-1,23519-1",
    "Name": "Rummu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23504-1",
    "SiriID": 4811,
    "Lat": 5923092,
    "Lng": 2420461,
    "Stops": "23503-1,23520-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23505-1",
    "SiriID": 4803,
    "Lat": 5923814,
    "Lng": 2427424,
    "Stops": "23506-1,23512-1",
    "Name": "Vasalemma",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23506-1",
    "SiriID": 4805,
    "Lat": 5923891,
    "Lng": 2427551,
    "Stops": "23505-1,23518-1,23514-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23513-1",
    "SiriID": 14166,
    "Lat": 5923787,
    "Lng": 2429768,
    "Stops": "23509-1,23515-1",
    "Name": "Vasalemma jaam",
    "Info": "",
    "Street": 0,
    "Area": "Vasalemma vald, Vasalemma alevik",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23703-1",
    "SiriID": 3990,
    "Lat": 5954619,
    "Lng": 2483992,
    "Stops": "23643-1,23659-1",
    "Name": "Kelvingi",
    "Info": 2,
    "Street": "",
    "Area": "Viimsi vald",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23647-1",
    "SiriID": 4515,
    "Lat": 5951277,
    "Lng": 2482722,
    "Stops": "23648-1",
    "Name": "Kõrgemäe ei kasuta",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23648-1",
    "SiriID": 4516,
    "Lat": 5951266,
    "Lng": 2482666,
    "Stops": "23647-1,23618-1,23682-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23708-1",
    "SiriID": 5705,
    "Lat": 5954304,
    "Lng": 2479679,
    "Stops": "23631-1,23630-1",
    "Name": "Suureniidu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23706-1",
    "SiriID": 28,
    "Lat": 5963715,
    "Lng": 2501195,
    "Stops": "",
    "Name": "Kelnase sadam",
    "Info": 4,
    "Street": "-",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23705-1",
    "SiriID": 29,
    "Lat": 5955141,
    "Lng": 2486665,
    "Stops": "23645-1",
    "Name": "Leppneeme sadam",
    "Info": 2,
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23683-1",
    "SiriID": 11577,
    "Lat": 5950517,
    "Lng": 2484258,
    "Stops": "23684-1,23678-1,23650-1",
    "Name": "Aiandi",
    "Info": "",
    "Street": "Aiandi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23684-1",
    "SiriID": 11578,
    "Lat": 5950473,
    "Lng": 2484232,
    "Stops": "23683-1,23690-1,23655-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23678-1",
    "SiriID": 10301,
    "Lat": 5950735,
    "Lng": 2484892,
    "Stops": "23679-1,23683-1",
    "Name": "Viievelle tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23679-1",
    "SiriID": 10302,
    "Lat": 5950751,
    "Lng": 2484972,
    "Stops": "23678-1,23685-1,23702-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23603-1",
    "SiriID": 11822,
    "Lat": 5950152,
    "Lng": 2485812,
    "Stops": "23699-1,23690-1,23701-1",
    "Name": "Soosepa",
    "Info": "",
    "Street": "Jõhvika tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23638-1",
    "SiriID": 4216,
    "Lat": 5956087,
    "Lng": 2480268,
    "Stops": "23636-1",
    "Name": "Rohuneeme",
    "Info": "",
    "Street": "Kalmistu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23692-1",
    "SiriID": 12901,
    "Lat": 5950994,
    "Lng": 2483245,
    "Stops": "50000-1,23709-1",
    "Name": "Kaluri tee",
    "Info": "",
    "Street": "Kaluri tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23687-1",
    "SiriID": 12858,
    "Lat": 5954629,
    "Lng": 2488184,
    "Stops": "23688-1,23671-1",
    "Name": "Lepalinnu",
    "Info": "",
    "Street": "Kiigemäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23688-1",
    "SiriID": 12859,
    "Lat": 5954654,
    "Lng": 2488169,
    "Stops": "23687-1,23663-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23670-1",
    "SiriID": 10287,
    "Lat": 5954356,
    "Lng": 2488317,
    "Stops": "23671-1,23667-1",
    "Name": "Mäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23671-1",
    "SiriID": 10288,
    "Lat": 5954418,
    "Lng": 2488335,
    "Stops": "23670-1,23687-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23702-1",
    "SiriID": 227,
    "Lat": 5950554,
    "Lng": 2485495,
    "Stops": "23604-1,23679-1",
    "Name": "Lageda tee",
    "Info": "",
    "Street": "Lageda tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23645-1",
    "SiriID": 4229,
    "Lat": 5955044,
    "Lng": 2486637,
    "Stops": "23705-1,23649-1,23662-1",
    "Name": "Leppneeme sadam",
    "Info": "",
    "Street": "Leppneeme sadama tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23662-1",
    "SiriID": 10285,
    "Lat": 5954795,
    "Lng": 2487554,
    "Stops": "23663-1,23645-1",
    "Name": "Leppneeme",
    "Info": "",
    "Street": "Leppneeme tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23663-1",
    "SiriID": 10286,
    "Lat": 5954774,
    "Lng": 2487639,
    "Stops": "23662-1,23688-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23649-1",
    "SiriID": 5500,
    "Lat": 5954707,
    "Lng": 2486386,
    "Stops": "23653-1,23645-1",
    "Name": "Leppneeme lasteaed",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23653-1",
    "SiriID": 9609,
    "Lat": 5954670,
    "Lng": 2486214,
    "Stops": "23649-1,23695-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23664-1",
    "SiriID": 10299,
    "Lat": 5951339,
    "Lng": 2485081,
    "Stops": "23665-1,23686-1",
    "Name": "Lubjamäe",
    "Info": "",
    "Street": "Lubja tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23665-1",
    "SiriID": 10300,
    "Lat": 5951363,
    "Lng": 2485063,
    "Stops": "23664-1,23656-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23685-1",
    "SiriID": 11575,
    "Lat": 5950989,
    "Lng": 2485399,
    "Stops": "23686-1,23679-1",
    "Name": "Paekaare",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23686-1",
    "SiriID": 11576,
    "Lat": 5951007,
    "Lng": 2485405,
    "Stops": "23685-1,23664-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23656-1",
    "SiriID": 12420,
    "Lat": 5951633,
    "Lng": 2484751,
    "Stops": "23619-1,23665-1",
    "Name": "Vainu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23619-1",
    "SiriID": 14338,
    "Lat": 5951664,
    "Lng": 2484754,
    "Stops": "23656-1,23641-1,23614-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23666-1",
    "SiriID": 10289,
    "Lat": 5954031,
    "Lng": 2488511,
    "Stops": "23667-1,23668-1",
    "Name": "Luhaääre",
    "Info": "",
    "Street": "Luhaääre tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23667-1",
    "SiriID": 10290,
    "Lat": 5954118,
    "Lng": 2488429,
    "Stops": "23666-1,23670-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23695-1",
    "SiriID": 13721,
    "Lat": 5954741,
    "Lng": 2485740,
    "Stops": "23615-1,23653-1",
    "Name": "Lännemäe",
    "Info": "",
    "Street": "Lännemäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20809-1",
    "SiriID": 2866,
    "Lat": 5949647,
    "Lng": 2491130,
    "Stops": "20808-1,20811-1",
    "Name": "Aiaotsa",
    "Info": "",
    "Street": "Muuga tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20808-1",
    "SiriID": 2867,
    "Lat": 5949684,
    "Lng": 2491100,
    "Stops": "20809-1,20807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20812-1",
    "SiriID": 2870,
    "Lat": 5948746,
    "Lng": 2492761,
    "Stops": "20105-1,20810-1",
    "Name": "Muuga",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20811-1",
    "SiriID": 2868,
    "Lat": 5949080,
    "Lng": 2491534,
    "Stops": "20810-1,20809-1",
    "Name": "Vesiniidu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20810-1",
    "SiriID": 2869,
    "Lat": 5949070,
    "Lng": 2491596,
    "Stops": "20811-1,20812-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23654-1",
    "SiriID": 9603,
    "Lat": 5949768,
    "Lng": 2484983,
    "Stops": "23691-1,20801-1,18910-1",
    "Name": "Tulbiaia",
    "Info": "",
    "Street": "Nelgi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20801-1",
    "SiriID": 1604,
    "Lat": 5949886,
    "Lng": 2483691,
    "Stops": "23655-1,23654-1",
    "Name": "Viimsi vallamaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23655-1",
    "SiriID": 9604,
    "Lat": 5949910,
    "Lng": 2483638,
    "Stops": "20801-1,18806-1,23684-1,20823-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23689-1",
    "SiriID": 12415,
    "Lat": 5950136,
    "Lng": 2486901,
    "Stops": "23701-1",
    "Name": "Kuremarja",
    "Info": "",
    "Street": "Pärnamäe tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23701-1",
    "SiriID": 236,
    "Lat": 5950111,
    "Lng": 2486895,
    "Stops": "23689-1,23603-1,19007-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23613-1",
    "SiriID": 4220,
    "Lat": 5952181,
    "Lng": 2485139,
    "Stops": "23614-1,23612-1",
    "Name": "Farmi",
    "Info": "",
    "Street": "Randvere tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23614-1",
    "SiriID": 4221,
    "Lat": 5952125,
    "Lng": 2484999,
    "Stops": "23613-1,23619-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20807-1",
    "SiriID": 2864,
    "Lat": 5949805,
    "Lng": 2490269,
    "Stops": "20806-1,23698-1,20808-1",
    "Name": "Hansunõmme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20806-1",
    "SiriID": 2865,
    "Lat": 5949790,
    "Lng": 2490169,
    "Stops": "20807-1,20805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23697-1",
    "SiriID": 14610,
    "Lat": 5949969,
    "Lng": 2490377,
    "Stops": "23698-1,23602-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23698-1",
    "SiriID": 14611,
    "Lat": 5949889,
    "Lng": 2490336,
    "Stops": "23697-1,20807-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23661-1",
    "SiriID": 10280,
    "Lat": 5951288,
    "Lng": 2483564,
    "Stops": "23709-1,23642-1",
    "Name": "Karulaugu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23709-1",
    "SiriID": 5719,
    "Lat": 5951249,
    "Lng": 2483507,
    "Stops": "23661-1,23692-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23641-1",
    "SiriID": 4218,
    "Lat": 5951891,
    "Lng": 2484080,
    "Stops": "23642-1,23619-1",
    "Name": "Krillimäe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23642-1",
    "SiriID": 4219,
    "Lat": 5951885,
    "Lng": 2484016,
    "Stops": "23641-1,23661-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20805-1",
    "SiriID": 2862,
    "Lat": 5949417,
    "Lng": 2489370,
    "Stops": "20804-1,20806-1,19005-1",
    "Name": "Metsakasti",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20804-1",
    "SiriID": 2863,
    "Lat": 5949410,
    "Lng": 2489382,
    "Stops": "20805-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23611-1",
    "SiriID": 4223,
    "Lat": 5952852,
    "Lng": 2485813,
    "Stops": "23612-1,23609-1",
    "Name": "Metskitse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23612-1",
    "SiriID": 4224,
    "Lat": 5952785,
    "Lng": 2485735,
    "Stops": "23611-1,23613-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20802-1",
    "SiriID": 1605,
    "Lat": 5950619,
    "Lng": 2482979,
    "Stops": "23710-1,23650-1",
    "Name": "Mõisapargi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20823-1",
    "SiriID": 3117,
    "Lat": 5950404,
    "Lng": 2482818,
    "Stops": "23704-1,23655-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23605-1",
    "SiriID": 4197,
    "Lat": 5951322,
    "Lng": 2490503,
    "Stops": "23606-1,23608-1",
    "Name": "Randvere aedlinn",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23606-1",
    "SiriID": 4198,
    "Lat": 5951264,
    "Lng": 2490583,
    "Stops": "23605-1,23707-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23601-1",
    "SiriID": 4193,
    "Lat": 5950602,
    "Lng": 2490560,
    "Stops": "23602-1,23707-1",
    "Name": "Randvere keskus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23602-1",
    "SiriID": 4194,
    "Lat": 5950393,
    "Lng": 2490473,
    "Stops": "23601-1,23697-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23609-1",
    "SiriID": 4225,
    "Lat": 5953032,
    "Lng": 2487592,
    "Stops": "23610-1,23611-1",
    "Name": "Tammneeme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23610-1",
    "SiriID": 4226,
    "Lat": 5953042,
    "Lng": 2487596,
    "Stops": "23609-1,23677-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23707-1",
    "SiriID": 5592,
    "Lat": 5950861,
    "Lng": 2490605,
    "Stops": "23601-1,23606-1",
    "Name": "Teekalda",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23607-1",
    "SiriID": 4227,
    "Lat": 5952211,
    "Lng": 2489371,
    "Stops": "23608-1,23674-1",
    "Name": "Tädu kuusk",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23608-1",
    "SiriID": 4228,
    "Lat": 5952103,
    "Lng": 2489568,
    "Stops": "23607-1,23605-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23650-1",
    "SiriID": 4354,
    "Lat": 5950806,
    "Lng": 2483160,
    "Stops": "20803-1,20802-1,23683-1",
    "Name": "Viimsi kool",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "50000-1",
    "SiriID": 5652,
    "Lat": 5950944,
    "Lng": 2483253,
    "Stops": "23692-1,20803-1",
    "Name": "Viimsi kool - ajutine",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "20803-1",
    "SiriID": 1606,
    "Lat": 5950906,
    "Lng": 2483017,
    "Stops": "23682-1,23650-1,50000-1",
    "Name": "Viimsi keskus",
    "Info": "",
    "Street": "Ravi tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23682-1",
    "SiriID": 9811,
    "Lat": 5950910,
    "Lng": 2482948,
    "Stops": "20803-1,23616-1,23648-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23615-1",
    "SiriID": 150,
    "Lat": 5954705,
    "Lng": 2485399,
    "Stops": "23643-1,23695-1",
    "Name": "Kivimäe",
    "Info": "",
    "Street": "Reinu tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23643-1",
    "SiriID": 151,
    "Lat": 5954717,
    "Lng": 2485373,
    "Stops": "23615-1,23703-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23657-1",
    "SiriID": 9708,
    "Lat": 5953874,
    "Lng": 2479494,
    "Stops": "23658-1,23630-1",
    "Name": "Liivaranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23658-1",
    "SiriID": 9709,
    "Lat": 5953878,
    "Lng": 2479448,
    "Stops": "23657-1,23629-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23659-1",
    "SiriID": 9670,
    "Lat": 5954201,
    "Lng": 2480897,
    "Stops": "23660-1,23703-1",
    "Name": "Vardi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23660-1",
    "SiriID": 9671,
    "Lat": 5954210,
    "Lng": 2480892,
    "Stops": "23659-1,23696-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23618-1",
    "SiriID": 4188,
    "Lat": 5951263,
    "Lng": 2481968,
    "Stops": "23652-1,23648-1,23616-1",
    "Name": "Haabneeme rand",
    "Info": "",
    "Street": "Rohuneeme tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23652-1",
    "SiriID": 9608,
    "Lat": 5951269,
    "Lng": 2481949,
    "Stops": "23618-1,23621-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23617-1",
    "SiriID": 4185,
    "Lat": 5950767,
    "Lng": 2482219,
    "Stops": "23616-1,23710-1",
    "Name": "Kalatööstuse",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23616-1",
    "SiriID": 4186,
    "Lat": 5950820,
    "Lng": 2482251,
    "Stops": "23617-1,23682-1,23618-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23623-1",
    "SiriID": 4200,
    "Lat": 5951973,
    "Lng": 2480869,
    "Stops": "23622-1,23620-1",
    "Name": "Lahe",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23628-1",
    "SiriID": 4206,
    "Lat": 5953666,
    "Lng": 2479360,
    "Stops": "23629-1,23627-1",
    "Name": "Liivaranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23629-1",
    "SiriID": 4207,
    "Lat": 5953670,
    "Lng": 2479350,
    "Stops": "23628-1,23658-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23621-1",
    "SiriID": 4189,
    "Lat": 5951603,
    "Lng": 2481489,
    "Stops": "23620-1,23652-1",
    "Name": "Meremärgi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23620-1",
    "SiriID": 4190,
    "Lat": 5951637,
    "Lng": 2481437,
    "Stops": "23621-1,23623-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23634-1",
    "SiriID": 4212,
    "Lat": 5955509,
    "Lng": 2479375,
    "Stops": "23635-1,23632-1",
    "Name": "Püünsi",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23635-1",
    "SiriID": 4213,
    "Lat": 5955581,
    "Lng": 2479380,
    "Stops": "23634-1,23637-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23624-1",
    "SiriID": 4201,
    "Lat": 5952713,
    "Lng": 2479771,
    "Stops": "23625-1,23626-1",
    "Name": "Ranna",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23625-1",
    "SiriID": 4202,
    "Lat": 5952633,
    "Lng": 2479762,
    "Stops": "23624-1,23673-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23622-1",
    "SiriID": 4199,
    "Lat": 5952102,
    "Lng": 2480585,
    "Stops": "23672-1,23623-1",
    "Name": "Rannarahva",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23672-1",
    "SiriID": 10281,
    "Lat": 5952165,
    "Lng": 2480421,
    "Stops": "23622-1,23673-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23632-1",
    "SiriID": 4210,
    "Lat": 5955089,
    "Lng": 2479517,
    "Stops": "23633-1,23634-1",
    "Name": "Rohuneeme kauplus",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23633-1",
    "SiriID": 4211,
    "Lat": 5955051,
    "Lng": 2479491,
    "Stops": "23632-1,23631-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23636-1",
    "SiriID": 4214,
    "Lat": 5955855,
    "Lng": 2479783,
    "Stops": "23637-1,23638-1",
    "Name": "Rohuneeme sadam",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23637-1",
    "SiriID": 4215,
    "Lat": 5955837,
    "Lng": 2479747,
    "Stops": "23636-1,23635-1,id123360",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23630-1",
    "SiriID": 4208,
    "Lat": 5954259,
    "Lng": 2479620,
    "Stops": "23708-1,23657-1",
    "Name": "Suureniidu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23631-1",
    "SiriID": 4209,
    "Lat": 5954344,
    "Lng": 2479656,
    "Stops": "23708-1,23696-1,23633-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23626-1",
    "SiriID": 4204,
    "Lat": 5953153,
    "Lng": 2479584,
    "Stops": "23627-1,23624-1",
    "Name": "Suurevälja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23627-1",
    "SiriID": 4205,
    "Lat": 5953164,
    "Lng": 2479550,
    "Stops": "23626-1,23628-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23673-1",
    "SiriID": 10282,
    "Lat": 5952246,
    "Lng": 2480281,
    "Stops": "23672-1,23625-1",
    "Name": "Tamme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23668-1",
    "SiriID": 10291,
    "Lat": 5953720,
    "Lng": 2489069,
    "Stops": "23669-1,23666-1",
    "Name": "Pällu",
    "Info": "",
    "Street": "Tammneeme tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23669-1",
    "SiriID": 10292,
    "Lat": 5953702,
    "Lng": 2489059,
    "Stops": "23668-1,23680-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23674-1",
    "SiriID": 10295,
    "Lat": 5952960,
    "Lng": 2488532,
    "Stops": "23675-1,23676-1,23607-1",
    "Name": "Tammneeme",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23675-1",
    "SiriID": 10296,
    "Lat": 5952973,
    "Lng": 2488575,
    "Stops": "23674-1,23681-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23676-1",
    "SiriID": 10297,
    "Lat": 5953048,
    "Lng": 2487869,
    "Stops": "23677-1,23674-1",
    "Name": "Tammneeme tee",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23677-1",
    "SiriID": 10298,
    "Lat": 5953040,
    "Lng": 2487827,
    "Stops": "23676-1,23610-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23680-1",
    "SiriID": 10293,
    "Lat": 5953434,
    "Lng": 2488888,
    "Stops": "23681-1,23669-1",
    "Name": "Väljaotsa",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23681-1",
    "SiriID": 10294,
    "Lat": 5953373,
    "Lng": 2488911,
    "Stops": "23680-1,23675-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23690-1",
    "SiriID": 12416,
    "Lat": 5950058,
    "Lng": 2484990,
    "Stops": "23691-1,23603-1,23684-1",
    "Name": "Tulika",
    "Info": "",
    "Street": "Tulbiaia tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23691-1",
    "SiriID": 12417,
    "Lat": 5950039,
    "Lng": 2484979,
    "Stops": "23690-1,23654-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "16507-1",
    "SiriID": 1617,
    "Lat": 5946088,
    "Lng": 2490639,
    "Stops": "16508-1,47105-1,18616-1",
    "Name": "Iru",
    "Info": 0,
    "Street": "Vana-Narva mnt",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23693-1",
    "SiriID": 113,
    "Lat": 5954484,
    "Lng": 2480355,
    "Stops": "23696-1",
    "Name": "Püünsi kool",
    "Info": 2,
    "Street": "Vanapere tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23696-1",
    "SiriID": 114,
    "Lat": 5954476,
    "Lng": 2480345,
    "Stops": "23693-1,23631-1,23660-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23604-1",
    "SiriID": 11823,
    "Lat": 5950410,
    "Lng": 2485818,
    "Stops": "23699-1,23702-1",
    "Name": "Vehema",
    "Info": "",
    "Street": "Vehema tee",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23699-1",
    "SiriID": 149,
    "Lat": 5950401,
    "Lng": 2485823,
    "Stops": "23604-1,23603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23704-1",
    "SiriID": 4421,
    "Lat": 5950526,
    "Lng": 2482736,
    "Stops": "23710-1,20823-1",
    "Name": "Poepeatus",
    "Info": "",
    "Street": "VIIMSI - ROHUNEEME",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "23710-1",
    "SiriID": 5828,
    "Lat": 5950557,
    "Lng": 2482719,
    "Stops": "23704-1,20802-1,23617-1",
    "Name": "Poepeatus AJUTINE",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000123-1",
    "SiriID": 9916,
    "Lat": 5901369,
    "Lng": 2505422,
    "Stops": "7000634-1",
    "Name": "Kaiu",
    "Info": 5,
    "Street": 0,
    "Area": "Rapla MV",
    "City": "Kaiu vald",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000204-1",
    "SiriID": 9911,
    "Lat": 5904187,
    "Lng": 2503130,
    "Stops": "7000203-1",
    "Name": "Kolgu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000203-1",
    "SiriID": 9912,
    "Lat": 5904209,
    "Lng": 2503129,
    "Stops": "7000204-1,7000201-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000201-1",
    "SiriID": 13291,
    "Lat": 5904233,
    "Lng": 2503253,
    "Stops": "7000202-1,7000203-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000202-1",
    "SiriID": 13292,
    "Lat": 5904205,
    "Lng": 2503296,
    "Stops": "7000201-1,7000633-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000214-1",
    "SiriID": 13352,
    "Lat": 5904466,
    "Lng": 2511669,
    "Stops": "7000213-1,7000265-1",
    "Name": "Kuimetsa rahvamaja",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000213-1",
    "SiriID": 9905,
    "Lat": 5904611,
    "Lng": 2511600,
    "Stops": "7000214-1,7000562-1",
    "Name": "",
    "Info": "",
    "Street": "Kose - Purila",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000256-1",
    "SiriID": 9899,
    "Lat": 5907929,
    "Lng": 2516587,
    "Stops": "7000257-1,22328-1",
    "Name": "Küüni",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000257-1",
    "SiriID": 9900,
    "Lat": 5907918,
    "Lng": 2516616,
    "Stops": "7000256-1,7000603-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000264-1",
    "SiriID": 9907,
    "Lat": 5904074,
    "Lng": 2508825,
    "Stops": "7000265-1,7000634-1",
    "Name": "Lagevainu",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000265-1",
    "SiriID": 9908,
    "Lat": 5904061,
    "Lng": 2508858,
    "Stops": "7000264-1,7000214-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000562-1",
    "SiriID": 9903,
    "Lat": 5905669,
    "Lng": 2513782,
    "Stops": "7000563-1,7000213-1",
    "Name": "Urke",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000563-1",
    "SiriID": 9904,
    "Lat": 5905662,
    "Lng": 2513826,
    "Stops": "7000562-1,7000604-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000604-1",
    "SiriID": 9901,
    "Lat": 5906705,
    "Lng": 2515475,
    "Stops": "7000603-1,7000563-1",
    "Name": "Vaopere",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000603-1",
    "SiriID": 9902,
    "Lat": 5906709,
    "Lng": 2515528,
    "Stops": "7000604-1,7000257-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000633-1",
    "SiriID": 9909,
    "Lat": 5903764,
    "Lng": 2507113,
    "Stops": "7000634-1,7000202-1",
    "Name": "Villema",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  },
  {
    "ID": "7000634-1",
    "SiriID": 9910,
    "Lat": 5903751,
    "Lng": 2507126,
    "Stops": "7000633-1,7000264-1,7000123-1",
    "Name": "",
    "Info": "",
    "Street": "",
    "Area": "",
    "City": "",
    "Pikas2018.12.24": ""
  }
]

export default stopData;